import React, { useState, } from 'react';
import OverflowTip from './OverflowTip';
import {
  Typography,
  Grid,
  Tooltip,
  Theme,
} from '@mui/material';
import {
  AddCircle,
  Delete,
} from '@mui/icons-material';
import { Icon, } from '../../Icon';
import {
  useTheme,
  makeStyles,
} from '@mui/styles';

const useStyles = (theme: any) =>({
  schemaItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  schemaItemText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    marginLeft: 5,
    width: 170,
  },
  schemaItemIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tableItem: {
    padding: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableWraper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tableItemText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    marginLeft: 5,
    width: 158,
  },
  tableIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  columnItem: {
    padding: 1,
    display: 'flex',
    alignItems: 'center',
  },
  columnItemText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginLeft: 5,
    width: 175,
  },
  columnIcon: {
    fontSize: 20,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
});

const useClasses = makeStyles((theme: Theme) => ({
  tableDeleteIcon: {
    color: 'rgba(0,0,0,0.30)',
    marginRight: 4,
    fontSize: 22,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  schemaItemDeleteIcon: {
    color: 'rgba(0,0,0,0.30)',
    fontSize: 22,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  schemaAddIcon: {
    color: theme.palette.primary.main,
    marginRight: 4,
    '&:hover': {
      color: '#83abe7',
    },
  },
}));


const SchemaTreeItem = ({
  label,
  handleAddTable,
  handleDeleteSchema,
}: {
  label: string,
  handleAddTable: () => void,
  handleDeleteSchema: () => void,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const classes = useClasses();

  const [hover, setHover,] = useState(false);
  return (
    <div style={styles.schemaItem} onMouseEnter={() => setHover(true)} onMouseLeave={(() => setHover(false))}>
      <Typography variant={'caption'} style={styles.schemaItemText}>
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
      <div style={styles.schemaItemIcons}>
        { hover &&
      <Tooltip title={'Delete Schema'}>
        <Delete
          className={classes.schemaItemDeleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteSchema();
          }}
        />
      </Tooltip>
        }
        <Tooltip title={'Add Table'}>
          <AddCircle
            className={classes.schemaAddIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleAddTable();
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

const TableTreeItem = ({ label, handleDelete, }: {label: string, handleDelete: () => void} ) => {
  const [hover, setHover,] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const classes = useClasses();
  return (
    <Grid container style={styles.tableItem} onMouseEnter={() => setHover(true)} onMouseLeave={(() => setHover(false))}>
      <div style={styles.tableWraper}>
        <Icon type="table" color="#D67E0A" size="md" />
        <Typography style={styles.tableItemText} variant={'caption'}>
          <OverflowTip>
            {label}
          </OverflowTip>
        </Typography>
      </div>
      { hover &&
      <Tooltip title={'Delete Table'}>
        <Delete
          className={classes.tableDeleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        />
      </Tooltip>
      }
    </Grid>
  );
};

const ColumnTreeItem = ({ label, }: {label: string} ) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <div style={styles.columnItem}>
      <Icon type="column" color="#1E5CAE" size="md" />
      <Typography
        variant={'caption'}
        style={styles.columnItemText}
      >
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
    </div>
  );
};

export const TreeItems = {
  Schema: SchemaTreeItem,
  Table: TableTreeItem,
  Column: ColumnTreeItem,
};
