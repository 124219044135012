import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useStyles, } from './styles';
import {
  GraphConfigType,
  GroupedSnapshotsType,
  RunRecordsGraphType,
} from '../interfaces';
import {
  Bar,
  VerticalLine,
} from './Elements';
import './TotalRecordsGraph.scss';
import useDraggableScroll from 'use-draggable-scroll';

const RenderTotalRecords = ({ graphConfig, data, max, } : RunRecordsGraphType) => {
  const {
    initialX,
    sectionWidth,
  } = graphConfig;

  let len = 0;
  let prevLen = 0;

  const calculatePercentiles = (max: number): [number, number, number, number, number] => {
    if (max < 20) {
      return [0, 5, 10, 15, 20,];
    }
    if (max < 60) {
      return [0, 15, 30, 45, 60,];
    }
    if (max < 100) {
      return [0, 25, 50, 75, 100,];
    }
    if (max < 200) {
      return [0, 50, 100, 150, 200,];
    }

    const power = max.toString().length - 1;
    const base = Math.pow(10, power);
    const maxPercentile = base + Math.ceil((max - base) / (base / 10)) * (base / 10);

    const step = maxPercentile / 4;

    return [0, step, step * 2, step * 3, maxPercentile,];
  };

  const getText = (number: number) => {
    if (number > 999999999) {
      return `${number / 1000000000}b`;
    } else if (number > 999999) {
      return `${number / 1000000}m`;
    } else if (number > 999) {
      return `${number / 1000}k`;
    } else {return number.toString();}
  };

  const percentiles = calculatePercentiles(max);

  return (
    <g>
      {
        data.map((runRecords, index) => {
          prevLen = len;
          len += runRecords.expanded ? runRecords.snapshots.length : 2;
          return runRecords.snapshots.map((record, idx) => {
            let y = 180;
            const totalRecords = record.properties?.totalRecords ? record.properties.totalRecords : 0;
            y = y - Math.round(totalRecords / (percentiles[4] / 180));
            if (idx === 0 && index === 0) {
              return (
                <g key={index * idx}>
                  <Tooltip
                    arrow
                    placement={'top'}
                    title={
                      <p>
                        Total Records: {totalRecords}
                      </p>
                    }
                  >
                    <g>
                      <Bar
                        className={`bar0`}
                        h={183}
                        w={sectionWidth - 6}
                        x={initialX + 4}
                        y={y}
                      />;
                      <VerticalLine
                        h={180}
                        w={1}
                        x={initialX + sectionWidth}
                        y={0}
                        dashed={5}
                        color={'#9d9d9d'}
                      />
                    </g>
                  </Tooltip>
                </g>
              );
            }
            if (idx === 0) {
              return (
                <g key={index * idx}>
                  <Tooltip
                    arrow
                    placement={'top'}
                    title={
                      <p>
                        Total Records: {totalRecords}
                      </p>
                    }
                  >
                    <g>
                      <Bar
                        className={`bar0`}
                        h={183}
                        w={sectionWidth - 6}
                        x={initialX + 4 + sectionWidth * prevLen}
                        y={y}
                      />;
                      <VerticalLine
                        h={180}
                        w={1}
                        x={initialX + sectionWidth * (prevLen + 1)}
                        y={0}
                        dashed={5}
                        color={'#9d9d9d'}
                      />
                    </g>
                  </Tooltip>
                </g>
              );
            } else {
              return (
                <g key={index * idx}>
                  {
                    runRecords.expanded ? (
                      <Tooltip
                        arrow
                        placement={'top'}
                        title={
                          <p>
                            Total Records: {totalRecords}
                          </p>
                        }
                      >
                        <g>
                          <Bar
                            className={`bar0`}
                            h={183}
                            w={sectionWidth - 6}
                            x={initialX + 4 + sectionWidth * (prevLen + idx)}
                            y={y}
                          />
                          <VerticalLine
                            h={180}
                            w={1}
                            x={initialX + sectionWidth * (prevLen + idx + 1)}
                            y={0}
                            dashed={5}
                            color={'#9d9d9d'}
                          />
                        </g>
                      </Tooltip>
                    ) : idx === 1 && (
                      <Tooltip
                        arrow
                        placement={'top'}
                        title={
                          <p>
                            Total Records: {totalRecords}
                          </p>
                        }
                      >
                        <g>
                          <Bar
                            className={`bar0`}
                            h={183}
                            w={sectionWidth - 6}
                            x={initialX + 4 + sectionWidth * (len - 1)}
                            y={y}
                          />
                          <VerticalLine
                            h={180}
                            w={1}
                            x={initialX + sectionWidth * len}
                            y={0}
                            dashed={5}
                            color={'#9d9d9d'}
                          />
                        </g>
                      </Tooltip>
                    )
                  }
                </g>
              );
            }
          });
        })
      }
      {
        calculatePercentiles(max).map((step, index) => (
          <text key={index} x={5} y={(4 - index) * 42 + 12}>{getText(step)}</text>
        ))
      }
    </g>
  );
};

const TotalRecordsGraph = ({ groupedSnapshots, }:{groupedSnapshots: Array<GroupedSnapshotsType>, }) => {
  const classes = useStyles();
  const [scrollElements, setScrollElements,] = useState<{el1: HTMLElement | null, el2: HTMLElement | null }>({ el1: null,
    el2: null, });
  const ref = useRef<HTMLDivElement>(null);
  const { onMouseDown, } = useDraggableScroll(ref, { direction: 'horizontal', });

  const config: GraphConfigType = {
    initialX: 70,
    initialY: 180,
    sectionHeight: -70,
    sectionWidth: 140,
    circleRadius: 11,
  };

  const getExpanded = () => {
    let x = 0;
    groupedSnapshots.forEach((rr) => {
      if (rr.expanded) {
        x += rr.snapshots.length;
      } else {
        x += 2;
      }
    });
    return x;
  };

  const getMax = () => {
    let max = 0;
    groupedSnapshots.forEach((g) => {
      g.snapshots.forEach((s) => {
        if (s.properties?.totalRecords && s.properties?.totalRecords > max) {
          max = s.properties.totalRecords;
        }
      });
    });
    return max;
  };

  useEffect(() => {
    setScrollElements({
      el1: document.getElementById('changehistorygraph'),
      el2: document.getElementById('runrecordsgraph'),
    });
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.totalRecordsGraphContainer}>
        <Typography variant={'subtitle2'} className={classes.totalRecordsGraphLabel}>
          Total records
        </Typography>
        <div
          id={'runrecordsgraph'}
          ref={ref}
          className={classes.totalRecordsGraphSvg}
          onMouseDown={onMouseDown}
          onScroll={() => {
            if (scrollElements.el1 && scrollElements.el2) {
              scrollElements.el1.scrollLeft = scrollElements.el2.scrollLeft;
            }
          }}
        >
          {
            groupedSnapshots.length > 0 && (
              <div>
                <svg
                  id="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  className="run-records-graph"
                  style={{
                    minWidth: `${(config.sectionWidth * getExpanded() + 64) * 0.0625}rem`,
                    maxWidth: '100vw',
                    overflow: 'auto',
                    flexDirection: 'column-reverse',
                    height: config.initialY,
                  }}
                >
                  <RenderTotalRecords
                    graphConfig={config}
                    data={groupedSnapshots}
                    max={getMax()}
                  />
                </svg>
              </div>
            )
          }
        </div>
      </Grid>
    </Grid>
  );
};

export { TotalRecordsGraph, };
