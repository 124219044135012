import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useStyles, } from './styles';
import {
  Box,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  Divider,
  MenuItem,
  Paper,
  Typography,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { Interfaces, } from '../../../config';
import {
  InputRules,
  OutputRules,
} from './Rules';
import {
  alertsServices,
  catalogsServices,
} from '../../../services';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useSnackbar, } from 'notistack';
import { AxiosResponse, } from 'axios';
import LoadingComponent from '../../Loading';
import CatalogIcons from '../../Catalogs/CatalogIcons/CatalogIcons';
import { helpers, } from '../../../utils';
import {
  StyledButton,
  StyledTextField,
} from '../../Reusable';
import { Save, } from '@mui/icons-material';

const emptyAlert: Interfaces.Alert = {
  name: '',
  description: '',
  relationId: '',
  relationType: 'CATALOG',
  inputRule: {
    name: InputRules[0]?.name || '',
    rule: {},
  },
  outputRule: {
    name: OutputRules[0]?.name || '',
    rule: {},
  },
  active: true,
  version: 1,
};

const AlertForm = () => {
  const classes = useStyles();
  const history = useNavigate();
  const { id, } = useParams<{ id?: string }>();
  const { enqueueSnackbar, } = useSnackbar();
  const [loading, setLoading,] = useState(false);
  const [alert, setAlert,] = useState<Interfaces.Alert>(emptyAlert);
  const [selectedInputRule, setSelectedInputRule,] = useState(InputRules[0]);
  const [selectedOutputRule, setSelectedOutputRule,] = useState(OutputRules[0]);
  const [catalogs, setCatalogs,] = useState<Array<Interfaces.InputCatalogMetadata>>([]);

  const fetchCatalogs = () => {
    catalogsServices.fetchCatalogsWithDataSources()
      .then((response: AxiosResponse<Array<Interfaces.InputCatalogMetadata>>) => {
        setCatalogs(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };



  useEffect(() => {
    if (id) {
      setLoading(true);
      alertsServices.fetchAlertById(id)
        .then((response: AxiosResponse<{data: Interfaces.Alert}>) => {
          const _tmpAlert = response.data.data;
          setAlert(_tmpAlert);
          setSelectedInputRule(InputRules.find((rule) => rule.name === _tmpAlert.inputRule.name) || InputRules[0]);
          setSelectedOutputRule(OutputRules.find((rule) => rule.name === _tmpAlert.outputRule.name) || OutputRules[0]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchCatalogs();
  },[]);

  const isValid = useMemo(() => (
    alert.name !== ''
    && alert.relationId !== ''
    && selectedInputRule.isValid
    && selectedOutputRule.isValid
  ), [alert, selectedOutputRule, selectedInputRule,]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Paper elevation={3}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding="0 20px 20px">
          <Box className={classes.wpapper}>
            <Typography variant="body1" className={classes.title}>General info:</Typography>
            <StyledTextField
              id="alert-name"
              label=""
              variant="outlined"
              placeholder="Enter name"
              onChange={(event) => {
                setAlert({
                  ...alert,
                  name: event.target.value,
                });
              }}
              value={alert.name}
              size={'small'}
              fullWidth
            />
            <StyledTextField
              id="alert-description"
              multiline
              minRows={3}
              value={alert.description}
              onChange={(event) => {
                setAlert({
                  ...alert,
                  description: event.target.value,
                });
              }}
              placeholder="Enter description"
              variant="outlined"
              fullWidth
            />
            <StyledTextField
              variant="outlined"
              fullWidth
              select
              size="small"
              label="Select catalog"
              value={alert.relationId}
              onChange={(event) => {
                setAlert({
                  ...alert,
                  relationId: event.target.value,
                });
              }}
            >
              {
                catalogs.map((catalog) => (
                  <MenuItem
                    key={catalog.name}
                    value={catalog.id}
                  >
                    <CatalogIcons catalog={catalog} source={true} run={true}/>
                  </MenuItem>
                ))
              }
            </StyledTextField>
          </Box>
          <Box className={classes.wpapper}>
            <FormControl>
              <FormLabel id="input-rule-radio-buttons-group-label"
                classes={{
                  root: classes.test,
                  focused: classes.focused,
                }}
              >
                <Typography variant="body1" className={classes.title}>Input Rules:</Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="input-rule-radio-buttons-group-label"
                name="input-rule-radio-buttons-group"
                value={alert.inputRule.name}
                onChange={(_event, value) => {
                  setAlert({
                    ...alert,
                    inputRule: {
                      name: value,
                      rule: {},
                    },
                  });
                }}
              >
                {
                  InputRules.map((Rule) => (
                    <FormControlLabel
                      key={Rule.name}
                      value={Rule.name}
                      onChange={() => {
                        setSelectedInputRule(Rule);
                      }}
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={Rule.label}
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
            <selectedInputRule.Component
              alert={alert}
              setAlert={setAlert}
              handleValidation={(isValid: boolean) => {
                setSelectedInputRule({
                  ...selectedInputRule,
                  isValid: isValid,
                });
              }}
            />
          </Box>
          <Box width="800px" marginTop={1}>
            <Divider orientation="horizontal" flexItem />
          </Box>
          <Box className={classes.wpapper}>
            <FormControl>
              <FormLabel
                id="exection-rule-radio-buttons-group-label"
                classes={{
                  root: classes.test,
                  focused: classes.focused,
                }}
              >
                <Typography variant="body1" className={classes.title}>Execution Rules:</Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="exection-rule-radio-buttons-group-label"
                name="execution-rule-radio-buttons-group"
                value={alert.outputRule.name}
                onChange={(_event, value) => {
                  setAlert({
                    ...alert,
                    outputRule: {
                      name: value,
                      rule: {},
                    },
                  });
                }}
              >
                {
                  OutputRules.map((Rule) => (
                    <FormControlLabel
                      key={Rule.name}
                      value={Rule.name}
                      onChange={() => {
                        setSelectedOutputRule(Rule);
                      }}
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={Rule.label}
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
            <selectedOutputRule.Component
              alert={alert}
              setAlert={setAlert}
              handleValidation={(isValid: boolean) => {
                setSelectedOutputRule({
                  ...selectedOutputRule,
                  isValid: isValid,
                });
              }}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop={2} width="800px">
            <Tooltip title={isValid ? '' : 'Please make sure to fill all the fields'}>
              <div>
                {
                  id ? (
                    <StyledButton
                      type="button"
                      variant="contained"
                      disabled={!isValid}
                      style={ { marginLeft: '20px', } }
                      color="primary"
                      size="large"
                      onClick={() => {
                        if (isValid) {
                          alertsServices.updateAlert(alert, id)
                            .then(() => {
                              history('/alerts');
                              enqueueSnackbar('Alert successfully updated!', { variant: 'success', });
                            });
                        }
                      }}
                      startIcon={loading ?
                        <CircularProgress
                          color="inherit"
                          style={{
                            width: 16,
                            height: 11,
                          }} />
                        :
                        <Save />}
                    >
                      Update
                    </StyledButton>
                  ) : (
                    <StyledButton
                      type="button"
                      variant="contained"
                      style={ { marginLeft: '20px', } }
                      disabled={!isValid}
                      color="primary"
                      size="large"
                      onClick={() => {
                        if (isValid) {
                          alertsServices.addAlert(alert)
                            .then(() => {
                              history('/alerts');
                              enqueueSnackbar('Alert successfully added!', { variant: 'success', });
                            });
                        }
                      }}
                      startIcon={loading ?
                        <CircularProgress
                          color="inherit"
                          style={{
                            width: 16,
                            height: 11,
                          }} />
                        :
                        <Save />}
                    >
                      Save
                    </StyledButton>
                  )
                }
              </div>
            </Tooltip>
            <StyledButton
              variant="outlined"
              size="large"
              className={classes.btn}
              onClick={() => {
                history('/alerts');
              }}
            >
              Cancel
            </StyledButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export { AlertForm, };
