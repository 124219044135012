import React, {
  useEffect,
  useState,
} from 'react';
import { Interfaces, } from '../../../../config';
import { ReusableModal, } from './ReusableModal';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../helpers';
import { catalogsServices, } from '../../../../services';

const ExtractDialog = ({
  catalog,
  isOpen,
  handleClose,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { enqueueSnackbar, } = useSnackbar();
  const [value, setValue,] = useState('');
  const [loading, setLoading,] = useState(true);

  useEffect(() => {
    if (isOpen) {
      const filtered = helpers.extractInputSchemaBySelection(JSON.parse(JSON.stringify(catalog)));
      const cleansed = helpers.cleanseSchema(filtered);
      catalogsServices.previewCatalog({
        ...catalog,
        schema: cleansed,
      })
        .then((response) => {
          setValue(JSON.stringify(response.data, null, 2));
          setLoading(false);
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {
      setLoading(true);
    };
  }, [isOpen,]);

  return (
    <ReusableModal
      open={isOpen}
      title={'Extract'}
      value={value}
      loading={loading}
      mode={'javascript'}
      handleClose={() => handleClose()}
    />
  );
};

export { ExtractDialog, };
