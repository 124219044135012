import React from 'react';
import classes from './EvolutionDetails.module.css';
import { BackButton, } from './BackButton';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Checklist, } from '@mui/icons-material';
import {
  GroupedSnapshotsType,
  SchemaChange,
} from '../interfaces';
import { Interfaces, } from '../../../config';
import moment from 'moment';

type Props = {
  onClose: () => void;
  schemaToCompare: {snapshotA: Interfaces.Schema, snapshotB: Interfaces.Schema, changes: SchemaChange};
  snapshots: GroupedSnapshotsType[];
  // eslint-disable-next-line no-unused-vars
  handleCompare: (snapshotA: Interfaces.Schema, snapshotB: Interfaces.Schema) => void;
}

const Header: React.FC<Props> = ({ onClose, schemaToCompare, snapshots, handleCompare, }) => {
  const [changeListDialog, setChangeListDialog,] = React.useState<boolean>(false);
  const { changes, } = schemaToCompare;

  const newMappedSnapshots = React.useMemo(() => {
    const mapped = snapshots.map((snapshot) => snapshot.snapshots[0]);
    return mapped.filter((sn) => (sn.version ?? 0) > (schemaToCompare.snapshotB.version ?? 0));
  }, [snapshots, schemaToCompare,]);

  const oldMappedSnapshots = React.useMemo(() => {
    const mapped = snapshots.map((snapshot) => snapshot.snapshots[0]);
    return mapped.filter((sn) => (sn.version ?? 0) < (schemaToCompare.snapshotA.version ?? 0));
  }, [snapshots, schemaToCompare,]);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerSectionLarge}>
          <BackButton onClick={onClose} />
          <Tooltip title={'View Change List'}>
            <Button
              variant="outlined"
              sx={{
                color: 'blue',
                borderColor: 'blue',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 255, 0.1)',
                  borderColor: 'blue',
                },
                '&:active': {
                  boxShadow: 'none',
                  backgroundColor: 'rgba(0, 0, 255, 0.2)',
                },
                marginLeft: 'auto',
              }}
              endIcon={<Checklist color={'primary'} />}
              onClick={() => setChangeListDialog(true)}
            >
              Change List
            </Button>
          </Tooltip>
        </div>
        <div className={classes.headerSectionSmall}>
          <div className={classes.dateTime}>
            <div>
              Date: {moment(schemaToCompare.snapshotA.properties.createdDate).format('DD/MM/YYYY')}
            </div>
            <div>
              Time: {moment(schemaToCompare.snapshotA.properties.createdDate).format('HH:mm')}
            </div>
          </div>
          <TextField select size={'small'} value={schemaToCompare.snapshotA.version} className={classes.textField}>
            {newMappedSnapshots.map((snapshot, index) => (
              <MenuItem
                key={index}
                value={snapshot.version}
                onClick={() => handleCompare(snapshot, schemaToCompare.snapshotB)}
              >
                Generation {snapshot.version}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.headerSectionSmall}>
          <div className={classes.dateTime}>
            <div>
              Date: {moment(schemaToCompare.snapshotB.properties.createdDate).format('DD/MM/YYYY')}
            </div>
            <div>
              Time: {moment(schemaToCompare.snapshotB.properties.createdDate).format('HH:mm')}
            </div>
          </div>
          <TextField select size={'small'} value={schemaToCompare.snapshotB.version} className={classes.textField}>
            {oldMappedSnapshots.map((snapshot, index) => (
              <MenuItem
                key={index}
                value={snapshot.version}
                onClick={() => handleCompare(schemaToCompare.snapshotA, snapshot)}
              >
                Generation {snapshot.version}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <Dialog fullWidth open={changeListDialog} onClose={() => setChangeListDialog(false)}>
        <DialogTitle>Change List</DialogTitle>
        <DialogContent>
          <div style={{ marginLeft: 10, }}>
            <Typography>Changed fields: {changes.changedFields.length}</Typography>
            {
              changes.changedFields.map((field, index) => (
                <Typography variant={'subtitle2'} key={index}>{`\xa0\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0\xa0${field.name}`}</Typography>
              ))
            }
            <br />
            <Typography>Added fields: {changes.addedFields.length}</Typography>
            {
              changes.addedFields.map((field, index) => (
                <Typography variant={'subtitle2'} key={index}>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0${field.name}`}</Typography>
              ))
            }
            <br />
            <Typography>Removed fields: {changes.removedFields.length}</Typography>
            {
              changes.removedFields.map((field, index) => (
                <Typography variant={'subtitle2'} key={index}>{`\xa0\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0\xa0${field.name}`}</Typography>
              ))
            }
            <br />
            {
              changes.tagsChange !== 0
              && (
                changes.tagsChange > 0
                  ? (
                    <Typography>{changes.tagsChange} tag\s added</Typography>
                  ) : (
                    <Typography>{changes.tagsChange * -1} tag\s removed</Typography>
                  )
              )
            }
            {changes.tagsChange !== 0 && <br />}
            {
              changes.labelsChange !== 0
              && (
                changes.labelsChange > 0
                  ? (
                    <Typography>{changes.labelsChange} label\s added</Typography>
                  ) : (
                    <Typography>{changes.labelsChange * -1} label\s removed</Typography>
                  )
              )
            }
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { Header, };
