import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import {
  Interfaces,
  Properties,
} from '../../../../config';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import moment from 'moment';
import { useAuth, } from '../../../../context';
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Delete,
  Description,
  Edit,
  PlayArrow,
} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { catalogsServices, } from '../../../../services';
import ModalConfirmation from '../../../ModalConfirmation/ModalConfirmation';
import { useSnackbar, } from 'notistack';
import { DataSourceLogo, } from '../../../DatasourceIcon';
import {
  DropDownMenu,
  MenuItemType,
} from '../../../Reusable/DropDownMenu';

interface CatalogTableRowProps {
  catalog: Interfaces.InputCatalogMetadata;
  index: number;
  filters: any;
  rowsPerPage: number;
  classes: any;
  runningCatalogs: string[];
  // eslint-disable-next-line no-unused-vars
  runCatalog: (catalogId: string) => void;
  fetchCatalogs: () => void;
}

const CatalogTableRow = ({ catalog, index, filters, rowsPerPage, classes, runningCatalogs, runCatalog, fetchCatalogs, } : CatalogTableRowProps) => {
  const history = useNavigate();
  const { enqueueSnackbar, } = useSnackbar();
  const [catalogToDelete, setCatalogToDelete,] = React.useState<Interfaces.InputCatalogMetadata | null>(null);
  const [loadDelete, setLoadDelete,] = React.useState<boolean>(false);

  const { isAuthorized, } = useAuth();
  const [writeAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_WRITE'));
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_READ'));
  const [readDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_READ'));
  const [adminAccess,] = React.useState(isAuthorized('ADAPTIVE_ADMIN'));
  const [executeAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_EXECUTE'));
  const [executeDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_EXECUTE'));

  const menuItems = React.useMemo(() => {
    const items: MenuItemType[] = [];
    if (catalog.dataSourceDescription === 'external') {
      return [];
    }
    if (writeAccess && readDSAccess && readAccess && executeDSAccess) {
      items.push({
        text: 'Edit',
        icon: <Edit />,
        onClick: () => history(`/catalog/edit/${catalog.id}`),
      });
    }
    if (catalog.lastRun) {
      items.push({
        text: 'Docs',
        icon: <Description />,
        onClick: () => window.open(`${Properties.BASE_URL}/datalineage/catalog/${catalog.id}/index.html`, '_blank', 'noreferrer'),
      });
    }
    if (adminAccess) {
      items.push({
        text: 'Delete',
        icon: loadDelete ? <CircularProgress /> : <Delete />,
        onClick: () => !loadDelete && setCatalogToDelete(catalog),
      });
    }
    return items;
  }, [catalog, writeAccess, readAccess, readDSAccess, executeAccess, executeDSAccess, runningCatalogs, adminAccess, loadDelete,]);

  const deleteCatalog = (catalogId: string) => {
    setLoadDelete(true);
    catalogsServices.deleteCatalog(catalogId)
      .then(() => {
        fetchCatalogs();
        enqueueSnackbar(`Catalog with id ${catalogId} deleted`, { variant: 'info', });
      })
      .catch(() => {
        enqueueSnackbar(`Failed to delete catalog with id = '${catalogId}'!`, { variant: 'error', });
      })
      .finally(() => {
        setLoadDelete(false);
      });
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={catalog.name}
      >
        <TableCell>{index + 1 + filters.page * rowsPerPage}</TableCell>
        <TableCell align="right" style={{ maxWidth: '50px', }}>
          <DataSourceLogo
            name={catalog.dataSourceInfo?.driverName as any}
            type={catalog.dataSourceInfo?.type as any}
            sizeWidth={'40%'}
            sizeHeight={'auto'}
            margin={'0'}
          />
        </TableCell>
        <TableCell align="left">
          <Tooltip title="View Catalog"
            style={{
              padding: '3px 3px',
              marginRight: '10px',
              cursor:'pointer',
            }}
            aria-label="view"
          >
            <Link to={`/catalog/view/${catalog.id}${catalog.dataSourceDescription === 'external' ? '/external' : ''}`}>{catalog.name}</Link>
          </Tooltip>
        </TableCell>
        <TableCell style={{ maxWidth: 400,
          overflowX: 'auto', }} align="left">{catalog.dataSourceName}</TableCell>
        <TableCell align="left">
          { catalog.lastRun && catalog.lastRun != '' &&
            <Tooltip title={catalog.lastRun} placement='top'>
              <div
                style={{
                  width: 'fit-content',
                }}
              >
                {moment(catalog.lastRun).fromNow()}
              </div>
            </Tooltip>
          }
        </TableCell>
        <TableCell align={'left'} >
          {catalog.schedule?.nextRun &&
            <Tooltip title={catalog.schedule?.nextRun} placement='top'>
              <div
                style={{
                  width: 'fit-content',
                }}
              >
                {moment(catalog.schedule?.nextRun).fromNow()}
              </div>
            </Tooltip>
          }
        </TableCell>
        <TableCell align="left" className={classes.dataSourceActionsCell}>
          {
            catalog.dataSourceDescription !== 'external' && (
              <>
                {executeAccess && (
                  <>
                    {
                      catalog.id && runningCatalogs.includes(catalog.id) ? (
                        <Tooltip title="Loading">
                          <IconButton
                            style={{
                              padding: '3px 3px',
                              marginRight: '10px',
                            }}
                          >
                            <CircularProgress
                              color="inherit"
                              style={{
                                width: 17,
                                height: 17,
                                fontWeight: 'bolder',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Run">
                          <IconButton
                            style={{
                              padding: '3px 3px',
                              marginRight: '10px',
                            }}
                            aria-label="run"
                            onClick={() => {
                              if (catalog.id) {
                                runCatalog(catalog.id);
                              }
                            }}
                          >
                            <PlayArrow />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </>
                )}
                <DropDownMenu items={menuItems} />
              </>
            )
          }
        </TableCell>
      </TableRow>
      <ModalConfirmation
        open={catalogToDelete !== null}
        onClose={() => setCatalogToDelete(null)}
        title={'Are you sure you want to delete the catalog?'}
        onBtnClick={() => {
          if (catalogToDelete?.id) {
            deleteCatalog(catalogToDelete.id);
            setCatalogToDelete(null);
          }
        }}
        colorBtn={'secondary'}
        btnText={'Delete'}
        onCancelBtnClick={() => setCatalogToDelete(null)} />
    </>
  );
};

export default CatalogTableRow;
