import React from 'react';
import { useStyles, } from './styles';
import { useAuth, } from '../../context';
import {
  Grid,
  Typography,
} from '@mui/material';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { authenticationServices, } from '../../services';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../utils';
import LoadingComponent from '../Loading';
import { LogoRosetta, } from '../../static/index';

const SuccessfulRegistration = () => {
  const classes = useStyles();
  const history = useNavigate();
  const { health, } = useAuth();
  const { id, } = useParams<{ id: string }>();
  const { enqueueSnackbar, } = useSnackbar();
  const [verified, setVerified,] = React.useState(false);
  const [error, setError,] = React.useState<string>();

  React.useEffect(() => {
    if (id) {
      authenticationServices.verifyEmail(id)
        .then(() => {
          enqueueSnackbar('Account successfully activated!', { variant: 'success', });
          setVerified(true);
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
          setError(helpers.getErrorMessage(error));
        });
    }
  }, [id,]);

  React.useEffect(() => {
    if (verified) {
      setTimeout(() => {
        history('/');
      }, 3000);
    }
  }, [verified,]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.mainGrid}
    >
      <Grid item xs={5} sm={6} md={5} lg={3} className={classes.formGrid}>
        <div
          className={classes.logoDiv}
        >
          <img src={LogoRosetta} className={classes.logo} alt="logo"/>
        </div>
        {
          !error && !verified && (
            <LoadingComponent />
          )
        }
        {
          error && (
            <Typography
              style={{
                color: '#f17b7b',
                textAlign: 'center',
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              {error}
            </Typography>
          )
        }
        {
          !error && verified && (
            <Typography
              className={classes.bottomText}
            >
              {`Your account was activated successfully!  `}
              <p
                className={classes.bottomLink}
                onClick={() => history('/login')}
              >
                Log In
              </p>
            </Typography>
          )
        }
        <div className={classes.versionDiv}>
          <span className={classes.version}>
            {'v'+ health.version || ''}
          </span>
        </div>
      </Grid>
    </Grid>
  );
};

export { SuccessfulRegistration, };
