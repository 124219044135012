import React, { useState, } from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import * as interfaces from '../../../config/interfaces';
import {
  Add as AddIcon,
  InfoOutlined as InfoOutlinedIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import {
  StyledButton,
  StyledTextField,
} from '../../Reusable';

const useStyles = makeStyles(() => ({
  textField: {
    paddingRight: 30,
  },
  paddingBottom: {
    paddingBottom: '2%',
  },
  labelsWrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
  labelWrapper: {
    marginRight: 10,
    display: 'inline',
  },
  addBtn: {
    minHeight: 39,
    marginLeft: 22,
  },
}));

export default function Attributes(props: any) {
  const classes = useStyles();
  const [state, setState,] = useState<interfaces.KeyValue>({
    key: '',
    value: '',
  });

  const {
    onAddAttribute,
    onRemoveAttribute,
    datasource,
    readOnly,
  } = props;

  const addAttribute = () => { {
    if (
      !Object.keys(datasource.attributes).some(
        (item: string) =>
          item === state.key && datasource.attributes[item] === state.value
      )
    )
    {if (onAddAttribute) {
      onAddAttribute(state);
    }}
    setState({
      key: '',
      value: '',
    });
  }
  };

  const renderAttribute = (key: string, value: string) => {
    return (
      <Grid container style={{ marginBottom: 10, }}>
        <Grid item xs={5}>
          <StyledTextField
            disabled
            id="disabled"
            fullWidth
            label="Key"
            value={key}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={5}>
          <StyledTextField
            disabled
            id="disabled"
            label="Value"
            fullWidth
            value={value}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={2}>
          <StyledButton
            variant="contained"
            color="secondary"
            className={classes.addBtn}
            onClick={() => {
              if (onRemoveAttribute) {
                onRemoveAttribute(key);
              }
            }}
          >
            <RemoveIcon />
          </StyledButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.labelWrapper} variant="subtitle1">
          Attributes:
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <StyledTextField
            disabled={readOnly}
            name="key"
            label="Key"
            fullWidth
            variant="outlined"
            size="small"
            value={state.key}
            onChange={(event) => {
              setState({
                ...state,
                key: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            disabled={readOnly}
            name="value"
            label="Value"
            fullWidth
            style={{
              marginLeft: 10,
              width: '317px',
            }}
            variant="outlined"
            size="small"
            value={state.value}
            onChange={(event) => {
              setState({
                ...state,
                value: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '28px',
          }}
        >
          <StyledButton
            variant="contained"
            color="primary"
            disabled={state.key === '' || state.value === '' || readOnly}
            onClick={addAttribute}
          >
            <AddIcon fontSize={'small'} />
          </StyledButton>
          <Tooltip title="You can add attributes like schema information here" placement="top">
            <InfoOutlinedIcon />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.labelsWrapper}>
        {datasource.attributes && Object.keys(datasource.attributes).map((key: string) => (
          renderAttribute(key, datasource.attributes[key])
        ))}
      </Grid>
    </Grid>
  );
}
