import React from 'react';
import { useParams, } from 'react-router-dom';
import { Interfaces, } from '../../config';
import { adminServices, } from '../../services';
import { AxiosResponse, } from 'axios';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../utils';
import LoadingComponent from '../Loading';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

const roles: Array<Interfaces.IRole> = [
  {
    id: 1,
    name: 'ADAPTIVE_ADMIN',
  },
  {
    id: 2,
    name: 'ADAPTIVE_USER',
  },
  {
    id: 3,
    name: 'ADAPTIVE_DS_READ',
  },
  {
    id: 4,
    name: 'ADAPTIVE_DS_WRITE',
  },
  {
    id: 5,
    name: 'ADAPTIVE_DS_EXECUTE',
  },
  {
    id: 6,
    name: 'ADAPTIVE_CAT_READ',
  },
  {
    id: 7,
    name: 'ADAPTIVE_CAT_WRITE',
  },
  {
    id: 8,
    name: 'ADAPTIVE_CAT_EXECUTE',
  },
  {
    id: 9,
    name: 'ADAPTIVE_DEP_READ',
  },
  {
    id: 10,
    name: 'ADAPTIVE_DEP_WRITE',
  },
  {
    id: 11,
    name: 'ADAPTIVE_DEP_EXECUTE',
  },
  {
    id: 12,
    name: 'ADAPTIVE_COL_READ',
  },
  {
    id: 13,
    name: 'ADAPTIVE_COL_WRITE',
  },
  {
    id: 14,
    name: 'ADAPTIVE_COL_EXECUTE',
  },
  {
    id: 15,
    name: 'ADAPTIVE_DT_READ',
  },
  {
    id: 16,
    name: 'ADAPTIVE_DT_WRITE',
  },
  {
    id: 17,
    name: 'ADAPTIVE_DT_EXECUTE',
  },
  {
    id: 18,
    name: 'ADAPTIVE_TAG_READ',
  },
  {
    id: 19,
    name: 'ADAPTIVE_TAG_WRITE',
  },
  {
    id: 20,
    name: 'ADAPTIVE_SCHED_READ',
  },
  {
    id: 21,
    name: 'ADAPTIVE_SCHED_WRITE',
  },
  {
    id: 22,
    name: 'ADAPTIVE_SEARCH_READ',
  },
  {
    id: 23,
    name: 'ADAPTIVE_EV_READ',
  },
  {
    id: 24,
    name: 'ADAPTIVE_ALERT_READ',
  },
  {
    id: 25,
    name: 'ADAPTIVE_ALERT_WRITE',
  },
  {
    id: 26,
    name: 'ADAPTIVE_SQLEXPL_READ',
  },
  {
    id: 27,
    name: 'ADAPTIVE_GIT_READ',
  },
  {
    id: 28,
    name: 'ADAPTIVE_GIT_WRITE',
  },
  {
    id: 29,
    name: 'ADAPTIVE_GIT_EXECUTE',
  },
];

const UserProfile = () => {
  const { id, } = useParams<{ id: string }>();
  const { enqueueSnackbar, } = useSnackbar();
  const [loading, setLoading,] = React.useState(true);
  const [user, setUser,] = React.useState<Interfaces.IUser>();
  const [hasError, setHasError,] = React.useState<string>();

  React.useEffect(() => {
    setLoading(true);
    id && adminServices.fetchUserById(id)
      .then((response: AxiosResponse<{ data: Interfaces.IUser}>) => {
        setHasError(undefined);
        setUser(response.data.data);
      })
      .catch((error) => {
        const errorMsg = helpers.getErrorMessage(error);
        enqueueSnackbar(errorMsg, { variant: 'error', });
        setHasError(errorMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id,]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div>
      {
        hasError && (
          <div>
            {hasError}
          </div>
        )
      }
      {
        !hasError && user && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">User Profile</Typography>
            </Grid>
            <Grid item xs={5} component={Paper}>
              <div style={{ display: 'flex',
                alignItems: 'center', }}>
                <Typography variant="subtitle1">ID:</Typography>
                <Typography style={{ marginLeft: 10, }}>{user.id}</Typography>
              </div>
              <div style={{ display: 'flex',
                alignItems: 'center', }}>
                <Typography variant="subtitle1">Username:</Typography>
                <Typography style={{ marginLeft: 10, }}>{user.username}</Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        checked={user.active}
                        onChange={() => {}}
                        name="active"
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
                <Button color={'secondary'} variant={'contained'}>
                  Restrict User
                </Button>
              </div>
            </Grid>
            <Grid item xs={7} />
            <Grid item xs={5} component={Paper} style={{ marginTop: 10, }}>
              <Typography variant="h6">Roles:</Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '440px',
                  overflowY: 'auto',
                  padding: 10,
                }}
              >
                {roles.map((role) => (
                  <FormControlLabel
                    key={role.id}
                    control={
                      <Checkbox
                        disabled
                        color={'primary'}
                        checked={user.roles.map((role) => role.name).includes(role.name)}
                        onChange={() => {
                          if (user.roles.map((role) => role.name).includes(role.name)) {
                            // add role
                          } else {
                            // remove role
                          }
                        }}
                        value={role.id}
                      />
                    }
                    label={role.name}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        )
      }
    </div>
  );
};

export { UserProfile, };
