import React from 'react';
import mysql from '../../DataSources/CardView/logo/mysql.png';
import postgres from '../../DataSources/CardView/logo/postgresql.png';
import mssql from '../../DataSources/CardView/logo/mssql.png';
import kinetica from '../../DataSources/CardView/logo/kinetica.png';
import db2 from '../../DataSources/CardView/logo/db2.png';
import oracle from '../../DataSources/CardView/logo/oracle.png';
import snowflake from '../../DataSources/CardView/logo/snowflake.png';
import bigquery from '../../DataSources/CardView/logo/bigquery.png';
import googleBucket from '../../DataSources/CardView/logo/googlecloud.png';
import jdbc from '../../DataSources/CardView/logo/jdbc.png';
import s3 from '../../DataSources/CardView/logo/s3.png';
import ftp from '../../DataSources/CardView/logo/ftp.png';
import hdfs from '../../DataSources/CardView/logo/hdfs.png';
import azure from '../../DataSources/CardView/logo/azure.png';
import file from '../../DataSources/CardView/logo/file.png';
import { Interfaces, } from '../../../config';
import { makeStyles, } from '@mui/styles';
import moment from 'moment';

const getDSJDBCIcon = (drivername: string | undefined) => {
  switch (drivername) {
  case 'mysql':
    return mysql;
  case 'postgres':
    return postgres;
  case 'sqlserver':
    return mssql;
  case 'kinetica':
    return kinetica;
  case 'db2':
    return db2;
  case 'snowflake':
    return snowflake;
  case 'oracle':
    return oracle;
  case 'bigquery':
    return bigquery;
  default:
    return jdbc;
  }
};

const getDSOtherIcon = (type: string | undefined) => {
  switch (type) {
  case 'GCS':
    return googleBucket;
  case 'S3':
    return s3;
  case 'FTP':
    return ftp;
  case 'HDFS':
    return hdfs;
  case 'AZURE_BLOB':
    return azure;
  case 'FILE':
    return file;
  default:
    return jdbc;
  }
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  icon: {
    maxHeight: '30px',
    maxWidth: '30px',
    marginRight: '10px',
    padding: '3px',
  },
  source: {
    color: 'grey',
    fontSize: '12px',
    alignSelf: 'center',
    marginLeft: '5px',
  },
});


const CatalogIcons = ({ catalog, source = false, run = false, } : { catalog: Interfaces.InputCatalogMetadata | undefined, source?: boolean, run?: boolean, }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      { catalog && catalog?.dataSourceInfo?.type === 'JDBC' ?
        <img
          className={classes.icon}
          alt={catalog?.name.toUpperCase()}
          src={getDSJDBCIcon(catalog.dataSourceInfo?.driverName)}
        /> :
        <img
          className={classes.icon}
          alt={catalog?.name.toUpperCase()}
          src={getDSOtherIcon(catalog?.dataSourceInfo?.type)}
        />
      }
      <div>
        { catalog?.name || 'Loading...'}
        <span className={classes.source}> {source && catalog?.dataSourceName}</span>
        <span className={classes.source}> {`'${run && moment(catalog?.lastRun).fromNow()}'`}</span>

      </div>
    </div>
  );
};

export default CatalogIcons;
