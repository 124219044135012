import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = {
  onClick: () => void;
}

const BackButton: React.FC<Props> = ({ onClick, }) => {

  return (
    <Button
      variant="outlined"
      startIcon={<ArrowBackIcon />}
      onClick={onClick}
      sx={{
        color: 'blue',
        borderColor: 'blue',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 255, 0.1)',
          borderColor: 'blue',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: 'rgba(0, 0, 255, 0.2)',
        },
      }}
    >
      Back
    </Button>
  );
};

export { BackButton, };
