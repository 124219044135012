import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { CustomTableHead, } from './CustomTableHead';
import { CustomTableRow, } from './CustomTableRow';
import { CustomTableType, } from './types';
import { CustomTableToolbar, } from './CustomTableToolbar';
import { stableSort, } from './helpers';
import { useLocalStorage, } from '../Hooks';
import { CustomTablePagination, } from './CustomTablePagination';
import LoadingComponent from '../Loading';

const CustomTable = <T,>({
  id,
  name,
  rows,
  columns,
  indexCell,
  customPagination,
  loading,
  rowActions,
  containerStyle,
}: CustomTableType<T>) => {
  const [page, setPage,] = React.useState(0);
  const [perPage, setPerPage,] = useLocalStorage(id,10);
  const [order, setOrder,] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy,] = React.useState<keyof T>();
  const [keyword, setKeyword,] = React.useState('');

  const filteredRows = React.useMemo(() => {
    return rows.filter((row) => {
      let _row = '';
      Object.values(row as object).forEach((value) => _row += value.toString());
      return _row.includes(keyword);
    });
  }, [keyword,]);

  return (
    <div style={{ position: 'relative', }}>
      {
        loading && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <LoadingComponent size={40} marginTop={0}/>
          </div>
        )
      }
      <CustomTableToolbar
        name={name}
        handleSearch={(keyword) => {
          if (customPagination) {
            customPagination.setKeyword(keyword);
            return;
          }
          setKeyword(keyword);
        }}
      />
      <TableContainer
        style={{
          ...(containerStyle ?? {}),
          opacity: loading ? 0.4 : 1,
          pointerEvents: loading ? 'none' : 'auto',
        }}
      >
        <Table stickyHeader>
          <CustomTableHead
            onRequestSort={(property) => {
              if (customPagination) {
                customPagination.setOrderBy(property);
                return;
              }
              setOrderBy(property);
              setOrder(order === 'asc' ? 'desc' : 'asc');
            }}
            orderBy={customPagination?.orderBy ?? orderBy}
            order={customPagination?.order ?? order}
            columns={columns}
            indexCell={indexCell}
            rowActions={rowActions}
          />
          <TableBody>
            {
              (customPagination === undefined ? stableSort(filteredRows, order, orderBy) : rows)
                .map((row, index) => (
                  <CustomTableRow<T>
                    key={index}
                    index={(customPagination ? customPagination.page * customPagination.perPage : page * perPage) + index + 1}
                    row={row}
                    columns={columns}
                    indexCell={indexCell}
                    rowActions={rowActions}
                  />
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <CustomTablePagination
        page={customPagination?.page ?? page}
        setPage={customPagination?.setPage ?? setPage}
        perPage={customPagination?.perPage ?? perPage}
        setPerPage={customPagination?.setPerPage ?? setPerPage}
        total={customPagination?.count ?? rows.length}
      />
    </div>
  );
};

export { CustomTable, };
