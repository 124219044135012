import React from 'react';
import {
  Box,
  Paper,
  Tab,
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import { Login, } from '../Login';
import { Register, } from '../Register';
import RosettaSvg from '../../assets/icons/RosettaDB.svg';
import { useAuth, } from '../../context';
// import adaptivescale from '../../images/adaptivescale.png';

const LoginWrapper = () => {
  const [value, setValue,] = React.useState('1');
  const { deploymentType, } = useAuth();
  const currentYear = new Date().getFullYear();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      style={ deploymentType === 'local' ? {
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginTop: 120,
      } : {
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        background: 'radial-gradient(circle, rgba(232,232,232,0.15730042016806722) 6%, rgba(218,218,218,0.33657212885154064) 41%, rgba(227,200,168,0.1741071428571429) 52%, rgba(255,145,13,0.14329481792717091) 82%)',
      }}
    >
      <Box display='flex' flexDirection='column' justifyContent='space-between' minHeight='100vh'>
        <Box>
          <Box textAlign='center' marginBottom={3} marginTop={16}>
            {
              deploymentType === 'local' ? (
                <img src={RosettaSvg} alt={'rosetta-logo'} width={300}/>
              ) : (
                <img src={RosettaSvg} alt={'rosetta-logo'} width={300}/>
              )
            }
          </Box>
          <Paper
            elevation={deploymentType === 'local' ? 0 : 2}
            style={{ padding: '10px',
              marginBottom: '20px', }}
          >
            {
              deploymentType === 'local' ? (
                <Box display='flex' width={400} height={250}>
                  <Login />
                </Box>
              ) : (
                <Box display='flex' width={400} height='100%' minHeight={500}>
                  <Box flex='1' padding={2}>
                    <TabContext value={value}>
                      <TabList onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary" textColor="primary" centered>
                        <Tab label="Login" value="1"/>
                        <Tab label="Sign Up" value="2" />
                      </TabList>
                      <TabPanel value="1">
                        <Login />
                      </TabPanel>
                      <TabPanel value="2">
                        <Register />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Box>
              )
            }
          </Paper>
        </Box>
        <Box textAlign='center'>
          <p>&copy; {currentYear} AdaptiveScale Inc.</p>
          <p>Rosettadb is a service of AdaptiveScale Inc.</p>
        </Box>
      </Box>

    </Box>
  );
};

export default LoginWrapper;
