import React from 'react';
import {
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import { BackupPanel, } from './BackupPanel';
import { makeStyles, } from '@mui/styles';
import GitConfigs from './GitConfigs';
import { useAuth, } from '../../context';

const useStyles = makeStyles({
  tabsContainer: {
    marginBottom: 0,
  },
  customButtonBaseRoot: {
    borderBottom: '2px solid transparent',
    padding: 5,
    minHeight: 30,
    minWidth: 140,
    marginRight: 10,
  },
  tabWrapper: {
    height: 30,
  },
  tabSelected: {
    borderBottom: '2px solid #3f51b5',
  },

});


const AdminPanel = () => {
  const classes = useStyles();
  const { deploymentType, } = useAuth();
  const [activeStep, setActiveStep,] = React.useState(deploymentType === 'local' ? 1 : 2);

  return (
    <Grid container direction='column'>
      <Tabs
        classes={{
          root: classes.tabsContainer,
        }}
        TabIndicatorProps={{ style: { display: 'none', }, }}
        value={activeStep}
        onChange={(event: any, value: any) => {
          console.debug('event', event, value);
          setActiveStep(value);
        }}
        aria-label="basic tabs example"
      >
        {
          deploymentType === 'local' && (
            <Tab
              classes={{
                root: classes.customButtonBaseRoot,
                wrapped: classes.tabWrapper,
                selected: classes.tabSelected,
              }}
              label="Backup"
              value={1}
            />
          )
        }
        <Tab
          classes={{
            root: classes.customButtonBaseRoot,
            wrapped: classes.tabWrapper,
            selected: classes.tabSelected,
          }}
          label="Git Config"
          value={2}
        />
      </Tabs>
      <Grid xs={12} style={{ marginTop: '30px', }}>
        {activeStep == 1 && <BackupPanel/>}
        {activeStep == 2 && <GitConfigs/>}
      </Grid>
    </Grid>
  );
};

export { AdminPanel, };
