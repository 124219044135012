import React from 'react';
import {
  Button,
  ButtonProps,
  styled,
} from '@mui/material';

interface IStyledButtonProps extends ButtonProps {
  component?: React.ElementType;
}

const StyledButton = styled(Button)<IStyledButtonProps>(() => ({
  margin: 7,
}));

export { StyledButton, };
