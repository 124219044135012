import React from 'react';
import { Interfaces, } from '../../../config';
import { alertsServices, } from '../../../services';
import { AxiosResponse, } from 'axios';
import { useSnackbar, } from 'notistack';
import {
  Box,
  Button,
  Chip,
  Paper,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useNavigate, } from 'react-router-dom';
import {
  ArrowBack,
  ArrowForward,
} from '@mui/icons-material';
import { helpers, } from '../../../utils';
import LoadingComponent from '../../Loading';
import { NoDataComponent, } from '../../NoDataComponent';
import { makeStyles, } from '@mui/styles';


const useStyles = makeStyles(() => ({
  menuItem: {
    width: 350,
    margin: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  alertName: {
    fontWeight: 'bold',
    maxWidth: '210px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  relationType: {
    fontSize: '12px',
    color: '#6b6b6b',
  },
  ruleText: {
    color: '#6b6b6b',
  },
  hoverBox: {
    '&:hover': {
      backgroundColor: '#f3f3f4',
    },
  },
}));

const AlertHistories = () => {
  const [histories, setHistories,] = React.useState<Array<Interfaces.AlertHistory>>([]);
  const [limit, setLimit,] = React.useState(5);
  const [offset, setOffset,] = React.useState(0);
  const [isLoading, setIsLoading,] = React.useState(false);

  const { enqueueSnackbar, } = useSnackbar();
  const classes = useStyles();
  const history = useNavigate();

  React.useEffect(() => {
    setIsLoading(true);
    alertsServices.fetchAlertHistories(limit, offset)
      .then((response: AxiosResponse<{ data: Array<Interfaces.AlertHistory> }>) => {
        setHistories(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => setIsLoading(false));
  }, [limit, offset,]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!isLoading && histories.length === 0) {
    return (
      <NoDataComponent
        data={{
          component: 'alerts',
          header: 'No Alert Histories',
          text1: 'There are no alerts run yet',
          text2: 'Or no alerts have been configured',
          route: '/alerts/add',
        }}
      />
    );
  }

  return (
    <div style={{ height: 'calc(100vh - 110px)',
      maxHeight: '770px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop:'20px', }}>
      <div>
        {
          histories.map((_history) => (
            <Paper key={_history.id} style={{ minWidth: '700px', }} elevation={3}>
              <Box
                onClick={() => {
                  history(`/alerts/report/${_history.alertId}`);
                }}
                key={_history.id}
                display="flex"
                width="100%"
                style={{
                  padding: '15px',
                  marginBottom: 10,
                  marginLeft: 0,
                  cursor: 'pointer',
                }}
                className={classes.hoverBox}
              >
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px" paddingX={3} style={{ borderBottom: '1px solid #ccc', }}>
                    <Typography variant='h5' className={classes.alertName}>{_history.alertName}</Typography>
                    <Typography variant='body2' className={classes.relationType}>{moment(_history.runTime).fromNow()}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="start" alignItems="center" paddingX={3}>
                    <Typography variant='body2' style={{ flex: 1, }}>Catalog</Typography>
                    <Typography variant='body2' className={classes.ruleText} style={{ flex: 3, }}>
                      {_history.relationName}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="start" alignItems="center" paddingX={3}>
                    <Typography variant='body2' style={{ flex: 1, }}>Input rule</Typography>
                    <Typography variant='body2' className={classes.ruleText} style={{ flex: 3, }}>
                      {`${_history.alertInputRule.name}: ${_history.alertInputRule.rule.path} ${_history.alertInputRule.rule.operator} ${_history.alertInputRule.rule.valueToCheck
                      }`}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="start" alignItems="center" paddingX={3}>
                    <Typography variant='body2' style={{ flex: 1, }}>Output rule</Typography>
                    <Typography variant='body2' className={classes.ruleText} style={{ flex: 3, }}>{_history.alertOutputRule.name}</Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          ))
        }
      </div>
      <Box display='flex' justifyContent='space-between' alignItems='center' minWidth={700} paddingBottom={3}>
        <Button
          style={{
            width: '130px',
          }}
          variant={'contained'}
          color={'primary'}
          disabled={offset === 0}
          onClick={() => setOffset(offset - 1)}
          startIcon={<ArrowBack/>}
        >
          Previos
        </Button>
        <Chip label={`Page ${offset+1}`}/>
        <Button
          style={{
            width: '130px',
          }}
          color={'primary'}
          variant={'contained'}
          disabled={histories.length < limit}
          endIcon={<ArrowForward/>}
          onClick={() => {
            setLimit(limit);
            setOffset(offset + 1);
          }}>
          Next
        </Button>
      </Box>
    </div>

  );
};

export { AlertHistories, };
