import React, { useState, } from 'react';
import { NoDataComponent, } from '../NoDataComponent';
import { CatalogTable, }  from './CatalogTable';
import CatalogCardsView from './CardList/CatalogCardsView';
import { useSnackbar, } from 'notistack';
import { catalogsServices, } from '../../services';
import { Interfaces, } from '../../config';
import { helpers, } from '../../utils';
import { AxiosResponse, } from 'axios';
import LoadingComponent from '../Loading';

function Catalogs() {
  const { enqueueSnackbar, } = useSnackbar();
  const [catalogs, setCatalogs,] = useState<Array<Interfaces.InputCatalogMetadata>>([]);
  const [loading, setLoading,] = useState(false);
  const [toggle, setToggle,] = useState(false);


  const changeToggle = () => {
    setToggle(!toggle);
  };

  const fetchCatalogs = () => {
    setLoading(true);
    catalogsServices.fetchCatalogsWithDataSources()
      .then((response: AxiosResponse<Array<Interfaces.InputCatalogMetadata>>) => {
        setCatalogs(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    fetchCatalogs();
  }, []);

  const navigationData = {
    component: 'catalogs',
    header: 'No Catalogs',
    text1: 'You have not created a catalog yet',
    text2: 'Create a catalog to get started',
    buttonText: 'New catalog',
    route: '/catalog/add',
  };

  if (loading && catalogs.length === 0) {
    return <LoadingComponent/>;
  }

  return (
    <div>
      {catalogs.length > 0 ? (
        <>
          {!toggle ?
            (<CatalogTable
              catalogs={catalogs}
              loading={loading}
              changeToggle={changeToggle}
              toggle={toggle}
              fetchCatalogs={fetchCatalogs}
            />)
            :

            (<CatalogCardsView
              catalogs={catalogs}
              loading={loading}
              changeToggle={changeToggle}
              toggle={toggle}
              fetchCatalogs={fetchCatalogs}
            />)}
        </>
      ) : (
        <NoDataComponent data={navigationData}/>
      )}
    </div>
  );
}

export default Catalogs;
