import React, {
  useEffect,
  useState,
} from 'react';
import { jobsServices, } from '../../services';
import { AxiosResponse, } from 'axios';
import { useSnackbar, } from 'notistack';
import { ModalWithTabs, } from './ModalWithTabs';

const ViewCodeDialog = ({
  isOpen,
  handleClose,
  sourceCatalog,
  targetDataSource,
  path,
}: {
  isOpen: boolean;
  handleClose: () => void;
  sourceCatalog: string,
  targetDataSource: string,
  path: string,
}) => {
  const { enqueueSnackbar, } = useSnackbar();
  const [value, setValue,] = useState('');
  const [scalaValue, setScalaValue,] = useState('');
  const [loading, setLoading,] = useState(true);
  console.log(value, scalaValue);

  useEffect(() => {
    if (isOpen) {
      jobsServices.getSparkCode({
        sourceCatalogId: sourceCatalog,
        targetDataSourceId: targetDataSource,
        targetDirectoryPath: path,
      })
        .then((response: AxiosResponse<{ data: string }>) => {
          setValue(response.data.data);
        })
        .catch((error) => {
          enqueueSnackbar(error.data, { variant: 'error', });
        })
        .finally(() => {
          setLoading(false);
        });
      jobsServices.getScalaCode({
        sourceCatalogId: sourceCatalog,
        targetDataSourceId: targetDataSource,
        targetDirectoryPath: path,
      })
        .then((response: AxiosResponse<{ data: string }>) => {
          setScalaValue(response.data.data);
        })
        .catch((error) => {
          enqueueSnackbar(error.data, { variant: 'error', });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {
      setLoading(true);
    };
  }, [isOpen,]);


  return (
    <ModalWithTabs
      open={isOpen}
      title={'Spark Code'}
      value={value}
      scalaValue={scalaValue}
      loading={loading}
      mode={'sql'}
      handleClose={() => handleClose()}
    />
  );
};

export { ViewCodeDialog, };
