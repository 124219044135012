import { ApiCall, } from './ApiCall';
import { RunSQL, } from './RunSQL';
import { RecordLimit, } from './RecordLimit';
import { TotalBytes, } from './TotalBytes';
import { SQLCheck, } from './SQLCheck';
import { SendMail, } from './SendMail';

export const InputRules: Array<{ name: string, label: string, isValid: boolean, Component: any }> = [
  {
    name: 'SizeInBytesCheck',
    label: 'Size in bytes',
    Component: TotalBytes,
    isValid: false,
  },
  {
    name: 'TotalRecordsCheck',
    label: 'Total records',
    Component: RecordLimit,
    isValid: false,
  },
  {
    name: 'SQLInputCheck',
    label: 'SQL check',
    Component: SQLCheck,
    isValid: false,
  },
];

export const OutputRules: Array<{ name: string, label: string, isValid: boolean, Component: any }> = [
  {
    name: 'SQLExecute',
    label: 'Run SQL',
    Component: RunSQL,
    isValid: false,
  },
  {
    name: 'ExecuteRequest',
    label: 'API Call',
    Component: ApiCall,
    isValid: false,
  },
  {
    name: 'SendMail',
    label: 'Send email',
    Component: SendMail,
    isValid: false,
  },
];
