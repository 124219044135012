import React from 'react';
import classes from './EvolutionDetails.module.css';
import {
  Group,
  Layer,
  Rect,
  Stage,
} from 'react-konva';
import { DBDiagramTable, } from '../../Catalogs/AddCatalog/ERDiagram/DBDiagramTable';
import { Interfaces, } from '../../../config';
import { ConnectionLines, } from './ConnectionLines';
import {
  findAllTables,
  groupSchemas,
} from '../../../utils/helpers';
import {
  MenuItem,
  TextField,
} from '@mui/material';
import { SentimentDissatisfied, } from '@mui/icons-material';

export type Point = {
  x: number;
  y: number;
}
export type LineType = {
  from: Point;
  to: Point;
}

interface Props {
  oldSchema: Interfaces.Schema
  newSchema: Interfaces.Schema;
}

const KonvaComparator: React.FC<Props> = ({ oldSchema, newSchema, }) => {
  const COLUMN_HEIGHT = 25;
  const TABLE_CONSTANT = 70;
  let startPos = 20;
  const CELL_SIZE = 20;
  const stageRef = React.useRef(null);

  const [stage, setStage,] = React.useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  const hasSchemas = React.useMemo(() => !(oldSchema?.fields[0]?.sourceType === 'table' || oldSchema?.fields[0]?.sourceType === 'view'), [oldSchema, newSchema,]);

  const [selectedSchema, setSelectedSchema,] = React.useState<Interfaces.Schema>(hasSchemas ? oldSchema.fields?.find((field) => field.fields?.length > 0) ?? oldSchema.fields[0] : oldSchema);

  const handleZoomIn = () => {
    setStage({ ...stage,
      scale: stage.scale + 0.1, });
    const _stage = stageRef.current as any;
    if (_stage) {
      _stage.scale({
        x: stage.scale + 0.1,
        y: stage.scale + 0.1,
      });
      _stage.batchDraw();
    }
  };

  const handleZoomOut = () => {
    setStage({ ...stage,
      scale: stage.scale - 0.1, });
    const _stage = stageRef.current as any;
    if (_stage) {
      _stage.scale({
        x: stage.scale - 0.1,
        y: stage.scale - 0.1,
      });
      _stage.batchDraw();
    }
  };

  const groupedTables = React.useMemo(() => {
    if (hasSchemas) {
      const selectedNewSchema = newSchema.fields.find((field) => field.name === selectedSchema.name);
      if (selectedNewSchema) {
        return groupSchemas(findAllTables(selectedSchema), findAllTables(selectedNewSchema));
      }
      return undefined;
    }
    return groupSchemas(findAllTables(oldSchema), findAllTables(newSchema));
  }, [selectedSchema,]);

  const getConnections = (oldColumns: Interfaces.Schema[], newColumns: Interfaces.Schema[], startPosition: number) => {
    const oldNames = oldColumns.map((old) => old.name);
    const newNames = newColumns.map((_new) => _new.name);
    const lines: LineType[] = [];

    oldNames.forEach((name, index) => {
      if (newNames.includes(name)) {
        const newIndex = newNames.findIndex((_new) => _new === name);
        if (newIndex !== -1) {
          lines.push({
            from: {
              x: 220,
              y: index * COLUMN_HEIGHT + startPosition + 36,
            },
            to: {
              x: 376,
              y: newIndex * COLUMN_HEIGHT + startPosition + 36,
            },
          });
        }
      }
    });
    return lines;
  };

  const canvasHeight: number = React.useMemo(() => {
    if (!groupedTables) {
      return 0;
    }
    return Object.keys(groupedTables).reduce((sum, key) => {
      let newHeight = 0;
      let oldHeight = 0;
      const oldTable = groupedTables[key].old as Interfaces.Schema | undefined;
      const newTable = groupedTables[key].new as Interfaces.Schema | undefined;
      if (oldTable?.fields) {
        oldHeight += COLUMN_HEIGHT * oldTable.fields.length + TABLE_CONSTANT;
      }
      if (newTable?.fields) {
        newHeight += COLUMN_HEIGHT * newTable.fields.length + TABLE_CONSTANT;
      }
      return sum + Math.max(oldHeight, newHeight);
    }, 0);
  }, [groupedTables,]);

  const handleWheel = (e: any) => {
    const isCtrlOrCmdPressed = e.evt.ctrlKey || e.evt.metaKey;

    if (isCtrlOrCmdPressed) {
      e.evt.preventDefault();

      const scaleBy = 1.02;
      const stage = e.target.getStage();
      const oldScale = stage.scaleX();
      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      };

      const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

      setStage({
        scale: newScale,
        x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
        y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
      });
    }
  };

  React.useEffect(() => {
    setSelectedSchema(oldSchema.fields?.find((field) => field.fields?.length > 0) ?? oldSchema);
  }, [hasSchemas, newSchema, oldSchema,]);

  return (
    <>
      {hasSchemas && oldSchema.fields.length > 1 && (
        <TextField
          select
          size={'small'}
          fullWidth
          label={'Select a schema to compare'}
          placeholder={'Select a schema to compare'}
          defaultValue={selectedSchema.name}
          onChange={(event) => {
            const schema = oldSchema.fields.find((field) => field.name === event.target.value);
            if (schema) {
              setSelectedSchema(schema);
            }
          }}
        >
          {oldSchema.fields.map((schema) => (
            <MenuItem key={schema.name} value={schema.name}>
              {schema.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {groupedTables && Object.keys(groupedTables).length === 0 && (
        <div className={classes.noTablesContainer}>
          <div className={classes.noTablesBold}>
            No tables in this schema!
            <SentimentDissatisfied style={{ fontSize: 32, }} />
          </div>
          <div className={classes.noTablesLight}>
            Please use the input above to see changes in other schemas
          </div>
        </div>
      )}
      <div className={classes.canvas} title={'Hold CMD or CTRL to zoom in/out'}>
        <div className={classes.canvasButtons}>
          <button className={classes.canvasButton} onClick={handleZoomIn}>+</button>
          <button className={classes.canvasButton} onClick={handleZoomOut}>-</button>
        </div>
        <Stage
          width={600}
          height={canvasHeight + 60}
          onWheel={handleWheel}
          draggable
          scaleX={stage.scale}
          scaleY={stage.scale}
          x={stage.x}
          y={stage.y}
          ref={stageRef}
        >
          <Layer
            x={-100}
            y={-100}
          >
            {[...Array(50),].map((_, i) => (
              <Rect
                key={i}
                x={i * CELL_SIZE}
                y={0}
                width={0}
                height={canvasHeight + 60}
                dash={[1, CELL_SIZE - 1,]}
                stroke="#ccc"
                strokeWidth={1}
              />
            ))}
          </Layer>
          <Layer>
            {groupedTables && Object.keys(groupedTables).map((key, index) => {
              const oldTable = groupedTables[key].old ? JSON.parse(JSON.stringify(groupedTables[key].old)) : undefined;
              const newTable = groupedTables[key].new ? JSON.parse(JSON.stringify(groupedTables[key].new)) : undefined;
              const comp = (
                <Group key={index}>
                  {oldTable && (
                    <DBDiagramTable
                      x={20}
                      y={startPos}
                      fields={oldTable.fields}
                      table={oldTable}
                      detailed
                      columnHeight={COLUMN_HEIGHT}
                      handleSelect={() => {}}
                      selectedFields={[]}
                      highLight
                      totalRecords={oldTable.properties?.totalRecords}
                    />
                  )}
                  {!oldTable && newTable && (
                    <DBDiagramTable
                      x={20}
                      y={startPos}
                      fields={newTable.fields}
                      table={newTable}
                      detailed
                      columnHeight={COLUMN_HEIGHT}
                      handleSelect={() => {}}
                      selectedFields={[]}
                      highLight
                      opacity={0.2}
                    />
                  )}
                  {oldTable && newTable && <ConnectionLines connections={getConnections(oldTable.fields, newTable.fields, startPos + 15)} tableName={key} />}
                  {newTable && (
                    <DBDiagramTable
                      x={380}
                      y={startPos}
                      fields={newTable.fields}
                      table={newTable}
                      detailed
                      columnHeight={COLUMN_HEIGHT}
                      handleSelect={() => {}}
                      selectedFields={[]}
                      highLight
                      totalRecords={newTable.properties?.totalRecords}
                    />
                  )}
                  {oldTable && !newTable && (
                    <DBDiagramTable
                      x={380}
                      y={startPos}
                      fields={oldTable.fields}
                      table={oldTable}
                      detailed
                      columnHeight={COLUMN_HEIGHT}
                      handleSelect={() => {}}
                      selectedFields={[]}
                      highLight
                      opacity={0.2}
                    />
                  )}
                </Group>
              );
              const oldTableFields = oldTable?.fields?.length ?? 0;
              const newTableFields = newTable?.fields?.length ?? 0;
              const maxFields = Math.max(oldTableFields, newTableFields);
              startPos += maxFields * 25 + TABLE_CONSTANT;
              return comp;
            })}
          </Layer>
        </Stage>
      </div>
    </>
  );
};

export { KonvaComparator, };
