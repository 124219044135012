import React, {
  useEffect,
  useState,
} from 'react';
import Style from './style';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  InfoOutlined,
  Loop,
  Visibility,
  VisibilityOff,
  Save,
} from '@mui/icons-material';
import logo from '../CardView/logo';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  Constants,
  Interfaces,
} from '../../../config';
import { DirectoryTree, } from '../DirectoryTree';
import LoadingComponent from '../../Loading';
import { NoDataV2, } from '../../NoDataV2';
import { useDSCalls, } from '../../../hooks';
import {
  StyledButton,
  StyledTextField,
} from '../../Reusable';

type Params = {
  oldName: string;
};

interface Props {
  schemaTypes: Array<string>;
}

function S3(props: Props) {
  const classes = Style();
  const location = useLocation();
  const navigate = useNavigate();
  const inEditMode = location.pathname.includes('/edit');
  const { oldName, } = useParams<Params>();
  const { schemaTypes, } = props;
  const [state, setState,] = useState<Interfaces.S3DataSource>({
    id: '',
    name: '',
    type: 'S3',
    schemaType: schemaTypes[0],
    uri: '',
    accessKeyId: '',
    accessKeySecret: '',
  });
  const [expandedElements, setExpandedElements,] = useState<Array<string>>([]);
  const [exploreState, setExploreState,] = useState(true);
  const {
    dataSources,
    loading: loadingDS,
    editDataSource,
    addDataSource,
    dataSourceToEdit: dataSourceToEditHook,
    directories,
    loadingEdit,
    errorEdit,
    loadingDirectories,
    errorDirectories,
    fetchDirectories,
    setDataSourceToEdit,
    fetchNestedDirectory,
    setTestDataSource,
  } = useDSCalls();

  const dataSourceToEdit = dataSourceToEditHook as Interfaces.S3DataSource | null;

  const getTypeFromName = (directory: Interfaces.Directory) => {
    if (directory.isDirectory) {
      return 'DIRECTORY';
    }
    const extension = directory.path.split('.').pop();
    if (extension === 'csv') {
      return 'CSV';
    }
    return 'AVRO';
  };

  const getTypeFromUri = (uri: string) => {
    const extension = uri.split('.').pop();
    if (extension === 'csv') {
      return 'CSV';
    } else if (extension === 'avro') {
      return 'AVRO';
    }
    return 'DIRECTORY';
  };

  const [showSecretKey, setShowSecretKey,] = useState<boolean>(false);

  const endpoint = inEditMode && dataSourceToEdit
    ? `data-sources/${dataSourceToEdit.id}/s3/dir`
    : Constants.api.endpoints.s3Directories;

  const fetchDirectoriesS3 = (accessKeySecret: string | null, uri: string | null, accessKeyId: string) => {
    fetchDirectories({
      accessKeyId: accessKeyId,
      accessKeySecret: accessKeySecret,
      uri: uri,
    }, endpoint);
  };

  useEffect(() => {
    setTestDataSource(null);
    if (inEditMode) {
      if (dataSourceToEdit) {
        setState({
          ...state,
          id: dataSourceToEdit?.id,
          name: dataSourceToEdit?.name,
          type: dataSourceToEdit?.type,
          uri: dataSourceToEdit?.uri,
          schemaType: dataSourceToEdit?.schemaType,
          accessKeyId: dataSourceToEdit?.accessKeyId,
          accessKeySecret: null,
        });
        fetchDirectoriesS3(null, dataSourceToEdit.uri === '' ? null : dataSourceToEdit.uri, dataSourceToEdit?.accessKeyId);
        setExploreState(false);
      } else {
        if (!dataSources) { return;}
        const dataSourceToChange = dataSources.find((item) => item.name === oldName) as Interfaces.S3DataSource;
        setDataSourceToEdit(dataSourceToChange);
      }
    }
  }, [dataSourceToEdit, dataSources,]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name;
    let value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const postSubmitAction = () => {navigate('/data-sources');};

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (inEditMode && oldName) {
      editDataSource( state, oldName, postSubmitAction);
    } else {
      addDataSource(state, postSubmitAction);
    }
  };

  const isExploreDisabled =
    state.accessKeyId === null
    || state.accessKeyId === ''
    || state.accessKeySecret === null
    || state.accessKeySecret === '';

  if (loadingEdit) {
    return <LoadingComponent message={'Loading data source'}/>;
  }

  if (errorEdit) {
    return (
      <p>{errorEdit}</p>
    );
  }

  if (inEditMode && !dataSourceToEdit && !loadingDS) {
    return (
      <p>No data source was selected for edit mode</p>
    );
  }

  return (
    <form name="addS3Form" onSubmit={handleSubmit}>
      <div className={classes.root}>
        <Grid container justifyContent='center'>
          <img alt="s3" src={logo.images.s3} className={classes.img} />
        </Grid>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={6}>
            <StyledTextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={state.name}
              fullWidth
              size="small"
              onChange={(event: any) => {
                handleChange(event);
              }}
            />
            <StyledTextField
              id="type"
              name="schemaType"
              variant="outlined"
              value={state.schemaType}
              fullWidth
              size="small"
              onChange={handleChange}
              select
              SelectProps={{
                native: true,
              }}
            >
              {schemaTypes.map((option: string) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </StyledTextField>
            <StyledTextField
              multiline
              rows={5.3}
              size={'small'}
              fullWidth
              value={state.accessKeyId}
              onChange={(event: any) => {
                handleChange(event);
              }}
              label="Access Key ID"
              name="accessKeyId"
            />
            <StyledTextField
              type={showSecretKey ? 'text' : 'password'}
              size={'small'}
              value={state.accessKeySecret}
              onChange={(event: any) => {
                handleChange(event);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowSecretKey(!showSecretKey)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showSecretKey ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              name="accessKeySecret"
              label="Secret Access Key"
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '4px',
              }}
            >
              <StyledTextField
                className={classes.paddingBox}
                style={{ width: '109%', }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <Tooltip title={'Reload the files'} placement="top">
                        <IconButton
                          disabled={exploreState}
                          color={'primary'}
                          edge={'end'}
                          onClick={() => {
                            fetchDirectoriesS3(state.accessKeySecret, state.uri === '' ? null : state.uri, state.accessKeyId);
                            setState({
                              ...state,
                              schemaType: getTypeFromUri(state.uri),
                            });
                          }}
                        >
                          <Loop />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                id="uri"
                name="uri"
                label="Uri"
                variant="outlined"
                value={state.uri}
                fullWidth
                size="small"
                onChange={(event: any) => {
                  handleChange(event);
                }}
              />
              <Tooltip title="Uri must start with 's3a://'" placement="top">
                <InfoOutlined
                  fontSize="small"
                  style={{
                    marginLeft: 10,
                  }}
                />
              </Tooltip>
            </div>
            {
              exploreState
                ? (
                  <Box className={classes.treeBoxEmpty}>
                    <StyledButton
                      disabled={isExploreDisabled}
                      onClick={() => {
                        fetchDirectoriesS3(state.accessKeySecret, state.uri === '' ? null : state.uri, state.accessKeyId);
                        setExploreState(false);
                      }}
                      color='primary'
                      variant='contained'
                      className={classes.exploreButton}
                    >
                      Explore
                    </StyledButton>
                    {
                      isExploreDisabled
                      && <Typography display={'block'} style={{ color: '#949494', }} variant={'caption'}>
                        Credentials required
                      </Typography>
                    }
                  </Box>
                )
                : (
                  <Box  className={classes.treeBox}>
                    {loadingDirectories && directories.length === 0 && <LoadingComponent size={60} message={'Loading bucket'} />}
                    {
                      errorDirectories &&
                      <div style={{ textAlign: 'center', }}>
                        <NoDataV2 message={errorDirectories} />
                        <StyledButton
                          onClick={() => {
                            fetchDirectories({
                              accessKeyId: state.accessKeyId,
                              accessKeySecret: state.accessKeySecret,
                              uri: state.uri === '' ? null : state.uri,
                            }, Constants.api.endpoints.s3Directories);
                            setExploreState(false);
                          }}
                          color='primary'
                          variant='contained'
                        >
                          Retry
                        </StyledButton>
                      </div>
                    }
                    {directories.length > 0 && !errorDirectories && (
                      <DirectoryTree
                        directories={directories}
                        isLoading={loadingDirectories}
                        expandedElements={expandedElements}
                        onTreeItemClick={(directory: Interfaces.Directory) => {
                          setState({
                            ...state,
                            uri: directory.path,
                            schemaType: getTypeFromName(directory),
                          });
                          if (directory.isDirectory) {
                            if (!directory.directories) {
                              fetchNestedDirectory({
                                accessKeyId: state.accessKeyId,
                                accessKeySecret: state.accessKeySecret,
                                uri: directory.path,
                              }, endpoint, directory.path);
                            }
                            if (expandedElements.includes(directory.path)) {
                              setExpandedElements([...expandedElements,].filter((expanded: string) => expanded !== directory.path));
                            } else {
                              setExpandedElements([...expandedElements, directory.path,]);
                            }
                          }
                        }}/>
                    )}
                  </Box>
                )
            }
          </Grid>
        </Grid>
        <div style={{ width: '100%', }}>
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{
              marginLeft: 0,
            }}
            startIcon={loadingDS ?
              <CircularProgress
                color="inherit"
                style={{
                  width: 16,
                  height: 11,
                }} />
              :
              <Save />}
          >
          Save
          </StyledButton>
          <StyledButton
            variant="outlined"
            size="large"
            className={classes.button}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </div>
    </form>
  );
}

export default S3;
