import React from 'react';
import { Chip, } from '@mui/material';
import {
  HighlightOff,
  Timelapse,
  CheckCircleOutline,
  ErrorOutline,
} from '@mui/icons-material';
import { Interfaces, } from '../../../../config';

function StatusChip ({ status, } : {status: Interfaces.deploymentStatusType}) {

  switch (status) {
  case 'STARTING':
    return (
      <Chip
        variant="outlined"
        color='primary'
        icon={
          <Timelapse/>
        }
        label={status.toLowerCase()}
      />
    );
  case 'START_FAILED':
    return (
      <Chip
        variant="outlined"
        color='secondary'
        icon={
          <HighlightOff/>
        }
        label={status.toLowerCase()}
      />
    );
  case 'RUNNING':
    return (
      <Chip
        variant="outlined"
        color='primary'
        icon={
          <Timelapse/>
        }
        label={status.toLowerCase()}
      />
    );
  case 'SUCCESS':
    return (
      <Chip
        variant="outlined"
        style={{
          color: 'green',
          borderColor: 'green',
        }}
        icon={
          <CheckCircleOutline
            style={{
              color: 'green',
            }}
          />
        }
        label={status.toLowerCase()}
      />
    );
  case 'FAILED':
    return (
      <Chip
        variant="outlined"
        color='secondary'
        icon={
          <HighlightOff/>
        }
        label={status.toLowerCase()}
      />
    );
  case 'STOPPED':
    return (
      <Chip
        variant="outlined"
        style={{
          color: 'gray',
          borderColor: 'gray',
        }}
        icon={
          <ErrorOutline />
        }
        label={status.toLowerCase()}
      />
    );
  default:
    return null;
  }
}

export default StatusChip;
