import React from 'react';
import {
  Group,
  Arrow,
} from 'react-konva';
import { LineType, } from '../EvolutionDetails/KonvaComparator';

interface Props {
  connections: LineType[];
  tableName: string;
}

const ConnectionLines: React.FC<Props> = ({ connections, }) => {
  const points = React.useMemo(() => {
    return connections.map((connection) => {
      const fromX = connection.from.x;
      const toX = connection.to.x;
      const midX = Math.abs(connection.to.x - connection.from.x) / 2 + fromX;
      const fromY = connection.from.y;
      const toY = connection.to.y;
      return [
        fromX, fromY,
        midX, fromY,
        midX, toY,
        toX, toY,
      ];
    });
  }, [connections,]);

  return (
    <Group>
      {points.map((line, index) => (
        <Arrow
          key={index}
          points={line}
          stroke={'gray'}
          fill={'gray'}
          strokeWidth={2}
          pointerLength={14}
          pointerWidth={8}
          bezier
        />
      ))}
    </Group>
  );
};

export { ConnectionLines, };
