import { Properties, } from '.';
import axios from 'axios';
import moment from 'moment';

const getAuthToken = () => `Bearer ${sessionStorage.getItem('token')}`;

export const getHeaders = (): Record<string, string> => {
  const token = sessionStorage.getItem('token') ? getAuthToken() : null;
  return token ? {
    'Authorization': token,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  } : {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  };
};

const aClient = axios.create({
  baseURL: Properties.API_URL,
  headers: getHeaders(),
});

export const axiosClient = () => aClient;

// axiosClient().interceptors.response.use((response) => {
//   return response;
// }, async function (error) {
//   const originalRequest = error.config;
//   if ([401, 403,].includes(error.response.status) && !originalRequest._retry) {
//     originalRequest._retry = true;
//     try {
//       originalRequest.headers['Authorization'] = getAuthToken();
//       axios.defaults.headers.common['Authorization'] = getAuthToken();
//       return axiosClient()(originalRequest);
//     } catch (_error: any) {
//       return Promise.reject(_error);
//     }
//   }
//   return Promise.reject(error);
// });

//
// // Set `RestLink` with your endpoint
// const restLink = new RestLink({
//   uri: Properties.NEW_API_URL,
//   bodySerializers: {
//     fileEncode: (data: any, headers: Headers) => {
//       const formData = new FormData();
//       formData.append('file', data, data.name);
//       formData.append('className', data.className || '');
//       headers.set('Accept', '*/*');
//       return {
//         body: formData,
//         headers,
//       };
//     },
//   },
// });
// const headers = new Headers({
//   // redirect: 'follow', // I tried that - doesn't work
//   'Content-Type': 'application/json',
// });
//
// const defaultOptions: DefaultOptions = {
//   watchQuery: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'ignore',
//   },
//   query: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'all',
//   },
// };
// const authRestLink = new ApolloLink((operation, forward) => {
//   operation.setContext(() => {
//     const token = sessionStorage.getItem('token') ? `Bearer ${sessionStorage.getItem('token')}` : null;
//     return {
//       headers: {
//         ...headers,
//         Accept: 'application/json',
//         Authorization: token,
//       },
//     };
//   });
//   return forward(operation).map((result) => {
//     const { restResponses, } = operation.getContext();
//     // eslint-disable-next-line no-unused-vars
//     const authTokenResponse = restResponses.find((res: { headers: { has: (arg0: string) => any; }; }) => res.headers.has('Authorization'));
//     // You might also filter on res.url to find the response of a specific API call
//     if (authTokenResponse) {
//       sessionStorage.setItem('token', authTokenResponse.headers.get('Authorization'));
//     }
//     return result;
//   });
// });
//
// // Setup your client
// const restClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: ApolloLink.from([authRestLink, restLink,]),
//   defaultOptions: defaultOptions,
// });

export interface Query {
  fields: Array<string> | null,
  keyword: string,
}

function mapSearchKeyword(keyword: string) {
  let value = keyword;
  let field: string[] | null = null;
  const matchingTags = [
    {
      name: 'tag:',
      field: 'tags',
    },
    {
      name: 'key:',
      field: 'labels.key',
    },
    {
      name: 'value:',
      field: 'labels.value',
    },
  ].filter((tag) => keyword.startsWith(tag.name));
  if (matchingTags.length > 0) {
    field = [matchingTags[0].field,];
    value = value.substring(matchingTags[0].name.length, keyword.length);
  }
  return {
    fields: field,
    keyword: value,
  };
}

const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString) {
    return '-';
  }
  return moment(dateString).format('DD MMM YYYY hh:mm:ss');
};

export { mapSearchKeyword, formatDate, };
