import React from 'react';
import { CircularProgress, } from '@mui/material';
import { Interfaces, } from '../../../../config';
import { DataSourceType, } from '../helpers';
import { MetadataHeader, } from './MetadataHeader';
import { NodeMetadata, } from './NodeMetadata';
import { TotalRecordsChart, } from './TotalRecordsChart';

const CatalogMetadata = ({
  catalog,
  setCatalog,
  selectedDataSource,
  dataSourceSchema,
  loadingSchema,
  schemaError,
  selectedNode,
  handleSelectNode,
  totalRecords,
  loadingTotalRecords,
  fetchTotalRecords,
  readOnly,
  external,
  setScrollToNode,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  // eslint-disable-next-line no-unused-vars
  setCatalog: (catalog: Interfaces.InputCatalogMetadata) => void;
  selectedDataSource: DataSourceType;
  dataSourceSchema: Interfaces.Schema;
  loadingSchema: boolean;
  schemaError: boolean;
  selectedNode: Interfaces.Schema | null;
  // eslint-disable-next-line no-unused-vars
  handleSelectNode: (node: Interfaces.Schema | null) => void;
  totalRecords: any;
  loadingTotalRecords: boolean;
  // eslint-disable-next-line no-unused-vars
  fetchTotalRecords: (selectedNode?: Interfaces.Schema) => void;
  readOnly?: boolean;
  external?: boolean;
  // eslint-disable-next-line no-unused-vars
  setScrollToNode?: (node: Interfaces.Schema) => void;
}) => {
  if (loadingSchema || schemaError) {
    return (
      <div style={{
        position: 'relative',
        textAlign: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      >
        {loadingSchema && <CircularProgress />}
        {!loadingSchema && schemaError && <p>Failed to get the schema</p>}
      </div>
    );
  }
  return (
    <>
      {
        selectedNode ? (
          <NodeMetadata
            catalog={catalog}
            selectedDataSource={selectedDataSource}
            selectedNode={selectedNode}
            setCatalog={setCatalog}
            handleSelectNode={(node) => handleSelectNode(node)}
            totalRecords={totalRecords}
            loadingTotalRecords={loadingTotalRecords}
            fetchTotalRecords={fetchTotalRecords}
            readOnly={readOnly}
            setScrollToNode={setScrollToNode}
          />
        ) : (
          <>
            <MetadataHeader
              catalog={catalog}
              schemaError={schemaError}
              selectedDataSource={selectedDataSource}
              dataSourceSchema={dataSourceSchema}
              totalRecords={totalRecords}
              loadingTotalRecords={loadingTotalRecords}
              fetchTotalRecords={fetchTotalRecords}
              external={external}
            />
            {
              selectedDataSource.type === 'JDBC' && (
                <TotalRecordsChart
                  totalRecords={totalRecords}
                  fetchTotalRecords={fetchTotalRecords}
                  loadingTotalRecords={loadingTotalRecords}
                  tableName={''}
                />
              )
            }
          </>
        )
      }
    </>
  );
};

export { CatalogMetadata, };
