import React, { useState, } from 'react';
import {
  Grid,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { Pagination, } from '@mui/lab';

const StyledTableCell = styled(TableCell)(({ theme, }) => ({
  maxWidth: '350px',
  maxHeight: '50px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  [`&.th`]:
    {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  [`&.tbody`]:
    {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme, }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const DataTable = ({ rows, ghostTable, }: { rows: Array<any>, ghostTable?: boolean, }) => {
  const [currentPage, setCurrentPage,] = useState<number>(1);
  const [mockData,] = useState(Array.from({ length: 10, }, (_, index) => ({
    'Col 1': index === 0 ? 'Col 1' : '',
    'Col 2': index === 0 ? 'Col 2' : '',
    'Col 3': index === 0 ? 'Col 3' : '',
    'Col 4': index === 0 ? 'Col 4' : '',
  })));
  const [pageItemCount, setPageItemCount,] = useState<number>(10);

  return (
    <TableContainer
      style={{
        maxHeight: '52vh',
        overflowX: 'auto',
        opacity: ghostTable ? 0.3 : 1,
      }}
      component={Paper}
    >
      <Table stickyHeader aria-label="customized table" >
        <TableHead key="table_head_1">
          <TableRow key="header-1">
            <StyledTableCell style={{ fontWeight: 'bold', }} align="left" width={60} key={0}>#</StyledTableCell>
            {
              Object.keys(ghostTable ? mockData[0] : rows[0]).map((key) => (
                <StyledTableCell style={{ fontWeight: 'bold', }} align="left" key={key}>{key}</StyledTableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (ghostTable ? mockData : rows).map((row: any, index: any) => {
              if (
                (index > 0) &&
                (index >= (((currentPage - 1) * pageItemCount)) + 1) &&
                (index < (currentPage * pageItemCount) + 1)
              ) {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left" key={`${index}_${0}`}>{index}</StyledTableCell>
                    {Object.keys(ghostTable ? mockData[0] : rows[0]).map((value: any) => (
                      <StyledTableCell align="left" key={`${index}_${value}`}>
                        {
                          typeof row[value] === 'object' ? JSON.stringify(row[value]) : row[value]
                        }
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                );
              }
            })
          }
        </TableBody>
      </Table>
      <Grid container style={{ marginTop: 10,
        marginBottom: 10, }}>
        <Grid item xs={10} style={{ marginTop: 6, }}>
          {
            rows && rows.length - 1 > 10 && (
              <Pagination
                count={Math.ceil((rows.length - 1) / pageItemCount)}
                page={currentPage}
                onChange={(_, pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            )
          }
        </Grid>
        <Grid item xs={1}>
          {
            rows.length > 10 && (
              <Tooltip title="Set limit">
                <TextField
                  style={{
                    margin: 1,
                    minWidth: 100,
                    marginLeft: 30,
                  }}
                  name="limit"
                  select
                  label="Limit"
                  variant="outlined"
                  size="small"
                  value={pageItemCount}
                  onChange={(event: any) => {
                    setPageItemCount(event.target.value || 1);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </TextField>
              </Tooltip>
            )
          }
        </Grid>
      </Grid>
    </TableContainer>
  );
};

export { DataTable, };
