import { Constants, } from '../config';
import { axiosClient, } from '../config/helpers';

export const runDetailsServices = {
  fetchRunDetails,
  fetchRunTimeline,
};

function fetchRunDetails(runId: string) {
  return axiosClient().get(`${Constants.api.endpoints.searchSnapshots}/${runId}`);
}

function fetchRunTimeline(catalogId: string) {
  return axiosClient().get(`${Constants.api.endpoints.searchSnapshots}/catalog/${catalogId}`);
}
