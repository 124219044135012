import React from 'react';
import {
  Tooltip,
  Typography,
  Toolbar,
} from '@mui/material';
import { makeStyles, } from '@mui/styles';
import { StyledTextField, } from '../../../Reusable';

const useStyles = makeStyles({
  root: {
    paddingLeft: '10px',
    paddingRight: '5px',
  },
  title: {
    flex: '1 1 45%',
  },
});

function DataSourceTableToolbar(props: any) {
  const classes = useStyles();
  const { handleSearch, } = props;
  return (
    <div style={ { marginTop: '20px', } }>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Connections
        </Typography>
        <Tooltip title="Search by name">
          <StyledTextField
            className="align-items-end"
            size={'small'}
            name="search"
            label="Search"
            color="primary"
            onChange={(event) => {
              handleSearch(event);
            }}
          />
        </Tooltip>
      </Toolbar>
    </div>
  );
}

export default DataSourceTableToolbar;
