import { makeStyles, } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  mainGrid: {
    minHeight: '85vh',
  },
  formGrid: {
    paddingInline: '30px',
  },
  logoDiv: {
    textAlign: 'center',
  },
  logo: {
    textAlign: 'center',
    width: '80%',
  },
  logoName: {
    fontWeight: 600,
    textAlign:'center',
  },
  logoEmail: {
    width: '40%',
  },
  error: {
    color: '#c62828',
  },
  button: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  bottomText: {
    textAlignLast: 'center',
  },
  bottomLink: {
    color: 'blue',
    textDecoration: 'underline',
    display: 'inline-flex',
    cursor: 'pointer',
  },
  versionDiv: {
    textAlignLast: 'center',
    marginTop: 10,
  },
  version: {
    fontWeight: 'bold',
    color: 'gray',
  },
  passwordErrorDiv: {
    border: '1px solid #cccccc',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '10px',
  },
  flexCenterStart1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  flexCenterStart2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'green',
  },
  termsOfUse: {
    fontSize: '12px',
  },
  marginRightCheckbox: {
    marginRight: '16px',
  },
}));
