import * as React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@mui/material';
import {
  ArrowDropUp,
  ArrowDropDown,
} from '@mui/icons-material';
import { useAuth, } from '../../../context';

const SaveAndRunButton = ({
  handleSaveClick,
  handleSaveAndRunClick,
  disabled,
  loading,
}: {
  handleSaveClick: any;
  handleSaveAndRunClick: any;
  disabled: boolean;
  loading: boolean;
}) => {
  const [open, setOpen,] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { isAuthorized, } = useAuth();
  const [writeAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_WRITE'));
  const [executeAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_EXECUTE'));

  const handleClose = (event: { target: any; }) => {
    if (anchorRef.current && (anchorRef?.current as any).contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        disabled={disabled}
        variant='contained'
        ref={anchorRef}
        color={'primary'}
        aria-label="split button"
        style={ { marginRight:'10px', } }
      >
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={handleSaveAndRunClick}
          disabled={!writeAccess && !executeAccess }
          style={{
            width: 144,
          }}
        >
          {loading ? 'Loading..' : 'Save and Run'}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement, }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {
                  writeAccess ? (
                    <>
                      <Button
                        onClick={handleSaveClick}
                        color={'primary'}
                        variant={'outlined'}
                        style={{
                          marginBottom: 1,
                          width: 184,
                        }}
                      >
                        {loading ? 'Loading...' : 'Save'}
                      </Button>
                    </>
                  ) : <></>
                }
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export { SaveAndRunButton, };
