import React, { useState, } from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DeploymentTableRow, } from './DeploymentTableRow';
import { Interfaces, } from '../../../config';
import { deploymentServices, } from '../../../services';
import { useSnackbar, } from 'notistack';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation';
import { NoDataComponent, } from '../../NoDataComponent';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
    maxHeight: 450,
  },
});

const DeploymentTable = ({
  deployments,
  updateDeploymentList,
}: {
  deployments: Array<Interfaces.Deployment>;
  // eslint-disable-next-line no-unused-vars
  updateDeploymentList: (deployments: Array<Interfaces.Deployment>) => void
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const [expanded, setExpanded,] = useState<Array<string>>([]);
  const [selectedDeployment, setSelectedDeployment,] = useState<Interfaces.Deployment | null>(null);

  if (deployments.length === 0) {
    return (
      <Paper
        style={{
          height: 'calc(100vh - 325px)',
          width: '100%',
          overflowY: 'scroll',
          backgroundColor: 'white',
          padding: 10,
          borderRadius: 7,
        }}
        elevation={0}
      >
        <NoDataComponent
          data={{
            component: 'deployments',
            text1: 'Collection is not deployed yet',
          }}
        />
      </Paper>
    );
  }

  return (
    <Paper
      style={{
        height: 'calc(100vh - 325px)',
        width: '100%',
        overflowY: 'scroll',
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 7,
      }}
      elevation={0}
    >
      <ModalConfirmation
        open={!!selectedDeployment}
        onClose={() => setSelectedDeployment(null)}
        title= {`Are you sure you want to delete deployment?`}
        onBtnClick={() => {
          selectedDeployment && deploymentServices.deleteDeployment(selectedDeployment.id)
            .then(() => {
              updateDeploymentList(deployments.filter((deployment) => deployment.id !== selectedDeployment.id));
              enqueueSnackbar(`Successfully deleted deployment`, { variant: 'success', });
            })
            .catch(() => {
              enqueueSnackbar('Failed to delete deployment', { variant: 'error', });
            })
            .finally(() => setSelectedDeployment(null));
        }}
        colorBtn={'secondary'}
        btnText={'Delete'}
        onCancelBtnClick={() => setSelectedDeployment(null)}

      />
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Executed</TableCell>
              <TableCell align="left">Target</TableCell>
              <TableCell align="left">Tests</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deployments.map((deployment: any) => (
              <DeploymentTableRow
                key={deployment.id}
                deployment={deployment}
                expanded={expanded.includes(deployment.id)}
                setExpand={() => {
                  if (expanded.includes(deployment.id)) {
                    setExpanded(expanded.filter((id) => id !== deployment.id));
                  } else {
                    setExpanded([...expanded, deployment.id,]);
                  }
                }}
                handleDelete={(deployment) => {
                  setSelectedDeployment(deployment);
                }}
                handleDownload={(deploymentId) => {
                  deploymentServices.downloadDeployment(deploymentId)
                    .then(() => {
                      enqueueSnackbar('Download success', { variant: 'success', });
                    })
                    .catch(() => {
                      enqueueSnackbar('Download fail', { variant: 'error', });
                    });
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DeploymentTable;
