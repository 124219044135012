import React, { useState, } from 'react';
import { FiberManualRecord, } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
} from '@mui/material';


const TestButton = ({ testStatus, testAction, }: { testStatus: boolean | null, testAction: () => void }) => {
  const [isInitialized, setIsInitialized,] = useState(false);
  const getIcon = () => {
    if (!isInitialized) {
      return <FiberManualRecord style={{ fill: 'white', }} />;
    }
    if (testStatus === null) {
      return <CircularProgress
        color="inherit"
        style={{
          width: 12,
          height: 12,
          marginRight: 6,
          marginLeft: 4,
        }}
      />;
    }
    if (testStatus) {
      return <FiberManualRecord style={{ fill: 'green', }} />;
    }
    return <FiberManualRecord style={{ fill: 'red', }} />;
  };
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => {
        setIsInitialized(true);
        testAction();
      }}
      endIcon={getIcon()}
    >
      Test
    </Button>
  );
};

export { TestButton, };
