import { makeStyles, } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  methodInput: {
    width: '170px',
    '& .MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    margin: 7,
  },
  urlInput: {
    '& .MuiInputBase-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));
