import React from 'react';
import {
  Button,
  ButtonGroup,
} from '@mui/material';
import {
  GetApp,
  Delete,
} from '@mui/icons-material';


const ActionsColumn = ({
  handleDelete,
  handleDownload,
} : {
  handleDelete: () => void;
  handleDownload: () => void;
}) => {
  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button onClick={handleDownload}>
        <GetApp/>
      </Button>
      <Button onClick={handleDelete}>
        <Delete/>
      </Button>
    </ButtonGroup>
  );
};

export default ActionsColumn;

