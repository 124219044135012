import React from 'react';
import { SearchInput, } from './SearchInput/SearchInput';
import { NoSearch, } from './NoSearch/NoSearch';
import {
  Box,
  Grid,
} from '@mui/material';
import { useAuth, } from '../../context';

function Search() {
  const { isAuthorized, } = useAuth();
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_SEARCH_READ'));

  return (
    <div style={{
      paddingTop: '64px',
      inset: 0,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      background: 'radial-gradient(circle, rgba(232,232,232,0.15730042016806722) 6%, rgba(218,218,218,0.33657212885154064) 41%, rgba(227,200,168,0.1741071428571429) 52%, rgba(255,145,13,0.14329481792717091) 82%)',
    }}>
      <Grid justifyContent="center" paddingX={'100px'} container style={ { marginTop:'50px', } }>
        <Box alignItems="center" justifyContent="center" sx={{ background: '#fff',
          height: 'fit-content',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', }}>
          { readAccess && (
            <>
              <SearchInput/>
              <NoSearch/>
            </>
          )}
        </Box>
      </Grid>
    </div>
  );
}

export default Search;
