import React, { useMemo, } from 'react';
import { Interfaces, } from '../../../../config';
import { TreeItems, } from './TreeItems';
import { TreeItem, } from '@mui/lab';

const RenderTree = ({
  schema,
  dataSourceId,
  originalSchema,
}: {
  schema: Interfaces.Schema;
  dataSourceId: string;
  originalSchema: Interfaces.Schema;
}) => {
  const _fields = useMemo(() => {
    return schema.fields || [];
  }, [schema,]);
  return (
    <TreeItem
      key={schema.name}
      nodeId={schema.properties.path}
      label={
        schema.sourceType === 'schema' ?
          <TreeItems.Schema label={schema.name}/>
          : schema.sourceType === 'table' ?
            <TreeItems.Table field={schema} originalSchema={originalSchema} label={schema.name} dataSourceId={dataSourceId}/>
            : schema.sourceType === 'view' ?
              <TreeItems.View label={schema.name} />
              : <TreeItems.Column label={schema.name} />
      }
    >
      {
        _fields.map((field) => (
          <RenderTree originalSchema={originalSchema} key={field.name} schema={field} dataSourceId={dataSourceId}/>
        ))
      }
    </TreeItem>
  );
};

export { RenderTree, };
