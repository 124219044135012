import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: 'calc(100vh - 60px)',
    minWidth: 350,
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    minWidth: 310,
    padding: '20px 0 10px 0',
    margin: '0 20px 0 20px',
    borderBottom: '2px solid #ccc',
  },
  header: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  links: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    padding: '5px 20px 15px 20px',

  },
  link: {
    display: 'flex',
    alignItems: 'end',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    fontWeight: 800,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  noAlerts: {
    padding: '30px 20px 15px 20px',
  },
}));
