import React from 'react';

export function NoSearch() {
  return (
    <div>
      <p className="f-s-11">
        <span className="bold f-s-16">Tips: </span> <br/>
        You can search by tags, field names, table names, database name, and more. Here’s a list
        of supported filters:
      </p>
      <ul>
        <li>string</li>
        <li>wild card: *(string)*</li>
        <li>tag: &#x3C;tag name (string)&#x3E; </li>
        <li>key: &#x3C;key name (string)&#x3E; </li>
        <li>val: &#x3C;(string)&#x3E; </li>
      </ul>
    </div>
  );
}
