import React from 'react';
import {
  AccountTreeRounded,
  CodeSharp,
  ImportExport,
  LocalOffer,
  MenuBook,
  NotificationsActive,
  Schedule,
  Search,
  SupervisorAccount,
} from '@mui/icons-material';

import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faDatabase, } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as CollectionSvg, } from '../../assets/icons/collection.svg';
import { Interfaces, } from '../../config';


const iconStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
  margin:'5 2 5 2px',
  width: '20px',
  height: '20px',
};
const DS_ICON = () => (
  <FontAwesomeIcon
    icon={faDatabase}
    color="inherit"
    size="lg"
    style={ iconStyle}
  />
);

const COL_ICON = () => (
  <CollectionSvg style={ iconStyle} />
);

interface SideBarElementType {
  path: string;
  text: string;
  icon: any;
  permission: Interfaces.AuthorityType;
}

export const SideBarElements: Array<SideBarElementType> = [
  {
    path: '/data-sources',
    text: 'Data Sources',
    icon: DS_ICON,
    permission: 'ADAPTIVE_DS_READ',
  },
  {
    path: '/catalogs',
    text: 'Catalogs',
    icon: MenuBook,
    permission: 'ADAPTIVE_CAT_READ',
  },
  {
    path: '/tags',
    text: 'Tags',
    icon: LocalOffer,
    permission: 'ADAPTIVE_TAG_READ',
  },
  {
    path: '/schedules',
    text: 'Schedules',
    icon: Schedule,
    permission: 'ADAPTIVE_SCHED_READ',
  },
  {
    path: '/schema-evolution',
    text: 'Schema Evolution',
    icon: AccountTreeRounded,
    permission: 'ADAPTIVE_EV_READ',
  },
  {
    path: '/search',
    text: 'Search',
    icon: Search,
    // not sure about following
    permission: 'ADAPTIVE_SEARCH_READ',
  },
  {
    path: '/sql',
    text: 'SQL',
    icon: CodeSharp,
    // not sure about following - I think we don't need this we only need DS read and DS execute to show this tab
    permission: 'ADAPTIVE_SQLEXPL_READ',
  },
  {
    path: '/data-transfer',
    text: 'Data Transfer',
    icon: ImportExport,
    permission: 'ADAPTIVE_DT_READ',
  },
  {
    path: '/models',
    text: 'Models',
    icon: COL_ICON,
    permission: 'ADAPTIVE_COL_READ',
  },
  {
    path: '/alerts',
    text: 'Alerts',
    icon: NotificationsActive,
    permission: 'ADAPTIVE_ALERT_READ',
  },
  {
    path: '/super/admin',
    text: 'Admin',
    icon: SupervisorAccount,
    permission: 'ADAPTIVE_ADMIN',
  },
];
