import React from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { DataSourceType, } from '../helpers';
import { useStyles, } from '../styles';
import { Interfaces, } from '../../../../config';

const CatalogFormInputFields = ({
  catalog,
  setCatalog,
  loadingSchema,
  inEditMode,
  dataSources,
  selectedDataSource,
  setSelectedDataSource,
  setFieldSelectionMode,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  // eslint-disable-next-line no-unused-vars
  setCatalog: (catalog: Interfaces.InputCatalogMetadata) => void;
  loadingSchema: boolean;
  inEditMode: boolean;
  dataSources: Array<DataSourceType>;
  selectedDataSource: DataSourceType | null;
  // eslint-disable-next-line no-unused-vars
  setSelectedDataSource: (dataSourceId: string) => void;
  // eslint-disable-next-line no-unused-vars
  setFieldSelectionMode: (mode: boolean) => void;
}) => {
  const classes = useStyles();
  return (
    <div style={{ padding: '10px 10px 0 10px', }}>
      <TextField
        name="name"
        id="standard-basic"
        label="Catalog Name"
        variant="outlined"
        style={{ marginBottom: 10, }}
        value={catalog.name}
        defaultValue={inEditMode ? 'catalog-name' : ''}
        fullWidth
        size="small"
        disabled={loadingSchema}
        onChange={(event) => {
          setCatalog({
            ...catalog,
            name: event.target.value,
          });
        }}
      />
      <FormControl
        fullWidth
        size="small"
      >
        <InputLabel>Data Source</InputLabel>
        <Select
          id='catalog-datasource-select'
          variant={'outlined'}
          placeholder={'Data Source'}
          disabled={inEditMode || loadingSchema}
          fullWidth
          label={'Data Source'}
          value={selectedDataSource?.id || ''}
          onChange={(event) => {
            setSelectedDataSource(event.target.value as string);
          }}
        >
          {
            dataSources.map((ds: DataSourceType, index: number) => (
              <MenuItem
                value={ds.id}
                key={`${ds.id}-${index}`}
              >
                {ds.name}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControlLabel
        className={classes.marginTop}
        control={
          <Switch
            color={'primary'}
            checked={catalog.includeAllSchemas}
            onChange={(event) => setFieldSelectionMode(event.target.checked)}
          />
        }
        label="Include all schemas"
      />
    </div>
  );
};

export { CatalogFormInputFields, };
