import {
  Constants,
  Interfaces,
} from '../config';
import { axiosClient, } from '../config/helpers';

export const datasourceServices = {
  fetchSupportedDrivers,
  fetchDataSources,
  fetchDataSource,
  addDataSource,
  editDataSource,
  deleteDataSource,
  testDataSource,
  testExistingDataSource,
  uploadDriver,
  executeQuery,
  generateQuery,
  fetchGCSDirectories,
  fetchS3Directories,
  fetchDirectories,
  fetchDBMSColumnTypes,
  fetchDatabase,
  getTableDDL,
};

function fetchSupportedDrivers() {
  return axiosClient().get(Constants.api.endpoints.supportedDrivers);
}

function fetchDataSources() {
  return axiosClient().get(Constants.api.endpoints.dataSources);
}

function fetchDataSource(dataSourceName: string) {
  return axiosClient().get(`${Constants.api.endpoints.dataSources}${dataSourceName}`);
}

function addDataSource(
  body:
    Interfaces.BaseDataSource |
    Interfaces.JDBCDataSource |
    Interfaces.GoogleBucketDataSource |
    Interfaces.S3DataSource |
    Interfaces.AzureDataSource
) {
  return axiosClient().post(`${Constants.api.endpoints.dataSource}${body.type.toLowerCase()}`, body);
}

function editDataSource(
  body:
    Interfaces.BaseDataSource |
    Interfaces.JDBCDataSource |
    Interfaces.GoogleBucketDataSource |
    Interfaces.S3DataSource |
    Interfaces.AzureDataSource,
  oldName: string
) {
  return axiosClient().patch(`${Constants.api.endpoints.dataSource}${body.type.toLowerCase()}/${oldName}`, body);
}

function deleteDataSource(dataSourceName: string) {
  return axiosClient().delete(`${Constants.api.endpoints.dataSources}${dataSourceName}`);
}

function testDataSource(
  dataSource:
    Interfaces.BaseDataSource |
    Interfaces.JDBCDataSource |
    Interfaces.GoogleBucketDataSource |
    Interfaces.S3DataSource |
    Interfaces.AzureDataSource
) {
  return axiosClient()
    .post(`${Constants.api.endpoints.dataSource}test/${dataSource.type.toLowerCase()}`, dataSource);
}

function testExistingDataSource(
  dataSource:
    Interfaces.BaseDataSource |
    Interfaces.JDBCDataSource |
    Interfaces.GoogleBucketDataSource |
    Interfaces.S3DataSource |
    Interfaces.AzureDataSource
) {
  return axiosClient()
    .post(`${Constants.api.endpoints.dataSources}${dataSource.id}/test/${dataSource.type.toLowerCase()}`, dataSource);
}

function uploadDriver(driverFile: any, driverClassName: string) {
  const formData = new FormData();
  formData.append('file', driverFile);
  formData.append('className', driverClassName);

  return axiosClient().post(
    Constants.api.endpoints.driver,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
}

function executeQuery(dataSourceName: string, statement: string, limit: number, abortSignal?: AbortSignal) {
  const config = abortSignal ? { signal: abortSignal, } : {};
  return axiosClient().post(`${Constants.api.endpoints.data}${dataSourceName}`, {
    statement: statement,
    limit: limit,
  }, config);
}

function generateQuery(dataSourceId: string, query: string) {
  return axiosClient().post(`${Constants.api.endpoints.data}query`, {
    dataSourceId,
    query,
  });
}

function fetchGCSDirectories(body: Interfaces.GCSDirectoriesRequest) {
  return axiosClient().post(Constants.api.endpoints.gcsDirectories, body);
}

function fetchS3Directories(body: Interfaces.S3DirectoriesRequest) {
  return axiosClient().post(Constants.api.endpoints.s3Directories, body);
}

function fetchDirectories(body: Interfaces.GCSDirectoriesRequest | Interfaces.S3DirectoriesRequest | Interfaces.AzureDirectoriesRequest | Interfaces.FTPDirectoriesRequest, endpoint: string) {
  return axiosClient().post(endpoint, body);
}

function fetchDBMSColumnTypes(dbmsName: string) {
  return axiosClient().get(`${Constants.api.endpoints.supportedDrivers}/${dbmsName}/types`);
}

function fetchDatabase(dataSourceId: string) {
  return axiosClient().get(`${Constants.api.endpoints.dataSources}${dataSourceId}/database`);
}

function getTableDDL(dataSourceId: string, body: Interfaces.Schema) {
  return axiosClient().post(`${Constants.api.endpoints.dataSources}${dataSourceId}/dsl`, body);
}
