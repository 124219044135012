import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

function CatalogTableHead(props: any) {
  const headCells = [
    {
      id: '',
      numeric: false,
      disablePadding: false,
      label: '',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Catalog Name',
    },
    {
      id: 'dataSourceDescription',
      numeric: false,
      disablePadding: false,
      label: 'Data Source',
    },
    {
      id: 'lastRun',
      numeric: false,
      disablePadding: false,
      label: 'Last Run',
    },
    {
      id: 'nextRun',
      numeric: false,
      disablePadding: false,
      label: 'Next Run',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
    },
  ];

  const createSortHandler = (property: any) => (event: any) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Typography variant="subtitle2" align="center">
            #
          </Typography>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              props.orderBy === headCell.id ? props.order : false
            }
          >
            <TableSortLabel
              active={props.orderBy === headCell.id}
              direction={
                props.orderBy === headCell.id ? props.order : 'asc'
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {props.orderBy === headCell.id ? (
                <span className={props.classes.visuallyHidden}>
                  {props.order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default CatalogTableHead;
