import React from 'react';
import classes from './EvolutionDetails.module.css';
import { Interfaces, } from '../../../config';
import {
  GroupedSnapshotsType,
  SchemaChange,
} from '../interfaces';
import { Header, } from './Header';
import { SchemaView, } from '../../SchemaView';
import { KonvaComparator, } from './KonvaComparator';
import { extractSelectedFields, } from '../helpers';
import { CircularProgress, } from '@mui/material';

type Props = {
  schemaToCompare?: {snapshotA: Interfaces.Schema, snapshotB: Interfaces.Schema, changes: SchemaChange};
  handleClose: () => void;
  snapshots: Array<GroupedSnapshotsType>;
  // eslint-disable-next-line no-unused-vars
  handleCompare: (snapshotA: Interfaces.Schema, snapshotB: Interfaces.Schema) => void;
}

const EvolutionDetails: React.FC<Props> = ({
  schemaToCompare,
  handleClose,
  snapshots,
  handleCompare,
}) => {
  const [loading, setLoading,] = React.useState<boolean>(true);
  const [schema, setSchema,] = React.useState<{snapshotA: Interfaces.Schema, snapshotB: Interfaces.Schema, changes: SchemaChange}>();

  const expandedFields = React.useMemo(() => {
    if (schemaToCompare) {
      return extractSelectedFields(schemaToCompare.snapshotA, schemaToCompare.snapshotB) ?? {
        schemaA: [],
        schemaB: [],
      };
    }
    return {
      schemaA: [],
      schemaB: [],
    };
  }, [schemaToCompare,]);

  React.useEffect(() => {
    setLoading(true);
    setSchema(undefined);
    setTimeout(() => {
      setSchema(schemaToCompare);
      setLoading(false);
    }, 500);
  }, [schemaToCompare,]);

  if (!schemaToCompare) {
    handleClose();
    return <></>;
  }

  return (
    <div className={classes.container}>
      {schemaToCompare && (
        <Header
          onClose={handleClose}
          schemaToCompare={schemaToCompare}
          snapshots={snapshots}
          handleCompare={handleCompare}
        />
      )}
      <div className={classes.body}>
        <div className={classes.canvasContainer}>
          {schemaToCompare && <KonvaComparator newSchema={schemaToCompare?.snapshotA} oldSchema={schemaToCompare?.snapshotB} />}
        </div>
        <div className={classes.treeViewContainer}>
          {!loading && schema?.snapshotA && (
            <div className={classes.schemaSide}>
              <SchemaView
                schema={schema?.snapshotA}
                expanded={expandedFields.schemaA}
              />
            </div>
          )}
          {!loading && schema?.snapshotB && (
            <div className={classes.schemaSide}>
              <SchemaView
                schema={schema?.snapshotB}
                expanded={expandedFields.schemaB}
              />
            </div>
          )}
          {loading && (
            <>
              <div className={classes.schemaSide}>
                <div className={classes.loaderContainer}>
                  <CircularProgress />
                  <div className={classes.loaderText}>
                    Loading Schema Tree
                  </div>
                </div>
              </div>
              <div className={classes.schemaSide}>
                <div className={classes.loaderContainer}>
                  <CircularProgress />
                  <div className={classes.loaderText}>
                    Loading Schema Tree
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { EvolutionDetails, };
