import React from 'react';
import {
  Stage,
  Layer,
  Line,
  Rect,
} from 'react-konva';
import { DBDiagramTable, } from './DBDiagramTable';
import { Interfaces, } from '../../../../config';
import {
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useSidebar, } from '../../../../context';

interface Coordinate {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  columnPositionStart: number;
  columnPositionEnd: number;
}

const DBDiagram = ({ catalog, }: { catalog: Interfaces.InputCatalogMetadata, }) => {
  const ROW_LENGTH = 5;
  const COLUMN_HEIGHT = 25;
  const TABLE_WIDTH = 350;
  const [tableHeight, setTableHeight,] = React.useState<number>(500);
  const initialX = 30;
  const initialY = 20;
  const [selectedSchema, setSelectedSchema,] = React.useState<Interfaces.Schema>(
    (catalog.schema?.fields[0]?.sourceType === 'table' || catalog.schema?.fields[0]?.sourceType === 'view') ? catalog.schema : catalog.schema?.fields[0]
  );
  const [coordinates, setCoordinates,] = React.useState<Array<Coordinate>>([]);
  const [selectedCoordinates, setSelectedCoordinates,] = React.useState<Array<Coordinate>>([]);
  const [selectedFields, setSelectedFields,] = React.useState<Array<Interfaces.FieldWithPosition>>([]);
  const [stage, setStage,] = React.useState({
    scale: 1,
    x: 0,
    y: 0,
  });
  const cellSize = 20;
  const { isOpen, } = useSidebar();


  const dottedBackground = (
    <Layer
      x={-5000}
      y={-5000}
    >
      {[...Array(500),].map((_, i) => (
        <Rect
          key={i}
          x={i * cellSize}
          y={0}
          width={0}
          height={window.innerHeight + 10000}
          dash={[1, cellSize - 1,]}
          stroke="#ccc"
          strokeWidth={1}
        />
      ))}
    </Layer>
  );

  const handleWheel = (e: any) => {
    e.evt.preventDefault();

    const scaleBy = 1.06;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStage({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };

  const fieldsWithPosition = React.useMemo(() => {
    const tmpSchema: Interfaces.FieldWithPosition = {
      ...selectedSchema,
      fields: [],
      positionX: 0,
      positionY: 0,
    };
    let maxFields = 0;
    selectedSchema?.fields?.forEach((field, index) => {
      const tmpField: Interfaces.FieldWithPosition = {
        ..._.cloneDeep(field) as Interfaces.FieldWithPosition,
        positionX: index % ROW_LENGTH,
        positionY: Math.floor(index / ROW_LENGTH),
      };
      tmpField.positionX = index % ROW_LENGTH;
      tmpField.positionY = Math.floor(index / ROW_LENGTH);
      if (tmpField.fields.length > maxFields) {
        maxFields = tmpField.fields.length;
      }
      tmpSchema.fields.push(tmpField);
    });
    setTableHeight(maxFields * COLUMN_HEIGHT + 60);
    return tmpSchema.fields;
  }, [selectedSchema,]);

  React.useMemo(() => {
    const coordinates: Array<Coordinate> = [];
    fieldsWithPosition.forEach((table) => {
      table?.fields.forEach((column, idx1) => {
        if (column.properties?.foreignKeys?.length > 0) {
          const path =
            `${column.properties?.foreignKeys[0]?.primaryTableSchema}.`
            + `${column.properties?.foreignKeys[0]?.primaryTableName}.`;

          for (const tmpField of fieldsWithPosition) {
            if (tmpField?.properties?.path === path) {
              let idx2 = 0;
              // eslint-disable-next-line no-unsafe-optional-chaining
              for (const column2 of tmpField?.fields) {
                if (column2?.name === column?.properties?.foreignKeys[0]?.primaryColumnName) {
                  break;
                }
                idx2 += 1;
              }
              coordinates.push({
                startX: table.positionX,
                startY: table.positionY,
                endX: tmpField.positionX,
                endY: tmpField.positionY,
                columnPositionStart: idx1,
                columnPositionEnd: idx2,
              });
              break;
            }
          }

        }
      });
    });
    setCoordinates(coordinates);
  }, [fieldsWithPosition,]);

  return (
    <div style={{ marginTop: 12, }}>
      <TextField
        style={{
          marginRight: 20,
          minWidth: 200,
        }}
        variant="outlined"
        select
        size="small"
        label="Schema"
        value={selectedSchema?.name}
        disabled={catalog.schema?.fields[0].sourceType === 'table' || catalog.schema?.fields[0].sourceType === 'view'}
        onChange={(e) => {
          const tmpSchema = catalog.schema?.fields?.find((field) => field.name === e.target.value);
          if (tmpSchema) {
            setSelectedSchema(tmpSchema);
          }
        }}
      >
        {
          catalog.schema.fields.map((schema) => (
            <MenuItem key={schema.name} value={schema.name}>
              {schema.name}
            </MenuItem>
          ))
        }
      </TextField>
      <Typography
        style={{
          float: 'right',
          fontWeight: 'bold',
          fontSize: '20px',
          color: 'gray',
        }}
      >
        {`Zoom: ${(stage.scale * 100).toFixed(0)} %`}
      </Typography>
      <Stage
        width={window.innerWidth - (isOpen ? 338 : 142)}
        height={window.innerHeight - 224}
        onWheel={handleWheel}
        draggable
        scaleX={stage.scale}
        scaleY={stage.scale}
        x={stage.x}
        y={stage.y}
        style={{
          border: '1px solid lightGray',
          borderRadius: 7,
          marginTop: 8,
          zIndex: -1,
        }}
      >
        {dottedBackground}
        <Layer x={initialX} y={initialY}>
          {
            coordinates.map((coordinate, index) => (
              <DiagramLine
                key={index}
                isSelected={_.some(selectedCoordinates, coordinate)}
                tableWidth={TABLE_WIDTH}
                tableHeight={tableHeight}
                coordinate={coordinate}
                columnHeight={COLUMN_HEIGHT}
                initialY={initialY}
              />
            ))
          }
        </Layer>
        {
          <Layer
            x={initialX}
            y={initialY}
          >
            {
              fieldsWithPosition.map((field, index) => (
                <DBDiagramTable
                  key={index}
                  detailed={true}
                  sourceType={field.sourceType}
                  columnHeight={COLUMN_HEIGHT}
                  table={field}
                  fields={field.fields}
                  x={field.positionX * TABLE_WIDTH}
                  y={field.positionY * tableHeight}
                  selectedFields={selectedFields}
                  handleSelect={(index) => {
                    const _selected: Array<Coordinate> = [];
                    const _rest: Array<Coordinate> = [];
                    const _selectedFields: Array<Interfaces.FieldWithPosition> = [];
                    coordinates.forEach((_coordinate) => {
                      const _isSelected = (
                        _coordinate.startX === field.positionX
                        && _coordinate.startY === field.positionY
                        && _coordinate.columnPositionStart === index
                      ) || (
                        _coordinate.endX === field.positionX
                        && _coordinate.endY === field.positionY
                        && _coordinate.columnPositionEnd === index
                      );
                      if (_isSelected) {
                        _selected.push(_coordinate);
                      } else {
                        _rest.push(_coordinate);
                      }
                    });
                    fieldsWithPosition.forEach((_field) => {
                      _selected.forEach((coordinate) => {
                        if (coordinate.startX === _field.positionX && coordinate.startY === _field.positionY) {
                          _field.matchingIndex = coordinate.columnPositionStart;
                          _selectedFields.push(_field);
                        }
                      });
                    });
                    field.matchingIndex = index;
                    setSelectedFields([field, ..._selectedFields,]);
                    setCoordinates([..._rest, ..._selected,]);
                    setSelectedCoordinates(_selected);
                  }}
                />
              ))
            }
          </Layer>
        }
      </Stage>
    </div>
  );
};

const DiagramLine = ({
  coordinate,
  isSelected,
  initialY,
  tableHeight,
  tableWidth,
  columnHeight,
}: {
  coordinate: Coordinate;
  isSelected?: boolean;
  initialY: number;
  tableHeight: number;
  tableWidth: number;
  columnHeight: number;
}) => {
  const calculatedP: Coordinate = {
    ...coordinate,
    startX: coordinate.startX * tableWidth,
    startY: (coordinate.startY * tableHeight + initialY - 10) + (coordinate.columnPositionStart + 1) * columnHeight,
    endX: coordinate.endX * tableWidth,
    endY: (coordinate.endY * tableHeight + initialY - 10) + (coordinate.columnPositionEnd + 1) * columnHeight,
  };
  return (
    <Line
      points={[
        calculatedP.startX, calculatedP.startY,
        calculatedP.startX - 50, calculatedP.startY,
        calculatedP.startX - 50, calculatedP.startY - 75,
        calculatedP.endX - 50, calculatedP.startY - 75,
        calculatedP.endX - 50, calculatedP.endY,
        calculatedP.endX, calculatedP.endY,
      ]}
      stroke={isSelected ? 'blue' : 'lightGray'}
      strokeWidth={4}
    />
  );
};

export { DBDiagram, };
