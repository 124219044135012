import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  Paper,
  Popper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { TableDesigner, } from '../TableDesigner';
import { SchemaTree, } from '../SchemaTree';
import {
  addIndexedUID,
  emptyTable,
  removeAdditionalFields,
  validateTable,
} from '../helpers';
import { Interfaces, } from '../../../config';
import { useSnackbar, } from 'notistack';
import {
  collectionServices,
  datasourceServices,
  gitConfigServices,
} from '../../../services';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AxiosResponse, } from 'axios';
import { helpers, } from '../../../utils';
import LoadingComponent from '../../Loading';
import _ from 'lodash';
import { TabPanel, } from './TabPanel';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/snippets/yaml';
import 'ace-builds/src-noconflict/ext-error_marker';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import {
  stringify as yamlStringify,
  parse as yamlParser,
} from 'yaml';
import {
  ArrowDropDown,
  ArrowDropUp,
  ListAlt,
  Code,
} from '@mui/icons-material';
import { v4, } from 'uuid';
import { DeleteDialog, } from '../DeleteDialog';
import GitIcons from '../../AdminPanel/GitConfigs/GitIcons';
import dbtIcon from '../../../assets/icons/dbt.svg';
import { withStyles, } from '@mui/styles';
import { DBTModels, } from '../DBTModels/DBTModels';
import { YAMLEditor, } from './YAMLEditor';

const StyledTab = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}))(Tab);

const emptyDatabase: Interfaces.ModelDatabase = {
  databaseProductName: '',
  databaseType: '',
  tables: [],
};

const emptyModel: Interfaces.Model = {
  name: '',
  version: '1',
  description: '',
  dataSourceId: '-1',
  database: emptyDatabase,
};

type Params = {
  id: string;
}

const AddModel = () => {
  const history = useNavigate();
  const { id, } = useParams<Params>();
  const { enqueueSnackbar, } = useSnackbar();
  const inEditMode = !!id;

  const [loading, setLoading,] = useState(true);
  const [model, setModel,] = useState<Interfaces.Model>(emptyModel);
  const [yamlModel, setYamlModel,] = useState<string>(yamlStringify(model));
  const [selectedTable, setSelectedTable,] = useState<Interfaces.ModelTable>();
  const [tableToDelete, setTableToDelete,] = useState<Interfaces.ModelTable | null>(null);
  const [schemaToDelete, setSchemaToDelete,] = useState<string | null>(null);
  const [dataSources, setDataSources,] = useState<Array<Interfaces.JDBCDataSource>>([]);
  const [loadingDataSources, setLoadingDataSources,] = useState<boolean>(false);
  const [tablesFromDatasource, setTablesFromDatasource,] = useState<Array<string>>([]);
  const [loadingDatabase, setLoadingDatabase,] = useState(false);

  const [repos, setRepos,] = useState<Array<Interfaces.ExternalRepo>>([]);
  const [loadingRepos, setLoadingRepos,] = useState<boolean>(false);

  const [dbmsTypes, setDbmsTypes,] = useState<Array<Interfaces.JDBCDriver>>([]);
  const [dbmsColumnTypes, setDbmsColumnTypes,] = useState<Array<Interfaces.DBMSType>>([]);
  const [buttonValue, setButtonValue,] = useState('');
  const [disabled, setDisabled,] = useState(false);

  const handleSubmitSave = () => {
    const _model: Interfaces.Model = JSON.parse(JSON.stringify(model));
    removeAdditionalFields(_model);
    setDisabled(true);
    if (id) {
      collectionServices.updateCollection(id, _model)
        .then(() => {
          setDisabled(false);
          enqueueSnackbar('success update', { variant: 'success', });
          history('/models');
        })
        .catch((error) => {
          setDisabled(false);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    } else {
      collectionServices.addCollection(_model)
        .then(() => {
          setDisabled(false);
          enqueueSnackbar('success add', { variant: 'success', });
          history('/models');
        })
        .catch((error) => {
          setDisabled(false);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    }
  };

  const handleSubmitSaveAndDeploy = () => {
    const _model: Interfaces.Model = JSON.parse(JSON.stringify(model));
    removeAdditionalFields(_model);
    setDisabled(true);
    if (id) {
      collectionServices.updateCollection(id, _model)
        .then(() => {
          setDisabled(false);
          enqueueSnackbar('success update', { variant: 'success', });
          history(`/models/${model.id}/deployment`);
        })
        .catch((error) => {
          setDisabled(false);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    } else {
      collectionServices.addCollection(_model)
        .then((response) => {
          setDisabled(false);
          enqueueSnackbar('success add', { variant: 'success', });
          response.data.data.id && history(`/models/${response.data.data.id}/deployment`);
        })
        .catch((error) => {
          setDisabled(false);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    }
  };

  const [tabValue, setTabValue,] = useState(0);

  const [open, setOpen,] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (event: { target: any; }) => {
    if (anchorRef.current && (anchorRef?.current as any).contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const fetchDataSources = () => {
    setLoadingDataSources(true);
    datasourceServices.fetchDataSources().then((result)=>{
      setDataSources(result.data.filter((db: Interfaces.BaseDataSource)=>db.type === 'JDBC'));
    }).catch((error)=>{
      enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
    }).finally(()=>{
      setLoadingDataSources(false);
    });
  };

  const fetchRepos = () => {
    setLoadingRepos(true);
    gitConfigServices.fetchGitConfigs()
      .then((result: AxiosResponse<{ data: Array<Interfaces.ExternalRepo> }>) => {
        setRepos(result.data.data);
      }).catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      }).finally(() => {
        setLoadingRepos(false);
      });
  };

  const fetchDBMSTypes = () => {
    datasourceServices.fetchSupportedDrivers()
      .then((result: AxiosResponse<Array<Interfaces.JDBCDriver>>) => {
        setDbmsTypes(result.data.filter((dbmsType: Interfaces.JDBCDriver) => dbmsType.names.indexOf('generic') === -1)
          .sort((a, b) => a.title.localeCompare(b.title)));
      }).catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  const fetchDBMSColumnsTypes = (dbmsType: string, updateModel?: boolean) => {
    datasourceServices.fetchDBMSColumnTypes(dbmsType)
      .then((result: AxiosResponse<Array<Interfaces.DBMSType>>) => {
        const sorted = result.data.sort((a, b) => a.type.localeCompare(b.type));
        setDbmsColumnTypes(sorted);
        if (updateModel) {
          setModel({
            ...model,
            database: {
              ...model.database,
              databaseType: dbmsType,
            },
          });
        }
      }).catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  const fetchDatasourceDatabase = (dataSourceId: string) => {
    setLoadingDatabase(true);
    const originalTables = model.database.tables.filter((table) => !tablesFromDatasource.includes(table.name));
    datasourceServices.fetchDatabase(dataSourceId)
      .then((result: AxiosResponse<Interfaces.ModelDatabase>) => {
        setTablesFromDatasource(result.data.tables.map((table) => table.name));
        const newDB: Interfaces.ModelDatabase={
          ...result.data,
        };
        const indexedNewModel = addIndexedUID({
          ...emptyModel,
          database: newDB,
        });
        const tables = _.merge(indexedNewModel.database.tables, originalTables);
        setModel({
          ...model,
          database: {
            ...indexedNewModel.database,
            tables: tables,
          },
        });
        fetchDBMSColumnsTypes(result.data.databaseType, false);
      }).catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        setModel({
          ...model,
          database: {
            ...model.database,
            tables: originalTables,
          },
        });
      }).finally(() => {
        setLoadingDatabase(false);
      });
  };

  const handleTableSave = (table: Interfaces.ModelTable, callback: boolean = true) => {
    let _tables = [...model.database.tables,];
    if (table._new) {
      table._uuid = `collection.${table.schema || '(no-schema)'}.database.${table.name}`;
    }
    const { isValid, table: valid_table, } = validateTable(table, _tables, model.database.databaseType, !!table._new);
    if (isValid) {
      const tableIdx = _tables.findIndex((_table) => _table._uuid === valid_table._uuid);
      if (tableIdx !== -1) {
        _tables[tableIdx] = valid_table;
      } else {
        _tables = [..._tables, valid_table,];
      }
      setModel({
        ...model,
        database: {
          ...model.database,
          tables: _tables,
        },
      });
      if (callback) {
        enqueueSnackbar('Saved table', { variant: 'success', });
      }
    } else {
      if (callback) {
        enqueueSnackbar('Table validation failed', { variant: 'error', });
      }
    }
  };

  useEffect(() => {
    fetchDataSources();
    fetchRepos();
    if (id) {
      collectionServices.fetchCollectionById(id)
        .then((response: AxiosResponse<{ data: Interfaces.Model }>) => {
          setModel(addIndexedUID(response.data.data));
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (model.dataSourceId !== '-1' && !inEditMode) {
      fetchDatasourceDatabase(model.dataSourceId);
    } else {
      const originalTables = model.database.tables.filter((table) => !tablesFromDatasource.includes(table.name));
      setModel({
        ...model,
        database: {
          ...model.database,
          tables: originalTables,
        },
      });
      fetchDBMSTypes();
    }
  },[model.dataSourceId,]);

  useEffect(() => {
    if (model.database.databaseType) {
      fetchDBMSColumnsTypes(model.database.databaseType);
    }
  }, [model.database.databaseType,]);

  useEffect(() => {
    const tmpModel: Interfaces.Model = JSON.parse(JSON.stringify(model));
    removeAdditionalFields(tmpModel);
    setYamlModel(yamlStringify(tmpModel));
  }, [model,]);

  useEffect(() => {
    if (tabValue === 1) {
      try {
        const _model = yamlParser(yamlModel) as Interfaces.Model;
        setSelectedTable(undefined);
        addIndexedUID(_model);
        setModel(_model);
      } catch (e) {
        console.debug(e);
      }
    }
  }, [yamlModel,]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Grid
      container
      direction='column'
      style={{
        marginTop: 10,
      }}
    >
      <DeleteDialog
        open={!!tableToDelete}
        title={'Delete table'}
        handleClose={() => setTableToDelete(null)}
        action={() => {
          const _tables = model.database?.tables?.filter((table) => table?._uuid !== tableToDelete?._uuid);
          setModel({
            ...model,
            database: {
              ...model.database,
              tables: _tables,
            },
          });
          enqueueSnackbar(`Successfully deleted table '${tableToDelete?.name}'`, { variant: 'info', });
          setTableToDelete(null);
        }}
      />
      <DeleteDialog
        open={!!schemaToDelete}
        title={`Delete schema ${schemaToDelete}`}
        handleClose={() => setSchemaToDelete(null)}
        action={() => {
          setModel({
            ...model,
            database: {
              ...model.database,
              tables: model.database.tables.filter((table) => table.schema !== schemaToDelete),
            },
          });
          enqueueSnackbar(`Successfully deleted schema '${schemaToDelete}'`, { variant: 'info', });
          setSchemaToDelete(null);
        }}
      />
      <form
        id={'model-form'}
        onSubmit={(e) => {
          e.preventDefault();
          buttonValue === 'save' && handleSubmitSave();
          buttonValue === 'saveAndDeploy' && handleSubmitSaveAndDeploy();
        }}
      >
        <Grid
          container
          xs={12}
          style={{
            background: 'white',
          }}
        >
          <Grid item xs={4} style={{ paddingRight: 7, }}>
            <TextField
              label="Model Name"
              value={model.name}
              onChange={(e) => {
                setModel({
                  ...model,
                  name: e.target.value,
                });
              }}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              style={{ marginBottom: 10, }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={3} style={{ paddingRight: 7, }}>
            <TextField
              id="outlined-select-currency"
              select
              label="Data Source"
              size='small'
              fullWidth
              value={model.dataSourceId}
              onChange={(e) => {
                setModel({
                  ...model,
                  dataSourceId: e.target.value,
                });
              }}
              defaultValue={-1}
              variant="outlined"
              disabled={loadingDataSources || inEditMode}
              InputProps={{
                startAdornment: loadingDataSources ? <CircularProgress size={20} style={{ marginRight: 10, }} /> : '',
              }}
            >
              <MenuItem key='-1' value='-1'>
                None
              </MenuItem>
              {dataSources.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.driverName} - {option.databaseName} ({option.name})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {model?.dataSourceId === '-1' && <Grid item xs={2} style={{ paddingRight: 7, }} direction='row'>
            <TextField
              id="outlined-select-currency"
              select
              label="DBMS Type"
              size='small'
              fullWidth
              value={model?.database?.databaseType}
              onChange={(e) => {
                fetchDBMSColumnsTypes(e.target.value, true);
              }}
              variant="outlined"
              disabled={loadingDataSources}
              required
              InputProps={{
                startAdornment: loadingDataSources ? <CircularProgress size={20} style={{ marginRight: 10, }} /> : '',
              }}
            >
              {dbmsTypes.map((option) => (
                <MenuItem key={option?.names[0]} value={option?.names[0]}>
                  {option?.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          }
          <Grid item xs direction='row'>
            <TextField
              id="outlined-select-repo"
              select
              label="Git Repo"
              size='small'
              fullWidth
              value={model.externalRepoId}
              onChange={(e) => {
                setModel({
                  ...model,
                  externalRepoId: e.target.value,
                });
              }}
              variant="outlined"
              disabled={loadingRepos}
              defaultValue={-1}
              InputProps={{
                startAdornment: loadingRepos ? <CircularProgress size={20} style={{ marginRight: 10, }} /> : '',
              }}
            >
              <MenuItem key='-1' value='-1'>
                None
              </MenuItem>
              {repos.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {<GitIcons type={option?.type} height={20} style={{ marginRight: '10px', }}/>} {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <TextField
            label="Model Description"
            id="outlined-size-small"
            variant="outlined"
            value={model.description}
            onChange={(e) => {
              setModel({
                ...model,
                description: e.target.value,
              });
            }}
            size='small'
            fullWidth
          />
        </Grid>
      </form>
      <Grid container xs={12} style={{ marginTop: 10, }}>
        <Grid item style={{ width: '300px', }}>
          <SchemaTree
            model={model}
            handleTableSelect={(table) => {
              if (selectedTable) {
                handleTableSave(selectedTable, false);
              }
              setSelectedTable(table);
            }}
            handleDeleteTable={(_table) => {
              if (_table._uuid == selectedTable?._uuid) {
                setSelectedTable(undefined);
              }
              setTableToDelete(_table);
            }}
            disabledAddTableButton={model.database.databaseType === ''}
            loadingReason={loadingDatabase ? 'Loading schema' : undefined}
            handleDeleteSchema={(schema) => {
              setSchemaToDelete(schema);
            }}
          />
        </Grid>
        <Grid item xs style={{ background: 'white', }}>
          <Tabs indicatorColor={'primary'} value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} style={{ paddingLeft: 20, }}>
            <StyledTab
              icon={<ListAlt color='primary'/>}
              label="Table designer"
              disabled={loadingDatabase || model.database.databaseType === ''}
            />
            <StyledTab
              icon={<Code color='primary'/>}
              label="DBML Editor"
              disabled={loadingDatabase || model.database.databaseType === ''}
            />
            <StyledTab
              icon={
                <img
                  style={{
                    height: 16,
                    width: 16,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  src={dbtIcon}
                  alt={'dbt'}
                />
              }
              label="DBT Model"
              disabled={loadingDatabase || model.database.databaseType === ''}
            />
          </Tabs>
          <div
            style={{
              padding: 20,
              borderRadius: 2,
              minHeight: 'calc(-360px + 100vh)',
              maxHeight: 'calc(-360px + 100vh)',
              marginTop: 10,
            }}
          >
            <TabPanel value={tabValue} index={0}>
              {
                selectedTable ? (
                  <TableDesigner
                    table={selectedTable}
                    updateTable={(table) => setSelectedTable(table)}
                    tableColumnTypes={dbmsColumnTypes}
                    dbmsType={model.database.databaseType}
                    handleTableSave={(table: Interfaces.ModelTable, callback: boolean = true) => {
                      handleTableSave(table, callback);
                      setSelectedTable(table);
                    }}
                  />
                ) : (
                  model.database.databaseType !== '' ? (
                    <div style={{ textAlign: 'center',
                      paddingTop: 'calc(-750px + 100vh)', }}>
                      <Typography>
                      Please select a table to edit or
                        <Typography
                          onClick={() => setSelectedTable({
                            ...emptyTable,
                            _uuid: v4(),
                          })}
                          style={{ cursor: 'pointer', }}
                          color='primary'
                        >
                        add a new one!
                        </Typography>
                      </Typography>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center',
                      paddingTop: 'calc(-750px + 100vh)', }}>
                      <Typography variant='h6' style={{ opacity: 0.5, }} >
                      Please select a database type
                      </Typography>
                    </div>
                  )
                )
              }
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <YAMLEditor
                yamlModel={yamlModel}
                setYamlModel={setYamlModel}
                selectedTable={selectedTable}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <DBTModels
                model={model}
                selectedTable={selectedTable}
              />
            </TabPanel>
          </div>
        </Grid>
      </Grid>

      <div>
        <Button
          style={{
            marginTop: 10,
            marginLeft: 15,
            float: 'right',
          }}
          color={'primary'}
          variant='outlined'
          onClick={() => history('/models')}
        >
            Cancel
        </Button>
        <React.Fragment >
          <ButtonGroup
            disabled={disabled}
            style={{
              marginTop: 10,
              float: 'right',
            }}
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button
              type={'submit'}
              form={'model-form'}
              color={'primary'}
              onClick={()=> setButtonValue('saveAndDeploy')}
            >
              Save and Deploy
            </Button>
            <Button
              color={'primary'}
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={() => setOpen((prevOpen) => !prevOpen)}
            >
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement, }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Button
                      color={'primary'}
                      variant={'outlined'}
                      type={'submit'}
                      form={'model-form'}
                      onClick={()=> setButtonValue('save')}
                      style={{
                        marginBottom: 1,
                        width: 210,
                      }}
                    >
                      Save
                    </Button>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      </div>
    </Grid>
  );
};

export default AddModel;
