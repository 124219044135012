import React, {
  useEffect,
  useState,
} from 'react';
import { Interfaces, } from '../../../config';
import { gitConfigServices, } from '../../../services';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../../utils';
import {
  Button,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles, } from '@mui/styles';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { TestButton, } from './TestButton';
import GitIcons from './GitIcons';
import { StyledTextField, } from '../../Reusable';

const useStyles = makeStyles({
  root: {
    marginTop: '40px',
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rightMargin: {
    marginRight: '8px',
  },
});


const TOKEN_TYPES: Interfaces.KeyValue = {
  'Token':'TokenRepoAuthentication',
  'SSH':'SSHRepoAuthentication',
  'SSHWithPassphrase':'SSHWithPassphraseRepoAuthentication',
  'UsernamePassword':'UsernamePasswordRepoAuthentication',
};

type Params = {
  gitConfigId?: string;
}

const GitConfigForm = () => {
  const { enqueueSnackbar, } = useSnackbar();
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const [configToEdit, setConfigToEdit,] = useState<Interfaces.ExternalRepo | null>(location.state?.configToEdit || null);
  const [types, setTypes,] = useState<Array<string>>([]);
  const { gitConfigId, } = useParams<Params>();
  const [test, setTest,] = useState<boolean | null>(null);

  const [state, setState,] = useState<Interfaces.ExternalRepo>({
    name: '',
    url: '',
    branch: '',
    type: '',
    authType: 'TokenRepoAuthentication',
    authentication: {
      type: 'Token',
      token: '',
    } as Interfaces.TokenRepoAuthentication,
  });

  useEffect(() => {
    gitConfigServices.getTypes()
      .then((response: any) => {
        setTypes(response?.data?.data);
      })
      .catch(() => {
        enqueueSnackbar('Failed to fetch types', { variant: 'error', });
      });
    if (configToEdit === null && gitConfigId !== undefined) {
      gitConfigServices.fetchGitConfigById(gitConfigId)
        .then((response) => {
          setConfigToEdit(response?.data?.data);
          setState({
            name: response?.data?.data?.name,
            url: response?.data?.data?.url,
            branch: response?.data?.data?.branch,
            authType: response?.data?.data?.authentication?.type?TOKEN_TYPES[response?.data?.data?.authentication?.type]:'TokenRepoAuthentication',
            type: response?.data?.data?.type,
          });
        })
        .catch((error: any) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    }
    if (configToEdit) {
      setState({
        ...state,
        ...configToEdit,
        authType: configToEdit.authentication?.type?TOKEN_TYPES[configToEdit.authentication?.type]:'TokenRepoAuthentication',
      });
    }
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (configToEdit && configToEdit.id) {
      updateConfig();
    } else {
      addGitConfig();
    }
    return;
  };

  const addGitConfig = () => {
    gitConfigServices.addGitConfig({
      name: state.name,
      url: state.url,
      branch: state.branch,
      authentication: state.authentication,
      type: state.type,
    })
      .then(() => {
        enqueueSnackbar('Added successfully', { variant: 'success', });
        history('/admin');
      })
      .catch((error: any) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  const updateConfig = () => {
    if (configToEdit?.id != null) {
      gitConfigServices.updateGitConfig({
        id: configToEdit.id,
        name:state.name,
        url: state.url,
        branch: state.branch,
        authentication: state.authentication,
        type: state.type,
      }, configToEdit?.id)
        .then(() => {
          enqueueSnackbar('Updated successfully', { variant: 'success', });
          history('/admin');
        })
        .catch((error: any) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <div>
      <Grid container justifyContent={'center'}
        className={classes.root}
        style={{ padding: '30px', }}
      >
        <Grid item xs={8}>
          <Typography
            variant="h5"
            style={{  marginBottom: '10px', }}
          >
            Git Configuration
          </Typography>
          <form
            id="config_form"
            name="addConfig"
            onSubmit={handleSubmit}
          >
            <StyledTextField
              select
              variant={'outlined'}
              placeholder={'Type'}
              label={'Type'}
              size={'small'}
              fullWidth
              value={state.type}
              onChange={(event) => {
                setState({ ...state,
                  type: event.target.value as string, });
              }}
            >
              {
                types && types.map((type) => (
                  <MenuItem selected={type === state.type} key={type} value={type}>
                    <div className={classes.displayFlex}>
                      <GitIcons type={type} height={20} className={classes.rightMargin} /> {type}
                    </div>
                  </MenuItem>
                ))
              }
            </StyledTextField>
            <StyledTextField
              value={state.name}
              id="standard-basic"
              size='small'
              name="name"
              label="Name"
              variant="outlined"
              onChange={handleChange}
              fullWidth
            />
            <StyledTextField
              onChange={handleChange}
              value={state.url}
              id="filled-basic"
              size='small'
              name="url"
              label="Url"
              variant="outlined"
              fullWidth
            />
            <StyledTextField
              name="branch"
              value={state.branch}
              onChange={handleChange}
              id="outlined-basic"
              size='small'
              label="Branch Name"
              variant="outlined"
              fullWidth
              required
            />
            <StyledTextField
              select
              label="Authentication Type"
              variant="outlined"
              size={'small'}
              fullWidth
              value={state.authType}
              onChange={(event)=>{
                const value = event.target.value;
                switch (value) {
                case 'UsernamePasswordRepoAuthentication': {
                  setState({
                    ...state,
                    authType: value,
                    authentication: {
                      userName: '',
                      password: '',
                      type: 'UsernamePassword',
                    } as Interfaces.UsernamePasswordRepoAuthentication,
                  });
                  return;
                }
                case 'TokenRepoAuthentication': {
                  setState({
                    ...state,
                    authType: value,
                    authentication: {
                      type: 'Token',
                      token: '',
                    } as Interfaces.TokenRepoAuthentication,
                  });
                  return;
                }
                case 'SSHRepoAuthentication': {
                  setState({
                    ...state,
                    authType: value,
                    authentication: {
                      type: 'SSH',
                      privateKeyContent:'',
                    } as Interfaces.SSHRepoAuthentication,
                  });
                  return;
                }
                case 'SSHWithPassphraseRepoAuthentication': {
                  setState({
                    ...state,
                    authType: value,
                    authentication: {
                      type: 'SSHWithPassphrase',
                      privateKeyContent: '',
                      passphrase: '',
                    } as Interfaces.SSHWithPassphraseRepoAuthentication,
                  });
                  return;
                }
                }
              }}
            >
              <MenuItem
                key='TokenRepoAuthentication'
                value='TokenRepoAuthentication'
                disabled={state.type === 'BITBUCKET'}
                selected={true}
              >
                Token
              </MenuItem>
              <MenuItem
                key='UsernamePasswordRepoAuthentication'
                value='UsernamePasswordRepoAuthentication'
                disabled={state.type === 'GITHUB'}
              >
                Username & Password
              </MenuItem>
              <MenuItem
                key='SSHRepoAuthentication'
                value='SSHRepoAuthentication'
              >
                SSH
              </MenuItem>
              <MenuItem
                key='SSHWithPassphraseRepoAuthentication'
                value='SSHWithPassphraseRepoAuthentication'
              >
                SSH with password
              </MenuItem>
            </StyledTextField>
            {
              state.authType == 'TokenRepoAuthentication' && (
                <StyledTextField
                  id="outlined-basic"
                  name="authentication"
                  value={state.authentication ? (state.authentication as Interfaces.TokenRepoAuthentication).token:''}
                  onChange={(event)=>setState({
                    ...state,
                    authentication:{
                      ...state.authentication,
                      token: event.target.value,
                    } as Interfaces.TokenRepoAuthentication,
                  })}
                  onFocus={(e) => {
                    if (e.target.value === '' && gitConfigId) {
                      setState({
                        ...state,
                        authentication: {
                          ...state.authentication,
                          token: '',
                        } as Interfaces.TokenRepoAuthentication,
                      });
                    }
                  }
                  }
                  size='small'
                  label="Key"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                />
              )
            }
            {
              state.authType == 'UsernamePasswordRepoAuthentication' && (
                <>
                  <StyledTextField
                    id="outlined-basic"
                    name="username"

                    value={state.authentication ? (state.authentication as Interfaces.UsernamePasswordRepoAuthentication).userName:''}
                    // onChange={handleChange}
                    onChange={(event)=>setState({
                      ...state,
                      authentication:{
                        ...state.authentication,
                        userName: event.target.value,
                      } as Interfaces.UsernamePasswordRepoAuthentication,
                    })}
                    size='small'
                    label="Username"
                    variant="outlined"
                    fullWidth
                  />
                  <StyledTextField
                    id="outlined-basic"
                    name="password"
                    type="password"
                    value={state.authentication ? (state.authentication as Interfaces.UsernamePasswordRepoAuthentication).password:''}
                    onChange={(event)=>setState({
                      ...state,
                      authentication:{
                        ...state.authentication,
                        password: event.target.value,
                      } as Interfaces.UsernamePasswordRepoAuthentication,
                    })}
                    onFocus={(e) => {
                      if (e.target.value === '' && gitConfigId) {
                        setState({
                          ...state,
                          authentication: {
                            ...state.authentication,
                            password: '',
                          } as Interfaces.UsernamePasswordRepoAuthentication,
                        });
                      }
                    }}
                    size='small'
                    label="Password"
                    variant="outlined"
                    fullWidth
                  />
                </>)
            }
            {
              state.authType == 'SSHRepoAuthentication' && (
                <StyledTextField
                  id="outlined-basic"
                  name="ssh"
                  value={state.authentication ? (state.authentication as Interfaces.SSHRepoAuthentication).privateKeyContent:''}
                  onChange={(event)=>setState({
                    ...state,
                    authentication:{
                      ...state.authentication,
                      privateKeyContent: event.target.value,
                    } as Interfaces.SSHRepoAuthentication,
                  })}
                  onFocus={(e) => {
                    if (e.target.value === '' && gitConfigId) {
                      setState({
                        ...state,
                        authentication: {
                          ...state.authentication,
                          privateKeyContent: '',
                        } as Interfaces.SSHRepoAuthentication,
                      });
                    }
                  }}
                  size='small'
                  label="Key"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                />
              )
            }
            {
              state.authType == 'SSHWithPassphraseRepoAuthentication' && (
                <>
                  <StyledTextField
                    id="outlined-basic"
                    name="privateKeyContent"
                    value={state.authentication ? (state.authentication as Interfaces.SSHWithPassphraseRepoAuthentication).privateKeyContent:''}
                    onChange={(event)=>setState({
                      ...state,
                      authentication:{
                        ...state.authentication,
                        privateKeyContent: event.target.value,
                      } as Interfaces.SSHWithPassphraseRepoAuthentication,
                    })}
                    onFocus={(e) => {
                      if (e.target.value === '' && gitConfigId) {
                        setState({
                          ...state,
                          authentication: {
                            ...state.authentication,
                            privateKeyContent: '',
                          } as Interfaces.SSHWithPassphraseRepoAuthentication,
                        });
                      }
                    }}
                    size='small'
                    label="Key"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                  />
                  <StyledTextField
                    id="outlined-basic"
                    name="passphrase"
                    value={state.authentication ? (state.authentication as Interfaces.SSHWithPassphraseRepoAuthentication).passphrase:''}
                    onChange={(event)=>setState({
                      ...state,
                      authentication:{
                        ...state.authentication,
                        passphrase: event.target.value,
                      } as Interfaces.SSHWithPassphraseRepoAuthentication,
                    })}
                    onFocus={(e) => {
                      if (e.target.value === '' && gitConfigId) {
                        setState({
                          ...state,
                          authentication: {
                            ...state.authentication,
                            passphrase: '',
                          } as Interfaces.SSHWithPassphraseRepoAuthentication,
                        });
                      }
                    }}
                    size='small'
                    label="Passphrase"
                    variant="outlined"
                    fullWidth
                  />
                </>
              )
            }
            <div style={{ float: 'right', }}>
              <TestButton
                testStatus={test}
                testAction={() => {
                  setTest(null);
                  if (state && state.id) {
                    gitConfigServices.testExistingConfig(state.id, {
                      name:state.name,
                      url: state.url,
                      branch: state.branch,
                      authentication: state.authentication,
                      type: state.type,
                    })
                      .then(() => {
                        setTest(true);
                      })
                      .catch((error: any) => {
                        setTest(false);
                        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
                      });
                  } else {
                    gitConfigServices.testNewConfig({
                      name:state.name,
                      url: state.url,
                      branch: state.branch,
                      authentication: state.authentication,
                      type: state.type,
                    })
                      .then(() => {
                        setTest(true);
                      })
                      .catch((error: any) => {
                        setTest(false);
                        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
                      });
                  }
                }}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveIcon />}
              style={{ marginRight: 0, }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: 10, }}
              onClick={()=>{
                history('/admin');
              }}
            >
              Cancel
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};


export default GitConfigForm;
