import { axiosClient, } from '../config/helpers';
import { Constants, } from '../config';

export const tagsServices = {
  fetchTags,
  fetchTagById,
  fetchTagRules,
  addTag,
  addTagFromCsv,
  editTag,
  deleteTag,
  activateBusinessTag,
  deActivateBusinessTag,
  fetchTagTemplates,
};

function fetchTags() {
  return axiosClient().get(Constants.api.endpoints.tags);
}

function fetchTagById(tagId: string) {
  return axiosClient().get(`${Constants.api.endpoints.tags}/${tagId}`);
}

function fetchTagRules() {
  return axiosClient().get(Constants.api.endpoints.tagRules);
}

function addTag(body: any) {
  return axiosClient().post(Constants.api.endpoints.tags, body);
}

function addTagFromCsv(file: any) {
  const formData = new FormData();
  formData.append('file', file);
  return axiosClient().post(
    Constants.api.endpoints.tagCsv,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
}

function editTag(tag: any) {
  return axiosClient().patch(`${Constants.api.endpoints.tags}/${tag.id}`, tag);
}

function deleteTag(tagId: string) {
  return axiosClient().delete(`${Constants.api.endpoints.tags}/${tagId}`);
}

function activateBusinessTag(tagId: string) {
  return axiosClient().get(`${Constants.api.endpoints.tags}/${tagId}/status/true`);
}

function deActivateBusinessTag(tagId: string) {
  return axiosClient().get(`${Constants.api.endpoints.tags}/${tagId}/status/false`);
}

function fetchTagTemplates() {
  return axiosClient().get(Constants.api.endpoints.tagTemplates);
}
