import React, {
  useEffect,
  useState,
} from 'react';
import { Interfaces, } from '../../../config';
import {
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, } from '@mui/icons-material';

interface Props {
  test: Interfaces.CollectionTableTest;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  handleChange: (test: Interfaces.CollectionTableTest) => void;
  // eslint-disable-next-line no-unused-vars
  removeTest: (uuid: string) => void;
}

const operators = [
  {
    value: '=',
  },
  {
    value: '!=',
  },
  {
    value: '>',
  },
  {
    value: '<',
  },
  {
    value: '<=',
  },
  {
    value: '>=',
  },
  {
    value: 'in',
  },
  {
    value: 'is null',
  },
  {
    value: 'is not null',
  },
  {
    value: 'between',
  },
  {
    value: 'like',
  },
  {
    value: 'ilike',
  },
  {
    value: 'unique',
  },
];

const TableDesignerColumnTest = (props: Props) => {
  const { test, handleChange, disabled, } = props;
  const [customTestValue, setCustomTestValue,] = useState<any>(test.value);

  useEffect(() => {
    test._uuid && handleChange({
      ...test,
      value: customTestValue,
    });
  }, [customTestValue,]);
  return (
    <div style={{ marginTop: 15,
      marginLeft: 70, }}>
      <TextField
        disabled={!!disabled}
        name={'testExpression'}
        label={'Operator'}
        select
        value={test.operator}
        variant={disabled ? 'standard' : 'outlined'}
        size='small'
        style={{
          marginRight: 7,
          width: 120,
        }}
        onChange={(e) => {
          test._uuid && handleChange({
            ...test,
            operator: e.target.value,
          });
        }}
      >
        {operators.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
      {
        test.operator === 'between' ? (
          <>
            <TextField
              disabled={!!disabled}
              name={'testName'}
              label={'Start'}
              value={customTestValue.firstValue ? customTestValue.firstValue : customTestValue}
              variant={disabled ? 'standard' : 'outlined'}
              size='small'
              style={{
                paddingRight: 7,
                maxWidth: 100,
              }}
              onChange={(e) => {
                setCustomTestValue({
                  ...customTestValue,
                  firstValue: e.target.value,
                });
              }}
            />
            <TextField
              name={'testName'}
              disabled={!!disabled}
              label={'End'}
              value={customTestValue.secondValue ? customTestValue.secondValue : customTestValue}
              variant={disabled ? 'standard' : 'outlined'}
              size='small'
              style={{
                paddingRight: 7,
                maxWidth: 100,
              }}
              onChange={(e) => {
                setCustomTestValue({
                  ...customTestValue,
                  secondValue: e.target.value,
                });
              }}
            />
          </>
        ) : (
          <TextField
            name={'testName'}
            label={'Test Value'}
            value={test.value}
            variant={disabled ? 'standard' : 'outlined'}
            disabled={!!disabled}
            size='small'
            style={{
              paddingRight: 7,
            }}
            onChange={(e) => {
              test._uuid && handleChange({
                ...test,
                value: e.target.value,
              });
            }}
          />
        )
      }
      <TextField
        name={'expected'}
        label={'Expected'}
        type={'number'}
        value={test.expected}
        variant={disabled ? 'standard' : 'outlined'}
        size='small'
        style={{
          paddingRight: 40,
        }}
        disabled={!!disabled}
        onChange={(e) => {
          test._uuid && handleChange({
            ...test,
            expected: parseInt(e.target.value),
          });
        }}
      />
      {
        !disabled && (
          <Tooltip title='Delete' placement='top'>
            <IconButton
              onClick={() => test._uuid && props.removeTest(test._uuid)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )
      }
    </div>
  );
};

export { TableDesignerColumnTest, };
