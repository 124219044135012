import React, {
  useMemo,
  useState,
} from 'react';
import { TreeView, } from '@mui/lab';
import {
  ChevronRight,
  ExpandMore,
} from '@mui/icons-material';
import { RenderTree, } from './RenderTree';
import { Interfaces, } from '../../../config';
import LoadingComponent from '../../Loading';


const emptyCollectionSchema: Interfaces.ModelSchema = {
  tables: [],
};

const SchemaTree = ({
  collection,
  loadingReason,
}: {
  collection: Interfaces.Model;
  loadingReason?: string;
}) => {
  const [groupedTables, setGroupedTables,] = useState<Interfaces.ModelSchema>(emptyCollectionSchema);

  useMemo(() => {
    const updated: Interfaces.ModelSchema = collection.database.tables.reduce(
      (groups: Interfaces.ModelSchema, item) => ({
        ...groups,
        [item.schema || '(no-schema)']: [...(groups[item.schema || '(no-schema)'] || []), item,],
      }),
      {} as Interfaces.ModelSchema
    );
    setGroupedTables(updated);
  }, [collection,]);

  return (
    <div
      style={{
        background: 'white',
        borderRadius: 7,
        marginRight: 10,
        padding: 10,
        width: 280,
      }}
    >
      {
        loadingReason ? (
          <LoadingComponent size={30} marginTop={220} message={loadingReason}/>
        ) : (
          <div
            style={{
              position: 'relative',
            }}
          >
            <TreeView
              defaultExpanded={Object.keys(groupedTables).length === 1 ? [`${collection.name} root`, `${collection.name}.${Object.keys(groupedTables)[0]}`,] : [`${collection.name} root`,]}
              defaultCollapseIcon={<ExpandMore/>}
              defaultExpandIcon={<ChevronRight/>}
              style={{
                overflowY: 'auto',
                paddingBottom: 20,
                height: 'calc(100vh - 124px)',
              }}
            >
              <RenderTree
                collectionName={collection.name}
                groupedTables={groupedTables}
              />
            </TreeView>
          </div>
        )
      }
    </div>
  );
};

export { SchemaTree, };
