import React, {
  useMemo,
  useState,
} from 'react';
import { TreeView, } from '@mui/lab';
import {
  ChevronRight,
  ExpandMore,
} from '@mui/icons-material';
import { RenderTree, } from './RenderTree';
import { Interfaces, } from '../../../config';
import {
  Button,
  Tooltip,
} from '@mui/material';
import { emptyTable, } from '../helpers';
import LoadingComponent from '../../Loading';


const emptyModelSchema: Interfaces.ModelSchema = {
  tables: [],
};

const SchemaTree = ({
  model,
  loadingReason,
  handleTableSelect,
  handleDeleteTable,
  disabledAddTableButton,
  handleDeleteSchema,
}: {
  model: Interfaces.Model;
  loadingReason?: string;
  // eslint-disable-next-line no-unused-vars
  handleTableSelect: (table: Interfaces.ModelTable) => void;
  // eslint-disable-next-line no-unused-vars
  handleDeleteTable: (table: Interfaces.ModelTable) => void;
  disabledAddTableButton: boolean;
  // eslint-disable-next-line no-unused-vars
  handleDeleteSchema: (schema: string) => void;
}) => {
  const [groupedTables, setGroupedTables,] = useState<Interfaces.ModelSchema>(emptyModelSchema);

  useMemo(() => {
    const updated: Interfaces.ModelSchema = model.database.tables.reduce(
      (groups: Interfaces.ModelSchema, item) => ({
        ...groups,
        [item.schema || '(no-schema)']: [...(groups[item.schema || '(no-schema)'] || []), item,],
      }),
      {} as Interfaces.ModelSchema
    );
    setGroupedTables(updated);
  }, [model,]);

  return (
    <div
      style={{
        minHeight: 'calc(-262px + 100vh)',
        maxHeight: 'calc(-262px + 100vh)',
        marginRight: 10,
        padding: 10,
        background: 'white',
      }}
    >
      {
        loadingReason ? (
          <LoadingComponent size={30} marginTop={140} message={loadingReason}/>
        ) : (
          <div
            style={{
              position: 'relative',
            }}
          >
            <Tooltip title={disabledAddTableButton ? 'Select a database type to add tables' : ''}>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: 10,
                }}
              >
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  disabled={disabledAddTableButton}
                  size={'small'}
                  onClick={() => handleTableSelect({ ...emptyTable, })}
                >
                  Add table
                </Button>
              </div>
            </Tooltip>
            <TreeView
              defaultCollapseIcon={<ExpandMore/>}
              defaultExpandIcon={<ChevronRight/>}
              defaultExpanded={Object.keys(groupedTables).length === 1 ? [`${model.name}.${Object.keys(groupedTables)[0]}`,] : []}
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingBottom: 20,
                minHeight: 'calc(-350px + 100vh)',
                maxHeight: 'calc(-350px + 100vh)',
                marginBottom: 7,
              }}
            >
              <RenderTree
                modelName={model.name}
                groupedTables={groupedTables}
                handleTableSelect={handleTableSelect}
                handleDeleteTable={handleDeleteTable}
                handleDeleteSchema={handleDeleteSchema}
              />
            </TreeView>
          </div>
        )
      }
    </div>
  );
};

export { SchemaTree, };
