import React, {
  useEffect,
  useState,
} from 'react';
import { Interfaces, } from '../../../config';
import {
  Button,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Delete,
  Edit,
} from '@mui/icons-material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { makeStyles, } from '@mui/styles';
import { gitConfigServices, } from '../../../services';
import { helpers, } from '../../../utils';
import { useSnackbar, } from 'notistack';
import LoadingComponent from '../../Loading';
import { useNavigate, } from 'react-router-dom';
import { useAuth, } from '../../../context';
import GitIcons from './GitIcons';
import { ModalConfirmation, } from '../../ModalConfirmation';
import { NoDataComponent, } from '../../NoDataComponent';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rightMargin: {
    marginRight: '8px',
  },
});


const GitConfigList = ()=> {
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const [page, setPage,] = React.useState(0);
  const [rowsPerPage, setRowsPerPage,] = React.useState(5);
  const [deletePopupState, setDeletePopupState,] = useState(false);
  const [gitConfigToDelete, setGitConfigToDelete,] = useState<any>(null);
  const [loading, setLoading,] = useState(false);
  const [hasError, setHasError,] = useState<string | null>(null);
  const navigate = useNavigate();
  const { isAuthorized, } = useAuth();
  const [canRead,] = useState(isAuthorized('ADAPTIVE_GIT_READ'));
  const [canWrite,] = useState(isAuthorized('ADAPTIVE_GIT_WRITE'));
  const [canDelete,] = useState(isAuthorized('ADAPTIVE_ADMIN'));

  const [data, setData,] = useState<Array<Interfaces.ExternalRepo>>([]);

  useEffect(() => {
    setLoading(true);
    gitConfigServices.fetchGitConfigs()
      .then((response) => setData(response.data.data))
      .catch((error) => setHasError(helpers.getErrorMessage(error)))
      .finally(() => setLoading(false));
  }, []);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleDeletePopup = (repoId: any) => {
    setDeletePopupState(!deletePopupState);
    setGitConfigToDelete(repoId);
  };

  const deleteAction = () => {
    deleteGitConfig(gitConfigToDelete);
    handleDeletePopup(null);
  };

  const emptyRows = data ? rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) : rowsPerPage;

  const deleteGitConfig = (repoId: any) => {
    gitConfigServices.deleteGitConfig(repoId)
      .then(() => {
        gitConfigServices.fetchGitConfigs()
          .then((response) => setData(response.data.data))
          .catch((error) => setHasError(helpers.getErrorMessage(error)))
          .finally(() => setLoading(false));
        enqueueSnackbar('Deleted successfully', { variant: 'success', });
      })
      .catch((error: any) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };


  if (loading) {
    return <LoadingComponent size={100} message={'Loading config list...'}/>;
  }

  if (hasError) {
    return <>Error occurred: {hasError.toString()}</>;
  }

  if (!canRead) {
    return <Typography variant="h4">Not authorized</Typography>;
  }


  if (!loading && data.length === 0) {
    const navigationData = {
      component: 'git-configs',
      header: 'No data found',
      text1: 'You have not set a git configuration yet. ',
      text2: 'Create a git configuration to get started',
      buttonText: 'Add Git Config',
      route: '/git-config/add',
    };

    return <NoDataComponent data={navigationData} />;
  }

  return (
    <>
      {canWrite &&
        <Button
          style={{ marginBottom: 15, }}
          color="primary"
          variant="contained"
          onClick={()=> navigate(`/git-config/add`)}
        >
          Add git config
        </Button>}
      <TableContainer component={Paper}>
        <Typography variant="h5" style={{  marginLeft: '15px',
          marginTop: '10px', }}>Git Config List</Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Branch Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((repo: Interfaces.ExternalRepo) => (
              <TableRow key={repo.url}>
                <TableCell component="th" scope="row">
                  <div className={classes.displayFlex} >
                    {<GitIcons type={repo?.type} height={20} className={classes.rightMargin}/>}
                    {repo.name}
                  </div>
                </TableCell>
                <TableCell>
                  <Link href={repo.url} target="_blank">
                    {repo.url}
                  </Link>
                </TableCell>
                <TableCell>{repo.branch}</TableCell>
                <TableCell>
                  {canWrite &&
                  <Tooltip title="Edit">
                    <IconButton
                      style={{
                        padding: '3px 3px',
                        marginRight: '10px',
                      }}
                      aria-label="edit"
                      onClick={() => {
                        navigate(`/git-config/edit/${repo.id}`, { state: { configToEdit: repo, }, });
                      }}
                    >
                      <Edit/>
                    </IconButton>
                  </Tooltip>
                  }
                  {canDelete &&
                  <Tooltip title="Delete">
                    <IconButton
                      style={{
                        padding: '3px 3px',
                        marginRight: '10px',
                      }}
                      aria-label="delete"
                      onClick={() => {
                        handleDeletePopup(repo.id);
                      }}
                    >
                      <Delete/>
                    </IconButton>
                  </Tooltip>
                  }
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows, }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All',
                  value: -1, },]}
                colSpan={4}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page', },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ModalConfirmation
        open={deletePopupState && canDelete}
        onClose={() => handleDeletePopup(null)}
        title={'Are you sure you want to delete the Git Config?'}
        onBtnClick={() => deleteAction()}
        colorBtn={'secondary'}
        btnText={'Delete'}
        onCancelBtnClick={() => handleDeletePopup(null)} />
    </>
  );
};
export default GitConfigList;
