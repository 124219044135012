import { makeStyles, } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '15px',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  iconStyle: {
    padding: '3px 3px',
    marginRight: '10px',
  },
  alertStyle: { marginLeft: '20px',
    marginBottom: '10px', },
});

export { useStyles, };
