import React, { useState, } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingComponent from '../../../Loading';
import { makeStyles, } from '@mui/styles';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-javascript';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
}));

const ReusableModal = ({
  open,
  title,
  value,
  mode,
  loading,
  handleClose,
}: {
  open: boolean;
  title: string;
  value: string;
  mode: 'javascript' | 'sql';
  loading: boolean;
  handleClose: () => void;
}) => {
  const classes = useStyles();
  const [copyDisable, setCopyDisabled,] = useState(false);
  const [buttonText, setButtonText,] = useState('Copy');

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Card
        style={{
          width: '70%',
          height: 'inherit',
        }}
      >
        <CardHeader
          style={{
            borderBottom: '1px solid #80808045',
            boxShadow: '0 0 6px 0 #80808045',
          }}
          action={
            <IconButton aria-label="close" onClick={(event) => {
              event.stopPropagation();
              handleClose();
            }}>
              <CloseIcon />
            </IconButton>
          }
          title={title}
        />
        <CardContent
          style={{
            padding: 0,
            height: '76vh',
          }}
        >
          <div className={classes.paper}>
            {
              loading && (
                <LoadingComponent />
              )
            }
            {
              !loading && (
                <AceEditor
                  setOptions={{ useWorker: false, }}
                  height={'100%'}
                  width={'auto'}
                  mode={mode}
                  theme="tomorrow"
                  value={value}
                  fontSize={14}
                  readOnly
                  showPrintMargin={false}
                  showGutter={true}
                />
              )
            }
          </div>
        </CardContent>
        <CardActions
          style={{ marginLeft: 'auto',
            float: 'right', }}>
          <Button
            style={{ margin: 7, }}
            size="small"
            color="primary"
            variant="contained"
            disabled={copyDisable}
            onClick={() => {
              navigator.clipboard.writeText(value)
                .then(() => {
                  setButtonText('Copied!');
                  setCopyDisabled(true);
                });
            }}
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export { ReusableModal, };
