import { Interfaces, } from '../../../config';

const CommonProperties: Array<Interfaces.RosettaColumnPropertyType> = [
  {
    name: 'primaryKey',
    displayName: 'Primary Key',
    abbr: 'PK',
    isBoolean: true,
    disables: ['nullable', 'isNullable',],
  },
  {
    name: 'nullable',
    displayName: 'Nullable',
    abbr: 'NULL',
    isBoolean: true,
  },
];

export const GenericIntegerType: Interfaces.RosettaColumnType = {
  typeName: 'int',
  properties: [
    {
      name: 'isAutoincrement',
      displayName: 'Autoincrement',
      abbr: 'AIF',
      isBoolean: true,
      disables: ['nullable', 'isNullable',],
    },
    ...CommonProperties,
  ],
};

const _ColumnDisplaySize = {
  name: 'columnDisplaySize',
  displayName: 'Size',
  isBoolean: false,
  isNumeric: true,
};

export const GenericDoubleType: Interfaces.RosettaColumnType = {
  typeName: 'double',
  properties: [
    {
      name: 'precision',
      displayName: 'Precision',
      isBoolean: false,
    },
    {
      name: 'scale',
      displayName: 'Scale',
      isBoolean: false,
    },
    ...CommonProperties,
  ],
};

export const GenericStringType: Interfaces.RosettaColumnType = {
  typeName: 'string',
  properties: [
    ...CommonProperties,
  ],
};

export const ColumnTypes: Record<string, Record<string, Interfaces.RosettaColumnType>> = {
  mysql: {
    char: {
      typeName: 'char',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    varchar: {
      typeName: 'varchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    binary: {
      typeName:'binary',
      properties: [
        ...CommonProperties,
      ],
    },
    varbinary: {
      typeName:'varbinary',
      properties: [
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    tinyblob: {
      typeName:'tinyblob',
      properties: [],
    },
    tinytext: {
      typeName:'tinytext',
      properties: [],
    },
    text: {
      typeName: 'text',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    blob: {
      typeName: 'blob',
      properties:[
        _ColumnDisplaySize,
      ],
    },
    mediumtext: {
      typeName: 'mediumtext',
      properties:[
      ],
    },
    mediumblob: {
      typeName: 'mediumblob',
      properties:[
      ],
    },
    longtext: {
      typeName: 'longtext',
      properties:[
      ],
    },
    longblob: {
      typeName: 'longblob',
      properties:[
      ],
    },
    bit: {
      typeName: 'bit',
      ...GenericIntegerType,
    },
    tinyint: {
      typeName: 'tinyint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bool: {
      typeName: 'bool',
      properties: [
        ...CommonProperties,
      ],
    },
    boolean: {
      typeName: 'boolean',
      properties: [
        ...CommonProperties,
      ],
    },
    smallint: {
      typeName: 'smallint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    mediumint: {
      typeName: 'mediumint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    int: {
      typeName: 'int',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    integer: {
      typeName: 'integer',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bigint: {
      typeName: 'bigint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    float: {
      typeName:'float',
      ...GenericDoubleType,
    },
    double: {
      typeName:'double',
      ...GenericDoubleType,
    },
    'double precision': {
      typeName:'double precision',
      ...GenericDoubleType,
    },
    decimal: {
      typeName:'decimal',
      ...GenericDoubleType,
    },
    dec: {
      typeName: 'dec',
      ...GenericDoubleType,
    },
    date: {
      typeName: 'date',
      properties: [],
    },
    datetime: {
      typeName: 'datetime',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamp: {
      typeName: 'timestamp',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    time: {
      typeName: 'time',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    year: {
      typeName: 'time',
      properties: [],
    },
    'smallint unsigned':{
      typeName: 'smallint',
      properties: [
        ...CommonProperties,
      ],
    },
  },
  postgres: {
    integer: {
      typeName: 'integer',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    smallint: {
      typeName: 'smallint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bigint: {
      typeName:'bigint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    'double precision': {
      typeName:'double precision',
      ...GenericDoubleType,
    },
    decimal: {
      typeName:'decimal',
      ...GenericDoubleType,
    },
    numeric: {
      typeName:'numeric',
      ...GenericDoubleType,
    },
    real: {
      typeName: 'real',
      ...GenericDoubleType,
    },
    serial: {
      typeName: 'serial',
      ...GenericDoubleType,
    },
    bigserial: {
      typeName: 'bigserial',
      ...GenericDoubleType,
    },
    timestamp: {
      typeName: 'timestamp',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    date: {
      typeName: 'date',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    time: {
      typeName: 'time',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    interval: {
      typeName: 'interval',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    char: {
      typeName: 'char',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    varchar: {
      typeName: 'varchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    text: {
      typeName: 'text',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    character: {
      typeName: 'character',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    'character varying': {
      typeName: 'character varying',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    boolean: {
      typeName: 'boolean',
      properties: [
        ...CommonProperties,
      ],
    },
    money: {
      typeName: 'money',
      ...GenericDoubleType,
    },
    point: {
      typeName: 'point',
      properties: [],
    },
    line: {
      typeName:'line',
      properties: [],
    },
    lseg: {
      typeName:'lseg',
      properties: [],
    },
    box: {
      typeName:'box',
      properties: [],
    },
    path: {
      typeName:'path',
      properties: [],
    },
    polygon: {
      typeName: 'polygon',
      properties: [],
    },
    circle: {
      typeName: 'circle',
      properties: [],
    },
    int8: {
      typeName: 'int8',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bytea: {
      typeName:'bytea',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bool: {
      typeName:'bool',
      properties: [
        ...CommonProperties,
      ],
    },
    int4: {
      typeName: 'int4',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    float4: {
      typeName: 'float4',
      ...GenericDoubleType,
    },
    int2: {
      typeName: 'int2',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    smallserial: {
      typeName: 'smallserial',
      ...GenericDoubleType,
    },
    bit: {
      typeName: 'bit',
      ...GenericDoubleType,
    },
    year: {
      typeName: 'year',
      properties: [
        ...CommonProperties,
      ],
    },
    uuid: {
      typeName: 'uuid',
      properties: [
        ...CommonProperties,
      ],
    },
    bpchar: {
      typeName: 'bpchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    macaddr: {
      typeName: 'macaddr',
      properties:[
        ...CommonProperties,
      ],
    },
    pg_lsn: {
      typeName: 'pg_lsn',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _char: {
      typeName: '_char',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _text: {
      typeName: '_text',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    varbit: {
      typeName: 'varbit',
      ...GenericIntegerType,
    },
    float8: {
      typeName: 'float8',
      ...GenericDoubleType,
    },
    json: {
      typeName: 'json',
      properties: [],
    },
    jsonb: {
      typeName: 'jsonb',
      properties: [],
    },
    macaddr8: {
      typeName: 'macaddr8',
      properties:[
        ...CommonProperties,
      ],
    },
    timetz: {
      typeName: 'timetz',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamptz: {
      typeName: 'timestamptz',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    _float4: {
      typeName: '_float4',
      ...GenericDoubleType,
    },
    numrange: {
      typeName: 'numrange',
      ...GenericDoubleType,
    },
    _numeric: {
      typeName:'_numeric',
      ...GenericDoubleType,
    },
    _interval: {
      typeName: '_interval',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    _bit: {
      typeName: '_bit',
      ...GenericDoubleType,
    },
    _bool: {
      typeName:'_bool',
      properties: [
        ...CommonProperties,
      ],
    },
    _box: {
      typeName:'_box',
      properties: [],
    },
    _bpchar: {
      typeName: '_bpchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _bytea: {
      typeName:'_bytea',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _cidr: {
      typeName: '_cidr',
      properties: [],
    },
    _circle: {
      typeName: '_circle',
      properties: [],
    },
    _date: {
      typeName: '_date',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    _float8: {
      typeName: '_float8',
      ...GenericDoubleType,
    },
    _int2: {
      typeName: '_int2',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _int4: {
      typeName: '_int4',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _int8: {
      typeName: '_int8',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _json: {
      typeName: '_json',
      properties: [],
    },
    _jsonb: {
      typeName: '_jsonb',
      properties: [],
    },
    _line: {
      typeName:'_line',
      properties: [],
    },
    _lseg: {
      typeName:'_lseg',
      properties: [],
    },
    _macaddr: {
      typeName: '_macaddr',
      properties:[
        ...CommonProperties,
      ],
    },
    _macaddr8: {
      typeName: '_macaddr8',
      properties:[
        ...CommonProperties,
      ],
    },
    _money: {
      typeName: '_money',
      ...GenericDoubleType,
    },
    _pg_lsn: {
      typeName: '_pg_lsn',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _point: {
      typeName: '_point',
      properties: [],
    },
    _polygon: {
      typeName: '_polygon',
      properties: [],
    },
    _time: {
      typeName: '_time',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    _uuid: {
      typeName: '_uuid',
      properties: [
        ...CommonProperties,
      ],
    },
    _varbit: {
      typeName: '_varbit',
      ...GenericIntegerType,
    },
    _varchar: {
      typeName: '_varchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    _xml: {
      typeName: '_xml',
      properties: [],
    },
    _timestamp: {
      typeName: '_timestamp',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    _path: {
      typeName:'_path',
      properties: [],
    },
    _tsquery: {
      typeName:'_tsquery',
      properties: [],
    },
    _tsvector: {
      typeName:'_tsvector',
      properties: [],
    },
    _txid_snapshot: {
      typeName:'_txid_snapshot',
      properties: [],
    },
    mpaa_rating: {
      typeName:'_txid_snapshot',
      properties: [],
    },
    tsvector: {
      typeName:'tsvector',
      properties: [],
    },
  },
  bigquery: {
    array: {
      typeName: 'array',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bignumeric: {
      typeName: 'bignumeric',
      ...GenericDoubleType,
    },
    bool: {
      typeName:'bool',
      properties: [
        ...CommonProperties,
      ],
    },
    bytes: {
      typeName:'bytes',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    date: {
      typeName:'date',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    datetime: {
      typeName:'datetime',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    float64: {
      typeName: 'float64',
      ...GenericDoubleType,
    },
    geography: {
      typeName: 'geography',
      ...GenericDoubleType,
    },
    int64: {
      typeName: 'int64',
      ...GenericIntegerType,
    },
    interval: {
      typeName: 'interval',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    json: {
      typeName: 'json',
      properties: [],
    },
    numeric: {
      typeName: 'numeric',
      ...GenericDoubleType,
    },
    string: {
      typeName: 'string',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    struct: {
      typeName: 'struct',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    time: {
      typeName: 'time',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamp: {
      typeName: 'timestamp',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
  },
  oracle: {
    char: {
      typeName: 'char',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    varchar2: {
      typeName: 'varchar2',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    nchar: {
      typeName:'nchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    nvarchar2: {
      typeName:'nvarchar2',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    clob: {
      typeName:'clob',
      properties: [],
    },
    nclob: {
      typeName:'nclob',
      properties: [],
    },
    long: {
      typeName: 'long',
      properties: [],
    },
    number: {
      typeName: 'number',
      ...GenericDoubleType,
    },
    date: {
      typeName: 'date',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    blob: {
      typeName: 'blob',
      properties: [],
    },
    bfile: {
      typeName: 'bfile',
      properties: [],
    },
    raw: {
      typeName: 'raw',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    'long raw': {
      typeName: 'long raw',
      properties:[],
    },
    rowid: {
      typeName: 'rowid',
      properties:[],
    },
    mlslabel: {
      typeName: 'mlslabel',
      properties: [],
    },
    timestamp: {
      typeName: 'timestamp',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
  },
  snowflake: {
    number: {
      typeName: 'number',
      ...GenericDoubleType,
    },
    decimal: {
      typeName: 'decimal',
      ...GenericDoubleType,
    },
    numeric: {
      typeName:'numeric',
      ...GenericDoubleType,
    },
    int: {
      typeName:'int',
      ...GenericIntegerType,
    },
    integer: {
      typeName:'integer',
      ...GenericIntegerType,
    },
    bigint: {
      typeName:'bigint',
      ...GenericIntegerType,
    },
    smallint: {
      typeName: 'smallint',
      ...GenericIntegerType,
    },
    tinyint: {
      typeName: 'tinyint',
      ...GenericIntegerType,
    },
    byteint: {
      typeName: 'byteint',
      ...GenericIntegerType,
    },
    float: {
      typeName: 'float',
      ...GenericDoubleType,
    },
    float4: {
      typeName: 'float4',
      ...GenericDoubleType,
    },
    float8: {
      typeName: 'float8',
      ...GenericDoubleType,
    },
    double: {
      typeName: 'double',
      ...GenericDoubleType,
    },
    'double precision': {
      typeName: 'double precision',
      ...GenericDoubleType,
    },
    real: {
      typeName: 'real',
      ...GenericDoubleType,
    },
    varchar: {
      typeName: 'varchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    char: {
      typeName: 'char',
      properties:[],
    },
    character: {
      typeName: 'character',
      properties:[],
    },
    string: {
      typeName: 'string',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    text: {
      typeName: 'text',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    binary: {
      typeName: 'binary',
      properties: [],
    },
    varbinary: {
      typeName: 'varbinary',
      properties: [],
    },
    boolean: {
      typeName: 'boolean',
      properties: [
        ...CommonProperties,
      ],
    },
    date: {
      typeName: 'date',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    datetime: {
      typeName: 'datetime',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    time: {
      typeName: 'time',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamp: {
      typeName: 'timestamp',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamp_ltz: {
      typeName: 'timestamp_ltz',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamp_ntz: {
      typeName: 'timestamp_ntz',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamp_tz: {
      typeName: 'timestamp_tz',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    variant: {
      typeName: 'variant',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    object: {
      typeName: 'object',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    array: {
      typeName: 'array',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    geography: {
      typeName: 'geography',
      properties: [],
    },
    geometry: {
      typeName: 'geometry',
      properties: [],
    },
  },
  sqlserver: {
    bigint: {
      typeName: 'bigint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bit: {
      typeName: 'bit',
      ...GenericDoubleType,
    },
    decimal: {
      typeName:'decimal',
      ...GenericDoubleType,
    },
    int: {
      typeName:'int',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    money: {
      typeName:'money',
      ...GenericDoubleType,
    },
    numeric: {
      typeName:'numeric',
      ...GenericDoubleType,
    },
    smallint: {
      typeName: 'smallint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    smallmoney: {
      typeName: 'smallmoney',
      ...GenericDoubleType,
    },
    tinyint: {
      typeName: 'tinyint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    float: {
      typeName: 'float',
      ...GenericDoubleType,
    },
    real: {
      typeName: 'real',
      ...GenericDoubleType,
    },
    date: {
      typeName: 'date',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    datetime2: {
      typeName: 'datetime2',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    datetime: {
      typeName: 'datetime',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    datetimeoffset: {
      typeName: 'datetimeoffset',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    smalldatetime: {
      typeName: 'smalldatetime',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    time: {
      typeName: 'time',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    char: {
      typeName: 'char',
      properties:[],
    },
    text: {
      typeName: 'text',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    varchar: {
      typeName: 'varchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    nchar: {
      typeName: 'nchar',
      properties: [],
    },
    varbinary: {
      typeName: 'varbinary',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    ntext: {
      typeName: 'ntext',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    nvarchar: {
      typeName: 'nvarchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    binary: {
      typeName: 'binary',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    image: {
      typeName: 'image',
      properties: [],
    },
    cursor: {
      typeName: 'cursor',
      properties: [],
    },
    hierarchyid: {
      typeName: 'hierarchyid',
      properties: [],
    },
    sql_variant: {
      typeName: 'sql_variant',
      properties: [],
    },
    'Spatial Geometry Types': {
      typeName: 'Spatial Geometry Types',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    table: {
      typeName: 'table',
      properties: [],
    },
    rowversion: {
      typeName: 'rowversion',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    uniqueidentifier: {
      typeName: 'uniqueidentifier',
      properties: [
        ...CommonProperties,
      ],
    },
    geometry: {
      typeName: 'geometry',
      properties: [],
    },
    xml: {
      typeName: 'xml',
      properties: [],
    },
    'Spatial Geography Types"': {
      typeName: 'Spatial Geography Types"',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
  },
  db2: {
    character: {
      typeName: 'character',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    varchar: {
      typeName: 'varchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    clob: {
      typeName:'clob',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    graphic: {
      typeName:'graphic',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    vargraphic: {
      typeName:'vargraphic',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    dbclob: {
      typeName:'dbclob',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    binary: {
      typeName: 'binary',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    varbinary: {
      typeName: 'varbinary',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    blob: {
      typeName: 'blob',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    smallint: {
      typeName: 'smallint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    int: {
      typeName: 'int',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bigint: {
      typeName: 'bigint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    decimal: {
      typeName: 'decimal',
      ...GenericDoubleType,
    },
    decfloat: {
      typeName: 'decfloat',
      ...GenericDoubleType,
    },
    real: {
      typeName: 'real',
      ...GenericDoubleType,
    },
    double: {
      typeName: 'double',
      ...GenericDoubleType,
    },
    bool: {
      typeName: 'bool',
      properties:[
        ...CommonProperties,
      ],
    },
    boolean: {
      typeName: 'boolean',
      properties:[
        ...CommonProperties,
      ],
    },
    date: {
      typeName: 'date',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    time: {
      typeName: 'time',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamp: {
      typeName: 'timestamp',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
  },
  kinetica: {
    string: {
      typeName: 'string',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    integer: {
      typeName:'integer',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bigint: {
      typeName:'bigint',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    'varchar': {
      typeName:'varchar',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    bytes: {
      typeName:'bytes',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    int8: {
      typeName:'int8',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    int16: {
      typeName:'int16',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    int: {
      typeName:'int',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    long: {
      typeName:'long',
      properties:[
        _ColumnDisplaySize,
        ...CommonProperties,
      ],
    },
    float: {
      typeName:'float',
      ...GenericDoubleType,
    },
    double: {
      typeName:'double',
      ...GenericDoubleType,
    },
    decimal: {
      typeName:'decimal',
      ...GenericDoubleType,
    },
    date: {
      typeName: 'date',
      properties: [],
    },
    time: {
      typeName: 'time',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    timestamp: {
      typeName: 'timestamp',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
    datetime: {
      typeName: 'datetime',
      properties: [
        {
          name: 'format',
          displayName: 'Format',
          isBoolean: false,
        },
      ],
    },
  },
};
