import { axiosClient, } from '../config/helpers';
import { Constants, } from '../config';

export const backupServices = {
  addRestore,
  fetchBackup,
  fetchDownload,
  checkExists,
};

function fetchBackup() {
  return axiosClient().get(`${Constants.api.endpoints.backup}/start`);
}

function fetchDownload() {
  return axiosClient().get(
    `${Constants.api.endpoints.backup}/download`,
    {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    }
  ).then(( data: any ) => {
    const filename = data.headers['x-suggested-filename'] || 'zip.zip';
    const downloadUrl = window.URL.createObjectURL(new Blob([data.data,]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}

function addRestore(file: any) {
  const formData = new FormData();
  formData.append('file', file);
  return axiosClient().post(
    `${Constants.api.endpoints.backup}/restore`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
}

function checkExists() {
  return axiosClient().get(`${Constants.api.endpoints.backup}/check`);
}
