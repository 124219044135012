import { axiosClient, } from '../config/helpers';
import { Constants, } from '../config';

export const scheduleServices = {
  fetchSchedules,
  deleteSchedule,
  activateSchedule,
  deactivateSchedule,
};

function fetchSchedules() {
  return axiosClient().get(Constants.api.endpoints.scheduler);
}

function deleteSchedule(catalogId: string) {
  return axiosClient().delete(`${Constants.api.endpoints.scheduler}/${catalogId}`);
}

function activateSchedule(catalogId: string) {
  return axiosClient().get(`${Constants.api.endpoints.scheduler}/${catalogId}/activate`);
}

function deactivateSchedule(catalogId: string) {
  return axiosClient().get(`${Constants.api.endpoints.scheduler}/${catalogId}/deactivate`);
}
