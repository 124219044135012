import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) => ({
  toolBar: {
    background: theme.palette.background.paper,
    boxShadow: 'rgba(100, 100, 111, 0.4) 0px 1px 8px 0px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin',], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    borderRight: 'none',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    borderRight: 'none',
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  title: {
    cursor: 'pointer',
    fontSize: '16pt !important',
    marginLeft: '20px !important',
    color: 'black',
  },
  location: {
    fontSize: '15pt',
    fontWeight: 100,
    marginLeft: 13,
    marginRight: 10,
    color: 'black',
  },
  footerDiv: {
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    paddingBottom: 10,
    width: 'inherit',
  },
  versionStyle: {
    marginLeft: 10,
    padding: 5,
    fontSize: 15,
    fontWeight: 100,
  },
  item: {
    textDecoration: 'none',
    color: theme.palette.grey[600],
  },
  selectedItem: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  logo: {
    marginLeft: 10,
    width: 30,
    height: 30,
  },
  rosettaLogo: {
    marginLeft: 10,
    width: 140,
    height: 40,
    cursor: 'pointer',
  },
}));

export { useStyles, };
