import React, {
  useEffect,
  useState,
} from 'react';
import { useStyles, } from './styles';
import AceEditor from 'react-ace';
import { MenuItem, } from '@mui/material';
import { Interfaces, } from '../../../../config';
import { datasourceServices, } from '../../../../services';
import { AxiosResponse, } from 'axios';
import { DataSourceLogo, } from '../../../DatasourceIcon';
import { StyledTextField, } from '../../../Reusable';

const RunSQL = ({
  alert,
  setAlert,
  handleValidation,
}: {
  alert: Interfaces.Alert;
  // eslint-disable-next-line no-unused-vars
  setAlert: (alert: Interfaces.Alert) => void;
  // eslint-disable-next-line no-unused-vars
  handleValidation: (value: boolean) => void;
}) => {
  const classes = useStyles();
  const [dataSources, setDataSources,] = useState<Array<Interfaces.DataSourceType>>([]);

  useEffect(() => {
    datasourceServices.fetchDataSources()
      .then((response: AxiosResponse<Array<Interfaces.DataSourceType>>) => {
        setDataSources(response.data.filter((ds) => ds.type === 'JDBC'));
      });
  }, []);

  useEffect(() => {
    const dataSourceId = alert.outputRule.rule['datasourceId'];
    const query = alert.outputRule.rule['query'];
    const isValid = !!(
      dataSourceId && dataSourceId !== ''
      && query && query !== ''
    );
    handleValidation(isValid);
  }, [alert.outputRule,]);

  return (
    <div className={classes.containerWidth}>
      <form>
        <div
          className={classes.marginBottom}
        >
          <StyledTextField
            id="selectedDatasource"
            select
            label="Select a datasource"
            value={alert.outputRule.rule['datasourceId']}
            size={'small'}
            onChange={(event) => {
              setAlert({
                ...alert,
                outputRule: {
                  ...alert.outputRule,
                  rule: {
                    ...alert.outputRule.rule,
                    ['datasourceId']: event.target.value,
                  },
                },
              });
            }}
            variant="outlined"
            className={classes.marginBottom}
            style={{ width: '100%', }}
            required
          >
            {dataSources.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <div style={{
                  display: 'flex',
                  maxHeight: '30px',
                  minHeight: '30px',
                }}>
                  <div
                    style={{
                      marginRight: '10px',
                      maxHeight: '30px',
                      minHeight: '30px',
                      display: 'grid',
                      alignContent: 'center',
                    }}
                  >
                    <DataSourceLogo
                      name={option.driverName as any}
                      type={option.type as any}
                      margin={'0 20px'}
                      maxWidth={'50px'}
                      maxHeight={'30px'}
                    />
                  </div>
                  <div style={{ display: 'grid',
                    alignContent: 'center', }}>
                    {option.name}
                  </div>
                </div>
              </MenuItem>
            ))}
          </StyledTextField>
          <AceEditor
            placeholder="Write a SQL statement"
            mode="sql"
            width="auto"
            fontSize={18}
            height="200px"
            value={alert.outputRule.rule['query']}
            onChange={(value) => {
              setAlert({
                ...alert,
                outputRule: {
                  ...alert.outputRule,
                  rule: {
                    ...alert.outputRule.rule,
                    ['query']: value,
                  },
                },
              });
            }}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            style={{ width: '100%',
              border: '1px solid #ccc',
              borderRadius: 4, }}
          />
        </div>
      </form>
    </div>
  );
};
export { RunSQL, };
