import React, {
  useEffect,
  useState,
} from 'react';
import { makeStyles, } from '@mui/styles';
import { useSnackbar, } from 'notistack';
import { useParams, } from 'react-router-dom';

import {
  AutoAwesome,
  PlayCircleOutline,
} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Box,
  Tooltip,
} from '@mui/material';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/ext-error_marker';
import 'ace-builds/src-noconflict/snippets/sql';
import langTools from 'ace-builds/src-noconflict/ext-language_tools';
import { helpers, } from '../../../utils';
import { Interfaces, } from '../../../config';
import { NoDataV2, } from '../../NoDataV2';
import LoadingComponent from '../../Loading';
import {
  dataServices,
  datasourceServices,
} from '../../../services';
import axios, { AxiosResponse, } from 'axios';
import { DataTable, } from './DataTable';
import { SchemaTree, } from './SchemaTree';
import { useAuth, } from '../../../context';
import { format, } from 'sql-formatter';
import { useLocalStorage, } from 'usehooks-ts';
import { v4, } from 'uuid';
import { IQueryHistory, } from '../../../config/interfaces';
import { QueryHistory, } from './QueryHistory';

const useStyles = makeStyles(() => ({
  schemaViewContainer: {
    marginRight: 30,
    background: '#fff',
    borderRadius: 8,
    padding: '10px',
    height: '88vh',
  },
  schemaViewGrid: {
    overflowY: 'auto',
    marginTop: 10,
    height: '81vh',
  },
  inputs: {
    background: '#fff',
    borderRadius: 8,
    padding: '10px',
  },
}));

const QUERY_HISTORY_KEY = 'query_history_key';

const setCompletions = (
  schema: Interfaces.Schema,
  selectedDataSource: string,
  defaultCompleter: Array<any>,
  setDefaultCompleter: any
) => {
  if (defaultCompleter.length === 0) {
    setDefaultCompleter([
      langTools.snippetCompleter,
      langTools.textCompleter,
      langTools.keyWordCompleter,
    ]);
  } else {
    langTools.setCompleters(defaultCompleter);
  }
  langTools.addCompleter({
    getCompletions: function (
      editor: any,
      session: any,
      pos: any,
      prefix: any,
      callback: any
    ) {
      const completions: any = [];
      for (let field of schema?.fields || []) {
        completions.push({
          value: field.name,
          meta: selectedDataSource,
        });
        (field?.fields?.map((_field) => _field.name) || []).forEach(function (w) {
          completions.push({
            value: w,
            meta: selectedDataSource,
          });
        });
      }
      callback(null, completions);
    },
  });
};

const Editor = () => {
  const classes = useStyles();
  const { health, } = useAuth();
  const [query, setQuery,] = useState('');
  const [limit, setLimit,] = useState(10);
  const [defaultCompleter, setDefaultCompleter,] = useState<Array<any>>([]);
  const [dataSources, setDataSources,] = useState<Array<Interfaces.DataSourceType>>([]);
  const [schema, setSchema,] = useState<Interfaces.Schema | null>(null);
  const [selectedDataSource, setSelectedDataSource,] = useState<Interfaces.DataSourceType | null>(null);
  const [rows, setRows,] = useState<Array<any>>([]);
  const [loadingQuery, setLoadingQuery,] = React.useState<boolean>(false);
  const [naturalQuery, setNaturalQuery,] = React.useState<string>('');

  const [loadingDataSources, setLoadingDataSources,] = useState(false);
  const [loadingSchema, setLoadingSchema,] = useState(false);
  const [loadingRows, setLoadingRows,] = useState(false);
  const [queryExecutionTime, setQueryExecutionTime,] = useState<number>(-1);
  const [controller, setController,] = useState<AbortController>();

  const [queryHistory, setQueryHistory,] = useLocalStorage<IQueryHistory[]>(QUERY_HISTORY_KEY, []);

  const { enqueueSnackbar, } = useSnackbar();
  const params = useParams<{ dataSourceName: string }>();
  const { isAuthorized, } = useAuth();
  const [readDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_READ'));
  const [executeDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_EXECUTE'));

  useEffect(() => {
    setLoadingDataSources(true);
    datasourceServices.fetchDataSources()
      .then((response: AxiosResponse<Array<Interfaces.DataSourceType>>) => {
        setDataSources(response.data);
        if (params.dataSourceName) {
          const idx = response.data.findIndex((ds) => ds.name === params.dataSourceName);
          if (idx !== -1) {
            const tmpDs = response.data[idx];
            if (tmpDs.type === 'JDBC') {
              setSelectedDataSource(tmpDs);
            }
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingDataSources(false);
      });
    return;
  }, []);

  useEffect(() => {
    if (selectedDataSource) {
      setLoadingSchema(true);
      dataServices.fetchActiveSchema(selectedDataSource.name)
        .then((response: AxiosResponse<Interfaces.Schema>) => {
          const tmpSchema = helpers.addMissingFieldsToSchema(response.data, '', false);
          setSchema(tmpSchema);
          setCompletions(
            tmpSchema,
            selectedDataSource.name,
            defaultCompleter,
            setDefaultCompleter
          );
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => {
          setLoadingSchema(false);
        });
      return;
    }
    return () => {
      controller?.abort();
    };
  }, [selectedDataSource,]);

  if (loadingDataSources) {
    return <LoadingComponent />;
  }

  return (
    <Grid container>
      { readDSAccess && executeDSAccess && (
        <>
          <Grid item xs={3}>
            <Grid className={classes.schemaViewContainer}>
              <TextField
                variant="outlined"
                fullWidth
                select
                size="small"
                label="Select datasource"
                disabled={!!params.dataSourceName || loadingDataSources || loadingSchema}
                value={selectedDataSource?.name}
                onChange={(e) => {
                  const idx = dataSources.findIndex((ds) => ds.name === e.target.value);
                  if (idx !== -1) {
                    setSelectedDataSource(dataSources[idx]);
                  }
                }}
              >
                {
                  dataSources
                    .filter((dataSource) => dataSource.type === 'JDBC')
                    .map((dataSource) => (
                      <MenuItem key={dataSource.name} value={dataSource.name}>
                        {dataSource.name}
                      </MenuItem>
                    ))
                }
              </TextField>
              {
                loadingSchema && (
                  <LoadingComponent size={50} />
                )
              }
              {
                selectedDataSource && schema && !loadingSchema && (
                  <Grid className={classes.schemaViewGrid}>
                    <SchemaTree schema={schema} dataSource={selectedDataSource}/>
                  </Grid>
                )
              }
              {
                selectedDataSource === null && (
                  <NoDataV2 message={'No data source selected'} />
                )
              }
              {
                selectedDataSource && !schema && !loadingSchema && (
                  <NoDataV2 message={'No schema available'} />
                )
              }
            </Grid>
          </Grid>
          <Grid item xs={9} className={classes.inputs}>
            <Tooltip title={!health.isOpenAIConfigured? 'OpenAI API key not set, please update it inside application properties!' : ''}>
              <form onSubmit={async (event) => {
                event.preventDefault();
                setLoadingQuery(true);
                setQuery('');
                try {
                  const response: AxiosResponse<{
                      data: string
                    }> = await datasourceServices.generateQuery(`${selectedDataSource?.id}`, naturalQuery);

                  const formattedSql = format(response.data.data,  {
                    language: 'sql',
                    indentStyle: 'standard',
                  });

                  setQuery(formattedSql);
                } catch (error) {
                  enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
                } finally {
                  setLoadingQuery(false);
                }
              }}
              >
                <Box display={'flex'} gap={2} alignItems={'center'} paddingBottom={2}>
                  <TextField
                    value={naturalQuery}
                    onChange={(event) => setNaturalQuery(event.target.value)}
                    size={'small'}
                    fullWidth
                    label={'Natural Language Query'}
                    disabled={loadingQuery || loadingDataSources || !health.isOpenAIConfigured}
                  />
                  <Button
                    disabled={loadingQuery || loadingRows || loadingDataSources || !selectedDataSource || !health.isOpenAIConfigured}
                    variant={'contained'}
                    type={'submit'}
                    endIcon={
                      loadingRows ? (
                        <CircularProgress size={16} />
                      ) : (
                        <AutoAwesome />
                      )
                    }
                  >
                    Generate
                  </Button>
                </Box>
              </form>
            </Tooltip>
            <div
              style={{
                position: 'relative',
              }}
            >
              <AceEditor
                placeholder={loadingQuery ? 'Generating SQL statement...' : 'Write a SQL statement'}
                mode={'sql'}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 8,
                  resize: 'vertical',
                  minHeight: '200px',
                  maxHeight: '100vh',
                }}
                width="auto"
                fontSize={18}
                height="200px"
                value={query}
                onChange={(value: any) => {
                  setQuery(value);
                }}
                readOnly={loadingQuery || loadingRows || loadingDataSources}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
              />
              {queryHistory.length > 0 && (
                <QueryHistory
                  onQuerySelect={(qh) => {
                    const ds = dataSources.find((ds) => ds.id === qh.dataSourceId);
                    if (ds) {
                      setSelectedDataSource(ds);
                      setQuery(qh.query);
                      return;
                    }
                    enqueueSnackbar(`Datasource ${qh.dataSourceName} not available to be selected!`);
                  }}
                  queryHistory={queryHistory}
                />
              )}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                marginTop: 6,
                marginBottom: 20,
                alignItems: 'flex-start',
              }}
            >
              <Box marginTop={'10px'}>
                <Button
                  variant={loadingRows ? 'outlined' : 'contained'}
                  endIcon={
                    loadingRows ? (
                      <CircularProgress size={16} />
                    ) : (
                      <PlayCircleOutline />
                    )
                  }
                  color={'primary'}
                  style={{ height: 42, }}
                  disabled={!selectedDataSource || loadingSchema || loadingDataSources || loadingQuery}
                  onClick={() => {
                    setQueryExecutionTime(-1);
                    if (loadingRows) {
                      controller?.abort();
                      setLoadingRows(false);
                      return;
                    }
                    const abortController = new AbortController();
                    setController(abortController);
                    if (selectedDataSource) {
                      setLoadingRows(true);
                      const startTime = new Date();
                      datasourceServices.executeQuery(selectedDataSource.name, query, limit, abortController.signal)
                        .then((response: AxiosResponse<Array<any>>) => {
                          const executionTime = startTime.getMilliseconds() - new Date().getMilliseconds();
                          setQueryHistory([
                            ...queryHistory,
                            {
                              id: v4(),
                              query: query,
                              executionTime: executionTime > 0 ? executionTime : 10,
                              executedAt: startTime,
                              results: response.data,
                              dataSourceId: selectedDataSource.id,
                              dataSourceName: selectedDataSource.name,
                            },
                          ]);
                          setQueryExecutionTime(executionTime > 0 ? executionTime : 10);
                          setRows(response.data);
                        })
                        .catch((error) => {
                          if (axios.isCancel(error)) {
                            return;
                          }
                          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
                        })
                        .finally(() => {
                          setLoadingRows(false);
                        });
                    }
                  }}
                >
                  {loadingRows ? 'Cancel' : 'Run Query'}
                </Button>

                <TextField
                  style={{
                    margin: 1,
                    minWidth: 100,
                    marginLeft: 30,
                  }}
                  id="demo-simple-select-autowidth"
                  name="limit"
                  select
                  label="Limit"
                  variant="outlined"
                  size="small"
                  value={limit}
                  onChange={(event: any) => {
                    setLimit(event.target.value || 10);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                </TextField>
              </Box>
              {queryExecutionTime !== -1 && (
                <div
                  style={{
                    fontSize: 13,
                    color: '#858484',
                    marginLeft: 'auto',
                  }}
                >
                  Query executed in {queryExecutionTime} milliseconds
                </div>
              )}
            </div>
            <Box style={{ marginTop: '20px', }}>
              {
                loadingRows && (
                  <LoadingComponent />
                )
              }
              {
                !loadingRows && (
                  <DataTable rows={rows ?? []} ghostTable={!rows || rows?.length === 0} />
                )
              }
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Editor;
