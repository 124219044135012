import React, {
  useEffect,
  useState,
} from 'react';
import Style from './style';
import {
  Grid,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import logo from '../CardView/logo';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  Constants,
  Interfaces,
} from '../../../config';
import {
  InfoOutlined,
  Loop,
  Save,
} from '@mui/icons-material';
import { DirectoryTree, } from '../DirectoryTree';
import LoadingComponent from '../../Loading';
import { NoDataV2, } from '../../NoDataV2';
import { useDSCalls, } from '../../../hooks';
import {
  StyledButton,
  StyledTextField,
} from '../../Reusable';

type Params = {
  oldName: string;
};

interface Props {
  schemaTypes: Array<string>;
}

function Ftp(props: Props) {
  const classes = Style();
  const location = useLocation();
  const navigate = useNavigate();
  const { oldName, } = useParams<Params>();
  const inEditMode = location.pathname.includes('edit');
  const [expandedElements, setExpandedElements,] = useState<Array<string>>([]);
  const [exploreState, setExploreState,] = useState(true);


  const getTypeFromName = (directory: Interfaces.Directory) => {
    if (directory.isDirectory) {
      return 'DIRECTORY';
    }
    const extension = directory.path.split('.').pop();
    if (extension === 'csv') {
      return 'CSV';
    }
    return 'AVRO';
  };

  const getTypeFromUri = (uri: string) => {
    const extension = uri.split('.').pop();
    if (extension === 'csv') {
      return 'CSV';
    } else if (extension === 'avro') {
      return 'AVRO';
    }
    return 'DIRECTORY';
  };

  const { schemaTypes, } = props;
  const [state, setState,] = useState<Interfaces.BaseDataSource>({
    id: '',
    name: '',
    schemaType: schemaTypes[0],
    uri: '',
    type: 'FTP',
  });
  const {
    dataSources,
    loading: loadingDS,
    editDataSource,
    addDataSource,
    dataSourceToEdit: dataSourceToEditHook,
    directories,
    loadingEdit,
    errorEdit,
    loadingDirectories,
    errorDirectories,
    fetchDirectories,
    setDataSourceToEdit,
    fetchNestedDirectory,
    setTestDataSource,
  } = useDSCalls();

  const dataSourceToEdit = dataSourceToEditHook as Interfaces.BaseDataSource | null;
  const endpoint = inEditMode && dataSourceToEdit
    ? `data-sources/${dataSourceToEdit.id}/ftp/dir`
    : Constants.api.endpoints.ftpDirectories;

  const fetchDirectoriesFTP = (uri: string | null) => {
    fetchDirectories({
      uri,
    }, endpoint);
  };

  useEffect(() => {
    setTestDataSource(null);
    if (inEditMode) {
      if (dataSourceToEdit) {
        setState({
          ...state,
          id: dataSourceToEdit?.id,
          name: dataSourceToEdit?.name,
          type: dataSourceToEdit?.type,
          schemaType: dataSourceToEdit?.schemaType,
          uri: dataSourceToEdit?.uri,
        });
        fetchDirectoriesFTP(dataSourceToEdit.uri === '' ? null : dataSourceToEdit.uri);
        setExploreState(false);
      } else {
        if (!dataSources) {
          return;
        }
        const dataSourceToChange = dataSources.find((item) => item.name === oldName) as Interfaces.BaseDataSource;
        setDataSourceToEdit(dataSourceToChange);
      }
    }
  }, [dataSourceToEdit, dataSources,]);

  const handleChange = (event: any) => {
    let property = event.target.name;
    let value = event.target.value;

    setState({
      ...state,
      [property]: value,
    });
  };

  const postSubmitAction = () => {navigate('/data-sources');};

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (inEditMode && oldName) {
      editDataSource( state, oldName, postSubmitAction);
    } else {
      addDataSource(state, postSubmitAction);
    }
  };

  if (loadingEdit) {
    return <LoadingComponent message={'Loading data source'}/>;
  }

  if (errorEdit) {
    return (
      <p>{errorEdit}</p>
    );
  }

  if (inEditMode && !dataSourceToEdit && !loadingDS) {
    return (
      <p>No data source was selected for edit mode</p>
    );
  }

  return (
    <form name="ftpDataSource" onSubmit={handleSubmit}>
      <div className={classes.root}>
        <Grid container justifyContent='center'>
          <img
            src={logo.images.ftp}
            alt="FTP"
            className={classes.img}
          />
        </Grid>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={6}>
            <StyledTextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              size="small"
              value={state.name}
              onChange={(event: any) => {
                handleChange(event);
              }}
            />
            <StyledTextField
              id="type"
              name="schemaType"
              variant="outlined"
              value={state.schemaType}
              fullWidth
              size="small"
              onChange={handleChange}
              select
              SelectProps={{
                native: true,
              }}
            >
              {schemaTypes.map((option: string) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <StyledTextField
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <Tooltip title={'Reload the files'} placement="top">
                        <IconButton
                          disabled={exploreState}
                          color={'primary'}
                          edge={'end'}
                          onClick={() => {
                            fetchDirectoriesFTP(state.uri === '' ? null : state.uri);
                            setState({
                              ...state,
                              schemaType: getTypeFromUri(state.uri),
                            });
                          }}
                        >
                          <Loop />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                id="uri"
                name="uri"
                label="Uri"
                variant="outlined"
                size="small"
                value={state.uri}
                onChange={(event: any) => {
                  handleChange(event);
                }}
              />
              <Tooltip title="Uri must start with 'file:///'" placement="top">
                <InfoOutlined
                  fontSize="small"
                  style={{
                    marginLeft: 10,
                  }}
                />
              </Tooltip>
            </div>
            {
              exploreState
                ? (
                  <Box className={classes.treeBoxEmpty}>
                    <StyledButton
                      disabled={state.uri === '' || state.uri === null}
                      onClick={() => {
                        fetchDirectoriesFTP(state.uri === '' ? null : state.uri);
                        setExploreState(false);
                      }}
                      color='primary'
                      variant='contained'
                      className={classes.exploreButton}
                    >
                      Explore
                    </StyledButton>
                    {
                      (state.uri === '' || state.uri === null)
                        && <Typography display={'block'} style={{ color: '#949494', }} variant={'caption'}>
                          Uri is required
                        </Typography>
                    }
                  </Box>
                )
                : (
                  <Box  className={classes.treeBox}>
                    {loadingDirectories && directories.length === 0 && <LoadingComponent size={60} message={'Loading bucket'} />}
                    {
                      errorDirectories &&
                      <div style={{ textAlign: 'center', }}>
                        <NoDataV2 message={errorDirectories} />
                        <StyledButton
                          onClick={() => {
                            fetchDirectoriesFTP(state.uri === '' ? null : state.uri);
                            setExploreState(false);
                          }}
                          color='primary'
                          variant='contained'
                        >
                          Retry
                        </StyledButton>
                      </div>
                    }
                    {directories.length > 0 && !errorDirectories && (
                      <DirectoryTree
                        directories={directories}
                        isLoading={loadingDirectories}
                        expandedElements={expandedElements}
                        onTreeItemClick={(directory: Interfaces.Directory) => {
                          setState({
                            ...state,
                            uri: directory.path,
                            schemaType: getTypeFromName(directory),
                          });
                          if (directory.isDirectory) {
                            if (!directory.directories) {
                              fetchNestedDirectory({
                                uri: directory.path,
                              }, endpoint, directory.path);
                            }
                            if (expandedElements.includes(directory.path)) {
                              setExpandedElements([...expandedElements,].filter((expanded: string) => expanded !== directory.path));
                            } else {
                              setExpandedElements([...expandedElements, directory.path,]);
                            }
                          }
                        }}/>
                    )}
                  </Box>
                )
            }
          </Grid>
        </Grid>
        <div style={{ width: '100%', }}>
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            startIcon={loadingDS ?
              <CircularProgress
                color="inherit"
                style={{
                  width: 16,
                  height: 11,
                }} />
              :
              <Save />}
          >
            Save
          </StyledButton>
          <StyledButton
            variant="outlined"
            size="large"
            className={classes.button}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </div>
    </form>
  );
}

export default Ftp;
