import React from 'react';
import {
  Description,
  LocalOffer,
  AccountTreeRounded,
  NotificationsActive,
} from '@mui/icons-material';
import {
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { useNavigate, } from 'react-router-dom';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faDatabase, } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar, } from 'notistack';
import ScheduleIcon from '@mui/icons-material/Schedule';
import collection from '../../images/collection.png';
import gitIcon from '../../assets/icons/git.png';
import { tagsServices, } from '../../services';
import { helpers, } from '../../utils';
import { StyledButton, } from '../Reusable';
import { ReactComponent as CollectionSvg, } from '../../assets/icons/collection.svg';



function NoDataComponent(props: any) {
  const { data, tags, } = props;
  const { enqueueSnackbar, } = useSnackbar();
  const history = useNavigate();

  function getIcon() {
    if (data.component === 'catalogs') {
      return <Description color="disabled" style={{ fontSize: 80, }}/>;
    }
    if (data.component === 'tags') {
      return <LocalOffer color="disabled" style={{ fontSize: 80, }}/>;
    }
    if (data.component === 'dataSources') {
      return <FontAwesomeIcon icon={faDatabase} style={{ fontSize: 70,
        color: 'rgba(0, 0, 0, 0.26)', }}/>;
    }
    if (data.component === 'schema-evolution') {
      return <AccountTreeRounded color="disabled" style={{ fontSize: 80, }}/>;
    }
    if (data.component === 'schedules') {
      return <ScheduleIcon color="disabled" style={{ fontSize: 80, }}/>;
    }
    if (data.component === 'collections') {
      return <img alt={'some-image'} src={collection} color="disabled" style={{ width: 150, }}/>;
    }
    if (data.component === 'alerts') {
      return <NotificationsActive color="disabled" style={{ fontSize: 80, }}/>;
    }
    if (data.component === 'git-configs') {
      return <img alt={'git-image'} src={gitIcon} style={{ width: 80, }}/>;
    }
    if (data.component === 'deployments') {
      return <CollectionSvg style={{ width: 80,
        height: 80, }} />;
    }
  }

  const Input = styled('input')({
    display: 'none',
  });

  const importFromCSV = (event: any) => {
    // dispatch(addTagFromCsv(event.target.files[0], (isSuccess: boolean, message: any) => {
    //   if (!isSuccess) {
    //     enqueueSnackbar(message, { variant: 'error', });
    //   } else {
    //     enqueueSnackbar(message, { variant: 'success', });
    //   }
    // }));
    tagsServices.addTagFromCsv(event.target.files[0])
      .then(() => {
        enqueueSnackbar('Tag added successfully', { variant: 'success', });
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  function renderOptionalTags() {
    if (tags) {
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" color="textSecondary">
            OR
          </Typography>

          <Input
            onChange={(event: any) => {
              importFromCSV(event);
            }}
            hidden
            id="contained-button-file"
            type="file"
            accept=".csv"
          />
          <label htmlFor="contained-button-file">
            <StyledButton variant="outlined" color="primary" component={'span'}>
              {tags.buttonText2}
            </StyledButton>
          </label>
        </Grid>
      );
    }
  }

  return (
    <Grid id={'nodata-container'} container direction="row" justifyContent="center" alignItems="center">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={ { marginTop:'12%', } }
      >
        {getIcon()}
        <Typography style={{ marginTop: '20px', }} variant="h4">
          {data.header}
        </Typography>
        <Typography style={{ marginTop: '30px', }} color="textSecondary" variant="subtitle1">
          {data.text1}
        </Typography>
        <Typography color="textSecondary" variant="subtitle1">
          {data.text2}
        </Typography>
        {
          data.buttonText && (
            <StyledButton
              variant="outlined"
              style={ { marginTop:'50px', } }
              color="primary"
              size="large"
              onClick={() => {
                history(props.data.route);
              }}
            >
              {data.buttonText}
            </StyledButton>
          )
        }
        {renderOptionalTags()}
      </Grid>
    </Grid>
  );
}

export default NoDataComponent;
