import React, {
  useEffect,
  useState,
} from 'react';
import { ReusableModal, } from './ReusableModal';
import { Interfaces, } from '../../../../config';
import { helpers, } from '../helpers';
import { catalogsServices, } from '../../../../services';
import { useSnackbar, } from 'notistack';

const AvroDialog = ({
  catalog,
  isOpen,
  handleClose,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { enqueueSnackbar, } = useSnackbar();
  const [value, setValue,] = useState('');
  const [loading, setLoading,] = useState(true);

  useEffect(() => {
    if (isOpen) {
      const filtered: Interfaces.Schema = helpers.extractInputSchemaBySelection(JSON.parse(JSON.stringify(catalog)));
      const cleansed = helpers.cleanseSchema(filtered);
      catalogsServices.previewCatalog({
        ...catalog,
        schema: cleansed,
      })
        .then((response) => {
          const avroRes = helpers.getAvroSchema(response.data);
          setValue(JSON.stringify(avroRes, null, 2));
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {
      setLoading(true);
    };
  }, [isOpen,]);

  return (
    <ReusableModal
      open={isOpen}
      title={'Avro'}
      value={value}
      loading={loading}
      mode={'javascript'}
      handleClose={() => handleClose()}
    />
  );
};

export { AvroDialog, };
