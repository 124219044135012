import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import { Theme, } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '10px',
    },
    img: {
      width: '19%',
      marginBottom: '0.2rem',
    },
    inline: {
      margin: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paddingBox: {
      paddingBottom: '15px',
    },
    buttonTest: {
      float: 'right',
      margin: '8px',
    },
    button: {
      margin: theme.spacing(1),
    },
    treeBox: {
      border: '1px solid #c0c0c0',
      borderRadius: '5px',
      overflowY: 'auto',
      height: '80%',
      padding: '15px 3px',
    },
    treeBoxEmpty: {
      textAlign: 'center',
      border: '1px solid #c0c0c0',
      borderRadius: '5px',
      overflowY: 'auto',
      minHeight: '250px',
      height: '80%',
      paddingTop: 100,
      marginBottom: 15,
    },
    treeNew: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
    exploreButton: {
      height: 50,
      width: 120,
      fontSize: '1.2rem',
    },
  })
);

export default useStyles;
