import React from 'react';
import { CatalogTableHead, } from './CatalogTableHead';
import { CatalogTableToolbar, } from './CatalogTableToolbar';
import {
  Button,
  Input,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Switch,
  Box,
  Grid,
} from '@mui/material';
import { Interfaces, } from '../../../config';
import { useLocalStorage, } from '../../Hooks';
import { useAuth, } from '../../../context';
import { catalogsServices, } from '../../../services';
import { useSnackbar, } from 'notistack';
import { AxiosResponse, } from 'axios';
import { helpers, } from '../../../utils';
import CatalogTableRow from './CatalogTableRow/CatalogTableRow';
import { useNavigate, } from 'react-router-dom';
import { makeStyles, } from '@mui/styles';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: '10px',
  },
  table: {
    minWidth: 750,
  },
  dataSourceActionsCell: {
    minWidth:'154px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  fullHeight: {
    height: '100%',
  },
  icon: {
    maxWidth: '50px',
    maxHeight: '30px',
  },
});

interface CatalogTableProps {
  toggle: boolean;
  // eslint-disable-next-line no-unused-vars
  changeToggle: () => void;
  catalogs: Interfaces.InputCatalogMetadata[];
  loading: boolean;
  fetchCatalogs: () => void;
}

const CatalogTable = ({ changeToggle, toggle, catalogs, fetchCatalogs, }: CatalogTableProps) => {
  const classes = useStyles();
  const history = useNavigate();
  const { enqueueSnackbar, } = useSnackbar();
  const [runningCatalogs, setRunningCatalogs,] = React.useState<Array<string>>([]);
  const [filteredCatalogs, setFilteredCatalogs,] = React.useState<Array<Interfaces.InputCatalogMetadata>>(catalogs);
  const [rowsPerPage, setRowsPerPage,] = useLocalStorage('catalog_table.rows_per_page', 5);
  const { isAuthorized, } = useAuth();
  const [writeAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_WRITE'));
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_READ'));

  const [filters, setFilters,] = React.useState({
    checked: false,
    order: 'asc',
    orderBy: 'connectionName',
    page: 0,
  });

  const descendingComparator = (a: any, b: any, orderBy: any) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: any, orderBy: any) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (dataSources: any, comparator: any) => {
    const stabilizedThis = dataSources.map((el: any, index: number) => [
      el,
      index,
    ]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  };

  const handleSearch = (event: any) => {
    let filteredData = catalogs.filter((value) => value.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredCatalogs(filteredData);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = filters.orderBy === property && filters.order === 'asc';
    setFilters({
      ...filters,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const parseJsonFile= async (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      // @ts-ignore
      fileReader.onload = (event) => resolve(JSON.parse(event.target.result));
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsText(file);
    });
  };

  const uploadCatalog = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    try {
      // @ts-ignore
      const externalCatalog: Interfaces.InputCatalogMetadata = await parseJsonFile(event?.currentTarget?.files[0]);
      catalogsServices.addThirdPartyCatalog(externalCatalog).then(() => {
        enqueueSnackbar('Added successfully', { variant: 'success', });
        fetchCatalogs();
      }).catch((error: any) => {
        enqueueSnackbar(error.message || 'Unknown error', { variant: 'error', });
      });
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Unknown error', { variant: 'error', });
    }
  };

  const runCatalog = (catalogId: string) => {
    setRunningCatalogs([...runningCatalogs, catalogId,]);
    catalogsServices.runCatalog(catalogId)
      .then((response: AxiosResponse<Interfaces.Schema>) => {
        enqueueSnackbar(`Successfully ran catalog: '${response.data?.properties?.catalogName || catalogId}'`, { variant: 'info', });
        fetchCatalogs();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        const _idx = runningCatalogs.findIndex((cId: string) => cId !== catalogId);
        runningCatalogs.splice(_idx, 1);
        setRunningCatalogs(runningCatalogs);
      });
  };

  React.useEffect(() => {
    setFilteredCatalogs(catalogs);
  }, [catalogs,]);

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent='space-between' pb={2} >
        <Box display="flex" justifyContent='center'>
          {
            writeAccess && (
              <>
                <Button
                  style={{
                    marginRight: 10,
                    minWidth: 180,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history('/catalog/add');
                  }}
                >
                  Add catalog
                </Button>
              </>
            )
          }
          {
            writeAccess && (
              <>
                <Input
                  id="contained-button-file"
                  type="file"
                  inputProps={{ accept:'application/json', }}
                  onChange={async (event) => uploadCatalog(event)}
                  hidden
                  style={{ display: 'none', }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{
                      minWidth: 180,
                      height: '100%',
                    }}
                  >
                    Upload Catalog
                  </Button>
                </label>
              </>
            )
          }
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center">
          <Grid item>
              Table View
          </Grid>
          <Grid item>
            <Switch
              color='primary'
              checked={toggle}
              onChange={changeToggle}
              name="toggleSwitch" />
          </Grid>
          <Grid item>
              List View
          </Grid>
        </Box>
      </Box>
      <Paper hidden={!readAccess} className={classes.paper}>
        <CatalogTableToolbar handleSearch={handleSearch}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CatalogTableHead
              classes={classes}
              order={filters.order}
              orderBy={filters.orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {
                stableSort(
                  filteredCatalogs,
                  getComparator(filters.order, filters.orderBy)
                )
                  .slice(filters.page * rowsPerPage, filters.page * rowsPerPage + rowsPerPage)
                  .map((catalog: Interfaces.InputCatalogMetadata, index: number) => {
                    return (
                      <CatalogTableRow
                        key={catalog.id}
                        catalog={catalog}
                        index={index}
                        filters={filters}
                        rowsPerPage={rowsPerPage}
                        runningCatalogs={runningCatalogs}
                        runCatalog={runCatalog}
                        classes={classes}
                        fetchCatalogs={fetchCatalogs}
                      />
                    );
                  }
                  )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,]}
          component="div"
          count={filteredCatalogs.length}
          rowsPerPage={rowsPerPage}
          page={filters.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export { CatalogTable, };
