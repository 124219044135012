import {
  createStyles,
  makeStyles,
} from '@mui/styles';
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minWidth: 800,
      overflowX: 'auto',
    },
    changeHistoryLabel: {
      marginRight: 13,
      marginBottom: 30,
      writingMode: 'vertical-rl',
      transform: 'rotate(180deg)',
      position: 'absolute',
      height: 'inherit',
      textAlign: 'center',
      marginLeft: '10px',
      fontSize: 'large',
      fontWeight: 'bold',
    },
    changeHistoryLabelContainer: {
      maxWidth: 50,
      minHeight: 400,
      maxHeight: 'calc(-376px + 100vh)',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    changeHistoryContainer: {
      background: '#f5f5f5',
      minHeight: 400,
      minWidth: 'calc(-224px + 100vw)',
      maxHeight: 'calc(-430px + 100vh)',
      height: '60%',
      border: '2px solid #e1e0e0',
      overflow: 'hidden',
    },
    changeHistorySvg: {
      height: 'calc(-420px + 100vh)',
      maxHeight: 'calc(-420px + 100vh)',
      minHeight: 400,
      overflowX: 'hidden',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    totalRecordsGraphLabel: {
      marginRight: 13,
      writingMode: 'vertical-rl',
      transform: 'rotate(180deg)',
      height: 'inherit',
      textAlign: 'center',
      marginLeft: '10px',
      fontSize: 'large',
      fontWeight: 'bold',
    },
    totalRecordsGraphLabelContainer: {
      maxWidth: 50,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    totalRecordsGraphContainer: {
      background: '#eeeeee',
      display: 'flex',
      paddingTop: 10,
      height: 220,
      border: '2px solid #e1e0e0',
      borderRadius: 4,
      overflow: 'hidden',
    },
    totalRecordsGraphSvg: {
      maxHeight: '205px',
      maxWidth: 'calc(-224px + 100vw)',
      overflow: 'auto',
      marginTop: 5,
    },
    timeLineGraphLabelContainer: {
    },
    timeLineGraphContainer: {
      background: '#f5f8ff',
      height: 30,
      minWidth: 'calc(-224px + 100vw)',
      borderLeft: '2px solid #e1e0e0',
      borderRight: '2px solid #e1e0e0',
      overflow: 'hidden',
    },
    timeLineGraphSvg: {
      maxHeight: 30,
      maxWidth: 'calc(-224px + 100vw)',
      overflow: 'hidden',
      marginTop: 5,
    },
    noDataMessage: {
      paddingBottom: 20,
      color: '#a8a8a8',
      textAlign: 'center',
      marginBottom: '27vh',
    },
  })
);

export { useStyles, };
