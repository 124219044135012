import { Interfaces, } from '../../../config';

export const extractTablesFromSchema = (schema: Interfaces.Schema, tables: Array<Interfaces.Schema>) => {
  if (schema.fields) {
    schema.fields.map((field) => {
      if (field.sourceType === 'table') {
        tables.push(field);
      } else {
        return extractTablesFromSchema(field, tables);
      }
    });
  }
};
