const BASE_URL =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV === 'production'
    ? `${window.location.origin}`
    : `${window.location.protocol}//${window.location.hostname}:8080`;
const API_URL = `${BASE_URL}/api/`;
const NEW_API_URL = `${BASE_URL}/api/`;
const BUILD_FOR_CDAP = false;
const CDAP_NAV_PREFIX = BUILD_FOR_CDAP ? 'cdap/ns/:namespace/proedms' : '';
const obj = {
  BASE_URL,
  API_URL,
  NEW_API_URL,
  BUILD_FOR_CDAP,
  CDAP_NAV_PREFIX,
  API_STATUS_URL: `${API_URL}status`,
};
export default obj;
