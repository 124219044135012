import React, { useEffect, } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import {
  Description as DescriptionIcon,
  Search as SearchIcon,
  Storage as StorageIcon,
} from '@mui/icons-material';
import { helpers, } from '../../utils';
import { rosettaCards, } from './mockData';
import {
  useStyles,
  StyledButton,
  StyledCardContent,
  CardTitle,
  ButtonLabel,
} from './styles';
import { dataServices, } from '../../services';
import { useSnackbar, } from 'notistack';
import { CircularProgress, } from '@mui/material';
import { useAuth, } from '../../context';
import { RosettaIInfoSection, } from './RosettaIInfoSection';
import { useNavigate, } from 'react-router-dom';

const emptyStats = {
  activeScheduleCount : 0,
  catalogCount : 0,
  dataSourceCount : 0,
  tagCount : 0,
  collectionCount: 0,
  deploymentCount: 0,
};

function Dashboard() {
  const classes = useStyles();
  const {  health, } = useAuth();
  const history = useNavigate();
  const { enqueueSnackbar, } = useSnackbar();
  const [loading, isLoading,] = React.useState(false);
  const [stats, setSetStats,] = React.useState(emptyStats);

  const fetchStats = async () => {
    isLoading(true);
    return await dataServices.fetchDashboardStats()
      .then((response) => {
        setSetStats(response.data.data?.statistics);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        isLoading(false);
      });
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className={classes.dashboard}>
      <Grid
        container
        className={classes.root}
        spacing={2}
        justifyContent="center"
      >
        <Grid container item spacing={2} md={4} xs={12}>
          {loading ? (
            <Box className={classes.centerDiv}>
              <CircularProgress size={120} />
            </Box>
          ) : (
            <>
              { health.deploymentType === 'local' ? (
                <>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => history(`/data-sources`)}
                      style={{
                        justifyContent: 'flex-start',
                        padding: 0,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <StorageIcon
                          fontSize="medium"
                          style={{
                            color: 'white',
                          }}
                        />
                      </div>
                      <ButtonLabel>{stats.dataSourceCount} Data Sources</ButtonLabel>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => history(`/catalogs`)}
                      style={{ justifyContent: 'flex-start', }}
                    >
                      <div style={ { display:'flex', } }>
                        <SearchIcon
                          style={{
                            color: 'white',
                          }}
                          fontSize="medium"
                        />
                      </div>
                      <ButtonLabel>{stats.catalogCount} Catalogs</ButtonLabel>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => history(`/tags`)}
                      style={{ justifyContent: 'flex-start', }}
                    >
                      <div style={ { display:'flex', } }>
                        <DescriptionIcon
                          style={{
                            color: 'white',
                          }}
                          fontSize="medium"
                        />
                      </div>
                      <ButtonLabel>{stats.tagCount} Tags</ButtonLabel>
                    </StyledButton>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => history(`/data-sources`)}
                      style={{
                        justifyContent: 'flex-start',
                        padding: 0,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          background: 'red',
                        }}
                      >
                        <StorageIcon
                          fontSize="medium"
                          style={{
                            color: 'white',
                          }}
                        />
                      </div>
                      <ButtonLabel>{stats.dataSourceCount} Data Sources</ButtonLabel>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        history('/collections');
                      }}
                      style={{ justifyContent: 'flex-start', }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          background: 'red',
                        }}
                      >
                        <StorageIcon
                          fontSize="medium"
                          style={{
                            color: 'white',
                          }}
                        />
                      </div>
                      <ButtonLabel>{stats.collectionCount} Collections</ButtonLabel>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        history('/collections');
                      }}
                      style={{ justifyContent: 'flex-start', }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          background: 'red',
                        }}
                      >
                        <StorageIcon
                          fontSize="medium"
                          style={{
                            color: 'white',
                          }}
                        />
                      </div>
                      <ButtonLabel>{stats.deploymentCount} Deployments</ButtonLabel>
                    </StyledButton>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
        <RosettaIInfoSection />
      </Grid>
      <Grid container justifyContent="center" spacing={6}>
        {rosettaCards.map(({ title, Logo, description, uri, }: any) => (
          <Grid item key={title} xs={12} md={4}>
            <Card
              style={{
                minHeight: '290px',
                maxHeight: '290px',
                cursor: 'pointer',
              }}
              onClick={() => uri && window.open(uri, '_blank', 'noopener,noreferrer')}
            >
              <StyledCardContent>
                <Logo />
                <CardTitle
                  variant="h6"
                >
                  {title}
                </CardTitle>
              </StyledCardContent>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  align="center"
                >
                  {description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Dashboard;
