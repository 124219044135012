import React, { useState, } from 'react';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TextField,
  Typography,
} from '@mui/material';
import * as interfaces from '../../config/interfaces';
import { Add as AddIcon, } from '@mui/icons-material';
import OneLabel from './OneLabel/OneLabel';
import { Interfaces, } from '../../config';

export const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      paddingRight: 5,
      width: 200,
      marginTop: 8,
    },
    paddingBottom: {
      paddingBottom: '2%',
    },
    labelsWrapper: {
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    labelWrapper: {
      marginRight: 10,
      display: 'inline',
    },
    addBtn: {
      minHeight: 39,
      marginTop: 8,
    },
    renderedDiv: {
      border: '1px solid lightGray',
      padding: 1,
      paddingInline: 6,
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      marginBottom: 8,
      marginRight: 5,
    },
    font: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#666666',
    },
    table: {
      width: 300,
      maxWidth: '100%',
      borderRadius: 5,
      borderCollapse: 'separate',
      paddingBottom: 5,
    },
    tableRow: {
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    tableCell: {
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: 16,
      borderBottom: 'none',
    },
    deleteCell: {
      width: 48,
      padding: 0,
      margin: 0,
      borderBottom: 'none',
    },
  })
);

export default function LabelComponent(props: any) {
  const classes = useStyles();
  const [state, setState,] = useState<interfaces.CMLabels>({
    key: '',
    value: '',
  });

  const labelChangeHandler = (event: any) => {
    let key = event.target.name;
    let value = event.target.value;
    setState({
      ...state,
      [key]: value,
    });
  };
  const {
    onAddLabel,
    onRemoveLabel,
    metadata,
    readOnly,
  } = props;

  const addLabel = () => {
    if (
      !metadata.labels.some(
        (item: interfaces.CMLabels) =>
          item.key === state.key && item.value === state.value
      )
    ) {
      // dispatch(addLabel(state));
      if (onAddLabel) {
        onAddLabel(state);
      }
      setState({
        key: '',
        value: '',
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.labelWrapper} variant="subtitle1">
          Labels:
        </Typography>
      </Grid>
      {!readOnly && (
        <>
          <Grid container>
            <Grid item>
              <TextField
                className={classes.textField}
                disabled={readOnly}
                name="key"
                label="Key"
                variant="outlined"
                size="small"
                value={state.key}
                onChange={(event) => labelChangeHandler(event)}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.textField}
                disabled={readOnly}
                name="value"
                label="Value"
                variant="outlined"
                size="small"
                value={state.value}
                onChange={(event) => labelChangeHandler(event)}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={state.key === '' || state.value === '' || readOnly}
                onClick={addLabel}
                className={classes.addBtn}
              >
                <AddIcon fontSize={'small'} />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {metadata.labels.length > 0 && (
        <Grid item xs={12} className={classes.labelsWrapper}>
          <Table size="small" className={classes.table}>
            <div style={{
              width: '100%',
              height: '5px',
            }}/>
            <TableBody>
              {metadata.labels.map((label: Interfaces.CMLabels) => (
                <OneLabel
                  key={label.key}
                  label={label}
                  onRemoveLabel={onRemoveLabel}
                  readOnly={readOnly}
                />
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </Grid>
  );
}
