import React, {
  useEffect,
  useState,
} from 'react';
import Styles from './style';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  CircularProgress,
  Grid,
  Tooltip,
} from '@mui/material';
import {
  InfoOutlined,
  Save,
} from '@mui/icons-material';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faFileAlt, } from '@fortawesome/free-solid-svg-icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Interfaces, } from '../../../config';
import { useDSCalls, } from '../../../hooks';
import LoadingComponent from '../../Loading';
import {
  StyledButton,
  StyledTextField,
} from '../../Reusable';

type Params = {
  oldName: string;
};

interface Props {
  schemaTypes: Array<string>;
}

function File(props: Props) {
  const classes = Styles();
  const { oldName, } = useParams<Params>();
  const location = useLocation();
  const navigate = useNavigate();

  const inEditMode = location.pathname.includes('edit');
  const { schemaTypes, } = props;
  const [state, setState,] = useState<Interfaces.BaseDataSource>({
    id: '',
    name: '',
    type: 'FILE',
    uri: '',
    schemaType: schemaTypes[0],
  });
  const {
    dataSources,
    loading: loadingDS,
    error: errorDS,
    editDataSource,
    addDataSource,
    testDataSource,
    dataSourceToEdit: dataSourceToEditHook,
    loadingEdit,
    errorEdit,
    loadingTest,
    errorTest,
    testNewDataSource,
    testExistingDataSource,
    setDataSourceToEdit,
    setTestDataSource,
  } = useDSCalls();

  const dataSourceToEdit = dataSourceToEditHook as Interfaces.BaseDataSource | null;

  useEffect(() => {
    setTestDataSource(null);
    if (inEditMode) {
      if (dataSourceToEdit) {
        setState({
          ...state,
          id: dataSourceToEdit?.id,
          name: dataSourceToEdit?.name,
          type: dataSourceToEdit?.type,
          uri: dataSourceToEdit?.uri,
          schemaType: dataSourceToEdit?.schemaType,
        });
      } else {
        if (!dataSources) {return;}
        const dataSourceToChange = dataSources.find((item) => item.name === oldName) as Interfaces.BaseDataSource;
        setDataSourceToEdit(dataSourceToChange);
      }
    }
  }, [dataSourceToEdit, dataSources,]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const postSubmitAction = () => {navigate('/data-sources');};

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (inEditMode && oldName) {
      editDataSource( state, oldName, postSubmitAction);
    } else {
      addDataSource(state, postSubmitAction);
    }
  };

  const handleTestSuccess = () => {
    if (loadingTest) {
      return <CircularProgress
        color="inherit"
        style={{
          width: 11,
          height: 11,
        }}
      />;
    }
    if (errorTest) {
      return <FiberManualRecordIcon style={{ fill: 'red', }} />;
    }
    if (testDataSource && !errorTest) {
      return <FiberManualRecordIcon style={{ fill: 'green', }} />;
    } else if (!testDataSource && !errorDS) {
      return <FiberManualRecordIcon style={{ fill: 'grey', }} />;
    }
  };

  const onTestConnection = () => {
    inEditMode ? testExistingDataSource(state) : testNewDataSource(state);
  };

  if (loadingEdit) {
    return <LoadingComponent message={'Loading data source'}/>;
  }

  if (errorEdit) {
    return (
      <p>{errorEdit}</p>
    );
  }

  if (inEditMode && !dataSourceToEdit && !loadingDS) {
    return (
      <p>No file source was selected for edit mode</p>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center'>
        <FontAwesomeIcon
          icon={faFileAlt}
          color="grey"
          size="9x"
          className={classes.img}
        />
      </Grid>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={8}>
          <form name="addFileForm" onSubmit={handleSubmit}>
            <StyledTextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={state.name}
              fullWidth
              size="small"
              onChange={handleChange}
            />
            <StyledTextField
              id="schemaType"
              name="schemaType"
              label="Type"
              variant="outlined"
              value={state.schemaType}
              fullWidth
              size="small"
              onChange={handleChange}
              select
              SelectProps={{
                native: true,
              }}
              defaultValue='CSV'
            >
              {schemaTypes.map((option: string) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </StyledTextField>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <StyledTextField
                id="uri"
                name="uri"
                label="Uri"
                variant="outlined"
                value={state.uri}
                style={{
                  marginRight: 6,
                }}
                fullWidth
                size="small"
                onChange={handleChange}
              />
              <Tooltip title="Uri must start with 'file:///'" placement="top">
                <InfoOutlined fontSize="small" />
              </Tooltip>
              <StyledButton
                variant="contained"
                color="primary"
                style={{
                  width: 120,
                  marginRight: 0,
                  marginLeft: 14,
                }}
                startIcon={handleTestSuccess()}
                fullWidth
                onClick={() => {
                  onTestConnection();
                }}
              >
                Test
              </StyledButton>
            </div>
            <StyledButton
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              style={{ marginLeft: 0, }}
              startIcon={loadingDS ?
                <CircularProgress
                  color="inherit"
                  style={{
                    width: 16,
                    height: 11,
                  }} />
                :
                <Save />}
            >
              Save
            </StyledButton>
            <StyledButton
              variant="outlined"
              size="large"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </StyledButton>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

export default File;
