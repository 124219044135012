import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeView, } from '@mui/lab';
import { makeStyles, } from '@mui/styles';
import { Interfaces, } from '../../../config';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { StyledTreeItem, } from './StyledTreeItem';

const useStyles = makeStyles({
  treeNew: {
    height: 264,
    flexGrow: 1,
  },
  info: {
    minWidth: 150,
  },
});

interface TreeProps {
  expandedElements: Array<string>;
  onTreeItemClick: any;
  directories: Array<Interfaces.Directory>;
  isLoading: boolean;
}

const DirectoryTree = (props: TreeProps) => {
  const classes = useStyles();
  const {
    expandedElements,
    onTreeItemClick,
    directories,
    isLoading,
  } = props;

  const renderTree = (directory: Interfaces.Directory) => {
    const labelText = directory.name ? directory.name : directory.path.substring(directory.path.lastIndexOf('/') + 1);
    const date = new Date(directory.modificationTime);
    const icon = directory.isDirectory
      ? FolderIcon
      : DescriptionRoundedIcon;
    return (
      <StyledTreeItem
        nodeId={directory.path}
        labelText={labelText}
        labelIcon={icon}
        size={directory.length}
        time={date.toLocaleDateString()}
        onClick={() => onTreeItemClick(directory)}
      >
        {directory.isDirectory && (
          Array.isArray(directory.directories)
            ? directory.directories.map((sub: Interfaces.Directory) => renderTree(sub))
            : renderTree({
              ...directory,
              isDirectory: false,
              path: isLoading ? '/loading...' : '/empty',
            })
        )}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      className={classes.treeNew}
      expanded={expandedElements}
      defaultExpanded={expandedElements}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24, }} />}
    >
      {
        directories && directories.length > 0 && (
          directories.map((directory: Interfaces.Directory) => renderTree(directory))
        )
      }
    </TreeView>
  );
};

export { DirectoryTree, };
