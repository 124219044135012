import React from 'react';
import { adminServices, } from '../../../services';
import { AxiosResponse, } from 'axios';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../../utils';
import { Interfaces, } from '../../../config';
import { StatsCard, } from './StatsCard';
const AdminDashboard = () => {
  const { enqueueSnackbar, } = useSnackbar();
  const [stats, setStats,] = React.useState<Interfaces.IUserStats>();
  const [loading, setLoading,] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    adminServices.fetchStats()
      .then((response: AxiosResponse<{ data: Interfaces.IUserStats }>) => {
        setStats(response.data.data);
      })
      .catch((error) => enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div style={{ display: 'flex', }}>
      <StatsCard
        isLoading={loading}
        label={'Total users'}
        value={stats?.total ?? 0}
      />
      <StatsCard
        isLoading={loading}
        label={'Active users'}
        value={stats?.totalActive ?? 0}
      />
      <StatsCard
        isLoading={loading}
        label={'Total last week'}
        value={stats?.last7DaysTotal ?? 0}
      />
      <StatsCard
        isLoading={loading}
        label={'Today total'}
        value={stats?.todayTotal ?? 0}
      />
      <StatsCard
        isLoading={loading}
        label={'Yesterday total'}
        value={stats?.yesterdayTotal ?? 0}
      />
    </div>
  );
};

export { AdminDashboard, };
