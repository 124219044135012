import { axiosClient, } from '../config/helpers';
import {
  Constants,
  Interfaces,
} from '../config';

export const alertsServices = {
  fetchAlerts,
  fetchAlertById,
  addAlert,
  updateAlert,
  deleteAlert,
  fetchAlertHistories,
  fetchAlertHistoryById,
  fetchAlertHistoriesByCatalogId,
  fetchAlertHistoriesByAlertId,
  deleteAlertHistory,
  activateAlert,
  deactivateAlert,
};

function fetchAlerts() {
  return axiosClient().get(Constants.api.endpoints.alerts);
}

function fetchAlertById(alertId: string) {
  return axiosClient().get(`${Constants.api.endpoints.alerts}${alertId}`);
}

function activateAlert(alertId: string | undefined) {
  return axiosClient().get(`${Constants.api.endpoints.alerts}${alertId}/activate`);
}

function deactivateAlert(alertId: string | undefined) {
  return axiosClient().get(`${Constants.api.endpoints.alerts}${alertId}/deactivate`);
}

function addAlert(body: Interfaces.Alert) {
  return axiosClient().post(Constants.api.endpoints.alerts, body);
}

function updateAlert(body: Interfaces.Alert, alertId: string) {
  return axiosClient().patch(`${Constants.api.endpoints.alerts}${alertId}`, body);
}

function deleteAlert(alertId: string) {
  return axiosClient().delete(`${Constants.api.endpoints.alerts}${alertId}`);
}

function fetchAlertHistories(limit: number = 10, offset: number = 0) {
  return axiosClient().get(`${Constants.api.endpoints.alertHistories}?limit=${limit}&offset=${limit * offset}`);
}

function fetchAlertHistoryById(historyId: string) {
  return axiosClient().get(`${Constants.api.endpoints.alertHistories}${historyId}`);
}

function fetchAlertHistoriesByAlertId(alertId: string) {
  return axiosClient().get(`${Constants.api.endpoints.alertHistory}${alertId}/alert`);
}

function fetchAlertHistoriesByCatalogId(catalogId: string) {
  return axiosClient().get(`${Constants.api.endpoints.alertHistory}${catalogId}/catalog`);
}

function deleteAlertHistory(historyId: string) {
  return axiosClient().delete(`${Constants.api.endpoints.alertHistories}${historyId}`);
}
