import React from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import {
  TableChart,
  ViewColumn,
} from '@mui/icons-material';
import OverflowTip from '../../Models/SchemaTree/OverflowTip';
import { useTheme, } from '@mui/styles';

const useStyles = (theme: any) => ({
  schemaItem: {
    padding: 1,
    display: 'flex',
  },
  schemaItemText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    width: 210,
  },
  schemaItemIcon: {
    float: 'right',
    color: theme.palette.primary.main,
    marginRight: 4,
    fontSize: 24,
    '&:hover': {
      color: '#83abe7',
    },
  },
  tableItem: {
    display: 'flex',
    alignItems: 'center',
  },
  tableItemText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    marginLeft: 5,
    width: 160,
  },
  tableIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  tableDeleteIcon: {
    float: 'right',
    color: 'rgba(0,0,0,0.30)',
    fontSize: 20,
    marginLeft: 12,
    '&:hover': {
      color: 'rgba(0,0,0,0.56)',
    },
  },
  columnItem: {
    display: 'flex',
    alignItems: 'center',
  },
  columnItemText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    width: 175,
  },
  columnIcon: {
    fontSize: 20,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
});


const SchemaTreeItem = ({ label, }: {label: string,} ) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div style={classes.schemaItem}>
      <Typography variant={'caption'} style={classes.schemaItemText}>
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
    </div>
  );
};

const TableTreeItem = ({ label, }: {label: string} ) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Grid container style={classes.tableItem}>
      <TableChart style={classes.tableIcon} />
      <Typography style={classes.tableItemText} variant={'caption'}>
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
    </Grid>
  );
};

const ColumnTreeItem = ({ label, }: {label: string} ) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Grid container style={classes.tableItem} >
      <ViewColumn style={classes.tableIcon} />
      <Typography style={classes.tableItemText} variant={'caption'}>
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
    </Grid>
  );
};

export const TreeItems = {
  Schema: SchemaTreeItem,
  Table: TableTreeItem,
  Column: ColumnTreeItem,
};
