import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => (
  {
    paper: {
      height: '80vh',
      maxHeight: '95%',
      width: '100%',
      overflowY: 'scroll',
      marginTop: '10px',
    },
    table: {
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      '& thead th': {
        position: 'sticky',
        zIndex: 600,
        fallbacks: {
          position: '-webkit-sticky',
        },
      },
    },
    stickyTable: {
      position: 'sticky',
      zIndex: 500,
      overflow: 'visible',
      background: theme.palette.background.paper,
      fallbacks: {
        position: '-webkit-sticky',
      },
    },
    rotateIcon: {
      animation: '$spin 2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
    tableRow: {
      '&:hover': {
        backgroundColor: '#e8e8e8',
        cursor: 'pointer',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);
