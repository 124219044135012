import React from 'react';
import {
  CircularProgress,
  Paper,
  Typography,
} from '@mui/material';

interface Props {
  isLoading: boolean;
  label: string;
  value: number;
}

const StatsCard = ({
  isLoading,
  label,
  value,
}: Props) => {
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: 140,
        marginBottom: 20,
        padding: '25px 30px',
        marginRight: 10,
      }}
    >
      <Typography>
        {label}
      </Typography>
      {
        isLoading ? (
          <CircularProgress />
        ) : (
          <Typography variant={'h3'} style={{ marginTop: 'auto', }}>
            {value}
          </Typography>
        )
      }
    </Paper>
  );
};

export { StatsCard, };
