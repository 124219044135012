import {
  Constants,
  Interfaces,
} from '../config';
import { axiosClient, } from '../config/helpers';

export const passwordServices = {
  forgotPassword,
  resetPassword,
};

function forgotPassword(body: Interfaces.ForgotPassword) {
  return axiosClient().post(Constants.api.endpoints.requestReset, body);
}

function resetPassword(body: Interfaces.ResetPassword) {
  return axiosClient().post(Constants.api.endpoints.resetPassword, body);
}
