import mysql from '../DataSources/CardView/logo/mysql.png';
import postgres from '../DataSources/CardView/logo/postgresql.png';
import mssql from '../DataSources/CardView/logo/mssql.png';
import kinetica from '../DataSources/CardView/logo/kinetica.png';
import db2 from '../DataSources/CardView/logo/db2.png';
import oracle from '../DataSources/CardView/logo/oracle.png';
import snowflake from '../DataSources/CardView/logo/snowflake.png';
import bigquery from '../DataSources/CardView/logo/bigquery.png';
import googleBucket from '../DataSources/CardView/logo/googlecloud.png';
import file from '../DataSources/CardView/logo/file.png';
import s3 from '../DataSources/CardView/logo/s3.png';
import ftp from '../DataSources/CardView/logo/ftp.png';
import hdfs from '../DataSources/CardView/logo/hdfs.png';
import azure from '../DataSources/CardView/logo/azure.png';
import jdbc from '../DataSources/CardView/logo/jdbc.png';
import React from 'react';

interface DataSourceLogoProps {
    name:'mysql'|'postgres'|'kinetica'|'db2'|'oracle'|'snowflake'|'bigquery'|'jdbc'|'sqlserver';
    type:'GCS'|'S3'|'FTP'|'HDFS'|'AZURE_BLOB'|'FILE'|'jdbc';
    sizeWidth?:string;
    sizeHeight?:string;
    margin:string;
    maxWidth?: string;
    maxHeight?: string;
}

export const getDsIconUri = (name: string, type: string) => {
  switch (name) {
  case 'mysql':
    return mysql;
  case 'postgres':
    return postgres;
  case 'sqlserver':
    return mssql;
  case 'kinetica':
    return kinetica;
  case 'db2':
    return db2;
  case 'oracle':
    return oracle;
  case 'snowflake':
    return snowflake;
  case 'bigquery':
    return bigquery;
  default:
    switch (type) {
    case 'GCS':
      return googleBucket;
    case 'FILE':
      return file;
    case 'S3':
      return s3;
    case 'FTP':
      return ftp;
    case 'HDFS':
      return hdfs;
    case 'AZURE_BLOB':
      return azure;
    default:
      return jdbc;
    }
  }
};

const DataSourceLogo = ({ name, type, sizeWidth, sizeHeight, margin, maxWidth, maxHeight, }: DataSourceLogoProps) => {

  return (
    <img
      src={getDsIconUri(name, type)}
      alt={`${name} logo`}
      height={sizeHeight}
      width={sizeWidth}
      style={{
        margin: margin,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
      }}
    />
  );
};

export { DataSourceLogo, };
