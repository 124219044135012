import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { Interfaces, } from '../../../config';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import {
  LocalOffer,
  Label,
} from '@mui/icons-material';
import { Icon, } from '../../Icon';
import { DataSourceLogo, } from '../../DatasourceIcon';
import { formatDate, } from '../../../config/helpers';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      // width: '70%',
      marginBottom: '20px',
      minWidth: 800,
    },
    root1: {
      display: 'flex',
      width: '60%',
      marginLeft: '200px',
      marginBottom: '60px',
    },
    card: {
      backgroundImage: 'linear-gradient(to right top, #ffffff, #ffffff, #eeeeee, #EBEBEB, #d4d4d4)',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    cover: {
      backgroundSize: 'contain',
      width: 130,
      margin: '0 20px',
    },
    title: {
      borderBottom: '1px solid #ccc',
      paddingBottom: '5px',
      marginBottom: '15px',
    },
    content: {

    },
    playIcon: {
      height: 38,
      width: 38,
    },
  })
);



const CatalogCard = ({
  catalog,
  handleSelect,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  // eslint-disable-next-line no-unused-vars
  handleSelect: (catalog: Interfaces.InputCatalogMetadata) => void;
}) => {
  const classes = useStyles();

  const countTags = (schema: Interfaces.Schema, tagsCount = 0 ) => {
    schema?.fields.map((field) => {
      tagsCount += field.tags.length;
      if (field.fields != null) {
        tagsCount += countTags(field, 0);
      }
    });
    return tagsCount;
  };

  const countLabels = (schema: Interfaces.Schema, labelsCount = 0 ) => {
    schema?.fields.map((field) => {
      labelsCount += field.labels.length;
      if (field.fields != null) {
        labelsCount += countLabels(field, 0);
      }
    });
    return labelsCount;
  };

  const countTables = (schema: Interfaces.Schema) => {
    if (schema.fields[0]?.type === 'table') {
      return schema.fields?.length;
    } else {
      let total = 0;
      schema.fields?.map((field) => {
        total += field.fields?.length;
      });
      return total;
    }
  };

  return (
    <Tooltip title={catalog.lastRunId ? '' : 'Catalog is not run yet'} placement={'right'}>
      <Card
        className={classes.root}
        style={{ cursor: catalog.lastRunId ? 'pointer' : 'not-allowed', }}
        onClick={() => handleSelect(catalog)}
      >
        <DataSourceLogo
          name={catalog.dataSourceInfo?.driverName as any}
          type={catalog.dataSourceInfo?.type as any}
          sizeWidth={'130'}
          sizeHeight={'130'}
          margin={'20px'}
        />
        <div className={classes.details}>
          <CardContent>
            <Typography component="h6" variant="h5" className={classes.title}>
              {catalog.name}
            </Typography>
            <Grid container className={classes.content}>
              <Grid item xs={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Type:
                  <Typography variant="subtitle2" color="textPrimary">
                    {catalog.dataSourceInfo?.type}
                  </Typography>
                </Typography>
                <Box display='flex' justifyContent='flex-start' alignItems='center' marginTop={1}>
                  {
                    catalog.schema.fields[0]?.sourceType === 'schema' && (
                      <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={1}>
                        <Typography variant="body2" color="textSecondary" style={{ marginRight: 2, }}>
                          {catalog.schema.fields?.length}
                        </Typography>
                        <Icon type={'schema'} color="#29913A"/>
                      </Box>
                    )
                  }
                  <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={1}>
                    <Typography variant="body2" color="textSecondary" style={{ marginRight: 2, }}>
                      {countTables(catalog.schema)}
                    </Typography>
                    <Icon type={'table'} color="#D67E0A"/>
                  </Box>
                  <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={1}>
                    <Typography variant="body2" color="textSecondary">
                      {countTags(catalog.schema)}
                    </Typography>
                    <LocalOffer
                      fontSize='small'
                      color='primary'
                      style={{
                        fontSize: 15,
                        marginBottom: -2,
                      }}
                    />
                  </Box>
                  <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={1}>
                    <Typography variant="body2" color="textSecondary">
                      {countLabels(catalog.schema)}
                    </Typography>
                    <Label fontSize='small' color='primary' style={{ fontSize: 18, }}/>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Data Source Name:
                  <Typography variant="subtitle2" color="textPrimary">
                    {catalog.dataSourceName}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Last Run:
                  <Typography variant="subtitle2" color="textPrimary">
                    {formatDate(catalog.lastRun)}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <>
                  {catalog.schedule?.nextRun && (
                    <Typography variant="subtitle2" color="textSecondary">
                      Next Run:
                      <Typography variant="subtitle2" color="textPrimary">
                        {formatDate(catalog.schedule?.nextRun)}
                      </Typography>
                    </Typography>
                  )}
                </>
              </Grid>
            </Grid>
          </CardContent>
        </div>
      </Card>
    </Tooltip>
  );
};

export { CatalogCard, };
