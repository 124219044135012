import { axiosClient, } from '../config/helpers';
import {
  Constants,
  Interfaces,
} from '../config';

export const dataServices = {
  fetchActiveSchema,
  fetchSampleData,
  fetchDashboardStats,
};

function fetchActiveSchema(dataSourceName: string) {
  return axiosClient().get(`${Constants.api.endpoints.dataSources}${dataSourceName}/schema`);
}

function fetchSampleData(dataSourceName: string, body: Interfaces.Schema) {
  return axiosClient().post(`${Constants.api.endpoints.data}${dataSourceName}/sample-data`, body);
}

function fetchDashboardStats() {
  return axiosClient().get(`${Constants.api.endpoints.dashboardStats}`);
}
