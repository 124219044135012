import React, { useState, } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { Interfaces, } from '../../../config';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import {
  LocalOffer,
  MoreVert,
  PlayArrow,
  Label,
  Edit,
  Delete,
  Description,
} from '@mui/icons-material';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { useAuth, } from '../../../context';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation';
import { Icon, } from '../../Icon';
import { DataSourceLogo, } from '../../DatasourceIcon';
import { formatDate, } from '../../../config/helpers';



const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      // width: '70%',
      marginBottom: '20px',
      minWidth: 800,
    },
    root1: {
      display: 'flex',
      width: '60%',
      marginLeft: '200px',
      marginBottom: '60px',
    },
    card: {
      backgroundImage: 'linear-gradient(to right top, #ffffff, #ffffff, #eeeeee, #EBEBEB, #d4d4d4)',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    DataSourceLogo: {
      backgroundSize: 'contain',
      margin: '20px',
    },
    title: {
      borderBottom: '1px solid #ccc',
      paddingBottom: '5px',
      marginBottom: '15px',
    },
    content: {

    },
    playIcon: {
      height: 38,
      width: 38,
    },
  })
);



const CatalogCard = ({
  catalog,
  loadingCatalogs,
  runCatalog,
  deleteCatalog,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  loadingCatalogs: Array<string>;
  // eslint-disable-next-line no-unused-vars
  runCatalog: (catalogId: string) => void;
  // eslint-disable-next-line no-unused-vars
  deleteCatalog: (catalogId: string) => void;
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl,] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const [deletePopupState, setDeletePopupState,] = useState(false);
  const [catalogToDelete, setCatalogToDelete,] = useState<any>(null);
  const { isAuthorized, } = useAuth();
  const [writeAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_WRITE'));
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_READ'));
  const [readDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_READ'));
  const [adminAccess,] = React.useState(isAuthorized('ADAPTIVE_ADMIN'));
  const [executeAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_EXECUTE'));
  const [executeDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_EXECUTE'));


  const editCatalog = (catalogId: string) => {
    navigate(`catalog/edit/${catalogId}`);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeletePopup = (catalogId: any) => {
    setDeletePopupState(!deletePopupState);
    setCatalogToDelete(catalogId);
  };

  const deleteAction = () => {
    handleDeletePopup(null);
    deleteCatalog(catalogToDelete);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const countTags = (schema: Interfaces.Schema, tagsCount = 0 ) => {
    schema?.fields.map((field) => {
      tagsCount += field.tags.length;
      if (field.fields != null) {
        tagsCount += countTags(field, 0);
      }
    });
    return tagsCount;
  };

  const countLabels = (schema: Interfaces.Schema, labelsCount = 0 ) => {
    schema?.fields.map((field) => {
      labelsCount += field.labels.length;
      if (field.fields != null) {
        labelsCount += countLabels(field, 0);
      }
    });
    return labelsCount;
  };

  const countTables = (schema: Interfaces.Schema) => {
    if (schema.fields[0]?.type === 'table') {
      return schema.fields?.length;
    } else {
      let total = 0;
      schema.fields?.map((field) => {
        total += field.fields?.length;
      });
      return total;
    }
  };

  return (
    <>
      <Card className={classes.root} >
        <DataSourceLogo
          name={catalog.dataSourceInfo?.driverName as any}
          type={catalog.dataSourceInfo?.type as any}
          sizeWidth={'130'}
          sizeHeight={'130'}
          margin={'20px'}
        />
        <div className={classes.details}>
          <CardContent>
            <Link to={`/catalog/view/${catalog.id}${catalog.dataSourceDescription === 'external' ? '/external' : ''}`}>
              <Typography component="h6" variant="h5" className={classes.title}>
                {catalog.name}
              </Typography>
            </Link>
            <Grid container className={classes.content}>
              <Grid item xs={2}>
                <Typography variant="subtitle2" color="textSecondary">
                Type:
                  <Typography variant="subtitle2" color="textPrimary">
                    {catalog.dataSourceInfo?.type}
                  </Typography>
                </Typography>
                <Box display='flex' justifyContent='flex-start' alignItems='center' marginTop={1}>
                  {
                    catalog.schema.fields[0]?.sourceType === 'schema' && (
                      <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={1}>
                        <Typography variant="body2" color="textSecondary" style={{ marginRight: 2, }}>
                          {catalog.schema.fields?.length}
                        </Typography>
                        <Icon type={'schema'} color="#29913A"/>
                      </Box>
                    )
                  }
                  <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={1}>
                    <Typography variant="body2" color="textSecondary" style={{ marginRight: 2, }}>
                      {countTables(catalog.schema)}
                    </Typography>
                    <Icon type={'table'} color="#D67E0A"/>
                  </Box>
                  <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={1}>
                    <Typography variant="body2" color="textSecondary">
                      {countTags(catalog.schema)}
                    </Typography>
                    <LocalOffer
                      fontSize='small'
                      color='primary'
                      style={{
                        fontSize: 15,
                        marginBottom: -2,
                      }}
                    />
                  </Box>
                  <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={1}>
                    <Typography variant="body2" color="textSecondary">
                      {countLabels(catalog.schema)}
                    </Typography>
                    <Label fontSize='small' color='primary' style={{ fontSize: 18, }}/>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2" color="textSecondary">
                Data Source Name:
                  <Typography variant="subtitle2" color="textPrimary">
                    {catalog.dataSourceName}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Last Run:
                  <Typography variant="subtitle2" color="textPrimary">
                    {formatDate(catalog.lastRun)}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <>
                  {catalog.schedule?.nextRun && (
                    <Typography variant="subtitle2" color="textSecondary">
                                        Next Run:
                      <Typography variant="subtitle2" color="textPrimary">
                        {formatDate(catalog.schedule.nextRun)}
                      </Typography>
                    </Typography>
                  )}
                </>
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" justifyContent='flex-end' alignItems='center' height='100%'>
                  <Box display="flex" justifyContent='cneter' alignItems='center'>
                    {catalog.dataSourceDescription !== 'external' &&
                (
                  executeAccess && (
                    <>
                      <Tooltip title='Run the Catalog'>

                        <IconButton aria-label="run"
                          size='small'
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={() => catalog.id && runCatalog(catalog.id)}
                        >
                          {catalog.id && loadingCatalogs.includes(catalog.id) && <CircularProgress
                            color="inherit"
                            size={24}
                          />}
                          {catalog.id && !loadingCatalogs.includes(catalog.id) && <PlayArrow
                            style={{
                              color: 'green',
                            }}/>}
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                )
                    }
                  </Box>
                  <Box display="flex" justifyContent='center' alignItems='center'>
                    {catalog.dataSourceDescription !== 'external' && <>
                      <IconButton aria-label="more"
                        onClick={(event)=>handleClick(event)}
                      >
                        <MoreVert fontSize='small'/>
                      </IconButton>
                      <Menu
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={()=>handleClose()}
                      >
                        {
                          readAccess && (
                            <>
                              <MenuItem onClick={() => {
                                handleClose;
                                window.open(`datalineage/catalog/${catalog.id}/index.html`, '_blank', 'noreferrer');
                              }}
                              color='action'
                              >
                                <Description fontSize='small' style={{ marginRight: '10px', }} color='action' />Docs
                              </MenuItem>
                            </>
                          )
                        }
                        {
                          (readAccess && readDSAccess && writeAccess && executeDSAccess) && (
                            <>
                              <MenuItem onClick={() => {
                                handleClose();
                                if (catalog?.id != null) {
                                  editCatalog(catalog?.id);
                                }
                              }}
                              color='action'
                              >
                                <Edit fontSize='small' style={{ marginRight: '10px', }} color='action'/> Edit
                              </MenuItem>
                            </>
                          )
                        }
                        {
                          adminAccess && (
                            <>
                              <MenuItem onClick={() => {
                                handleClose;
                                if (catalog?.id != null) {
                                  handleDeletePopup(catalog.id);
                                }
                              }}
                              color='action'
                              >
                                <Delete fontSize='small' style={{ marginRight: '10px', }} color='action'/>Delete
                              </MenuItem>
                            </>
                          )
                        }
                      </Menu>
                    </>}
                  </Box>
                </Box>
              </Grid>
            </Grid>


          </CardContent>
        </div>
      </Card>
      <ModalConfirmation
        open={deletePopupState}
        onClose={() => handleDeletePopup(null)}
        title={'Are you sure you want to delete the Catalog?'}
        onBtnClick={() => deleteAction()}
        colorBtn={'secondary'}
        btnText={'Delete'}
        onCancelBtnClick={() => handleDeletePopup(null)} />
    </>
  );
};

export { CatalogCard, };
