import React from 'react';
import { useStyles, } from './styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from '@mui/material';
import { Interfaces, } from '../../../config';

const StyledTableCell = styled(TableCell)(({ theme, }) => ({
  maxWidth: '350px',
  maxHeight: '50px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  [`&.th`]:
    {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  [`&.tbody`]:
    {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme, }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const NodeMetadataFields = ({ selectedNode, }: { selectedNode: Interfaces.Schema; }) => {
  const classes = useStyles();

  return selectedNode?.fields?.length > 0 ? (
    <Grid item xs={12}>
      <p className={classes.sectionTitle} style={{ marginTop: '20px', }}>
        Fields
      </p>
      <TableContainer
        style={{
          width: '100%',
          overflowX: 'auto',
          border: '1px solid #e0e0e0',
        }}
        component={Paper}
      >
        <Table stickyHeader size="small" aria-label="customized table">
          <TableHead key="table_head_1">
            <TableRow key="header-1">
              <StyledTableCell style={{ fontWeight: 'bold', }} align="left" width={60} key={0}>#</StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', }} align="left">Name</StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', }} align="left">Source Type</StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', }} align="left">Type</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (selectedNode.sourceType === 'table' || selectedNode.sourceType === 'schema' || selectedNode.sourceType === 'view') &&
              selectedNode.fields.map((item: any, index: number) => {
                return (
                  <StyledTableRow
                    key={index}
                    style={{ cursor: 'pointer', }}
                  >
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell align="left">{item.name}</StyledTableCell>
                    <StyledTableCell align="left">{item.sourceType}</StyledTableCell>
                    <StyledTableCell align="left">{item.type}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  ) : null;
};

export { NodeMetadataFields, };
