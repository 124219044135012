import React, { useState, } from 'react';
import { Interfaces, } from '../../../config';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Cancel, } from '@mui/icons-material';
import { useStyles, } from '../LabelComponent';

interface OneLabelProps {
  label: Interfaces.CMLabels;
  readOnly?: boolean;
  // eslint-disable-next-line no-unused-vars
  onRemoveLabel: (label: Interfaces.CMLabels) => void;
}

const OneLabel = ({ label, readOnly, onRemoveLabel, }: OneLabelProps) => {
  const classes = useStyles();
  const [showDelete, setShowDelete,] = useState(false);

  return (
    <TableRow key={label.key}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
      className={classes.tableRow}
    >
      <TableCell component="th" scope="row" className={classes.tableCell}>
        {label.key}: {label.value}
      </TableCell>
      <TableCell
        className={classes.deleteCell}>
        {!readOnly && showDelete && (
          <IconButton
            size={'small'}
            color="default"
            disabled={readOnly}
            onClick={() => {
              if (onRemoveLabel) {
                onRemoveLabel(label);
              }
            }}
          >
            <Cancel/>
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default OneLabel;
