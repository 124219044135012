import React from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useStyles, } from './styles';
import { useAuth, } from '../../context';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  Lock,
  Visibility,
  VisibilityOff,
  Check,
} from '@mui/icons-material';
import { passwordServices, } from '../../services';
import { helpers, } from '../../utils';
import { useSnackbar, } from 'notistack';

const ResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { health, deploymentType, } = useAuth();
  const { token, }: any = useParams();
  const [registerCredentials, setCredentials,] = React.useState({
    newPassword: '',
    repeatNewPassword: '',
  });

  const [loading, setLoading,] = React.useState(false);
  const { enqueueSnackbar, } = useSnackbar();
  const [confirmPassword, setConfirmPassword,] = React.useState<string | null>(null);
  const [checkPasswordLength, setCheckPasswordLength,] = React.useState(false);
  const [checkPasswordUpperCase, setCheckPasswordUpperCase,] = React.useState(false);
  const [checkPasswordLowerCase, setCheckPasswordLowerCase,] = React.useState(false);
  const [checkPasswordNumber, setCheckPasswordNumber,] = React.useState(false);
  const [viewPasswordValidation, setViewPasswordValidation,] = React.useState(false);
  const [hasErorrPassword, setHasErorrPassword,] = React.useState(false);
  const [hasErorrConfirmPassword, setHasErorrConfirmPassword,] = React.useState(false);
  const [showPassword, setShowPassword,] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  let passwordIsValid = false;

  if (!passwordIsValid && checkPasswordLength && checkPasswordUpperCase && checkPasswordLowerCase && checkPasswordNumber) {
    passwordIsValid = true;
  }

  const isValidPassword = (password: string) => {
    if (hasErorrPassword) {
      setHasErorrPassword(false);
    }
    if (!viewPasswordValidation && password.length > 0) {
      setViewPasswordValidation(true);
    }
    if (!checkPasswordLength && password.length >= 12) {
      setCheckPasswordLength(true);
    }
    if (checkPasswordLength && password.length < 12) {
      setCheckPasswordLength(false);
    }
    if (!checkPasswordUpperCase && password.match(/[A-Z]/)) {
      setCheckPasswordUpperCase(true);
    }
    if (checkPasswordUpperCase && !password.match(/[A-Z]/)) {
      setCheckPasswordUpperCase(false);
    }
    if (!checkPasswordLowerCase && password.match(/[a-z]/)) {
      setCheckPasswordLowerCase(true);
    }
    if (checkPasswordLowerCase && !password.match(/[a-z]/)) {
      setCheckPasswordLowerCase(false);
    }
    if (!checkPasswordNumber && password.match(/[0-9]/)) {
      setCheckPasswordNumber(true);
    }
    if (checkPasswordNumber && !password.match(/[0-9]/)) {
      setCheckPasswordNumber(false);
    }
  };

  const handleResetPassword = async () => {
    if (!passwordIsValid) {
      setHasErorrPassword(true);
      return;
    }
    if (registerCredentials.newPassword !== confirmPassword) {
      setHasErorrConfirmPassword(true);
      return;
    }
    setLoading(true);
    let newPassword = registerCredentials.newPassword;
    let repeatNewPassword = registerCredentials.newPassword;
    if (deploymentType === 'cloud') {
      newPassword = await helpers.sha256(registerCredentials.newPassword);
      repeatNewPassword = await helpers.sha256(registerCredentials.repeatNewPassword);
    }
    passwordServices.resetPassword({
      ...registerCredentials,
      newPassword,
      repeatNewPassword,
      token,
    })
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Password has been changed', { variant: 'success', });
        navigate('/');
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.mainGrid}
    >
      <Grid item xs={5} sm={6} md={5} lg={3} className={classes.formGrid}>
        <div
          className={classes.logoDiv}
        >
          <img src='/logo.png' className={classes.logo} alt="logo"/>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleResetPassword();
        }}>
          <Typography
            className={classes.logoName}
            variant={'h4'}
          >
            Password Reset
          </Typography>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            error={hasErorrPassword}
            placeholder="New Password"
            margin="normal"
            variant="outlined"
            size="medium"
            name="newPassword"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={registerCredentials.newPassword}
            onChange={(event) => {
              setCredentials({
                ...registerCredentials,
                newPassword: event.target.value,
              });
              isValidPassword(event.target.value);
            }}
          />
          { viewPasswordValidation && (
            <div className={classes.passwordErrorDiv}>
              <Typography variant="subtitle1">Your Password must contain:</Typography>
              <Typography variant="body1" color="inherit" className={!checkPasswordLength ? classes.flexCenterStart1 : classes.flexCenterStart2}>
                { checkPasswordLength ? <Check fontSize='small' color='inherit'/> : <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div> }
            &nbsp;&nbsp;At least 12 characters
              </Typography>
              <Typography variant="body1" color="inherit" className={!checkPasswordLowerCase  ? classes.flexCenterStart1 : classes.flexCenterStart2}>
                { checkPasswordLowerCase ? <Check fontSize='small' color='inherit'/> : <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div> }
            &nbsp;&nbsp;Lower case letters (a-z)
              </Typography>
              <Typography variant="body1" color="inherit" className={!checkPasswordUpperCase ? classes.flexCenterStart1 : classes.flexCenterStart2}>
                { checkPasswordUpperCase ? <Check fontSize='small' color='inherit'/> : <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div> }
            &nbsp;&nbsp;Upper case letters (A-Z)
              </Typography>
              <Typography variant="body1" color="inherit" className={!checkPasswordNumber ? classes.flexCenterStart1 : classes.flexCenterStart2}>
                { checkPasswordNumber ? <Check fontSize='small' color='inherit'/> : <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div> }
            &nbsp;&nbsp;Numbers (0-9)
              </Typography>
            </div>
          )}
          <TextField
            fullWidth
            type='password'
            error={hasErorrConfirmPassword}
            placeholder="Repeat New Password"
            margin="normal"
            variant="outlined"
            size="medium"
            name="repeatNewPassword"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock/>
                </InputAdornment>
              ),
            }}
            value={registerCredentials.repeatNewPassword}
            onChange={(event) => {
              setCredentials({
                ...registerCredentials,
                repeatNewPassword: event.target.value,
              });
              setConfirmPassword(event.target.value);
              if (hasErorrConfirmPassword) {setHasErorrConfirmPassword(false);}
            }}
          />
          {registerCredentials.newPassword !== '' && registerCredentials.repeatNewPassword !== null && registerCredentials.newPassword !== registerCredentials.repeatNewPassword && (
            <span
              className={classes.error}
            >
              Passwords are not matching
            </span>
          )}
          <Button
            fullWidth
            color="primary"
            type="submit"
            variant="contained"
            className={classes.button}
            disabled={loading}
          >
            Reset Password
          </Button>
        </form>
        <Typography
          className={classes.bottomText}
        >
          {`Want to go back?  `}
          <p
            className={classes.bottomLink}
            onClick={() => navigate('/')}
          >
            Log In
          </p>
        </Typography>
        <div className={classes.versionDiv}>
          <span className={classes.version}>
            {'v'+ health.version || ''}
          </span>
        </div>
      </Grid>
    </Grid>
  );
};

export { ResetPassword, };
