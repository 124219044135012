import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  CircularProgress,
  Theme,
  useTheme,
} from '@mui/material';
import { useAuth, } from '../../context';


const useStyles = (theme: Theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  textMain: {
    fontSize: '13rem',
    textAlign: 'center',
    marginTop: '10%',
  },
  textSecondary: {
    textAlign: 'center',
  },
});


function ApiOffline() {
  const theme = useTheme();
  const styles = useStyles(theme);

  const { loadingHealth, fetchApiStatus, } = useAuth();

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Typography variant="h1" sx={styles.textMain} component="h2">
          OOPS!
        </Typography>
        <Typography sx={styles.textSecondary} variant="h4" gutterBottom>
          IT SEEMS THAT THE API IS OFFLINE
        </Typography>
      </div>
      <div style={{ textAlign: 'center', }}>
        {loadingHealth && <CircularProgress/>}
        {!loadingHealth && <Button
          style={{  }}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => fetchApiStatus()}
        >
          {loadingHealth && <CircularProgress/>} Check Again
        </Button>
        }
      </div>
      <Grid item xs={5}/>
    </div>
  );
}

export default ApiOffline;
