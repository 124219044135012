import React from 'react';
import { SnackbarProvider, } from 'notistack';
import './index.css';
import App from './App';
import { createRoot, } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import {
  ThemeProvider,
  createTheme,
} from '@mui/material';

import {
  AuthProvider,
  SidebarProvider,
} from './context';
import {
  grey,
  red,
  yellow,
} from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3F51B5',
      contrastText: '#fff',
    },
    secondary: {
      main: red[400],
      contrastText: '#fff',
    },
    error: {
      main: red[700],
    },
    warning: {
      main: yellow[600],
    },
    text: {
      primary: grey[900],
      secondary: grey[600],
      disabled: grey[400],
    },
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
    },
  },
});

const root = createRoot(document.getElementById('root' ) as HTMLElement);

root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <SidebarProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <App/>
          </ThemeProvider>
        </AuthProvider>
      </SidebarProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
