import React, { useState, } from 'react';
import { SaveAndRunButton, } from './SaveAndRunButton';
import {
  Button,
  Grid,
} from '@mui/material';
import { Interfaces, } from '../../../config';
import { useNavigate, } from 'react-router-dom';
import { useStyles, } from './styles';
import { helpers, } from './helpers';
import { catalogsServices, } from '../../../services';
import { useSnackbar, } from 'notistack';
import { AxiosResponse, } from 'axios';

const SaveAndCancelButtons = ({
  catalog,
  inEditMode,
  loadingSchema,
  schemaError,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  inEditMode: boolean;
  loadingSchema: boolean;
  schemaError: boolean;
}) => {
  const history = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const [loading, setLoading,] = useState(false);
  // const { addCatalog, updateCatalog, } = useCatalogs();

  const runCatalog = (catalogId: string) => {
    catalogsServices.runCatalog(catalogId)
      .then((response: AxiosResponse<Interfaces.Schema>) => {
        enqueueSnackbar(`Successfully ran catalog: '${response.data?.properties?.catalogName || catalogId}'`, { variant: 'info', });
        history('/catalogs');
        setLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        setLoading(false);
      });
  };

  const addCatalog = (body: Interfaces.InputCatalogMetadata, isRunCatalog: boolean = false) => {
    setLoading(true);
    catalogsServices.addCatalog(body)
      .then((response: AxiosResponse<{message: string}>) => {
        enqueueSnackbar('Successfully saved catalog', { variant: 'success', });
        if (isRunCatalog) {
          runCatalog(response.data.message);
        } else {
          history('/catalogs');
          setLoading(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        setLoading(false);
      });
  };

  const updateCatalog = (catalog: Interfaces.InputCatalogMetadata, isRunCatalog = false) => {
    setLoading(true);
    catalog.id && catalogsServices.updateCatalog(catalog, catalog.id)
      .then(() => {
        enqueueSnackbar('Successfully updated catalog', { variant: 'success', });
        if (isRunCatalog) {
          catalog.id && runCatalog(catalog.id);
        } else {
          history('/catalogs');
          setLoading(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        setLoading(false);
      });
  };

  const saveCatalog = (isRunCatalog = false) => {
    const filteredSchema =
      helpers.extractInputSchemaBySelection(JSON.parse(JSON.stringify(catalog)) as Interfaces.InputCatalogMetadata);
    const cleansed = helpers.cleanseSchema(filteredSchema);
    const body = {
      ...catalog,
      schema: cleansed,
    };
    addCatalog(body,isRunCatalog);
  };

  const handleUpdateCatalog = (isRunCatalog = false) => {
    const filteredSchema =
      helpers.extractInputSchemaBySelection(JSON.parse(JSON.stringify(catalog)) as Interfaces.InputCatalogMetadata);
    const cleansed = helpers.cleanseSchema(filteredSchema);
    const body = {
      ...catalog,
      schema: cleansed,
    };
    updateCatalog(body,isRunCatalog);
  };

  return (
    <Grid container justifyContent={'flex-end'} >
      <SaveAndRunButton
        loading={loading}
        disabled={!catalog.dataSourceId || !catalog.name || schemaError || loading || loadingSchema}
        handleSaveClick={() => {
          if (inEditMode) {
            handleUpdateCatalog();
          } else {
            saveCatalog();
          }
        }}
        handleSaveAndRunClick={() => {
          if (inEditMode) {
            handleUpdateCatalog(true);
          } else {
            saveCatalog(true);
          }
        }}
      />
      <Button
        className={classes.closeButton}
        variant={'outlined'}
        color={'primary'}
        onClick={() => history('/catalogs')}
      >
        Cancel
      </Button>
    </Grid>
  );
};

export { SaveAndCancelButtons, };
