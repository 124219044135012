import {
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Search, } from '@mui/icons-material';
import React, {
  ChangeEvent,
  useState,
} from 'react';
import { makeStyles, } from '@mui/styles';
import { useNavigate, } from 'react-router-dom';

interface Props {
  small?: boolean,
}

const useStyles = makeStyles(() => ({
  small: {
    height: '35px',
  },
}));

export function SearchInput(props: Props) {
  const classes = useStyles();
  const history = useNavigate();
  const [keyword, setKeyword,] = useState<string>('');

  function handleKeyPress(target: React.KeyboardEvent<HTMLDivElement>) {
    if (target.key === 'Enter') {
      const encodedKeyword = encodeURIComponent(keyword.replaceAll(' ', ''));
      history(`/search/${encodedKeyword}/1`);
    }
  }

  function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    // currently '%' is not supported in search, so it is removed from the input value
    if (event.target.value.slice(-1) !== '%') {
      setKeyword(event.target.value);
    }
  }

  return (
    <Box>
      <TextField
        className={props.small ? classes.small : ''}
        id="input-with-icon-adornment"
        value={keyword}
        fullWidth
        variant="outlined"
        size="small"
        placeholder="Type enter to search..."
        onKeyPress={(target) => handleKeyPress(target)}
        onChange={(event) => handleChange(event)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search/>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
