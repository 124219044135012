import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    display: 'none',
  },
  inline: {
    margin: theme.spacing(1),
  },
  img: {
    width: '20%',
    marginBottom: '2rem',
    marginTop: '35px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paddingBox: {
    margin: 7,
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 10,
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
}));

export default useStyles;
