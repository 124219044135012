import { axiosClient, } from '../config/helpers';
import {
  Constants,
  Interfaces,
} from '../config';

export const registerServices = {
  register,
  resendVerification,
};

function register(body: Interfaces.Register) {
  return axiosClient().post(Constants.api.endpoints.register, body);
}

function resendVerification(body: Interfaces.ResendVerification) {
  return axiosClient().post(Constants.api.endpoints.resendVerification, body);
}
