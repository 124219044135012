import React, {
  useEffect,
  useState,
} from 'react';
import { NoDataComponent, } from '../NoDataComponent';
import { collectionServices, } from '../../services';
import { AxiosResponse, } from 'axios';
import { Interfaces, } from '../../config';
import { helpers, } from '../../utils';
import { useSnackbar, } from 'notistack';
import { ModelsTable, } from './ModelsTable';
import LoadingComponent from '../Loading';
import { Button, } from '@mui/material';
import { useNavigate, } from 'react-router-dom';

const navigationData = {
  component: 'collections',
  header: 'No Models',
  text1: 'You have not configured a model yet',
  text2: 'Create a model to get started',
  buttonText: 'Add a Model',
  route: '/models/add',
};

const Models = () => {
  const { enqueueSnackbar, } = useSnackbar();
  const [collections, setCollections,] = useState<Array<Interfaces.Model>>([]);
  const [loading, setLoading,] = useState(true);
  const history = useNavigate();

  useEffect(() => {
    collectionServices.fetchCollections()
      .then((response: AxiosResponse<{ data: Array<Interfaces.Model> }>) => {
        setCollections(response?.data?.data);
      })
      .catch((reason) => {
        enqueueSnackbar(helpers.getErrorMessage(reason), { variant: 'error', });
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <LoadingComponent message={'Loading data sources'}/>;
  }

  return (
    <div>
      {
        collections.length > 0 ? (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ marginBottom: 17, }}
              onClick={() => {
                history('/models/add');
              }}
            >
              Add model
            </Button>
            <ModelsTable
              models={collections}
              updateModels={setCollections}
            />
          </>
        ) : (
          <NoDataComponent data={navigationData} />
        )
      }
    </div>
  );
};

export default Models;
