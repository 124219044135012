import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  PlayArrow,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Loop as LoopIcon,
  CheckCircle as CheckCircleIcon,
  Report as ReportIcon,
} from '@mui/icons-material';
import { useParams, } from 'react-router-dom';
import {
  Constants,
  Interfaces,
} from '../../config';
import { helpers, } from '../../utils';
import { useSnackbar, } from 'notistack';
import { makeStyles, } from '@mui/styles';
import { useInterval, } from 'usehooks-ts';
import { ViewCodeDialog, } from  './ViewCodeDialog';
import { useAuth, } from '../../context';
import LoadingComponent from '../Loading';
import { jobsServices, } from '../../services';
import { AxiosResponse, } from 'axios';
import { useDSCalls, } from '../../hooks';
import { catalogsServices, } from '../../services';
import { RunAndCodeBtn, } from './RunAndCodeBtn';
import { StyledTextField, } from '../Reusable';
import CatalogIcons from '../Catalogs/CatalogIcons/CatalogIcons';
import arrowRight from '../../assets/icons/arrow-right.svg';
import { formatDate, } from '../../config/helpers';

const headCells = [
  {
    id: '#',
    numeric: true,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'jobId',
    numeric: true,
    disablePadding: true,
    label: 'Job ID',
  },
  {
    id: 'sourceCatalog',
    numeric: false,
    disablePadding: false,
    label: 'Source Catalog',
  },
  {
    id: 'targetDataSource',
    numeric: false,
    disablePadding: false,
    label: 'Target Datasource',
  },
  {
    id: 'startTime',
    numeric: true,
    disablePadding: true,
    label: 'Start Time',
  },
  {
    id: 'endTime',
    numeric: true,
    disablePadding: true,
    label: 'End Time',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

export const useStyles = makeStyles((theme: Theme) =>(
  // () =>
  // createStyles({
  {
    table: {
      minWidth: 650,
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      '& thead th': {
        position: 'sticky',
        zIndex: 600,
        fallbacks: {
          position: '-webkit-sticky',
        },
      },
    },
    stickyTable: {
      position: 'sticky',
      zIndex: 500,
      overflow: 'visible',
      background: theme.palette.background.paper,
      fallbacks: {
        position: '-webkit-sticky',
      },
    },
    rotateIcon: {
      animation: '$spin 2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  })
);

function Row(props:Interfaces.DataTransferResourceAction<Interfaces.DataTransfer>) {
  const classes = useStyles();
  const job = props.data?.data;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              console.debug('Row-Click');
              if (props.onOpen && !props.isOpen) {
                console.debug('Row-Open');
                props.onOpen();
              }
              if (props.onClose && props.isOpen) {
                console.debug('Row-Close');
                props.onClose();
              }
            }
            }
          >
            {props.isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {job.jobId}
        </TableCell>
        <TableCell><Link href={`/catalog/edit/${job.sourceCatalogId}`}>{job.sourceCatalogName || job.sourceCatalogId}</Link></TableCell>
        <TableCell>{job.targetDataSourceName || job.targetDataSourceId}</TableCell>
        <TableCell>{formatDate(job.startTime)}</TableCell>
        <TableCell align="center">{formatDate(job.endTime)}</TableCell>
        {job.status ==='RUNNING' && <TableCell><LoopIcon className={classes.rotateIcon} /></TableCell>}
        {job.status ==='SUCCESS' && <TableCell><CheckCircleIcon style={{ color:'green', }}/></TableCell>}
        {job.status ==='FAILED' && <TableCell><ReportIcon style={{ color:'red', }}/> </TableCell>}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0,
          paddingTop: 0,
          backgroundColor: '#fafbfc', }} colSpan={7} >
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, }}>
              <Typography variant="h6" gutterBottom component="div">
                Log
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(job.data).map((key) => (
                    <TableRow key={job.jobId+key}>
                      <TableCell component="th" scope="row" style={{ width: '350px',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word', }}>
                        {key}
                      </TableCell>
                      <TableCell style={{ overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word', }}>{job.data[key]}</TableCell>
                    </TableRow>))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface DataTransferInputUIIface {
  filteredCatalogs:Array<Interfaces.InputCatalogMetadata>,
  catalogs:Array<Interfaces.InputCatalogMetadata>,
  dataSources: Interfaces.DataSourceType[] | null,
  dataTransfers:Interfaces.DataTransfer[],
  fetchJobs:()=>void,
}

function DataTransferInput(props: DataTransferInputUIIface) {
  const params = useParams<{ dataSourceName: string }>();
  const { enqueueSnackbar, } = useSnackbar();
  const [selectedDataSource, setSelectedDataSource,] = useState('');
  const [selectedCatalog, setSelectedCatalog,] = useState('');
  const [selectedCatalogDS, setSelectedCatalogDS,] = useState('');
  const [targetPath, setTargetPath,] = useState('');
  const dataSourceUri = props.dataSources?.find((tmpSource)=>tmpSource.id==selectedDataSource)?.uri || '';
  const dataSourceUriSchema = dataSourceUri.substring(0,dataSourceUri.indexOf('://'))+'://';
  const [viewCode, setViewCode,] = useState(false);
  const [loadingJob, setLoadingJob,] = useState(false);

  const { isAuthorized, } = useAuth();
  const [readAccess,] = useState(isAuthorized('ADAPTIVE_DT_READ'));
  const [readDSAccess,] = useState(isAuthorized('ADAPTIVE_DS_READ'));
  const [readCATAccess,] = useState(isAuthorized('ADAPTIVE_CAT_READ'));
  const [executeAccess,] = useState(isAuthorized('ADAPTIVE_DT_EXECUTE'));

  const runJob = (catalogId:string, dataSourceId:string, targetPath:string) => {
    setLoadingJob(true);
    jobsServices.runJob({
      sourceCatalogId: catalogId,
      targetDataSourceId: dataSourceId,
      targetDirectoryPath: targetPath,
    })
      .then((response) => {
        enqueueSnackbar(`Status of data transfer is "${response.data.data?.status}"`, { variant: 'success', });
        props.fetchJobs();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingJob(false);
      });
  };

  return (
    <>
      <ViewCodeDialog path={''} targetDataSource={selectedDataSource} sourceCatalog={selectedCatalog} isOpen={viewCode} handleClose={() => setViewCode(false)}/>
      <Grid xs={5} style={{ marginBottom: 30, }}>
        <Typography
          paddingBottom={1}
        >Source
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          select
          size="small"
          label="Select catalog"
          disabled={!!params.dataSourceName || !readCATAccess}
          value={selectedCatalog}
          onChange={(e) => {
            setSelectedCatalog(e.target.value);
            props.filteredCatalogs
              .filter((catalog) => catalog.id === e.target.value)
              .map((catalog) => setSelectedCatalogDS(catalog.dataSourceId));
          }}
        >
          {props.catalogs &&
            props.filteredCatalogs &&
            props.filteredCatalogs.map((catalog) => (
              <MenuItem key={catalog.name} value={catalog.id}>
                <CatalogIcons catalog={catalog} run={true} />
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid xs={2}
        alignItems='center'
        display="flex"
        justifyContent="center"
      >
        <img
          alt={'arrow-right'}
          style={{
            height: 60,
            width: 80,
            marginTop: 20,
          }}
          src={arrowRight}
        />
      </Grid>
      <Grid xs={5} >
        <Grid item xs={12}>
          <Typography
            paddingBottom={1}
          >
            Target
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            label="Select target"
            disabled={!!params.dataSourceName || !readDSAccess}
            value={selectedDataSource}
            onChange={(e) => {
              setSelectedDataSource(e.target.value);
              const dataSourceFound = props.dataSources?.find((tmpSource)=>tmpSource.id==e.target.value);
              if (dataSourceFound) {
                const uri = dataSourceFound.uri;
                const uriSchema = uri.substring(0,uri.indexOf('://'))+'://';
                setTargetPath(uri.substring(0, uri.lastIndexOf('/')).replace(uriSchema, ''));
              }
            }}
          >
            {props.dataSources && props.dataSources
              .filter((dataSource) => ['GCS', 'S3', 'JDBC',].indexOf(dataSource.type)>-1)
              .filter((dataSource) => selectedCatalogDS !== dataSource.id)
              .map((dataSource) => (
                dataSource.type === 'JDBC' ?
                  <MenuItem key={dataSource.name} value={dataSource.id}>
                    {dataSource.name} (Beta)
                  </MenuItem> :
                  <MenuItem key={dataSource.name} value={dataSource.id}>
                    {dataSource.name}
                  </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
      {
        props.dataSources && props.dataSources
          .filter((dataSource) => ['JDBC',].indexOf(dataSource.type)>-1).map((ele)=>ele.id).includes(selectedDataSource) ?
          <Grid container spacing={2}>
            <Grid item xs={10}/>
            <Grid item xs={2} style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              <RunAndCodeBtn
                handleRunClick={()=> {
                  const finalPath = dataSourceUriSchema + targetPath;
                  runJob(selectedCatalog, selectedDataSource, finalPath);
                }}
                handleCodeClick={() => setViewCode(true)}
                disabledRunBtn={selectedDataSource=='' || selectedCatalog == '' || targetPath=='' || loadingJob || !readAccess || !executeAccess}
                disabledCodeBtn={selectedDataSource=='' || selectedCatalog == '' || loadingJob}
                runBtnText={loadingJob ? 'Running' : 'Run'}
              />
            </Grid>
          </Grid>
          :
          <Grid container spacing={2}>
            <Grid item xs={7}/>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography style={{ marginRight: 8, }}>Path:</Typography>
              <StyledTextField
                size={'small'}
                fullWidth
                value={targetPath}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {dataSourceUriSchema}
                    </InputAdornment>
                  ),
                }}
                onChange={(event)=>{
                  let sanitizedValue = event.target.value;
                  sanitizedValue=sanitizedValue.replace('//','/');
                  setTargetPath(''+sanitizedValue);
                }}/>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  float:'right',
                  width:'100%',
                  margin: '7px 0',
                  height: '40px',
                }}
                disabled={selectedDataSource=='' || selectedCatalog == '' || targetPath=='' || loadingJob || !readAccess || !executeAccess}
                endIcon={<PlayArrow />}
                onClick={()=>{
                  const finalPath = dataSourceUriSchema + targetPath;
                  runJob(selectedCatalog, selectedDataSource, finalPath);
                }}
              >
                {loadingJob && 'Running'}
                {!loadingJob && 'Run'}
              </Button>
            </Grid>
          </Grid>
      }
    </>
  );
}

function DataTransfer() {
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const [expandedRows, setExpandedRows,] = useState<Array<string>>([]);
  const [filteredCatalogs, setFilteredCatalogs,] = useState<Array<Interfaces.InputCatalogMetadata>>([]);
  const [dataTransfers, setDataTransfers,] = useState<Array<Interfaces.DataTransfer>>([]);
  const [loadingDataTransfers, setLoadingDataTransfers,] = useState<boolean>(false);
  const [errorDataTransfers, setErrorDataTransfers,] = useState<string | null>(null);
  const [catalogs, setCatalogs,] = useState<Array<Interfaces.InputCatalogMetadata>>([]);

  const { isAuthorized, } = useAuth();
  const [readAccess,] = useState(isAuthorized('ADAPTIVE_DT_READ'));

  const fetchCatalogs = () => {
    catalogsServices.fetchCatalogsWithDataSources()
      .then((response: AxiosResponse<Array<Interfaces.InputCatalogMetadata>>) => {
        setCatalogs(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };
  const { dataSources, } = useDSCalls();

  const fetchJobs = () => {
    setLoadingDataTransfers(true);
    setErrorDataTransfers(null);
    jobsServices.fetchJobs()
      .then((response: AxiosResponse<any>) => {
        const sortedDT = response.data.data.map((job:Interfaces.DataTransfer)=>
          ({ data: job, })).sort((a: Interfaces.DataTransfer, b: Interfaces.DataTransfer)=>{
          return b.data.startTime - a.data.startTime;
        });
        setDataTransfers(sortedDT);
      })
      .catch((error) => {
        setErrorDataTransfers(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingDataTransfers(false);
      }
      );
  };

  const refreshJobs = () => {
    jobsServices.fetchJobs()
      .then((response: AxiosResponse<any>) => {
        const sortedDT = response.data.data.map((job:Interfaces.DataTransfer)=>
          ({ data: job, })).sort((a: Interfaces.DataTransfer, b: Interfaces.DataTransfer) => {
          return b.data.startTime - a.data.startTime;
        });
        setDataTransfers(sortedDT);
      });
  };

  useEffect(()=>{
    fetchJobs();
    fetchCatalogs();
  }, []);

  useInterval(()=>{
    refreshJobs();
  }, Constants.api.longPolling.dataTransfer.interval);

  useEffect(()=>{
    console.debug('expandedRows-updated', expandedRows);
  }, [expandedRows,]);

  useEffect(() =>{
    if (dataSources && catalogs) {
      setFilteredCatalogs(catalogs.filter((catalog)=>dataSources.find((ds)=>ds.id===catalog.dataSourceId)?.type==='JDBC'));
    }
  },[catalogs, dataSources,]);

  return (
    <Grid container style={{ height: 'calc(100vh - 114px)', }}>
      <Grid container style={{ background: '#fff',
        padding: 10,
        borderRadius: 8,
        width: '100%', }}>
        <DataTransferInput
          filteredCatalogs={filteredCatalogs}
          catalogs={catalogs}
          dataSources={dataSources}
          dataTransfers={dataTransfers}
          fetchJobs={fetchJobs}
        />
      </Grid>
      {loadingDataTransfers &&
        <Paper style={{ width:'100%',
          height:'calc(100vh - 240px)',
          alignItems:'center', }}><LoadingComponent/></Paper>}
      {errorDataTransfers && <Paper style={{ width:'100%',
        height:'10vh',
        alignItems:'center', }}>
        <Typography>Failed to fetch jobs  <Button
          variant={'outlined'}
          color={'primary'}
          disabled={loadingDataTransfers}
          onClick={()=>{
            fetchJobs();
          }}
        >Retry</Button>
        </Typography>
      </Paper>}
      {readAccess && !loadingDataTransfers && dataTransfers &&
        <Paper
          elevation={5}
          style={{
            height: 'calc(100vh - 240px)',
            width: '100%',
            overflow: 'auto',
            marginTop: '10px',
          }}>
          <TableContainer className={`${classes.table} ${classes.stickyTable}`}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCells) => (
                    <TableCell
                      key={headCells.id}
                    >
                      {headCells.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTransfers?.map((job) =>
                  <Row
                    key={job.data.jobId}
                    isLoading={false}
                    message={''}
                    hasError={false}
                    data={job}
                    isOpen={expandedRows.indexOf(job.data.jobId)> -1}
                    onOpen={()=>setExpandedRows([...expandedRows, job.data.jobId,])}
                    onClose={()=> setExpandedRows(expandedRows.filter((value)=>value!=job.data.jobId))}/>)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      }
    </Grid>
  );
}

export default DataTransfer;
