import React from 'react';
import { Grid, } from '@mui/material';
import { Interfaces, } from '../../../config';
import { NodeMetadataDetails, } from './NodeMetadataDetails';
import { NodeMetadataTagsLabels, } from './NodeMetadataTagsLabels';
import { NodeMetadataFields, } from './NodeMetadataFields';
import { useStyles, } from './styles';

const NodeMetadata = ({ selectedNode, }: { selectedNode: Interfaces.Schema; }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <p className={classes.sectionTitle}>
        Metadata
      </p>
      <NodeMetadataDetails selectedNode={selectedNode}/>
      {
        (selectedNode.tags.length > 0 || selectedNode.labels.length > 0) && (
          <NodeMetadataTagsLabels selectedNode={selectedNode}/>
        )
      }
      {
        (selectedNode.sourceType === 'table' || selectedNode.sourceType === 'schema' || selectedNode.sourceType === 'view') && (
          <NodeMetadataFields selectedNode={selectedNode} />
        )
      }
    </Grid>
  );
};

export { NodeMetadata, };
