import { axiosClient, } from '../config/helpers';
import {
  Constants,
  Interfaces,
} from '../config';
import * as interfaces from '../config/interfaces';

export const catalogsServices = {
  fetchCatalogs,
  fetchCatalogById,
  deleteCatalog,
  addCatalog,
  updateCatalog,
  runCatalog,
  previewCatalog,
  fetchSchema,
  fetchTotalRecords,
  dslPreview,
  fetchRunHistory,
  fetchCatalogsWithDataSources,
  addThirdPartyCatalog,
  fetchThirdPartyCatalogByIdOrName,
  fetchCatalogWithDataSources,
  applyAutoTagging,
};

function fetchCatalogs() {
  return axiosClient().get(Constants.api.endpoints.catalogs);
}

function fetchCatalogById(catalogId: string) {
  return axiosClient().get(`${Constants.api.endpoints.catalogs}${catalogId}`);
}

function deleteCatalog(catalogId: string) {
  return axiosClient().delete(`${Constants.api.endpoints.catalogs}${catalogId}`);
}

function addCatalog(body: interfaces.InputCatalogMetadata) {
  return axiosClient().post(Constants.api.endpoints.catalogs, body);
}

function updateCatalog(body: interfaces.InputCatalogMetadata, catalogName: string) {
  return axiosClient().patch(`${Constants.api.endpoints.catalogs}${catalogName}`, body);
}

function runCatalog(catalogId: string) {
  return axiosClient().get(`${Constants.api.endpoints.catalogs}${catalogId}/run`);
}

function previewCatalog(body: interfaces.InputCatalogMetadata) {
  return axiosClient().post(`${Constants.api.endpoints.catalog}preview`, body);
}

function fetchSchema(dataSourceId: string, abortSignal?: AbortSignal) {
  const config = abortSignal ? { signal: abortSignal, } : {};
  return axiosClient().get(`${Constants.api.endpoints.dataSources}${dataSourceId}/schema`, config);
}

function fetchTotalRecords(catalog: Interfaces.InputCatalogMetadata, dataSourceName: string) {
  return axiosClient().post(`${Constants.api.endpoints.data}${dataSourceName}/info`, catalog);
}

function dslPreview(catalog: interfaces.InputCatalogMetadata) {
  return axiosClient().post('/catalog/dsl', catalog);
}

function fetchRunHistory(catalogId: string) {
  return axiosClient().get(`${Constants.api.endpoints.searchSnapshots}/catalog/${catalogId}`);
}

function fetchCatalogsWithDataSources() {
  return axiosClient().get(Constants.api.endpoints.catalogsWithDs);
}

function addThirdPartyCatalog(body: interfaces.InputCatalogMetadata) {
  return axiosClient().post(`${Constants.api.endpoints.catalog}third-party`, body);
}

function fetchThirdPartyCatalogByIdOrName(idOrName: string) {
  return axiosClient().get(`${Constants.api.endpoints.catalogs}third-party/${idOrName}`);
}

function fetchCatalogWithDataSources(catalogId: string) {
  return axiosClient().get(`${Constants.api.endpoints.catalogsWithDs}${catalogId}`);
}

function applyAutoTagging(body: interfaces.InputCatalogMetadata) {
  return axiosClient().post(`${Constants.api.endpoints.catalog}auto-tag`, body);
}
