import React from 'react';
import { LogoRosetta, } from '../../static';
import {
  Grid,
  Theme,
} from '@mui/material';
import { makeStyles, } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => (
  {
    animation: {
      animation: `$myEffect 2000ms infinite ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-100%)',
      },
      '70%': {
        opacity: 0.7,
        transform: 'translateY(30%)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(10%)',
      },
    },
  }
));

function Loader() {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" style={{ marginTop: '20%', }}>
      <div className={classes.animation}>
        <img alt="RosettaDBLogo" width="100px" height="100px" src={LogoRosetta}/>
      </div>
    </Grid>
  );
}

export { Loader, };
