import React from 'react';
import {
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useStyles, } from '../DataSourceTable';
import {
  Delete,
  Edit,
} from '@mui/icons-material';
import { DataSourceMenu, } from '../DataSourceMenu';
import { Interfaces, } from '../../../../config';
import ModalConfirmation from '../../../ModalConfirmation/ModalConfirmation';
import { DataSourceLogo, } from '../../../DatasourceIcon';



interface TableRowDSProps {
  dataSource: Interfaces.BaseDataSource |
  Interfaces.JDBCDataSource |
  Interfaces.GoogleBucketDataSource |
  Interfaces.S3DataSource |
  Interfaces.AzureDataSource;
  index: number;
  canWrite: boolean;
  canDelete: boolean;
  canExecute: boolean;
  // eslint-disable-next-line no-unused-vars
  handleEdit: (dataSource: any) => void;
  // eslint-disable-next-line no-unused-vars
  deleteDataSource: (dataSourceId: string, setLoader: React.Dispatch<React.SetStateAction<boolean>>) => Promise<void>;
}

const TableRowDS = ({ dataSource, index, canWrite, canDelete, canExecute, handleEdit,  deleteDataSource, } : TableRowDSProps) => {
  const classes = useStyles();
  const [loadDelete, setLoadDelete,] = React.useState(false);
  const [deletePopupState, setDeletePopupState,] = React.useState(false);
  const [dataSourceToDelete, setDataSourceToDelete,] = React.useState<Interfaces.DataSourceType | null>(null);

  const handleDeletePopup = (
    dataSource:
      Interfaces.BaseDataSource |
      Interfaces.JDBCDataSource |
      Interfaces.GoogleBucketDataSource |
      Interfaces.S3DataSource |
      Interfaces.AzureDataSource |
      null
  ) => {
    setDeletePopupState(!deletePopupState);
    setDataSourceToDelete(dataSource);
  };

  const deleteAction = () => {
    if (dataSourceToDelete?.name) {
      deleteDataSource(dataSourceToDelete?.name, setLoadDelete);
      handleDeletePopup(null);
    }
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={dataSource.name}
      >
        <TableCell>{index + 1}</TableCell>
        <TableCell align="right" style={{ maxWidth: '50px', }}>
          <DataSourceLogo
            name={dataSource.driverName as any}
            type={dataSource.type as any}
            sizeWidth={'40%'}
            sizeHeight={'auto'}
            margin={'0'}
          />
        </TableCell>
        <TableCell align="left">
          {dataSource.name}
        </TableCell>
        <TableCell style={{ maxWidth: 400,
          overflowX: 'auto', }} align="left">{dataSource.uri}</TableCell>
        <TableCell align="left">{dataSource.type}</TableCell>
        <TableCell align="left" className={classes.dataSourceActionsCell}>
          {canWrite && (
            <Tooltip title="Edit">
              <IconButton
                style={{
                  padding: '3px 3px',
                  marginRight: '10px',
                }}
                aria-label="edit"
                onClick={() => {
                  handleEdit(dataSource);
                }}
              >
                <Edit/>
              </IconButton>
            </Tooltip>
          )}
          {canDelete && (
            <Tooltip title="Delete">
              <IconButton
                style={{
                  padding: '3px 3px',
                  marginRight: '10px',
                }}
                aria-label="delete"
                onClick={() => {
                  handleDeletePopup(dataSource);
                }}
              >
                {loadDelete ? <CircularProgress size={20}/> : <Delete/>}
              </IconButton>
            </Tooltip>
          )}
          {canExecute && (
            <DataSourceMenu dataSource={dataSource}/>
          )}
        </TableCell>
      </TableRow>
      <ModalConfirmation
        open={deletePopupState}
        onClose={() => handleDeletePopup(null)}
        title={'Are you sure you want to delete the Data Source?'}
        onBtnClick={() => deleteAction()}
        colorBtn={'secondary'}
        btnText={'Delete'}
        onCancelBtnClick={() => handleDeletePopup(null)} />
    </>
  );
};

export default TableRowDS;
