
import React, {
  useEffect,
  useState,
} from 'react';
import { useSnackbar, } from 'notistack';
import { AxiosResponse, } from 'axios';
import { Interfaces, } from '../config';
import { datasourceServices, } from '../services';
import { helpers, } from '../utils';
import { helpersServices, } from '../services';


const useDSCalls = () => {
  const { enqueueSnackbar, } = useSnackbar();
  const [dataSources, setDataSources,] = React.useState<Array<Interfaces.DataSourceType> | null>(null);
  const [loading, setLoading,] = React.useState(false);
  const [error, setError,] = React.useState<string | null>(null);
  const [supportedDrivers, setSupportedDrivers,] = React.useState<Array<Interfaces.JDBCDriver> | null>(null);
  const [loadingDrivers, setLoadingDrivers,] = React.useState(false);
  const [errorDrivers, setErrorDrivers,] = React.useState<string | null>(null);
  const [schemaTypes, setSchemaTypes,] = React.useState<Array<string>>([]);
  const [dataSourceToEdit, setDataSourceToEdit,] = useState<Interfaces.DataSourceType | null>(null);
  const [loadingEdit, setLoadingEdit,] = useState(false);
  const [errorEdit, setErrorEdit,] = useState<string | null>(null);
  const [testDataSource, setTestDataSource,] = useState<Interfaces.DataSourceType | null>(null);
  const [loadingTest, setLoadingTest,] = useState(false);
  const [errorTest, setErrorTest,] = useState<string | null>(null);
  const [directories, setDirectories,] = useState<Interfaces.Directory[]>([]);
  const [loadingDirectories, setLoadingDirectories,] = useState(false);
  const [errorDirectories, setErrorDirectories,] = useState<string | null>(null);

  const fetchDataSources = () => {
    setLoading(true);
    setError(null);
    datasourceServices.fetchDataSources()
      .then((response: AxiosResponse<Array<Interfaces.DataSourceType>>) => {
        setDataSources(response.data);
      })
      .catch((error) => {
        setError(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSupportedDrivers = () => {
    setLoadingDrivers(true);
    setErrorDrivers(null);
    datasourceServices.fetchSupportedDrivers()
      .then((response: AxiosResponse<Array<Interfaces.JDBCDriver>>) => {
        setSupportedDrivers(response.data);
      })
      .catch((error) => {
        setErrorDrivers(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingDrivers(false);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const addDataSource = (body: Interfaces.DataSourceType, callback?: () => void) => {
    setError(null);
    setLoading(true);
    datasourceServices.addDataSource(body)
      .then(() => {
        callback && callback();
        enqueueSnackbar(`Successfully added ${body.name}`, { variant: 'success', });
      })
      .catch((error) => {
        setError(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const editDataSource = (body: Interfaces.DataSourceType, oldName: string, callback?: () => void) => {
    setError(null);
    setLoading(true);
    datasourceServices.editDataSource(body, oldName)
      .then(() => {
        callback && callback();
        enqueueSnackbar(`Successfully updated ${body.name}`, { variant: 'success', });
      })
      .catch((error) => {
        setError(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoading(false);
      }
      );
  };

  // eslint-disable-next-line no-unused-vars
  const deleteDataSource = async (dataSourceId: string, setLoader: (loader: boolean) => void) => {
    setLoader(true);
    await datasourceServices.deleteDataSource(dataSourceId)
      .then(() => {
        enqueueSnackbar('Data source deleted successfully', { variant: 'success', });
        fetchDataSources();
      })
      .catch((error) => {
        setError(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchSchemaTypes = () => {
    helpersServices.fetchSchemaTypes()
      .then((response: AxiosResponse<Array<string>>) => {
        setSchemaTypes(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  const fetchDataSource = async (name: string) => {
    setLoadingEdit(true);
    setErrorEdit(null);
    await datasourceServices.fetchDataSource(name)
      .then((response) => {
        setDataSourceToEdit(response.data);
      })
      .catch((error) => {
        setErrorEdit(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingEdit(false);
      });
  };

  const testNewDataSource = async (data: Interfaces.DataSourceType) => {
    setLoadingTest(true);
    setErrorTest(null);
    await datasourceServices.testDataSource(data)
      .then((response) => {
        setTestDataSource(response.data);
      })
      .catch((error) => {
        setErrorTest(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingTest(false);
      });
  };

  const testExistingDataSource = async (data: Interfaces.DataSourceType) => {
    setLoadingTest(true);
    setErrorTest(null);
    await datasourceServices.testExistingDataSource(data)
      .then((response) => {
        setTestDataSource(response.data);
      })
      .catch((error) => {
        setErrorTest(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingTest(false);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const uploadDriverAPI = (driverFile: File , driverClassName: string, callback: (filePath?: string) => void) => {
    setLoadingTest(true);
    setErrorTest(null);
    datasourceServices.uploadDriver(driverFile, driverClassName)
      .then((response) => {
        callback(response.data.data);
        enqueueSnackbar('Driver uploaded successfully', { variant: 'success', });
      })
      .catch((error) => {
        setErrorTest(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        callback();
      })
      .finally(() => {
        setLoadingTest(false);
      });
  };

  const fetchDirectories = async (body: Interfaces.DirectoriesRequest, endpoint: string) => {
    setLoadingDirectories(true);
    setErrorDirectories(null);
    await datasourceServices.fetchDirectories(body, endpoint)
      .then((response: AxiosResponse<Interfaces.Directory[]>) => {
        setDirectories(response.data);
      })
      .catch((error) => {
        setErrorDirectories(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingDirectories(false);
      });
  };


  const fetchNestedDirectory = async (body: Interfaces.DirectoriesRequest, endpoint: string, parent: string) => {
    setLoadingDirectories(true);
    setErrorDirectories(null);
    await datasourceServices.fetchDirectories(body, endpoint)
      .then((response: AxiosResponse<Interfaces.Directory[]>) => {
        const newDirectories = directories.map((directory: Interfaces.Directory) => {
          helpers.setChildDirectories(directory, parent, response.data);
          return directory;
        });
        setDirectories(newDirectories);
      })
      .catch((error) => {
        setErrorDirectories(helpers.getErrorMessage(error));
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingDirectories(false);
      });
  };

  useEffect(() => {
    fetchDataSources();
  },[]);

  return { dataSourceToEdit,
    dataSources,
    supportedDrivers,
    schemaTypes,
    loading,
    error,
    loadingDrivers,
    errorDrivers,
    testDataSource,
    directories,
    loadingTest,
    errorTest,
    loadingEdit,
    errorEdit,
    loadingDirectories,
    errorDirectories,
    fetchDataSources,
    fetchSupportedDrivers,
    fetchSchemaTypes,
    addDataSource,
    editDataSource,
    deleteDataSource,
    uploadDriverAPI,
    fetchDataSource,
    testNewDataSource,
    testExistingDataSource,
    fetchDirectories,
    setTestDataSource,
    setDataSourceToEdit,
    fetchNestedDirectory, };
};

export { useDSCalls, };
