import {
  TextField,
  styled,
} from '@mui/material';

const StyledTextField = styled(TextField)(() => ({
  margin: '7px 0',
}));

export { StyledTextField, };
