import React from 'react';
import useStyles from './styles';
import {
  Paper,
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Close,
  Done,
  Report as ReportIcon,
} from '@mui/icons-material';
import { alertsServices, } from '../../../services';
import {
  Link,
  useParams,
} from 'react-router-dom';
import { AxiosResponse, } from 'axios';
import { Interfaces, } from '../../../config';
import LoadingComponent from '../../Loading';
import moment from 'moment';
import AceEditor from 'react-ace';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../../utils';

const AlertReport = () => {
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const { id, } = useParams<{ id: string }>();
  const [alert, setAlert,] = React.useState<Interfaces.Alert>();
  const [histories, setHistories,] = React.useState<Array<Interfaces.AlertHistory>>([]);
  const [loading, setLoading,] = React.useState(false);

  React.useEffect(() => {
    if (id) {
      alertsServices.fetchAlertById(id)
        .then((response: AxiosResponse<{data: Interfaces.Alert}>) => {
          setAlert(response.data.data);
          alertsServices.fetchAlertHistoriesByAlertId(id)
            .then((response: AxiosResponse<{ data: Array<Interfaces.AlertHistory> }>) => {
              setHistories(response.data.data);
            })
            .catch((error) => {
              enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
            })
            .finally(() => {
              setLoading(false);
            });
        });
    }
  }, [id,]);

  if (loading) {
    return <LoadingComponent />;
  }


  return (
    <Paper elevation={3} className={classes.container}>
      {alert && histories.length > 0 ? (
        <Box width={1200}>
          <Box paddingX={3} paddingTop={3} textAlign='center'>
            <Typography variant="h3" component="h2" color="textPrimary"> Alert Report</Typography>
          </Box>
          <Box display='flex' flexWrap='wrap' padding={3} justifyContent='center' textAlign='center'>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textPrimary" gutterBottom>
                      Alert Name
                </Typography>
                <Typography variant="body1">
                  {alert?.name}
                </Typography>
              </CardContent>
            </Card>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textPrimary" gutterBottom>
                      Catalog Name
                </Typography>
                <Typography variant="body1">
                  <Link to={`/catalog/edit/${alert?.relationId}`}>
                    {histories[0]?.relationName}
                  </Link>
                </Typography>
              </CardContent>
            </Card>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textPrimary" gutterBottom>
                      Alert Description
                </Typography>
                <Typography variant="body1">
                  {alert?.description}
                </Typography>
              </CardContent>
            </Card>

          </Box>
          <Box className={classes.root1}>
            <Box className={classes.alertInputRule} style={{ maxHeight: 120,
              marginBottom: 20, }}>
              <Box display='flex' justifyContent='space-between' >
                <Box>
                  <Typography className={classes.ruleTitle} variant="h6">Input Rule</Typography>
                  <Typography className={classes.ruleValue} variant="subtitle1" component="h2">
                    {alert?.inputRule?.name}
                  </Typography>
                </Box>
                <Box
                  style={{
                    border: '1px solid',
                    borderColor: '#f3f3f4',
                    padding: '3px',
                    borderRadius: '5px',
                  }}
                >
                  <AceEditor
                    style={{
                      maxHeight: 120,
                    }}
                    mode="text"
                    theme="tomorrow"
                    value={alert?.inputRule ? JSON.stringify(alert?.inputRule, null, 2) : ''}
                    fontSize={14}
                    wrapEnabled
                    readOnly
                    showGutter={false}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.alertOutputRule} style={{ maxHeight: 120,
              marginBottom: 30, }}>
              <Box display='flex' justifyContent='space-between'>
                <Box>
                  <Typography className={classes.ruleTitle} variant="h6">Output Rule</Typography>
                  <Typography className={classes.ruleValue} variant="subtitle1">
                    {alert?.outputRule?.name}
                  </Typography>
                </Box>
                <Box
                  style={{
                    border: '1px solid',
                    borderColor: '#f3f3f4',
                    padding: '3px',
                    borderRadius: '5px',
                  }}
                >
                  <AceEditor
                    style={{ maxHeight: 120, }}
                    mode="text"
                    theme="tomorrow"
                    value={alert?.outputRule ? JSON.stringify(alert?.outputRule, null, 2) : ''}
                    fontSize={14}
                    wrapEnabled
                    readOnly
                    showGutter={false}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography className={classes.ruleTitle} variant="h6">History</Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell align="center">Input Status</TableCell>
                      <TableCell align="center">Output Status</TableCell>
                      <TableCell align="center">Executed</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      histories.map((history) => (
                        <TableRow key={history.id}>
                          <TableCell>
                            {history.alertStatus ?
                              <CheckCircleIcon style={{ color:'green', }}/> : <ReportIcon style={{ color:'red', }}/>}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip placement={'top'} title={history.inputError ? history?.inputErrorMessage : 'Success'}>
                              {history.inputError ?
                                <Close color={'secondary'}/> : <Done style={{ color:'green', }}/>}
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip placement={'top'} title={history.outputError ? history?.outputErrorMessage : 'Success'}>
                              {history.outputError ?
                                <Close color={'secondary'}/> : <Done style={{ color:'green', }}/>}
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {moment(history.runTime).fromNow()}
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box textAlign='center' padding={3}>
          <h1>No Alert was found</h1>
        </Box>
      )}

    </Paper>
  );
};

export { AlertReport, };
