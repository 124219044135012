import React from 'react';
import {
  Rect,
  Text,
  Group,
} from 'react-konva';
import { Interfaces, } from '../../../../config';

type colorType = 'black' | 'default' | 'red' | 'green' | 'yellow';
interface Props {
  x: number;
  y: number;
  fields: Array<Interfaces.Schema>;
  table: Interfaces.Schema;
  sourceType?: string;
  detailed: boolean;
  columnHeight: number;
  // eslint-disable-next-line no-unused-vars
  handleSelect: (colIdx: number) => void;
  selectedFields: Array<Interfaces.FieldWithPosition>;
  textColor?: colorType;
  highLight?: boolean;
  opacity?: number;
  totalRecords?: number;
}
const colorMap: { [key: string]: string } = {
  default: '#034e8d',
  lightBlue: '#0774d0',
  black: '#000',
  yellow: '#cb712d',
  green: '#458d29',
  red: '#b63636',
};

const DBDiagramTable = ({
  x,
  y,
  table,
  fields,
  sourceType,
  detailed,
  columnHeight,
  handleSelect,
  selectedFields,
  highLight,
  opacity = 1,
  totalRecords,
}: Props) => {
  const yIndex = totalRecords ? 15 : 0;

  const TABLE_WIDTH = 200;
  return (
    <Group opacity={opacity}>
      <Rect
        width={TABLE_WIDTH}
        x={x}
        y={y + yIndex - 10}
        height={detailed ? fields.length * columnHeight + 38 : 35 }
        stroke={'#989797'}
        fill={'#ffffff'}
        cornerRadius={4}
      />
      <Rect
        width={TABLE_WIDTH}
        x={x}
        y={y - 10}
        height={35 + yIndex}
        fill={highLight ? table.isChanged ? colorMap.yellow : table.isRemoved ? colorMap.red : table.isNew ? colorMap.green : colorMap.default : sourceType === 'view' ? colorMap.lightBlue : colorMap.default}
        stroke={'#989797'}
        cornerRadius={4}
      />
      <Text
        height={35}
        y={y - 10}
        x={x}
        text={table.name}
        width={TABLE_WIDTH}
        fontSize={18}
        fill="white"
        verticalAlign="middle"
        align={'center'}
      />
      <Text
        height={35}
        y={y + yIndex - 5}
        x={x}
        text={`${totalRecords ? `Total Records: ${totalRecords}` : ''}`}
        width={TABLE_WIDTH}
        fontSize={12}
        fill="white"
        verticalAlign="middle"
        align={'center'}
      />
      {
        detailed && fields.map(((field, index) => (
          <Group key={index}>
            { index % 2 === 0 ? (
              <Rect
                width={TABLE_WIDTH - 2}
                height={columnHeight - 2}
                stroke={selectedFields.find((_field) => _field.properties.path === table.properties?.path)?.matchingIndex === index ? 'blue' : '#f1f1f1'}
                strokeWidth={2}
                fill={'#f1f1f1'}
                x={x + 1}
                y={y + (index + 1) * columnHeight + yIndex}
              />
            ) : (
              <Rect
                width={TABLE_WIDTH - 2}
                height={columnHeight - 2}
                stroke={selectedFields.find((_field) => _field.properties.path === table.properties?.path)?.matchingIndex === index ? 'blue' : 'white'}
                strokeWidth={2}
                fill={'white'}
                x={x + 1}
                y={y + (index + 1) * columnHeight + yIndex}
              />
            )
            }
            {field.isRemoved && (
              <Text
                padding={10}
                onClick={() => handleSelect(index)}
                x={x - 24}
                y={y + (index + 1) * columnHeight - 10 + yIndex}
                height={columnHeight}
                text={'-'}
                fontSize={22}
                fill={colorMap.red}
                verticalAlign="left"
                align={'left'}
              />
            )}
            {field.isNew && (
              <Text
                padding={10}
                onClick={() => handleSelect(index)}
                x={x - 26}
                y={y + (index + 1) * columnHeight - 8 + yIndex}
                height={columnHeight}
                text={'+'}
                fontSize={22}
                fill={colorMap.green}
                verticalAlign="left"
                align={'left'}
              />
            )}
            <Text
              padding={10}
              onClick={() => handleSelect(index)}
              x={x}
              y={y + (index + 1) * columnHeight + yIndex}
              height={columnHeight}
              text={field.name.length >= 13 ? `${field.name.slice(0,13)}...` : field.name}
              fontSize={15}
              fill={highLight ? field.isChanged ? colorMap.yellow : field.isRemoved ? colorMap.red : field.isNew ? colorMap.green : colorMap.default : colorMap.black}
              verticalAlign="middle"
              align={'left'}
            />
            <Text
              onClick={() => handleSelect(index)}
              padding={10}
              x={x}
              y={y + (index + 1) * columnHeight + yIndex}
              height={columnHeight}
              width={TABLE_WIDTH}
              text={`${field.type} ${field.properties?.foreignKeys?.length > 0 ? ' - FK' : ''}`}
              fontSize={12}
              fill="gray"
              verticalAlign="middle"
              align={'right'}
            />
          </Group>
        )))
      }
    </Group>
  );
};

export { DBDiagramTable, };
