import React, { useEffect, } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { makeStyles, } from '@mui/styles';
import { Theme, } from '@mui/material';
import {
  AboutUs,
  AddDataSources,
  Catalogs,
  Dashboard,
  DataSourceDashboard,
  EditDataSource,
  Editor,
  Loader,
  SchemaEvolution,
  Search,
  SearchDetails,
  SearchResults,
  TagsTable,
  TagsForm,
  DataTransfer,
  CatalogSelection,
  AdminPanel,
  GitConfigForm,
  Models,
  AddModel,
  Deployment,
  AddCatalog,
  ViewCatalog,
  AlertForm,
  Alerts,
  AlertReport,
  AlertHistories,
  Schedules,
  Sidebar,
  LoginWrapper,
  SuperAdmin,
  UserProfile,
} from './components';
import { Constants, } from './config';
import { ApiOffline, } from './components/ApiOffline';
import ReactGA from 'react-ga';
import { useAuth, } from './context';
import { axiosClient, } from './config/helpers';
import { AuthorizedHoc, } from './context/AuthContext';
import {
  SuccessfulRegistration,
  ResetPassword,
  ResendVerification,
} from './components/Register';
import TryNow from './components/TryNow';
import RosettaLogo from './assets/icons/cropped-databasee-removebg-preview.png';

ReactGA.initialize(Constants.gaTrackingId);

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    display: 'flex',
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '20px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  main: {
    padding: '10px 30px 30px 30px',
    marginTop: '64px',
    height: 'calc(-64px + 100vh)',
    overflowY: 'auto',
  },
}));

function App() {
  const classes = useStyles();
  const {
    user,
    loading,
    logout,
    health,
    loadingHealth,
    deploymentType,
  } = useAuth();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (deploymentType === 'cloud') {
      let link: any = document.querySelector('link[rel~=\'icon\']');
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = RosettaLogo;
      document.title = 'RosettaDB';
    }
  }, [deploymentType,]);

  if (loadingHealth || loading || !health.status) {
    return <Loader/>;
  }

  if (health.status === Constants.api.states.stopped) {
    return (
      <div>
        <ApiOffline/>
      </div>
    );
  }

  if (health.isAuthenticationEnabled && !user) {
    return (
      <div>
        <div className={classes.root}>
          <Router>
            <Routes>
              <Route path="/" element={<LoginWrapper />}/>
              <Route path={'/register-success/:id'} element={<SuccessfulRegistration />} />
              <Route path={'/password-reset/:token'} element={<ResetPassword />} />
              <Route path={'/resend-verification'} element={<ResendVerification />} />
              <Route path={'/try-now'} element={<TryNow />} />
              <Route element={<Navigate to={'/'} />}/>
            </Routes>
          </Router>
        </div>
      </div>
    );
  }

  axiosClient().interceptors.response.use((response) => {
    return response;
  }, async function (error) {
    if (error?.response?.status === 401) {
      logout();
    }
    return Promise.reject(error);
  });

  return (
    <div>
      <div className={classes.root}>
        <Router>
          <Sidebar />
          <main className={classes.content}>
            <div className={classes.main}>
              <Routes>
                <Route path="/" element={<Dashboard />}/>
                <Route
                  path="/sql"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SQLEXPL_READ',]}>
                      <Editor/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/data-sources/:dataSourceName/sql"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SQLEXPL_READ',]}>
                      <Editor/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/data-sources"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DS_READ',]}>
                      <DataSourceDashboard />
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/data-source/edit/:type/:oldName/:isJdbc?"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DS_WRITE', 'ADAPTIVE_DS_READ',]}>
                      <EditDataSource/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/data-source/:type"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DS_WRITE',]}>
                      <AddDataSources/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/catalog/edit/:catalogId/:tab?"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_DS_READ', 'ADAPTIVE_DS_EXECUTE', 'ADAPTIVE_CAT_WRITE',]}>
                      <AddCatalog/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/catalog/view/:catalogId/:external?"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_DS_READ',]}>
                      <ViewCatalog/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/catalog/edit/:catalogId"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_DS_READ', 'ADAPTIVE_DS_EXECUTE', 'ADAPTIVE_CAT_WRITE',]}>
                      <AddCatalog/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/catalog/add"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_DS_READ', 'ADAPTIVE_DS_EXECUTE', 'ADAPTIVE_CAT_WRITE',]}>
                      <AddCatalog/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/catalogs"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ',]}>
                      <Catalogs/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/tag/edit/:id"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_TAG_READ', 'ADAPTIVE_TAG_WRITE',]}>
                      <TagsForm/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/tag/add"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_TAG_READ', 'ADAPTIVE_TAG_WRITE',]}>
                      <TagsForm/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/tags"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_TAG_READ',]}>
                      <TagsTable/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/schedules"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SCHED_READ',]}>
                      <Schedules/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/search/details/:runId"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SEARCH_READ',]}>
                      <SearchDetails/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/search/:query/:page"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SEARCH_READ',]}>
                      <SearchResults/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/search"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SEARCH_READ',]}>
                      <Search/>
                    </AuthorizedHoc>
                  }
                />
                <Route path="/about" element={<AboutUs />}/>
                <Route
                  path="/admin"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_GIT_READ',]}>
                      <AdminPanel/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/data-transfer"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DT_READ',]}>
                      <DataTransfer/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/select-catalog"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_EV_READ',]}>
                      <CatalogSelection/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/schema-evolution/:catalogId?"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_EV_READ',]}>
                      <SchemaEvolution />
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/git-config/add"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_GIT_READ', 'ADAPTIVE_GIT_WRITE',]}>
                      <GitConfigForm/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/git-config/edit/:gitConfigId"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_GIT_READ', 'ADAPTIVE_GIT_WRITE',]}>
                      <GitConfigForm/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/models"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_COL_READ',]}>
                      <Models/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/models/add"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_COL_READ', 'ADAPTIVE_COL_WRITE', 'ADAPTIVE_DS_READ',]}>
                      <AddModel/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/models/edit/:id"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_COL_READ', 'ADAPTIVE_COL_WRITE', 'ADAPTIVE_DS_READ',]}>
                      <AddModel/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/models/:id/deployment"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DEP_READ', 'ADAPTIVE_COL_READ', 'ADAPTIVE_DS_READ',]}>
                      <Deployment/>
                    </AuthorizedHoc>
                  }
                />
                <Route path="/alerts/add/:id?" element={<AlertForm />} />
                <Route
                  path={'/alerts/report/:id'}
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ALERT_READ',]}>
                      <AlertReport/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/alerts"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ALERT_READ',]}>
                      <Alerts/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path="/alert/histories"
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ALERT_READ',]}>
                      <AlertHistories/>
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path={'/super/admin/:tab?'}
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ADMIN',]}>
                      <SuperAdmin />
                    </AuthorizedHoc>
                  }
                />
                <Route
                  path={'/profile/:id'}
                  element={
                    <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ADMIN',]}>
                      <UserProfile />
                    </AuthorizedHoc>
                  }
                />
                <Route element={<Navigate to={'/'} />}/>
              </Routes>
            </div>
          </main>
        </Router>
      </div>
    </div>
  );
}

export default App;
