import { CatalogScheduleJob, } from './interfaces';

const dataSourceTypeMap: Record<string, string> = {
  FILE: 'file',
  FTP: 'ftp',
  BIGQUERY: '',
  DATABASE: 'jdbc',
  JDBC: 'jdbc',
  GCS: 'gcs',
  S3: 's3',
  AZURE_BLOB: 'azure_blob',
  HDFS: 'hdfs',
};

export const initialSchedule: CatalogScheduleJob = {
  id:'',
  jobName: '',
  cronExpression: '0 0 00 1/1 * ? *',
  catalogId: '',
  isActive: true,
  startDate: null,
  endDate: null,
  nextRun: null,
  active: true,
};

export default {
  uiVersion: '2.1.1 Beta',
  actions: {
    dataSource: {
      SET_SUPPORTED_DRIVERS: 'SET_SUPPORTED_DRIVERS',
      BEGIN_FETCH_SUPPORTED_DRIVERS: 'BEGIN_FETCH_SUPPORTED_DRIVERS',
      FETCH_SUPPORTED_DRIVERS_FAILED: 'FETCH_SUPPORTED_DRIVERS_FAILED',
      BEGIN_FETCH_DATA_SOURCES: 'BEGIN_FETCH_DATA_SOURCES',
      SET_DATA_SOURCES: 'SET_DATA_SOURCES',
      FETCH_DATA_SOURCES_FAILED: 'FETCH_DATA_SOURCES_FAILED',
      SET_EDIT_DATA_SOURCE: 'SET_EDIT_DATA_SOURCE',
      BEGIN_CREATE_DATA_SOURCE: 'BEGIN_CREATE_DATA_SOURCE',
      END_CREATE_DATA_SOURCE: 'END_CREATE_DATA_SOURCE',
      BEGIN_EDIT_DATA_SOURCE: 'BEGIN_EDIT_DATA_SOURCE',
      END_EDIT_DATA_SOURCE: 'END_EDIT_DATA_SOURCE',
      BEGIN_TEST_DATA_SOURCE: 'BEGIN_TEST_DATA_SOURCE',
      TEST_DATA_SOURCE_SUCCESS: 'TEST_DATA_SOURCE_SUCCESS',
      TEST_DATA_SOURCE_FAILED: 'TEST_DATA_SOURCE_FAILED',
      CLEAR_TEST_DATA_SOURCE: 'CLEAR_TEST_DATA_SOURCE',
      BEGIN_DRIVER_UPLOAD: 'BEGIN_DRIVER_UPLOAD',
      DRIVER_UPLOAD_SUCCESS: 'DRIVER_UPLOAD_SUCCESS',
      DRIVER_UPLOAD_FAILED: 'DRIVER_UPLOAD_FAILED',
      FETCH_DATASOURCE_BEGIN: 'FETCH_DATASOURCE_BEGIN',
      FETCH_DATASOURCE_FAIL: 'FETCH_DATASOURCE_FAIL',
      FETCH_DIRECTORIES_BEGIN: 'FETCH_DIRECTORIES_BEGIN',
      FETCH_DIRECTORIES_SUCCESS: 'FETCH_DIRECTORIES_SUCCESS',
      FETCH_DIRECTORIES_FAIL: 'FETCH_DIRECTORIES_FAIL',
      FETCH_NESTED_DIRECTORIES_BEGIN: 'FETCH_NESTED_DIRECTORIES_BEGIN',
      FETCH_NESTED_DIRECTORIES_SUCCESS: 'FETCH_NESTED_DIRECTORIES_SUCCESS',
      FETCH_NESTED_DIRECTORIES_FAIL: 'FETCH_NESTED_DIRECTORIES_FAIL',
    },
    catalog: {
      SET_AUTO_SELECT_ALL_FIELDS: 'SET_AUTO_SELECT_ALL_FIELDS',
      SET_INCLUDE_ALL_SCHEMAS: 'SET_INCLUDE_ALL_SCHEMAS',
      SET_SELECTED_NODE_IN_SCHEMA: 'SET_SELECTED_NODE_IN_SCHEMA',
      SET_LABELS_IN_SCHEMA: 'SET_LABELS_IN_SCHEMA',
      SET_METADATA: 'SET_METADATA',
      ADD_TAG: 'ADD_TAG',
      REMOVE_TAG: 'REMOVE_TAG',
      ADD_LABEL: 'ADD_LABEL',
      DELETE_LABEL: 'DELETE_LABEL',
      CHANGE_STEP: 'CHANGE_STEP',
      SET_NEW_CATALOG: 'SET_NEW_CATALOG',
      FETCH_CATALOGS_BEGIN: 'FETCH_CATALOGS_BEGIN',
      FETCH_CATALOGS_SUCCESS: 'FETCH_CATALOGS_SUCCESS',
      FETCH_CATALOGS_FAIL: 'FETCH_CATALOGS_FAIL',
      FETCH_CATALOG_BY_ID_BEGIN: 'FETCH_CATALOG_BY_ID_BEGIN',
      FETCH_CATALOG_BY_ID_SUCCESS: 'FETCH_CATALOG_BY_ID_SUCCESS',
      FETCH_CATALOG_BY_ID_FAIL: 'FETCH_CATALOG_BY_ID_FAIL',
      CLEAR_ERROR: 'CLEAR_ERROR',
      SAVE_CATALOG_BEGIN: 'SAVE_CATALOG_BEGIN',
      SAVE_CATALOG_SUCCESS: 'SAVE_CATALOG_SUCCESS',
      SAVE_CATALOG_FAILED: 'SAVE_CATALOG_FAILED',
      SET_SELECTED_DATA_SOURCE: 'SET_SELECTED_DATA_SOURCE',
      FETCH_SCHEMA_BEGIN: 'FETCH_SCHEMA_BEGIN',
      FETCH_SCHEMA_SUCCESS: 'FETCH_SCHEMA_SUCCESS',
      FETCH_SCHEMA_FAILED: 'FETCH_SCHEMA_FAILED',
      SET_EDIT_CATALOG: 'SET_EDIT_CATALOG',
      PREVIEW_CATALOG_BEGIN: 'PREVIEW_CATALOG_BEGIN',
      PREVIEW_CATALOG_FAILED: 'PREVIEW_CATALOG_FAILED',
      PREVIEW_CATALOG_SUCCESS: 'PREVIEW_CATALOG_SUCCESS',
      CLEAR_PREVIEW_CATALOG: 'CLEAR_PREVIEW_CATALOG',
      CLEAR_CATALOG_EDITING: 'CLEAR_CATALOG_EDITING',
      DELETE_CATALOGS_BEGIN: 'DELETE_CATALOGS_BEGIN',
      DELETE_CATALOGS_SUCCESS: 'DELETE_CATALOGS_SUCCESS',
      DELETE_CATALOGS_FAIL: 'DELETE_CATALOGS_FAIL',
      RUN_CATALOG_BEGIN: 'RUN_CATALOG_BEGIN',
      RUN_CATALOG_SUCCESS: 'RUN_CATALOG_SUCCESS',
      RUN_CATALOG_FAIL: 'RUN_CATALOG_FAIL',
      FILTER_CATALOGS: 'FILTER_CATALOGS',
      FETCH_TOTAL_RECORDS_BEGIN: 'FETCH_TOTAL_RECORDS_BEGIN',
      FETCH_TOTAL_RECORDS_SUCCESS: 'FETCH_TOTAL_RECORDS_SUCCESS',
      FETCH_TOTAL_RECORDS_FAIL: 'FETCH_TOTAL_RECORDS_FAIL',
      DSL_PREVIEW_BEGIN: 'DSL_PREVIEW_BEGIN',
      DSL_PREVIEW_SUCCESS: 'DSL_PREVIEW_SUCCESS',
      DSL_PREVIEW_FAIL: 'DSL_PREVIEW_FAIL',
      FETCH_RUN_HISTORY: 'FETCH_RUN_HISTORY',
      FETCH_RUN_HISTORY_SUCCESS: 'FETCH_RUN_HISTORY_SUCCESS',
      FETCH_RUN_HISTORY_FAIL: 'FETCH_RUN_HISTORY_FAIL',
    },
    tags: {
      CLEAR_ERROR: 'CLEAR_ERROR',
      FETCH_TAGS_BEGIN: 'FETCH_TAGS_BEGIN',
      FETCH_TAGS_SUCCESS: 'FETCH_TAGS_SUCCESS',
      FETCH_TAGS_FAILURE: 'FETCH_TAGS_FAILURE',
      FETCH_TAG_BY_ID_BEGIN: 'FETCH_TAG_BY_ID_BEGIN',
      FETCH_TAG_BY_ID_SUCCESS: 'FETCH_TAG_BY_ID_SUCCESS',
      FETCH_TAG_BY_ID_FAIL: 'FETCH_TAG_BY_ID_FAIL',
      ADD_TAG_BEGIN: 'ADD_TAG_BEGIN',
      ADD_TAG_SUCCESS: 'ADD_TAG_SUCCESS',
      ADD_TAG_FAILED: 'ADD_TAG_FAILED',
      ADD_TAG_FROM_CSV_BEGIN: 'ADD_TAG_FROM_CSV_BEGIN',
      ADD_TAG_FROM_CSV_SUCCESS: 'ADD_TAG_FROM_CSV_SUCCESS',
      ADD_TAG_FROM_CSV_FAILED: 'ADD_TAG_FROM_CSV_FAILED',
      DELETE_TAG_BEGIN: 'DELETE_TAG_BEGIN',
      DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
      DELETE_TAG_FAILED: 'DELETE_TAG_FAILED',
      ACTIVATE_BUSINESS_TAG_BEGIN: 'ACTIVATE_BUSINESS_TAG_BEGIN',
      ACTIVATE_BUSINESS_TAG_SUCCESS: 'ACTIVATE_BUSINESS_TAG_SUCCESS',
      ACTIVATE_BUSINESS_TAG_FAIL: 'ACTIVATE_BUSINESS_TAG_FAIL',
      DEACTIVATE_BUSINESS_TAG_BEGIN: 'DEACTIVATE_BUSINESS_TAG_BEGIN',
      DEACTIVATE_BUSINESS_TAG_SUCCESS: 'DEACTIVATE_BUSINESS_TAG_SUCCESS',
      DEACTIVATE_BUSINESS_TAG_FAIL: 'DEACTIVATE_BUSINESS_TAG_FAIL',
      BUSINESS_TAG_SET_ATTRIBUTE: 'BUSINESS_TAG_SET_ATTRIBUTE',
      SET_CHECKED_TAGS: 'SET_CHECKED_TAGS',
      FILTER_TAGS: 'FILTER_TAGS',
      SET_SHOW_ACTIVE_TAGS: 'SET_SHOW_ACTIVE_TAGS',
      ADD_MATCHING_PATTERNS: 'ADD_MATCHING_PATTERNS',
      DELETE_MATCHING_PATTERNS: 'DELETE_MATCHING_PATTERNS',
      SET_DATA_TO_EDIT: 'SET_DATA_TO_EDIT',
      FETCH_TAG_RULES_BEGIN: 'FETCH_TAG_RULES_BEGIN',
      FETCH_TAG_RULES_SUCCESS: 'FETCH_TAG_RULES_SUCCESS',
      FETCH_TAG_RULES_FAIL: 'FETCH_TAG_RULES_FAIL',
      FETCH_TEMPLATES_BEGIN: 'FETCH_TEMPLATES_BEGIN',
      FETCH_TEMPLATES_SUCCESS: 'FETCH_TEMPLATES_SUCCESS',
      FETCH_TEMPLATES_FAIL: 'FETCH_TEMPLATES_FAIL',
    },
    data: {
      BEGIN_EXECUTE_QUERY: 'BEGIN_EXECUTE_QUERY',
      EXECUTE_QUERY_SUCCESS: 'EXECUTE_QUERY_SUCCESS',
      EXECUTE_QUERY_FAIL: 'EXECUTE_QUERY_FAIL',
      EXECUTE_QUERY_CLEAR: 'EXECUTE_QUERY_CLEAR',
      BEGIN_FETCH_ACTIVE_SCHEMA: 'BEGIN_FETCH_ACTIVE_SCHEMA',
      SET_ACTIVE_SCHEMA: 'SET_ACTIVE_SCHEMA',
      FETCH_SAMPLE_DATA_BEGIN: 'FETCH_SAMPLE_DATA_',
      FETCH_SAMPLE_DATA_SUCCESS: 'FETCH_SAMPLE_DATA_SUCCESS',
      FETCH_SAMPLE_DATA_FAIL: 'FETCH_SAMPLE_DATA_FAIL',
      FETCH_ACTIVE_SCHEMA_FAILED: 'FETCH_ACTIVE_SCHEMA_FAILED',
      SET_ACTIVE_DATA_SOURCE: 'SET_ACTIVE_DATA_SOURCE',
      SET_ACTIVE_TABLE: 'SET_ACTIVE_TABLE',
    },
    helpers: {
      FETCH_SCHEMA_TYPES_SUCCESS: 'FETCH_SCHEMA_TYPES_SUCCESS',
      SHOW_TIMELINE: 'SHOW_TIMELINE',
      SET_API_IS_ONLINE: 'SET_API_IS_ONLINE',
      SET_API_IS_OFFLINE: 'SET_API_IS_OFFLINE',
    },
    runDetails: {
      FETCH_RUN_DETAILS_BEGIN: 'FETCH_RUN_DETAILS_BEGIN',
      FETCH_RUN_DETAILS_FAIL: 'FETCH_RUN_DETAILS_FAIL',
      FETCH_RUN_DETAILS_SUCCESS: 'FETCH_RUN_DETAILS_SUCCESS',
      FETCH_RUN_TIMELINE_BEGIN: 'FETCH_RUN_TIMELINE_BEGIN',
      FETCH_RUN_TIMELINE_SUCCESS: 'FETCH_RUN_TIMELINE_SUCCESS',
      FETCH_RUN_TIMELINE_FAILED: 'FETCH_RUN_TIMELINE_FAILED',
      SET_METADATA: 'SET_METADATA',
      SET_ACTIVE_RUN_RECORD: 'SET_ACTIVE_RUN_RECORD',
      TIMELINE_GOTO_NEXT_PAGE: 'TIMELINE_GOTO_NEXT_PAGE',
      TIMELINE_GOTO_PREVIOUS_PAGE: 'TIMELINE_GOTO_PREVIOUS_PAGE',
      CLEAR_STATE: 'CLEAR_STATE',
    },
    schedules: {
      CLEAR_ERROR: 'CLEAR_ERROR',
      FETCH_SCHEDULES_BEGIN: 'FETCH_SCHEDULES_BEGIN',
      FETCH_SCHEDULES_SUCCESS: 'FETCH_SCHEDULES_SUCCESS',
      FETCH_SCHEDULES_FAIL: 'FETCH_SCHEDULES_FAIL',
      DELETE_SCHEDULE_BEGIN: 'DELETE_SCHEDULE_BEGIN',
      DELETE_SCHEDULE_SUCCESS: 'DELETE_SCHEDULE_SUCCESS',
      DELETE_SCHEDULE_FAIL: 'DELETE_SCHEDULE_FAIL',
      DEACTIVATE_SCHEDULE_BEGIN: 'DEACTIVATE_SCHEDULE_BEGIN',
      DEACTIVATE_SCHEDULE_SUCCESS: 'DEACTIVATE_SCHEDULE_SUCCESS',
      DEACTIVATE_SCHEDULE_FAIL: 'DEACTIVATE_SCHEDULE_FAIL',
      ACTIVATE_SCHEDULE_BEGIN: 'ACTIVATE_SCHEDULE_BEGIN',
      ACTIVATE_SCHEDULE_SUCCESS: 'ACTIVATE_SCHEDULE_SUCCESS',
      ACTIVATE_SCHEDULE_FAIL: 'ACTIVATE_SCHEDULE_FAIL',
      FILTER_SCHEDULES: 'FILTER_SCHEDULES',
      SEARCH_SCHEDULES: 'SEARCH_SCHEDULES',
      SET_SHOW_ACTIVE_SCHEDULES: 'SET_SHOW_ACTIVE_SCHEDULES',
      SET_CHECKED_SCHEDULES: 'SET_CHECKED_SCHEDULES',
    },
    schemaEvolution: {
      SET_LATEST_SCHEMA: 'SET_LATEST_SCHEMA',
      FETCH_LATEST_SCHEMA_BEGIN: 'FETCH_LATEST_SCHEMA_BEGIN',
      FETCH_LATEST_SCHEMA_FAILED: 'FETCH_LATEST_SCHEMA_FAILED',
      CLEAR_EVOLUTION_DATA: 'CLEAR_EVOLUTION_DATA',
      SET_OLD_SCHEMA: 'SET_OLD_SCHEMA',
    },
    search: {
      FETCH_METADATA_SEARCH_RESULTS_BEGIN: 'FETCH_METADATA_SEARCH_RESULTS_BEGIN',
      FETCH_METADATA_SEARCH_RESULTS_FAILURE: 'FETCH_METADATA_SEARCH_RESULTS_FAILURE',
      FETCH_METADATA_SEARCH_RESULTS_SUCCESS: 'FETCH_METADATA_SEARCH_RESULTS_SUCCESS',
      CLEAR_SEARCH_STATE: 'CLEAR_SEARCH_STATE',
      FILTER_RESULTS: 'FILTER_RESULTS',
      CHANGE_PAGE: 'CHANGE_PAGE',
    },
    jobs: {
      FETCH_JOBS_BEGIN: 'FETCH_JOBS_BEGIN',
      FETCH_JOBS_SUCCESS: 'FETCH_JOBS_SUCCESS',
      FETCH_JOBS_FAILED: 'FETCH_JOBS_FAILED',
      RUN_JOB_BEGIN: 'RUN_JOB_BEGIN',
      RUN_JOB_SUCCESS: 'RUN_JOB_SUCCESS',
      RUN_JOB_FAILED: 'RUN_JOB_FAILED',
      RUN_JOB_END: 'RUN_JOB_END',
    },
    authentication: {
      BEGIN_AUTHENTICATION: 'BEGIN_AUTHENTICATION',
      AUTHENTICATION_SUCCESS: 'AUTHENTICATION_SUCCESS',
      AUTHENTICATION_FAIL: 'AUTHENTICATION_FAIL',
      LOGOUT: 'LOGOUT',
    },
    health: {
      BEGIN_FETCH_API_STATUS: 'BEGIN_FETCH_API_STATUS',
      FETCH_API_STATUS_SUCCESS: 'FETCH_API_STATUS_SUCCESS',
      FETCH_API_STATUS_FAIL: 'FETCH_API_STATUS_FAIL',
    },
  },
  api: {
    endpoints: {
      supportedDrivers: 'data-source/jdbc/drivers',
      searchSnapshots: 'search/snapshots',
      dataSources: 'data-sources/',
      dataSource: 'data-source/',
      catalogs: 'catalogs/',
      catalogsWithDs: 'catalogs-with-ds/',
      catalog: 'catalog/',
      search: 'search',
      driver: 'driver',
      tags: 'tags',
      backup: 'backup',
      tagCsv: 'tag/csv',
      tagRules: 'tag/static-rules',
      scheduler: 'scheduler',
      data: 'data/',
      schemaTypes: 'data-source/schema-types',
      jobs: 'jobs',
      dataTransfer: 'data/transfer',
      tagTemplates: 'templates',
      status: 'status',
      gcsDirectories: 'data-source/gcs/dir',
      s3Directories: 'data-source/s3/dir',
      azureDirectories: 'data-source/azure_blob/dir',
      ftpDirectories: 'data-source/ftp/dir',
      hdfsDirectories: 'data-source/hdfs/dir',
      externalRepos: 'external-repos/',
      externalRepo: 'external-repo/',
      collections: 'collections/',
      collection: 'collection/',
      deployments: 'deployments/',
      alerts: 'alerts/',
      alertHistories: 'alert-histories/',
      alertHistory: 'alert-history/',
      dashboardStats: 'dashboard/stats',
      register: 'signup',
      resendVerification: 'auth/resend_verification',
      resetPassword : 'auth/reset',
      requestReset : 'auth/request_reset',
      users: 'users',
      stats: 'stats',
    },
    states: {
      stopped: 'STOPPED',
      running: 'RUNNING',
    },
    longPolling: {
      dataTransfer: {
        interval: 15000,
      },
    },
  },
  path: {
    dataSourceTypeMap: dataSourceTypeMap,
  },
  queryFields: {
    catalog: 'id name dataSourceName dataSourceId dataSourceDescription includeAllSchemas schema schedule lastRun lastRunId',
    tags: 'collection createdAt description id isActive matchingPatterns name updatedAt rules',
    scheduler: 'id dataSourceDescription dataSourceName includeAllSchemas lastRun lastRunId name schedule schema',
  },
  states: {
    initialSchedule,
  },
  gaTrackingId: 'UA-106524273-1',
  documentationUrl: 'http://docs.adaptivescale.com',
  rosettaDocumentationURL: 'http://docs.rosettadb.io',
};

