import { axiosClient, } from '../config/helpers';
import { Constants, } from '../config';
import * as interfaces from '../config/interfaces';

export const gitConfigServices = {
  fetchGitConfigs: fetchGitConfigs,
  fetchGitConfigById: fetchGitConfigById,
  deleteGitConfig: deleteGitConfig,
  addGitConfig: addGitConfig,
  updateGitConfig: updateGitConfig,
  versionSnapshot: versionSnapshot,
  getTypes: getTypes,
  testExistingConfig: testExistingConfig,
  testNewConfig: testNewConfig,
};

function fetchGitConfigs() {
  return axiosClient().get(Constants.api.endpoints.externalRepos);
}

function fetchGitConfigById(externalRepoId: string) {
  return axiosClient().get(`${Constants.api.endpoints.externalRepos}${externalRepoId}`);
}

function deleteGitConfig(externalRepoId: string) {
  return axiosClient().delete(`${Constants.api.endpoints.externalRepos}${externalRepoId}`);
}

function  addGitConfig(body: interfaces.ExternalRepo) {
  return axiosClient().post(Constants.api.endpoints.externalRepo, body);
}

function updateGitConfig(body: interfaces.ExternalRepo, externalRepoId: string) {
  return axiosClient().patch(`${Constants.api.endpoints.externalRepos}${externalRepoId}`, body);
}

function versionSnapshot(catalogId: string, externalRepoId: string) {
  return axiosClient().post(`${Constants.api.endpoints.externalRepo}version/${catalogId}/${externalRepoId}`);
}

function getTypes() {
  return axiosClient().get(`${Constants.api.endpoints.externalRepo}types`);
}

function testExistingConfig(configId: string, body: interfaces.ExternalRepo) {
  return axiosClient().post(`${Constants.api.endpoints.externalRepos}${configId}/test`, body);
}

function testNewConfig(body: interfaces.ExternalRepo) {
  return axiosClient().post(`${Constants.api.endpoints.externalRepo}test`, body);
}
