import React from 'react';
import { makeStyles, } from '@mui/styles';
import {
  MenuItem,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import { useNavigate, } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  menuItem: {
    width: 350,
    margin: '0',
    padding: '0 20px',
    cursor: 'auto',
    '&:hover': {
      backgroundColor: '#f3f3f4',
    },
  },
  alertCursor: {
    cursor: 'pointer',
  },
  alertName: {
    fontWeight: 'bold',
    maxWidth: '210px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  relationType: {
    fontSize: '12px',
    color: '#6b6b6b',
  },
  ruleText: {
    color: '#6b6b6b',
  },
  toggleButton: {
    alignSelf: 'center',
    marginLeft: '15px',
    cursor: 'pointer',
  },
}));

const Card = ({
  alertHistory,
  alertRead,
  alertUnread,
  setNotificationsEl,
  read,
}: {
  alertHistory: any;
  // eslint-disable-next-line no-unused-vars
  alertRead: (alertHistoryId: string) => void;
  // eslint-disable-next-line no-unused-vars
  alertUnread: (alertHistoryId: string) => void;
  setNotificationsEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  read: boolean;
}) => {
  const classes = useStyles();
  const history = useNavigate();

  return (
    <>
      <MenuItem
        className={classes.menuItem}
      >
        <Box
          display="flex"
          width="100%"
          style={{
            padding: '10px 5px 10px ',
            marginLeft: 0,
            borderRadius: 4,
          }}
        >
          <Box width="100%">
            <Box
              className={classes.alertCursor}
              onClick={() => {
                history(`/alerts/report/${alertHistory.alertId}`);
                alertRead(alertHistory.id);
                setNotificationsEl(null);
              }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1px">
                <Typography className={classes.alertName}>{alertHistory.alertName}</Typography>
                <Typography variant='body2' className={classes.relationType}>{moment(alertHistory.runTime).fromNow()}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant='body2'>Input rule</Typography>
                <Typography variant='body2' className={classes.ruleText}>{alertHistory.alertInputRule.name}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1px">
                <Typography variant='body2'>Output rule</Typography>
                <Typography variant='body2' className={classes.ruleText}>{alertHistory.alertOutputRule.name}</Typography>
              </Box>
            </Box>

          </Box>
          <Box
            onClick={read ? () => alertUnread(alertHistory.id) : () => alertRead(alertHistory.id)}
            className={classes.toggleButton}>
            <Tooltip title={read ? 'Mark as unread' :'Mark as read'} placement="left-start">
              <FiberManualRecordIcon fontSize='small' color={read ? 'disabled' :'primary'} />
            </Tooltip>
          </Box>

        </Box>

      </MenuItem>
    </>
  );
};

export { Card, };
