import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const RunHistoryTableHead = (props: any) => {
  const headCells = [
    {
      id: 'catalogId',
      numeric: true,
      disablePadding: false,
      label: 'Catalog ID',
    },
    {
      id: 'catalogName',
      numeric: false,
      disablePadding: false,
      label: 'Catalog Name',
    },
    {
      id: 'dataSourceName',
      numeric: false,
      disablePadding: false,
      label: 'Data Source',
    },
    {
      id: 'runId',
      numeric: false,
      disablePadding: false,
      label: 'Run ID',
    },
    {
      id: 'createdDate',
      numeric: true,
      disablePadding: false,
      label: 'Date Created',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Typography variant="subtitle2" align="center">
            #
          </Typography>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              props.orderBy === headCell.id ? props.order : false
            }
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { RunHistoryTableHead, };
