import React from 'react';
import TreeItem, { TreeItemProps, } from '@mui/lab/TreeItem';
import {
  createStyles,
  withStyles,
} from '@mui/styles';

export const StyledSchemaItem = withStyles(() =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 26,
    },
  })
)((props: TreeItemProps) => (
  <TreeItem {...props}  />
));
