import React from 'react';
import Style from './style';
import logo from './logo';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { useNavigate, } from 'react-router-dom';

interface ItemDetails {
  img: string;
  name: string;
  route: string;
}

interface Props {
  itemDetails: ItemDetails;
}

function CardView(props: Props) {
  const classes = Style();
  const navigate = useNavigate();
  const { itemDetails, } = props;

  return (
    <Grid xs={12} md={12} item className={classes.control}>
      <Card className={classes.card} onClick={() => itemDetails.route !== '' && navigate(itemDetails.route)}>
        <CardActionArea>
          <Box className={classes.boxContent}>
            {itemDetails.img !== '' && (
              <img
                alt={'Data source icon'}
                className={
                  (itemDetails.img === 'file' ? (
                    classes.fileMedia
                  ) : (
                    classes.media
                  ))
                }
                src={(logo as any).images[`${itemDetails.img}`]}
              />
            )}
          </Box>
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {itemDetails.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default CardView;
