import {
  axiosClient,
  Query,
} from '../config/helpers';
import { Constants, } from '../config';

export const searchServices = {
  doSearch,
};

function doSearch(body: Query) {
  return axiosClient().post(Constants.api.endpoints.search, body);
}
