import React from 'react';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import {
  faTable,
  faColumns,
  faProjectDiagram,
  faBorderNone,
  faDatabase,
} from '@fortawesome/free-solid-svg-icons';

// Sizes accepted: 2xs, xs, sm, lg, xl, 2xl

const getIconType = (type: string) => {
  switch (type) {
  case 'database':
    return faDatabase;
  case 'schema':
    return faProjectDiagram;
  case 'table':
    return faTable;
  case 'view':
    return faBorderNone;
  case 'column':
    return faColumns;
  default:
    return faColumns;
  }
};

export const Icon = ({ type, color, size = 'md', ...rest }: any) => (
  <FontAwesomeIcon icon={getIconType(type)} color={color} size={size} {...rest} />
);
