import React, { useEffect, } from 'react';
import { useStyles, } from './styles';
import AceEditor from 'react-ace';
import { Interfaces, } from '../../../../config';


const SQLCheck = ({
  alert,
  setAlert,
  handleValidation,
}: {
  alert: Interfaces.Alert;
  // eslint-disable-next-line no-unused-vars
  setAlert: (alert: Interfaces.Alert) => void;
  // eslint-disable-next-line no-unused-vars
  handleValidation: (value: boolean) => void;
}) => {
  const classes = useStyles();

  useEffect(() => {
    const query = alert.inputRule.rule['query'];
    handleValidation(query && query !== '');
  }, [alert.inputRule,]);

  return (
    <div className={classes.containerWidth}>
      <div
        className={classes.marginBottom}
      >
        <AceEditor
          placeholder="The SQL input rule will be fulfilled if the query returns any row."
          mode="sql"
          width="auto"
          fontSize={18}
          height="200px"
          enableBasicAutocompletion={true}
          enableLiveAutocompletion={true}
          value={alert.inputRule.rule['query']}
          onChange={(value) => {
            setAlert({
              ...alert,
              inputRule: {
                ...alert.inputRule,
                rule: {
                  ...alert.inputRule.rule,
                  ['query']: value,
                },
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export { SQLCheck, };
