import React, {
  useEffect,
  useState,
} from 'react';
import { SchedulesTable, } from './SchedulesTable';
import { NoDataComponent, } from '../NoDataComponent';
import { helpers, } from '../../utils';
import { Interfaces, } from '../../config';
import { useSnackbar, } from 'notistack';
import { scheduleServices, } from '../../services';
import LoadingComponent from '../Loading';
import { AxiosResponse, } from 'axios';

function Schedules() {
  const { enqueueSnackbar, } = useSnackbar();
  const [schedules, setSchedules,] = useState<Array<Interfaces.CatalogScheduleJob>>([]);
  const [loadingSchedules, setLoadingSchedules,] = useState<boolean>(false);
  const [filteredSchedules, setFilteredSchedules,] = useState<Array<Interfaces.CatalogScheduleJob>>([]);
  const navigationData = {
    component: 'schedules',
    header: 'No Schedules',
    text1: 'You have not created a schedule yet',
    text2: 'Create a schedule to get started',
    buttonText: 'Add schedule',
    route: '/select-catalog',
  };

  const fetchSchedules = () => {
    setLoadingSchedules(true);
    scheduleServices.fetchSchedules()
      .then((response: AxiosResponse<Array<Interfaces.CatalogScheduleJob>>) => {
        const sortedSchedules = response.data.sort((a, b) => {
          if (b.inputCatalogMetadata) {
            return a.inputCatalogMetadata?.name.localeCompare(b.inputCatalogMetadata?.name) || 0;
          } else {
            return a.id.localeCompare(b.id) || 0;
          }
        });
        setSchedules(sortedSchedules);
        setFilteredSchedules(sortedSchedules);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingSchedules(false);
      });
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  if (loadingSchedules && schedules.length === 0) {
    return <LoadingComponent message={'Loading schedules'}/>;
  }

  return (
    <div>
      {schedules.length > 0 ? (
        <SchedulesTable
          schedules={schedules}
          loadingSchedules={loadingSchedules}
          filteredSchedules={filteredSchedules}
          setFilteredSchedules={setFilteredSchedules}
          fetchSchedules={fetchSchedules}
        />
      ) : (
        <NoDataComponent data={navigationData}/>
      )}
    </div>
  );
}

export default Schedules;
