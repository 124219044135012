import React from 'react';
import {
  TableSortLabel,
  Typography,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';


const EnhancedTableHead = (props: any) => {
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'collection',
      numeric: false,
      disablePadding: false,
      label: 'Model',
    },
    { id: 'matchingRules',
      numeric: false,
      disablePadding: false,
      label: 'Matching Rules', },
    { id: 'rules',
      numeric: false,
      disablePadding: false,
      label: 'Rules', },
    { id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Created On', },
    { id: 'updatedAt',
      numeric: false,
      disablePadding: false,
      label: 'Updated On', },
    { id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions', },
  ];

  const createSortHandler = (property: any) => (event: any) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Typography variant="subtitle2" align="center">#</Typography>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              props.orderBy === headCell.id ? props.order : false
            }
            id={headCell.id}
          >
            <TableSortLabel
              active={props.orderBy === headCell.id}
              direction={props.orderBy === headCell.id ? props.order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {props.orderBy === headCell.id ? (
                <span className={props.classes.visuallyHidden}>
                  {props.order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { EnhancedTableHead, };
