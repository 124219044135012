import axios from 'axios';
import { axiosClient, } from '../config/helpers';
import {
  Constants,
  Properties,
} from '../config';

export const helpersServices = {
  fetchApiState,
  fetchApiStatus,
  fetchSupportedDrivers,
  fetchDataSources,
  fetchCatalogs,
  fetchSchemaTypes,
};

function fetchSupportedDrivers() {
  return axiosClient().get(Constants.api.endpoints.supportedDrivers);
}
function fetchDataSources() {
  return axiosClient().get(Constants.api.endpoints.dataSources);
}
function fetchCatalogs() {
  return axiosClient().get(Constants.api.endpoints.catalogs);
}
function fetchSchemaTypes() {
  return axiosClient().get(Constants.api.endpoints.schemaTypes);
}

function fetchApiState() {
  return axios.all([
    fetchSupportedDrivers(),
    fetchDataSources(),
    fetchCatalogs(),
    fetchSchemaTypes(),
  ]);
}

function fetchApiStatus() {
  return axiosClient().get(`${Properties.NEW_API_URL}status`);
}
