import { makeStyles, } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  radio: {
    '&$checked': {
      color: '#3f51b5',
    },
  },
  checked: {},
  test: {
    '&$focused': {
      color: '#767676',
    },
  },
  wpapper: {
    width: '800px',
    marginTop: '20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '20px',
  },
  focused: {},
  title: {
    marginBottom: '10px',
    paddingLeft: '5px',
    color: 'black',
    fontWeight: 500,
  },
  button:{
    float: 'left',
  },
  marginRight: {
    marginRight: '20px',
  },
  btn: {
    marginLeft: '20px',
    width: '130px',
  },
}));

