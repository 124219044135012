import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useStyles, } from './styles';
import { Interfaces, } from '../../../config';

const Header = ({
  catalogs,
  handleSelect,
  selectedCatalog,
}:{
  catalogs: Array<Interfaces.InputCatalogMetadata>;
  // eslint-disable-next-line no-unused-vars
  handleSelect: (catalogId: string) => void;
  selectedCatalog: string | null
}) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent={'flex-start'} className={classes.headerContainer}>
      <Grid item xs={1} className={classes.headerName}>
        <Typography variant={'h6'}>
          Catalog
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <FormControl
          size={'small'}
          className={classes.formControl}
        >
          <InputLabel id="demo-simple-select-label">
            Select a catalog
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            variant={'outlined'}
            label={'Select a catalog'}
            onChange={(e) => handleSelect(e.target?.value as string)}
            value={selectedCatalog || ''}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {
              catalogs.map((catalog) => (
                <MenuItem key={catalog.id} value={catalog.id}>{catalog.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export {
  Header,
};
