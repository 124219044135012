import { Theme, } from '@mui/material';
import { makeStyles, } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    minHeight: 750,
  },
  card: {
    minWidth: 350,
    maxWidth: 350,
    minHeight:150,
    margin: 5,
    border: '1px solid #dadada',
  },
  root1: {
    paddingLeft: theme.spacing(5),
  },
  table: {
    minWidth: 950,
    marginTop: theme.spacing(2),
  },
  history: {
    marginBottom: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    paddingTop: '30px',
    fontWeight: 550,
  },
  pos: {
    marginBottom: 12,
  },
  alertInputRule: {
    marginBottom: theme.spacing(1),
    width: '70%',
  },
  alertOutputRule: {
    marginBottom: theme.spacing(1),
    marginTop: '30px',
    width: '70%',
  },
  ruleTitle: {
    marginTop: '30px',
    fontWeight: 'bold',
  },
  ruleValue: {
    color: '#868686FF',
  },
}));

export default useStyles;
