import { ReactComponent as UserGuideIcon, } from '../../assets/icons/userGuide.svg';
import { ReactComponent as TutorialIcon, } from '../../assets/icons/tutorial.svg';
import { ReactComponent as DocumentationIcon, } from '../../assets/icons/documentation.svg';
import { ReactComponent as SlackIcon, } from '../../assets/icons/logo-slack.svg';

export const cards = [
  {
    title: 'User Guide',
    Logo: UserGuideIcon,
    uri: null,
    description:
      'Hands on guides for developers, business analysts and data analysts to get started with AdaptiveScale',
  },
  {
    title: 'Tutorial',
    Logo: TutorialIcon,
    uri: 'https://www.youtube.com/@adaptivescale',
    description:
      'Step-by-step instructions for solving common use-cases using AdaptiveScale',
  },
  {
    title: 'Documentation',
    Logo: DocumentationIcon,
    uri: 'https://docs.rosettadb.io',
    description:
      'Detailed technical documentation for using, extending and administering AdaptiveScale',
  },
];

export const rosettaCards = [
  {
    title: 'Documentation',
    Logo: DocumentationIcon,
    uri: 'https://docsrosetta.netlify.app/',
    description:
      'Detailed technical documentation for using, extending and administering RosettaDB',
  },
  {
    title: 'Tutorial',
    Logo: TutorialIcon,
    uri: 'https://www.youtube.com/@adaptivescale',
    description:
      'Hands on guides for developers, business analysts and data analysts to get started with RosettaDB',
  },
  {
    title: 'Slack',
    Logo: SlackIcon,
    uri: 'https://join.slack.com/t/rosettadb/shared_invite/zt-1fq6ajsl3-h8FOI7oJX3T4eI1HjcpPbw',
    description:
      'Join RosettaDB Slack channel',
  },
];
