import React from 'react';
import {
  Switch,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  Delete,
  Description,
  Edit,
} from '@mui/icons-material';
import { alertsServices, } from '../../../services';
import { Interfaces, } from '../../../config';
import { useSnackbar, } from 'notistack';
import { useNavigate, } from 'react-router-dom';
import { useStyles, } from './styles';
import {
  DropDownMenu,
  MenuItemType,
} from '../../Reusable/DropDownMenu';

interface Props {
  index: number;
  alert: Interfaces.Alert;
  readAccess: boolean;
  writeAccess: boolean;
  readDSAccess: boolean;
  readCATAccess: boolean;
  adminAccess: boolean;
  // eslint-disable-next-line no-unused-vars
  handleDelete: (alert: Interfaces.Alert) => void;
}

const AlertsTableRow = ({
  index,
  alert,
  readAccess,
  writeAccess,
  readDSAccess,
  readCATAccess,
  adminAccess,
  handleDelete,
}: Props) => {
  const { enqueueSnackbar, } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isActive, setIsActive,] = React.useState<boolean>(alert.active);

  const menuItems: MenuItemType[] = React.useMemo(() => {
    const items: MenuItemType[] = [];

    if (readAccess && writeAccess && readDSAccess && readCATAccess) {
      items.push({
        text: 'Edit',
        icon: <Edit />,
        onClick: () => {
          navigate(`/alerts/add/${alert.id}`);
        },
      });
    }

    items.push({
      text: 'Report',
      icon: <Description />,
      onClick: () => {
        navigate(`alerts/report/${alert.id}`);
      },
    });

    if (adminAccess) {
      items.push({
        text: 'Delete',
        icon: <Delete />,
        onClick: () => {
          handleDelete(alert);
        },
      });
    }

    return items;
  }, [alert, readAccess, writeAccess, readDSAccess, readCATAccess, adminAccess, handleDelete, navigate,]);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={alert.name}
      data-testid={'alerts-table-row'}
    >
      <TableCell>{index + 1}</TableCell>
      <TableCell align="left">{alert.name}</TableCell>
      <TableCell align="left">{alert.inputRule.name}</TableCell>
      <TableCell align="left">{alert.outputRule.name}</TableCell>
      <TableCell align="left" className={classes.dataSourceActionsCell}>
        <Tooltip title={isActive ? 'Deactivate' : 'Activate'}>
          <Switch
            color='primary'
            checked={isActive}
            onClick={() => {
              if (isActive) {
                alertsServices.deactivateAlert(alert.id)
                  .then(() => {
                    enqueueSnackbar('Successfully deactivated alert', { variant: 'success', });
                    setIsActive(false);
                  });
              } else {
                alertsServices.activateAlert(alert.id)
                  .then(() => {
                    enqueueSnackbar('Successfully activated alert', { variant: 'success', });
                    setIsActive(true);
                  });
              }
            }}
          />
        </Tooltip>
        <DropDownMenu items={menuItems} />
      </TableCell>
    </TableRow>
  );
};

export { AlertsTableRow, };
