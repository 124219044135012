import React, { useState, } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { TableInfo, } from './TableInfo';
import { TableDesignerColumn, } from './TableDesignerColumn';
import { Interfaces, } from '../../../config';
import { v4, } from 'uuid';
import { emptyColumn, } from '../helpers';
import { AddNewColumn, } from './AddNewColumn';

const TableDesigner = ({
  table,
  handleTableSave,
  tableColumnTypes,
  dbmsType,
  updateTable,
}: {
  table: Interfaces.ModelTable;
  // eslint-disable-next-line no-unused-vars
  handleTableSave: (table: Interfaces.ModelTable, callback?: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  updateTable: (table: Interfaces.ModelTable) => void;
  tableColumnTypes?: Array<Interfaces.DBMSType> | null,
  dbmsType: string,
}) => {
  const [error, setError,] = useState<null | string>(null);

  const [selectedColumn, setSelectedColumn,] = useState<Interfaces.ModelTableColumn>({
    ...emptyColumn,
    _uuid: v4(),
  });

  function handleOnDragEnd(result: any) {
    if (!result.destination) {return;}
    const items = Array.from(table.columns);
    const [reorderedItem,] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    handleTableSave({
      ...table,
      columns: items,
    });
  }

  return (
    <div>
      <TableInfo
        table={table}
        handleChange={(table: Interfaces.ModelTable) => updateTable(table)}
        handleTableSave={handleTableSave}
      />
      <div
        style={{
          overflowY: 'auto',
          maxHeight: 'calc(-460px + 100vh)',
          minHeight: 'calc(-460px + 100vh)',
          paddingTop: 10,
        }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='designer-columns'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="designer-columns"
              >
                {
                  table.columns.map((column, index) => (
                    <Draggable key={column.name} draggableId={column.name} index={index}>
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <TableDesignerColumn
                            handleSave={() => handleTableSave(table, false)}
                            index={index}
                            totalElements={table.columns.length}
                            handleChange={(newColumn) => {
                              const tmpColumnIdx = table.columns.findIndex((c) => c._uuid === newColumn._uuid);
                              if (tmpColumnIdx !== undefined) {
                                const columns = [...table.columns,];
                                columns[tmpColumnIdx] = newColumn;
                                updateTable({
                                  ...table,
                                  columns,
                                });
                              }
                            }}
                            handleColumnOrderChange={(direction: boolean) => {
                              const tmpColumns = table.columns;
                              tmpColumns.splice(index, 1);
                              tmpColumns.splice(direction ? index - 1 : index + 1, 0, column);
                              updateTable({
                                ...table,
                                columns: tmpColumns,
                              });
                            }}
                            dbmsType={dbmsType}
                            removeColumn={() => {
                              updateTable({
                                ...table,
                                columns: table.columns.filter((_column) => _column._uuid !== column._uuid),
                              });
                            }}
                            columnTypes={tableColumnTypes}
                            column={column}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                }
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <AddNewColumn
          column={selectedColumn}
          handleChange={(column) => {
            setSelectedColumn(column);
          }}
          error={error}
          handleSave={() => {
            const idx = table.columns.findIndex((column) => column._uuid === selectedColumn._uuid);
            if (idx !== -1) {
              const _columns = table.columns;
              _columns[idx] = selectedColumn;
              handleTableSave( {
                ...table,
                columns: _columns,
              });
              setSelectedColumn({
                ...emptyColumn,
                _uuid: v4(),
              });
              setError(null);
            } else {
              const exists = table.columns.findIndex((_column) => _column.name === selectedColumn.name);
              if (exists === -1) {
                handleTableSave({
                  ...table,
                  columns: [
                    ...table.columns,
                    selectedColumn,
                  ],
                });
                setSelectedColumn({
                  ...emptyColumn,
                  _uuid: v4(),
                });
                setError(null);
              } else {
                setError('Column name already in use');
              }
            }
          }}
          columnTypes={tableColumnTypes}
          dbmsType={dbmsType}
        />
      </div>
    </div>
  );
};

export { TableDesigner, };
