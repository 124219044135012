import React from 'react';

const useLocalStorage = (name: string, defaultVal: number) => {
  const [value, setValue,] = React.useState<number>(parseInt(localStorage.getItem(name) || defaultVal.toString()));

  React.useEffect(() => {
    localStorage.setItem(name, JSON.stringify(value));
  }, [value,]);

  return [value, setValue,] as const;
};

export { useLocalStorage, };
