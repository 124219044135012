import React from 'react';
import { AxiosResponse, } from 'axios';

const useApiCall = <T,>(
  method: () => Promise<AxiosResponse<T>>,
  updateDeps: Array<any> = []
) => {
  const [loading, setLoading,] = React.useState(true);
  const [data, setData,] = React.useState<T>();
  const [error, setError,] = React.useState();

  React.useEffect(() => {
    setLoading(true);
    method()
      .then((res) => setData(res.data))
      .catch((error) => setError(error))
      .finally(() => {
        setLoading(false);
      });
  }, [...updateDeps,]);

  return React.useMemo(() => ({
    loading,
    data,
    error,
  }), [loading,]);
};

export { useApiCall, };
