import React, { useState, } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import {
  GetApp,
  Publish,
} from '@mui/icons-material';
import { backupServices, } from '../../services';
import { helpers, } from '../../utils';
import { useSnackbar, } from 'notistack';
import { useAuth, } from '../../context';

const BackupPanel = () => {
  const [isLoading, setIsLoading,] = useState<number | null>(null);
  const { enqueueSnackbar, } = useSnackbar();
  const [restorePopupState, setRestorePopupState,] = useState(false);
  const { isAuthorized, } = useAuth();
  const [adminAccess,] = useState(isAuthorized('ADAPTIVE_ADMIN'));

  const handleRestorePopup = () => {
    setRestorePopupState(!restorePopupState);
  };

  const download = () => {
    setIsLoading(2);
    backupServices.fetchDownload()
      .then(() => {
        setIsLoading(null);
        enqueueSnackbar('Download successful', { variant: 'success', });
      })
      .catch((error) => {
        setIsLoading(null);
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  const upload = (file: any | null) => {
    setIsLoading(3);
    backupServices.addRestore(file)
      .then(() => {
        setIsLoading(null);
        enqueueSnackbar('Restore successful', { variant: 'success', });
      })
      .catch((error) => {
        setIsLoading(null);
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  return (
    <Paper variant="outlined" style={{ padding: '30px', }}>
      <Typography style={{ marginBottom: '30px', }}>
        Create a backup file of your system settings for download, or restore your settings from a previous backup.
      </Typography>
      <Grid container>
        <Grid item xs={10}>
          {
            adminAccess && (
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginInline: '20px',
                  marginBottom: '10px',
                }}
                endIcon={
                  isLoading === 2
                    ? <CircularProgress size={18} style={{ color: 'white', }} />
                    : <GetApp/>
                }
                onClick={ () => download()}
              >
                Backup & Download
              </Button>
            )
          }
          {
            adminAccess && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleRestorePopup()}
                endIcon={
                  isLoading === 3
                    ? <CircularProgress size={18} style={{ color: 'white', }} />
                    : <Publish/>
                }
                component="span"
                style={{
                  marginInline: '20px',
                  marginBottom: '10px',
                  paddingInline: '25px',
                }}
              >
                Restore
              </Button>
            )
          }
        </Grid>
      </Grid>
      <Dialog
        open={restorePopupState}
        onClose={() => {
          handleRestorePopup();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'By restoring all the settings will be overwritten!'}
        </DialogTitle>
        <DialogActions style={{
          justifyContent: 'center',
          paddingBottom: '20px',
        }}>
          <input
            style={{ display: 'none', }}
            id="contained-button-file"
            onChange={ (e) => {
              if (e.target.files) {
                upload(e.target?.files[0]);
                handleRestorePopup();
              }
            }}
            type="file"
            multiple
          />
          <label htmlFor="contained-button-file">
            <Button
              color="primary"
              variant="contained"
              component="span"
            >
              Restore
            </Button>
          </label>
          <Button
            onClick={() => {
              handleRestorePopup();
            }}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export { BackupPanel, };
