import { axiosClient, } from '../config/helpers';
import { Constants, } from '../config';


const fetchUsers = (queryParams: string) => {
  return axiosClient().get(`${Constants.api.endpoints.users}/?${queryParams}`);
};

const fetchStats = () => {
  return axiosClient().get(`${Constants.api.endpoints.stats}/users/`);
};

const fetchUserById = (id: string) => {
  return axiosClient().get(`${Constants.api.endpoints.users}/${id}`);
};

export const adminServices = {
  fetchUsers,
  fetchUserById,
  fetchStats,
};
