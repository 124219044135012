import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import {
  CheckCircleOutline,
  Mail,
} from '@mui/icons-material';
import { useNavigate, } from 'react-router-dom';
import { useStyles, } from './styles';
import { LogoRosetta, } from '../../static/index';

const CheckEmail = ({ title, subtitle, isSuccessfulRegistration, }: {title: string, subtitle: string, isSuccessfulRegistration?: boolean}) => {
  const classes = useStyles();
  const history = useNavigate();

  return (
    <Box
      alignItems="center"
      justifyContent="center"
    >
      <div
        style={{
          textAlign: 'center',
          marginTop: '8%',
          width: '100%',
        }}
      >
        <div
          className={classes.logoDiv}
        >
          <img src={LogoRosetta} className={classes.logoEmail} alt="logo"/>
        </div>
        <div
          style={{
            alignItems: 'baseline',
            display: 'inline-flex',
          }}
        >
          <Typography
            variant={'h4'}
            style={{
              color: 'black',
            }}
          >
            {title}
          </Typography>
          <CheckCircleOutline
            color={'primary'}
            style={{
              marginLeft: 10,
              fontSize: '30px',
              alignSelf: 'center',
            }}
          />
        </div>
        <div
          style={{
            marginTop: 10,
            color: '#595959',
            fontSize: '17px',
          }}
        >
          {subtitle}
        </div>
        <div>
          <Mail
            color={'primary'}
            style={{
              marginTop: 20,
              fontSize: '60px',
            }}
          />
          {isSuccessfulRegistration &&
            <Typography
              style={{
                textAlignLast: 'center',
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'blue',
                fontSize: '15px',
                marginTop: '10px',
              }}
              onClick={() => history(`/resend-verification`)}
            >
              Resend email verification
            </Typography>
          }
          <Typography
            style={{
              textAlignLast: 'center',
            }}
          >
            Remember your password? {' '}
            <p
              style={{
                color: 'blue',
                textDecoration: 'underline',
                display: 'inline-flex',
                cursor: 'pointer',

              }}
              onClick={() => history('/')}
            >
              Log in
            </p>
          </Typography>
        </div>
      </div>
    </Box>
  );
};

export { CheckEmail, };
