import React from 'react';
import { TreeView, } from '@mui/lab';
import {
  ChevronRight,
  ExpandMore,
} from '@mui/icons-material';
import { RenderTree, } from './RenderTree';
import { Interfaces, } from '../../../../config';
import LoadingComponent from '../../../Loading';

const SchemaTree = ({
  schema,
  dataSource,
  loadingReason,
}: {
  schema: Interfaces.Schema;
  dataSource: Interfaces.DataSourceType,
  loadingReason?: string;
}) => {
  return (
    <div
      style={{
        border: '1px solid lightGray',
        minHeight: 'calc(-190px + 100vh)',
        maxHeight: 'calc(-190px + 100vh)',
        borderRadius: 7,
        padding: 10,
      }}
    >
      {
        loadingReason ? (
          <LoadingComponent size={30} marginTop={140} message={loadingReason}/>
        ) : (
          <TreeView
            defaultCollapseIcon={<ExpandMore/>}
            defaultExpandIcon={<ChevronRight/>}
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              paddingBottom: 20,
              minHeight: 'calc(-200px + 100vh)',
              maxHeight: 'calc(-200px + 100vh)',
              marginBottom: 7,
            }}
          >
            {
              schema.fields.map((field) => (
                <RenderTree
                  originalSchema={schema}
                  key={field.name}
                  schema={field}
                  dataSourceId={dataSource.id}
                />
              ))
            }
          </TreeView>
        )
      }
    </div>
  );
};

export { SchemaTree, };
