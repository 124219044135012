import { Interfaces, } from '../../../config';
import _ from 'lodash';
import { v4 as uuidv4, } from 'uuid';
import * as interfaces from '../../../config/interfaces';

const mapNewSchemaToExistingSchema = (newSchema: Interfaces.Schema, existingSchema: Interfaces.Schema, parent: Interfaces.Schema | null = null) => {
  _.set(newSchema, '_selected', true);
  _.set(newSchema, 'labels', _.get(existingSchema, 'labels', []));
  _.set(newSchema, 'tags', _.get(existingSchema, 'tags', []));
  _.set(newSchema, 'properties.description', _.get(existingSchema, 'properties.description', ''));
  _.set(newSchema, 'properties.includeAllFields', _.get(existingSchema, 'properties.includeAllFields', false));
  _.set(newSchema, 'properties.metadata_check', _.get(existingSchema, 'properties.metadata_check', undefined));
  if (Object.keys(newSchema).indexOf('fields') > -1) {
    newSchema.fields.forEach((newField) => {
      const _existingFields = existingSchema.fields?.map((_field) => _field.name);
      if (_existingFields?.includes(newField.name)) {
        existingSchema.fields.forEach((existingField) => {
          console.debug('comparing', newField, existingField);
          if (newField.name === existingField.name) {
            mapNewSchemaToExistingSchema(newField, existingField, existingField);
          }
        });
      } else {
        _.set(newField, 'properties.includeAllFields', !!parent?.properties?.includeAllFields);
      }
    });
  }
};

const includeAllChildren = (tree: Interfaces.Schema, treeNode: Interfaces.Schema, selected: boolean) => {
  _.set(tree, treeNode._path + '.properties.includeAllFields', selected);
  if (treeNode?.fields?.length > 0) {
    treeNode.fields.forEach((field) => includeAllChildren(tree, field, selected));
  }
};

const setNodeSelectionTree = (tree: Interfaces.Schema, treeNode: Interfaces.Schema, state: boolean) => {
  if (treeNode._path + '._selected' !== '._selected') {
    _.set(tree, treeNode._path + '._selected', state);
  } else {
    tree._selected = state;
  }
  if (treeNode?.fields?.length > 0) {
    treeNode.fields.forEach((field) => setNodeSelectionTree(tree, field, state));
  }
};

const setReverseSelection = (tree: Interfaces.Schema, treeNode: Interfaces.Schema) => {
  if (treeNode._path) {
    const _path = treeNode._path.split('.');
    if (_path.length >= 1) {
      let newPath = _path[0];
      _path?.forEach((section, index) => {
        _.set(tree, `${newPath}._selected`, true);
        newPath = `${newPath}.${_path[index + 1]}`;
      });
      tree._selected = true;
    }
  }
};

const extractSingleField = (schema: Interfaces.Schema, selectedNode: Interfaces.Schema) => {
  let tmpSchema = _.cloneDeep(schema);
  let newSchema = _.cloneDeep(tmpSchema);
  if (selectedNode._path) {
    const _path = selectedNode._path.split('.');
    if (_path.length > 0) {
      let fp = _path[0];
      _path.forEach((level, index) => {
        const tmp =_.get(tmpSchema, fp);
        fp = `${fp}.${_path[index + 1]}`;
        _.set(newSchema, `${'fields[0].'.repeat(index)}fields`, [tmp,]);
      });
    }
  }
  return handleNodeSelection(newSchema);
};

const getErrorMessage = (error: any) => {
  return error?.response?.data?.message || error.message;
};

const extractSelectedOnlyFields = (schema: Interfaces.Schema): Interfaces.Schema => {
  const hasFields = Object.keys(schema).indexOf('fields') > -1;
  if (!hasFields) {
    return {
      ...schema,
    };
  }
  return {
    ...schema,
    fields: schema.fields.filter((field: Interfaces.Schema) => field._selected).map((subField: Interfaces.Schema) => extractSelectedOnlyFields(subField)),
  };
};

const extractInputSchemaBySelection = (inputCatalog: Interfaces.InputCatalogMetadata): Interfaces.Schema => {
  if (inputCatalog.includeAllSchemas) {
    return inputCatalog.schema;
  }
  return extractSelectedOnlyFields(inputCatalog.schema);
};

const cleanseSchema = (schema: Interfaces.Schema): Interfaces.Schema => {
  const hasFields = Object.keys(schema).indexOf('fields') > -1;
  delete schema._path;
  delete schema._uid;
  delete schema._selected;
  delete schema.__typename;
  delete schema.__typename;
  if (hasFields) {
    return {
      ...schema,
      fields: schema.fields.map((field) => cleanseSchema(field)),
    };
  }
  return {
    ...schema,
  };
};

const getAvroSchema = (schema: Interfaces.Schema): Interfaces.Schema => {
  const _schema: any = schema;
  const hasFields = Object.keys(_schema).indexOf('fields') > -1;
  delete _schema.properties;
  delete _schema.tagRules;
  delete _schema.labels;
  delete _schema.sourceType;
  delete _schema.tags;

  if (hasFields) {
    return {
      ..._schema,
      fields: _schema.fields.map((field: any) => getAvroSchema(field)),
    };
  }
  return {
    ..._schema,
  };
};

const handleNodeSelection = (node: Interfaces.Schema, select: boolean = true): Interfaces.Schema => {
  node._selected = select;
  if (node.fields) {
    return {
      ...node,
      fields: node.fields.map((_field) => handleNodeSelection(_field)),
    };
  }
  return {
    ...node,
  };
};

const updateCatalogField = (fieldPath: string, newValue: string | number | boolean | Interfaces.Schema, catalog: Interfaces.InputCatalogMetadata) => {
  return _.set(catalog, fieldPath, newValue);
};

const updateSchemaField = (fieldPath: string, newValue: Interfaces.Schema, schema: Interfaces.Schema) => {
  return _.set(schema, fieldPath, newValue);
};

const addMissingFieldsToSchema = (data: Interfaces.Schema, path: string, isSelected: boolean) => {
  data._path = path;
  data._uid = uuidv4();
  data._selected = isSelected;
  const separator = data._path !== '' ? '.' : '';
  data.properties.includeAllFields = data.properties.includeAllFields || false;
  if (data.fields) {
    if (data.fields) {
      for (let index in data.fields) {
        addMissingFieldsToSchema(data.fields[index], path + separator + 'fields[' + index + ']', isSelected);
      }
    }
  }
  return data;
};

const addTag = (tag: string, tree: Interfaces.Schema, treeNode: Interfaces.Schema) => {
  let tags = _.get(tree, treeNode._path + '.tags');
  tags.push(tag);
  _.set(tree, treeNode._path + '.tags', tags);
  return tags;
};

const deleteTag = (tag: string, tree: Interfaces.Schema, treeNode: Interfaces.Schema) => {
  let tags = _.get(tree, treeNode._path + '.tags');
  tags.splice(tags.indexOf(tag), 1);
  _.set(tree, treeNode._path + '.tags', tags);
  return tags;
};

const addLabel = (label: interfaces.CMLabels, tree: Interfaces.Schema, treeNode: Interfaces.Schema) => {
  let labels = _.get(tree, treeNode._path + '.labels');
  console.debug('labels', labels);
  labels.push(label);
  _.set(tree, treeNode._path + '.labels', labels);
  return labels;
};

const deleteLabel = (label: interfaces.CMLabels, tree: Interfaces.Schema, treeNode: Interfaces.Schema) => {
  let labels = _.get(tree, treeNode._path + '.labels');
  labels = labels.filter((l: { key: string; }) => l.key !== label.key) || [];
  _.set(tree, treeNode._path + '.labels', labels);
  return labels;
};

const findElement: any = (fields: Array<Interfaces.Schema>, path: string) => {
  if (!fields) {
    return;
  }
  for (const field of fields) {
    if (field.properties.path === path) {
      return field;
    }
    const _field = findElement(field.fields, path);
    if (_field) {
      return _field;
    }
  }
};

const mapTotalRecordsToSchema = (schema: Interfaces.Schema, totalRecords: any) => {
  Object.keys(totalRecords).filter((key) => {
    if (!totalRecords[key].totalRecords && !totalRecords[key].sizeInBytes) {
      delete totalRecords[key];
    }
  });
  Object.keys(totalRecords).forEach((key) => {
    const entry = findElement(schema.fields, totalRecords[key].path);
    if (entry) {
      entry.properties.totalRecords = totalRecords[key].totalRecords;
      entry.properties.sizeInBytes = totalRecords[key].sizeInBytes;
    }
  });
};

const countTotalTables = (schema: Interfaces.Schema) => {
  let total = 0;
  schema.fields?.map((field) => {
    if (field?.sourceType === 'schema') {
      total += field.fields?.length || 0;
    }
    else if (field?.sourceType === 'table') {
      total += 1;
    }
  });
  return total;
};

const mapAutoTags = (schema: Interfaces.Schema) => {
  if (schema.properties?.metadata_check?.pii_type) {
    schema.tags = [...(schema.tags ?? []), schema.properties?.metadata_check?.pii_type,];
  }
  if (schema.fields) {
    schema.fields?.forEach((field) => {
      mapAutoTags(field);
    });
  }
  return schema;
};

export type DataSourceType =
  Interfaces.BaseDataSource |
  Interfaces.JDBCDataSource |
  Interfaces.GoogleBucketDataSource |
  Interfaces.S3DataSource |
  Interfaces.AzureDataSource

export const helpers = {
  includeAllChildren,
  mapNewSchemaToExistingSchema,
  setNodeSelectionTree,
  getErrorMessage,
  extractInputSchemaBySelection,
  extractSelectedOnlyFields,
  cleanseSchema,
  getAvroSchema,
  handleNodeSelection,
  updateCatalogField,
  addMissingFieldsToSchema,
  addTag,
  deleteTag,
  addLabel,
  deleteLabel,
  setReverseSelection,
  mapTotalRecordsToSchema,
  extractSingleField,
  countTotalTables,
  findElement,
  mapAutoTags,
  updateSchemaField,
};
