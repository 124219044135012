import React from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { TotalRecords, } from './TotalRecords';
import {
  DataSourceType,
  helpers,
} from '../helpers';
import { Interfaces, } from '../../../../config';
import { useStyles, } from '../styles';
import { MainActionButtons, } from '../MainHeader/MainActionButtons';


const MetadataHeader = ({
  selectedDataSource,
  dataSourceSchema,
  totalRecords,
  loadingTotalRecords,
  fetchTotalRecords,
  external,
  catalog,
  schemaError,
}: {
  selectedDataSource: DataSourceType;
  dataSourceSchema: Interfaces.Schema;
  totalRecords: any;
  loadingTotalRecords: boolean;
  fetchTotalRecords: () => void;
  external?: boolean;
  catalog: Interfaces.InputCatalogMetadata;
  schemaError: boolean;
}) => {
  const classes = useStyles();

  if (external) {
    return (
      <Grid
        container
        justifyContent={'center'}
        direction='row'
        style={{
          marginBottom: 30,
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
            marginTop: 50,
          }}
        >
          No datasource information available for third party catalogs.
        </Typography>
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
            marginBottom: 50,
          }}
        >
          Please select a field to see field specific data.
        </Typography>
      </Grid>
    );
  }
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
            marginBottom: 15,
          }}
        >
          {selectedDataSource?.name}
        </Typography>
        <Box>
          <MainActionButtons
            catalog={catalog}
            selectedDataSource={selectedDataSource}
            schemaError={schemaError}
          />
        </Box>
      </Box>

      <Grid container direction='row'
        style={{
          marginBottom: 30,
        }}
      >
        <Grid item xs={6} container direction='row'>
          <Grid item xs={1} style={{ marginRight: 4, }}>
            <Typography
              variant='body2'
              style={{
                color: 'gray',
                marginBottom: 5,
              }}
            >
              Type
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant='body2'
            >
              {selectedDataSource?.type}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ textAlign: 'center', }}
        className={classes.fieldsWrapperMain}
        alignItems='center'
      >
        <Grid item xs={3}>
          <Grid item style={{ marginLeft: 10, }}>
            {
              (selectedDataSource.type === 'JDBC' ?
                (
                  <>
                      Tables
                    <p>{dataSourceSchema && helpers.countTotalTables(dataSourceSchema)}</p>
                  </>
                ) : (
                  <>
                      Fields
                    <p>{dataSourceSchema?.fields?.length}</p>
                  </>
                )
              )}
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={4}>
          <Grid item style={{ marginLeft: 15, }}>
            URI
            <p>{selectedDataSource.uri}</p>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={4} style={{ marginTop: -12, }}>
          {
            selectedDataSource.type === 'JDBC' ? (
              <TotalRecords
                totalRecords={totalRecords}
                fetchTotalRecords={fetchTotalRecords}
                loadingTotalRecords={loadingTotalRecords}
                tableName={''}
                totalTables={catalog.schema ? catalog.includeAllSchemas ? helpers.countTotalTables(catalog?.schema) : undefined : undefined}
              />
            ) : (
              <>
                Type
                <p>{selectedDataSource.type}</p>
              </>
            )
          }
        </Grid>
      </Grid>
    </>
  );
};

export { MetadataHeader, };
