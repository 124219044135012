import React, { useState, } from 'react';
import { useStyles, } from './styles';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {
  Constants,
  Interfaces,
} from '../../../../config';
import { Delete, } from '@mui/icons-material';
import Cron from 'react-cron-generator';
import { SaveAndCancelButtons, } from '../SaveAndCancelButtons';
import cronstrue from 'cronstrue';
import LoadingComponent from '../../../Loading';


const ScheduleForm = ({
  catalog,
  setCatalog,
  inEditMode,
  loadingSchema,
  schemaError,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  // eslint-disable-next-line no-unused-vars
  setCatalog: (catalog: Interfaces.InputCatalogMetadata) => void;
  inEditMode: boolean;
  loadingSchema: boolean;
  schemaError: boolean;
}) => {
  const classes = useStyles();
  const [isChanged, setIsChanged,] = useState(false);

  const getDate = (date: string) => {
    const _date = new Date(date);
    return _date.toISOString().split('T')[0];
  };

  if (loadingSchema) {
    return <LoadingComponent />;
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 164px)',
    }}>
      <Box className={classes.container}>
        <FormControlLabel
          control={
            <Switch
              checked={!!catalog.schedule}
              onChange={() => {
                if (!catalog.schedule) {
                  setCatalog({
                    ...catalog,
                    schedule: {
                      ...Constants.states.initialSchedule,
                    },
                  });
                } else {
                  setCatalog({
                    ...catalog,
                    schedule: null,
                  });
                }
              }}
              color="primary"
            />
          }
          label="Set Schedule"
        />
        {
          catalog.schedule && (
            <div style={{ marginTop: '30px', }}>
              <Grid container>
                <Grid item xs={5}>
                  <form className={classes.schedule} noValidate>
                    <TextField
                      style={{ marginBottom: '30px', }}
                      id="date"
                      label="Start Date"
                      fullWidth
                      type="date"
                      name="startDate"
                      defaultValue={catalog.schedule?.startDate ? getDate(catalog.schedule?.startDate) : ''}
                      onChange={(e) => {
                        if (catalog.schedule) {
                          setCatalog({
                            ...catalog,
                            schedule: {
                              ...catalog.schedule,
                              startDate: e.target.value,
                            },
                          });
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="date"
                      label="End Date"
                      fullWidth
                      type="date"
                      name="endDate"
                      defaultValue={catalog.schedule?.endDate ? getDate(catalog.schedule?.endDate) : ''}
                      onChange={(e: any) => {
                        if (catalog.schedule) {
                          setCatalog({
                            ...catalog,
                            schedule: {
                              ...catalog.schedule,
                              endDate: e.target.value,
                            },
                          });
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                  <Grid container spacing={2}>
                    <Paper style={{
                      marginTop: '20px',
                      marginLeft: '8px',
                    }}>
                      <Grid container>
                        <Typography style={{ padding: '10px', }}>
                          {catalog.schedule?.startDate
                            ? `From: ${getDate(catalog.schedule.startDate)}`
                            : ''}
                          {catalog.schedule?.cronExpression
                            ? cronstrue.toString(catalog.schedule.cronExpression)
                            : catalog.schedule.cronExpression}
                          {catalog.schedule?.endDate
                            ? `Until: ${getDate(catalog.schedule.endDate)}`
                            : ''}
                        </Typography>
                        <IconButton
                          style={{
                            padding: '3px 3px',
                            marginRight: '10px',
                          }}
                          aria-label="delete"
                          onClick={() => {
                            setCatalog({
                              ...catalog,
                              schedule: null,
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={7}>
                  <div className={classes.test}>
                    {
                      catalog.schedule && (
                        <Cron
                          onChange={(e: any) => {
                            catalog.schedule && setCatalog({
                              ...catalog,
                              schedule: {
                                ...catalog.schedule,
                                cronExpression: e,
                              },
                            });
                            if (isChanged) {
                              catalog.schedule && setCatalog({
                                ...catalog,
                                schedule: {
                                  ...catalog.schedule,
                                  cronExpression: e,
                                },
                              });
                            } else {
                              setIsChanged(true);
                            }
                          }}
                          value={catalog.schedule?.cronExpression || ''}
                          showResultText={true}
                          showResultCron={true}
                        />
                      )
                    }
                  </div>

                </Grid>
              </Grid>
            </div>
          )
        }
      </Box>
      <Grid container justifyContent={'flex-end'} style={{ marginTop: 'auto', }}>
        <SaveAndCancelButtons
          catalog={catalog}
          inEditMode={inEditMode}
          loadingSchema={loadingSchema}
          schemaError={schemaError}
        />
      </Grid>
    </div>
  );
};

export { ScheduleForm, };
