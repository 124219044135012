import React from 'react';
import {
  makeStyles,
  createStyles,
} from '@mui/styles';
import {
  Typography,
  Grid,
  Link,
} from '@mui/material';
import moment from 'moment';
import Rosetta from '../../assets/icons/RosettaDB.svg';

import { useAuth, } from '../../context';

const useStyles = makeStyles(() =>
  createStyles({
    expireDate: {
      color: 'grey',
    },
    nameAndVersion: {
      fontWeight: 'bold',
    },
    infoSection: {
      padding: 20,
      textAlignLast: 'center',
    },
    infoGrid: {
      alignSelf: 'center',
    },
  })
);

const AboutUs = () => {
  const classes = useStyles();
  const { health, } = useAuth();
  return (
    <div data-testid="about-us">
      { health.deploymentType === 'local' ? (
        <Grid container style={{ marginTop: 100, }}>
          <Grid
            item
            xs={4}
            style={{
              marginTop: 50,
              margin: '0 auto',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={Rosetta} alt="rosettadb-logo" width="400px" />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={classes.infoGrid}>
                <div className={classes.infoSection}>
                  <Typography variant="h4" className={classes.nameAndVersion}>
                    {health.license?.customerName}  Version {health.version}
                  </Typography>
                  <Typography variant="h5">{health.license?.licenseType}</Typography>
                  <Typography variant="h6" className={classes.expireDate}>
                    Expires {moment(health.license?.licenseExpiration).fromNow()}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ marginTop: 100, }}>
          <Grid
            item
            xs={4}
            style={{
              marginTop: 30,
              margin: '0 auto',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={Rosetta} alt="rosettadb-logo" width="400px"/>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 50, }}>
            <Grid container>
              <Grid item xs={12} className={classes.infoGrid}>
                <div className={classes.infoSection}>
                  <Typography variant="h5" className={classes.nameAndVersion}>
                    Platform - RosettaDB Version {health.version}
                  </Typography>
                  <Typography variant="h5" className={classes.nameAndVersion}>
                    RosettaDB - Version {health.version}
                  </Typography>
                  <Typography variant="subtitle1" style={{ marginTop: 30, }} >Rosetta Cloud is a service of AdaptiveScale Inc.</Typography>
                  <Typography variant="subtitle1" style={{ marginTop: 5, }}>
                  With Rosetta Cloud you have an easy to use Graphical User Interface <br/> to our open source RosettaDB software for managing database schemas and migrating data.
                  </Typography>
                  <Typography variant="subtitle1"
                    style={{
                      marginTop: 50,
                      fontWeight: 550,
                    }}>
                    <Link href='https://www.adaptivescale.com/'>Try AdaptiveScale for enterprise class features</Link></Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AboutUs;
