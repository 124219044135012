import React, { useState, } from 'react';
import GitConfigList from './GitConfigList';
import {
  Paper,
  Typography,
} from '@mui/material';
import { useAuth, } from '../../../context';


const GitConfigs = () => {
  const { isAuthorized, } = useAuth();
  const [canRead,] = useState(isAuthorized('ADAPTIVE_GIT_READ'));

  return (
    <>
      {canRead ? (
        <>
          <GitConfigList />
        </>
      ) : (
        <><Paper style={{ padding: '15px', }}><Typography variant="h4">Not authorized</Typography></Paper></>
      )}
    </>
  );
};

export default GitConfigs;
