import React, {
  useEffect,
  useState,
} from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import LoadingComponent from '../../Loading';
import { SearchInput, } from '../SearchInput/SearchInput';
import {
  useLocation,
  useParams,
} from 'react-router-dom';
import { helpers, } from '../../../utils';
import { SchemaView, } from '../../SchemaView';
import { useAuth, } from '../../../context';
import { runDetailsServices, } from '../../../services';
import { useSnackbar, } from 'notistack';
import { cloneDeep, } from 'lodash';
import { addUid, } from '../../../utils/helpers';
import { NodeMetadata, } from '../NodeMetadata';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: '44px',
  },
  treeViewGrid: {
    backgroundColor: 'white',
    height: 'calc(-230px + 100vh)',
    border: '1px solid #8080803b',
    minHeight: '470px',
    marginTop: '15px',
    overflow: 'auto',
  },
  treeViewGridExpanded: {
    height: 'calc(-150px + 100vh)',
  },
  runId: {
    paddingLeft: '10px',
  },
  formControl: {
    minWidth: 300,
  },
  metadataStyle: {
    paddingLeft: '10px',
    background: 'gainsboro',
  },
  paddingRight: {
    paddingRight: '15px',
  },
  heading: {
    fontSize: '15px',
    marginBottom: '3px',
    fontWeight: 'bolder',
  },
}));

function SearchDetails() {
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const [detailsData, setDetailsData,] = useState<any>(null);
  const [loadingDetailsData, setLoadingDetailsData,] = useState<boolean>(false);
  const [selectedMetadata, setSelectedMetadata,] = useState<any>(null);

  const params = useParams<{ runId: string }>();
  const location = useLocation();

  const fetchRunDetails = (runId: string) => {
    setLoadingDetailsData(true);
    runDetailsServices.fetchRunDetails(runId)
      .then((res) => {
        let snapshots = cloneDeep(res.data);
        snapshots = addUid(snapshots);
        setDetailsData({
          ...snapshots,
          id: snapshots.properties.runId,
        });
      })
      .catch((error: any) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingDetailsData(false);
      });
  };

  useEffect(() => {
    params.runId && fetchRunDetails(params.runId);
  }, [params.runId,]);

  const pathArray = location?.state?.item?.path?.substring(1)?.split('/');

  const { selectedTreeNodes, selectedSchema, } =
    detailsData && pathArray ? helpers.findPath({ ...detailsData, }, pathArray) : [];

  const { isAuthorized, } = useAuth();
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_SEARCH_READ'));

  const renderHeader = () => {
    const { state, } = location;
    if (state?.item) {
      const { row, item, } = state;
      return (
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.heading} align="left">
                  #
                </TableCell>
                <TableCell className={classes.heading} align="left">
                  Name
                </TableCell>
                <TableCell className={classes.heading} align="left">
                  Data Source
                </TableCell>
                <TableCell className={classes.heading} align="left">
                  Type
                </TableCell>
                <TableCell className={classes.heading} align="left">
                  Tags
                </TableCell>
                <TableCell className={classes.heading} align="left">
                  Labels
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">{row}</TableCell>
                <TableCell align="left">{item.name}</TableCell>
                <TableCell align="left">{item.dataSourceName}</TableCell>
                <TableCell align="left">{item.sourceType ? item.sourceType : item.type}</TableCell>
                <TableCell align="left">{item.tags.join()}</TableCell>
                <TableCell align="left">{item.labels.length}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      );
    }
  };

  return (
    <div>
      { readAccess && (
        <>
          {loadingDetailsData ? (
            <LoadingComponent/>
          ) : (
            <Grid container spacing={2}>
              <Grid xs={7} item style={{  }}>
                {location.state && renderHeader()}
                <Grid className={location.state ? classes.treeViewGrid: `${classes.treeViewGrid} ${classes.treeViewGridExpanded}`}>
                  <div style={{ margin: '15px', }}>
                    {detailsData && (
                      <SchemaView
                        schema={detailsData}
                        selectedTreeNode={
                          selectedTreeNodes
                            ? selectedTreeNodes[selectedTreeNodes.length - 1]
                            : selectedMetadata?._uid
                        }
                        expanded={selectedTreeNodes}
                        onTreeNodeClick={(treeNode: any) => {
                          setSelectedMetadata(treeNode);
                        }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid xs={5} item alignItems={'center'}>
                <Grid item xs={12}>
                  <SearchInput small/>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 30,
                    height: 'calc(100vh - 208px)',
                    overflowY: 'auto',
                  }}
                >
                  {selectedMetadata && <NodeMetadata selectedNode={selectedMetadata} />}
                  {!selectedMetadata && selectedSchema && <NodeMetadata selectedNode={selectedSchema} />}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

export { SearchDetails, };
