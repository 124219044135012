import React, {
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  LinearProgress,
  Link,
} from '@mui/material';
import { useStyles, } from '../styles';

const TotalRecords = ({
  totalRecords,
  fetchTotalRecords,
  loadingTotalRecords,
  tableName,
  totalTables,
} : {
  totalRecords: any;
  fetchTotalRecords: () => void;
  loadingTotalRecords: boolean;
  tableName?: string;
  totalTables?: number;
}) => {
  const classes = useStyles();
  const [totalR, setTotalR,] = useState(0);
  const [totalS, setTotalS,] = useState(0);
  const [value, setValue,] = useState<any>(null);

  useEffect(() => {
    if (totalRecords) {
      let tempTotalR = 0;
      let tempTotalS = 0;
      [...totalRecords,]
        .filter((t) => t.totalRecords !== undefined)
        .forEach((t) => {
          tempTotalR += t.totalRecords;
          tempTotalS += parseInt(t.sizeInBytes);
        });
      setTotalR(tempTotalR);
      setTotalS(tempTotalS);
    }
  }, [totalRecords,]);

  useEffect(() => {
    if (Symbol.iterator in Object(totalRecords)) {
      const element = [...totalRecords,].find((_table) => _table.name === tableName);
      if (element) {
        setValue(element);
        return;
      }
      const elements = tableName === '' ? [...totalRecords,] : [...totalRecords,].filter((_table) => _table.name.startsWith(tableName));
      if (elements.length === 0) {
        setValue({
          totalRecords: -1,
          sizeInBytes: -1,
        });
        return;
      }
      let totalR = 0;
      let totalS = 0;
      elements.forEach((element) => {
        totalR += element.totalRecords;
        totalS += element.sizeInBytes;
      });
      setValue({
        totalRecords: totalR,
        sizeInBytes: totalS,
      });
    }
  }, [totalRecords, tableName,]);

  return (
    <>
      {
        (totalRecords === null || value?.totalRecords === -1 || (totalTables ? totalRecords?.length !== totalTables : false)) && (
          <Grid
            container
            justifyContent={'center'}
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Grid item xs={12}>
              <p>Total Records</p>
            </Grid>
            <Grid item xs={12}>
              {
                loadingTotalRecords ? (
                  <LinearProgress
                    style={{
                      marginTop: 10,
                    }}
                  />
                ) : (
                  <Link
                    className={classes.linkStyle}
                    onClick={() => {
                      fetchTotalRecords();
                    }}
                  >
                    Get Record Count
                  </Link>
                )
              }
            </Grid>
          </Grid>
        )
      }
      {
        totalRecords && value?.totalRecords !== -1 && (totalTables ? totalRecords?.length === totalTables : true) && (
          <Grid container>
            <Grid item xs={6}>
              <p>Total Records</p>
              <p>
                {
                  tableName ? value?.totalRecords !== undefined ? value.totalRecords : 'NA' : totalR
                }
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>Size in bytes</p>
              <p>
                {
                  tableName ? value?.sizeInBytes !== undefined ? value.sizeInBytes : 'NA' : totalS
                }
              </p>
            </Grid>
          </Grid>
        )
      }
    </>
  );
};

export { TotalRecords, };
