import { Interfaces, } from '../../config';

export const addIndexedUID = (collection: Interfaces.Model) => {
  collection.database.tables.map((table) => {
    table._uuid = `collection.database.${table.name}`;
    table.columns.map((column) => {
      column._uuid = table._uuid = `collection.database.${table.name}.${column.name}`;
      column.tests && column.tests.assertion.map(async (test) => {
        test._uuid = `collection.database.${table.name}.${column.name}.tests.${JSON.stringify(test)}`;
      });
    });
  });
  return collection;
};

export const rosettaTranslationMatrix: { [key: string]: Array<string> } = {
  'bigquery': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
  'snowflake': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
  'mysql': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
  'postgres': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
  'kinetica': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
  'spanner': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
  'sqlserver': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
  'db2': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
  'oracle': ['bigquery', 'snowflake', 'mysql', 'postgres', 'kinetica', 'spanner', 'sqlserver', 'db2', 'oracle',],
};
