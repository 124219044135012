import React from 'react';
import GithubIcon from '../../../assets/icons/github.png';
import GitlabIcon from '../../../assets/icons/gitlab.png';
import BitbucketIcon from '../../../assets/icons/bitbucket.png';
import GitIcon from '../../../assets/icons/git.png';

const getGitIcon = (gitType: string | undefined) => {
  switch (gitType) {
  case 'BITBUCKET':
    return BitbucketIcon;
  case 'GITHUB':
    return GithubIcon;
  case 'GITLAB':
    return GitlabIcon;
  default:
    return GitIcon;
  }
};

const GitIcons = ({ type, height = 20, style, className, } : { type: string, height?: number, style?: React.CSSProperties, className?: string } ) => {

  return (
    <img src={getGitIcon(type)} alt="Git Icon" height={height} style={style} className={className}/>
    
  );
};

export default GitIcons;
