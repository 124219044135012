import React, {
  useEffect,
  useState,
} from 'react';
import { Typography, } from '@mui/material';
import { Interfaces, } from '../../../../config';
import { useStyles, } from '../styles';
import {
  CatalogMetadata,
  DefaultMetadataPlaceHolder,
} from '../Metadata';
import { DataSourceType, } from '../helpers';
import { SchemaViewer, } from './SchemaViewer';
import { CatalogFormInputFields, } from './CatalogFormInputFields';
import { SaveAndCancelButtons, } from '../SaveAndCancelButtons';

const CatalogForm = ({
  catalog,
  setCatalog,
  setFieldSelectionMode,
  handleSelectAllFields,
  inEditMode,
  loadingSchema,
  schemaError,
  dataSources,
  selectedDataSource,
  setSelectedDataSource,
  isBucket,
  totalRecords,
  loadingTotalRecords,
  fetchTotalRecords,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  // eslint-disable-next-line no-unused-vars
  setCatalog: (catalog: Interfaces.InputCatalogMetadata) => void;
  // eslint-disable-next-line no-unused-vars
  setFieldSelectionMode: (value: boolean) => void;
  handleSelectAllFields: () => void;
  inEditMode: boolean;
  loadingSchema: boolean;
  schemaError: boolean;
  dataSources: Array<DataSourceType>;
  selectedDataSource: DataSourceType | null;
  // eslint-disable-next-line no-unused-vars
  setSelectedDataSource: (id: string) => void;
  isBucket: boolean;
  totalRecords: any;
  loadingTotalRecords: boolean;
  // eslint-disable-next-line no-unused-vars
  fetchTotalRecords: (selectedNode?: Interfaces.Schema) => void;
}) => {
  const classes = useStyles();
  const [selectedNode, setSelectedNode,] = useState<Interfaces.Schema | null>(null);
  const [scrollToNode, setScrollToNode,] = useState<Interfaces.Schema>();

  useEffect(() => {
    setSelectedNode(null);
  }, [selectedDataSource,]);


  return (
    <div
      style={{
        display: 'flex',
        marginTop: 10,
        height: 'calc(100vh - 174px)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#fff',
          borderRadius: 10,
          minHeight: '100%',
          maxHeight: '100%',
          padding: 4,
          marginRight: 20,
          flex: 1,
          minWidth: 380,
        }}
      >
        <CatalogFormInputFields
          catalog={catalog}
          setCatalog={(catalog) => setCatalog(catalog)}
          loadingSchema={loadingSchema}
          inEditMode={inEditMode}
          dataSources={dataSources}
          selectedDataSource={selectedDataSource}
          setSelectedDataSource={(dataSourceId) => setSelectedDataSource(dataSourceId)}
          setFieldSelectionMode={(mode) => setFieldSelectionMode(mode)}
        />
        <div className={classes.treeViewGrid}>
          {
            selectedDataSource ? (
              <SchemaViewer
                catalog={catalog}
                setCatalog={(catalog) => setCatalog(catalog)}
                handleSelectAllFields={() => handleSelectAllFields()}
                loadingSchema={loadingSchema}
                schemaError={schemaError}
                isBucket={isBucket}
                selectedNode={selectedNode}
                selectNode={(node) => setSelectedNode(node)}
                scrollToNode={scrollToNode}
              />
            ) : (
              <div style={{ marginTop: 120, }}>
                <Typography align={'center'} variant={'h5'}>Please select a data source</Typography>
              </div>
            )
          }
        </div>
      </div>
      <div style={{
        flex: 3,
        height: '100%',
      }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '95%',
            overflowY: 'auto',
          }}
        >
          {
            !selectedDataSource && <DefaultMetadataPlaceHolder />
          }
          {
            selectedDataSource && (
              <CatalogMetadata
                catalog={catalog}
                setCatalog={setCatalog}
                selectedDataSource={selectedDataSource}
                dataSourceSchema={catalog.schema}
                loadingSchema={loadingSchema}
                schemaError={schemaError}
                selectedNode={selectedNode}
                handleSelectNode={(node) => setSelectedNode(node)}
                totalRecords={totalRecords}
                loadingTotalRecords={loadingTotalRecords}
                fetchTotalRecords={fetchTotalRecords}
                setScrollToNode={setScrollToNode}
              />
            )
          }
        </div>
        <div
          style={{
            paddingTop: 10,
            justifyContent: 'flex-end',
          }}
        >
          <SaveAndCancelButtons
            catalog={catalog}
            inEditMode={inEditMode}
            loadingSchema={loadingSchema}
            schemaError={schemaError}
          />
        </div>
      </div>
    </div>
  );
};

export { CatalogForm, };
