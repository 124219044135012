import { axiosClient, } from '../config/helpers';
import {
  Constants,
  Interfaces,
} from '../config';

export const deploymentServices = {
  fetchDeployments,
  fetchDeploymentsByCollectionId,
  createDeployment,
  deleteDeployment,
  downloadDeployment,
};

function fetchDeployments() {
  return axiosClient().get(Constants.api.endpoints.deployments);
}

function fetchDeploymentsByCollectionId(collectionId: string) {
  return axiosClient().get(`${Constants.api.endpoints.collections}${collectionId}/deployments`);
}

function createDeployment(body: Interfaces.NewDeployment) {
  return axiosClient().post(Constants.api.endpoints.deployments, body);
}

function deleteDeployment(id: string) {
  return axiosClient().delete(`${Constants.api.endpoints.deployments}${id}`);
}

function downloadDeployment(deploymentId: string) {
  return axiosClient().get(
    `${Constants.api.endpoints.deployments}${deploymentId}/download`,
    {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    }
  ).then(( data: any ) => {
    const filename = data.headers['x-suggested-filename'] || 'deployment.zip';
    const downloadUrl = window.URL.createObjectURL(new Blob([data.data,]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}
