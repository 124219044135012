import React, { useMemo, } from 'react';
import { TreeView, } from '@mui/lab';
import {
  ChevronRight,
  ExpandMore,
} from '@mui/icons-material';
import { useStyles, } from './styles';
import { StyledSchemaItem, } from './StyledSchemaItem';
import { DataRepresentation, } from './DataRepresentation';
import { Interfaces, } from '../../config';
import _ from 'lodash';

interface Props {
  schema: Interfaces.Schema;
  // eslint-disable-next-line no-unused-vars
  onTreeNodeClick?: (node: Interfaces.Schema) => void;
  showCheckBox?: boolean;
  checkBoxReadOnly?: boolean;
  checkBoxDefaultState?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelectChange?: (node: Interfaces.Schema, state: boolean) => void;
  minimal?: boolean;
  selectedTreeNode?: string;
  expanded?: Array<string>;
  scrollToNode?: Interfaces.Schema;
}

function SchemaView({
  schema,
  onTreeNodeClick,
  showCheckBox,
  checkBoxDefaultState,
  checkBoxReadOnly,
  onSelectChange,
  minimal,
  selectedTreeNode,
  expanded,
  scrollToNode,
}: Props) {
  const classes = useStyles();
  const [_expanded, setExpanded,] = React.useState<Array<string>>([schema._uid ?? schema.name, ...(expanded ?? []),]);

  const RenderTree = ({
    treeNode,
    schema,
  }: {
    treeNode: Interfaces.Schema;
    schema: Interfaces.Schema;
  }) => {

    const _fields = useMemo(() => {
      return treeNode.fields || [];
    }, [treeNode,]);

    return (
      <StyledSchemaItem
        key={treeNode.name}
        id={treeNode._uid}
        nodeId={treeNode._uid || treeNode.name}
        label={
          <DataRepresentation
            minimal={minimal}
            treeNode={treeNode}
            dataToRender={schema}
            showCheckBox={showCheckBox}
            checkBoxDefaultState={treeNode.properties?.includeAllFields || checkBoxDefaultState}
            checkBoxReadOnly={checkBoxReadOnly || treeNode.properties?.includeAllFields}
            onTreeNodeClick={(selectedTreeNode: any) =>
              onTreeNodeClick
              && onTreeNodeClick(selectedTreeNode)
            }
            selectedTreeNode={selectedTreeNode}
            onSelectChange={onSelectChange && onSelectChange}
          />
        }
      >
        {
          _fields.map((item: any, index: number) =>
            <RenderTree key={index} treeNode={item} schema={schema} />
          )
        }
      </StyledSchemaItem>
    );
  };

  React.useEffect(() => {
    if (scrollToNode) {
      const element = document.getElementById(scrollToNode._uid ?? '');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', });
      }
    }
  }, [scrollToNode,]);

  const expandedElements = React.useMemo(() => {
    const expandedTmp: Array<string> = _expanded ?? [];
    if (scrollToNode) {
      const _path = scrollToNode._path?.split('.');
      if (_path && _path?.length >= 1) {
        let newPath = _path[0];
        _path?.forEach((_ignore, index) => {
          const tmp = _.get(schema, `${newPath}`);
          if (tmp) {
            expandedTmp.push(tmp._uid ?? tmp.name);
          }
          newPath = `${newPath}.${_path[index + 1]}`;
        });
      }
    }
    return [..._expanded, ...expandedTmp,];
  }, [scrollToNode, schema, _expanded,]);

  return (
    <TreeView
      className={
        minimal
          ? classes.minimalTreeView
          : classes.root
      }
      expanded={expandedElements}
      defaultCollapseIcon={<ExpandMore />}
      onNodeToggle={(ignore, nodeIds) => {
        setExpanded(nodeIds);
      }}
      defaultExpandIcon={<ChevronRight />}
    >
      <RenderTree treeNode={schema} schema={schema} />
    </TreeView>
  );
}

export { SchemaView, };
