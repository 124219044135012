import React from 'react';
import { EnhancedTableHead, } from './EnhancedTableHead';
import { EnhancedTableToolbar, } from './EnhancedTableToolbar';
import {
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableBody,
} from '@mui/material';
import { useStyles, } from './styles';
import { useAuth, } from '../../../context';
import TableRowSchedule from './TableRowSchedule';
import { Interfaces, } from '../../../config';

interface ScheduleTableProps {
  schedules: Array<Interfaces.CatalogScheduleJob>;
  loadingSchedules: boolean;
  filteredSchedules: Array<Interfaces.CatalogScheduleJob>;
  setFilteredSchedules: React.Dispatch<React.SetStateAction<Array<Interfaces.CatalogScheduleJob>>>;
  fetchSchedules: () => void;
}

const SchedulesTable = ({ schedules,
  filteredSchedules,
  setFilteredSchedules,
  fetchSchedules, } : ScheduleTableProps) => {
  const [filters, setFilters,] = React.useState({
    checked: false,
    order: 'asc',
    orderBy: 'catalogName',
    page: 0,
    rowsPerPage: 5,
  });
  const classes = useStyles();
  const { isAuthorized, } = useAuth();

  const readAccess = isAuthorized('ADAPTIVE_SCHED_READ');
  const writeAccess = isAuthorized('ADAPTIVE_SCHED_WRITE');
  const adminAccess = isAuthorized('ADAPTIVE_ADMIN');

  const descendingComparator = (a: any, b: any, orderBy: any) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: any, orderBy: any) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (dataSources: any, comparator: any) => {
    const stabilizedThis = dataSources.map((el: any, index: number) => [
      el,
      index,
    ]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  };

  const handleSearch = (event: any) => {
    let filteredData = schedules.filter((value: Interfaces.CatalogScheduleJob) => value.inputCatalogMetadata?.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredSchedules(filteredData);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = filters.orderBy === property && filters.order === 'asc';
    setFilters({
      ...filters,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const handleChangePage = (event: any, newPage: number) => {
    setFilters({ ...filters,
      page: newPage, });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setFilters({
      ...filters,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  React.useEffect(() => {
    fetchSchedules();
  }, []);

  if (!readAccess) {
    return <div>Not authorized</div>;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          handleSearch={handleSearch}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={filters.order}
              orderBy={filters.orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(
                filteredSchedules,
                getComparator(filters.order, filters.orderBy)
              )
                .slice(filters.page * filters.rowsPerPage, filters.page * filters.rowsPerPage + filters.rowsPerPage)
                .map((schedule: Interfaces.CatalogScheduleJob, index: number) => {
                  return (
                    <TableRowSchedule
                      key={schedule.id}
                      schedule={schedule}
                      index={index}
                      writeAccess={writeAccess}
                      adminAccess={adminAccess}
                      fetchSchedules={fetchSchedules}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,]}
          component="div"
          count={filteredSchedules.length}
          rowsPerPage={filters.rowsPerPage}
          page={filters.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export { SchedulesTable, };
