import React from 'react';
import { NavLink, } from 'react-router-dom';
import clsx from 'clsx';
import {
  CssBaseline,
  Link,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { InfoOutlined, } from '@mui/icons-material';
import { Constants, } from '../../config';
import { SideBarElements, } from './SideBarElements';
import {
  useAuth,
  useSidebar,
} from '../../context';
import { useStyles, } from './styles';
import { NavBar, } from './NavBar';

function Sidebar() {
  const classes = useStyles();
  const { isOpen, } = useSidebar();
  const { isAuthorized, health, } = useAuth();

  return (
    <div className="sidebar">
      <CssBaseline/>
      <NavBar />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
        PaperProps={{
          sx: {
            height: 'calc(100% - 65px)',
            top: 65,
          },
        }}
      >
        <List style={{ paddingTop: 0, }}>
          {
            SideBarElements.filter((el) => isAuthorized(el.permission)).map((element) => (
              <NavLink
                key={element.text}
                to={element.path}
                className={({ isActive, }) => isActive ? classes.selectedItem : classes.item}
              >
                <ListItem>
                  <ListItemIcon style={{ color: 'inherit', }}>
                    <element.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={element.text}
                  />
                </ListItem>
              </NavLink>
            ))
          }
        </List>
        {!isOpen ? (
          <div className={classes.footerDiv}>
            <Link href={Constants.rosettaDocumentationURL} target="_blank" underline="none">
              <InfoOutlined color="action"/>
            </Link>
          </div>
        ) : (
          <div className={classes.footerDiv}>
            <Typography style={{ fontWeight: 600, }}>Rosetta Enterprise
              <Link href={'/about'} className={classes.versionStyle}>
                {health.version || ''}
              </Link>
            </Typography>
            { health.license?.licenseType === 'Trial' &&
              (
                <Typography
                  style={{
                    fontWeight: 200,
                    fontSize: '13px',
                    color: '#5b5b5b',
                  }}
                >
                  {health.license?.licenseType}
                </Typography>
              )
            }
            <Link href={Constants.rosettaDocumentationURL} target="_blank" underline="none">
              Documentation
            </Link>
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default Sidebar;
