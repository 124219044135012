import React from 'react';
import { UsersTable, } from './UsersTable';
import { AdminDashboard, } from './AdminDashboard';

const SuperAdmin = () => {
  return (
    <div>
      <AdminDashboard />
      <UsersTable />
    </div>
  );
};

export { SuperAdmin, };
