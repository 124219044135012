import React from 'react';
import {
  CircularProgress,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Delete,
  Edit,
} from '@mui/icons-material';
import { BusinessTag, } from '../../../config/interfaces';
import { useNavigate, } from 'react-router-dom';
import { tagsServices, } from '../../../services';
import { helpers, } from '../../../utils';
import { useSnackbar, } from 'notistack';
import { useAuth, } from '../../../context';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation';
import {
  DropDownMenu,
  MenuItemType,
} from '../../Reusable/DropDownMenu';
import { formatDate, } from '../../../config/helpers';

type Props = {
  tag: BusinessTag;
  index: number;
  fetchTags: () => void;
}

const TagsTableRow: React.FC<Props> = ({ tag, index, fetchTags, }) => {
  const navigate = useNavigate();
  const { isAuthorized, } = useAuth();

  const { enqueueSnackbar, } = useSnackbar();

  const [loading, setLoading,] = React.useState<boolean>(false);
  const [deleteModal, setDeleteModal,] = React.useState<boolean>(false);

  const adminAccess = isAuthorized('ADAPTIVE_ADMIN');
  const readAccess = isAuthorized('ADAPTIVE_TAG_READ');
  const writeAccess = isAuthorized('ADAPTIVE_TAG_WRITE');

  const activateBusinessTag = async () => {
    setLoading(true);
    try {
      await tagsServices.activateBusinessTag(tag.id);
      await fetchTags();
      enqueueSnackbar('Tag activated successfully', { variant: 'success', });
    } catch (error) {
      enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
    } finally {
      setLoading(false);
    }
  };

  const deActivateBusinessTag = async () => {
    setLoading(true);
    try {
      await tagsServices.deActivateBusinessTag(tag.id);
      enqueueSnackbar('Tag deactivated successfully', { variant: 'success', });
      await fetchTags();
    } catch (error) {
      enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
    } finally {
      setLoading(false);
    }
  };

  const deleteTag = async () => {
    setLoading(true);
    try {
      await tagsServices.deleteTag(tag.id);
      enqueueSnackbar('Tag deleted successfully', { variant: 'success', });
      await fetchTags();
    } catch (error) {
      enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
    } finally {
      setLoading(false);
    }
  };

  const menuItems: MenuItemType[] = React.useMemo(() => {
    const items: MenuItemType[] = [];
    if (readAccess && writeAccess) {
      items.push({
        text: 'Edit',
        icon: <Edit />,
        onClick: () => navigate(`/tag/edit/${tag.id}`),
      });
    }
    if (adminAccess) {
      items.push({
        text: 'Delete',
        icon: loading ? (
          <CircularProgress
            color="inherit"
            size={'1rem'} />
        ) : <Delete />,
        onClick: () => setDeleteModal(true),
      });
    }
    return items;
  }, [readAccess, writeAccess, adminAccess, loading,]);

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={tag.name}
      className={'tags-table-row'}
    >
      <TableCell padding="checkbox">
        <Typography variant="subtitle2" align="center">
          {index + 1}
        </Typography>
      </TableCell>
      <TableCell align="left">{tag.name}</TableCell>
      <TableCell align="left">{tag.collection}</TableCell>
      <TableCell align="left">
        {tag.matchingPatterns}
      </TableCell>
      <TableCell align="left">{tag.rules?.map((rule) => rule.name).join(', ')}</TableCell>
      <TableCell align="left">{formatDate(tag.createdAt)}</TableCell>
      <TableCell align="left">{formatDate(tag.updatedAt)}</TableCell>
      <TableCell align="left">
        <Tooltip title={ tag.isActive ? 'Deactivate' : 'Activate'}>
          <Switch
            color='primary'
            checked={tag.isActive}
            disabled={loading}
            onClick={async () => {
              if (tag.isActive) {
                await deActivateBusinessTag();
                return;
              }
              await activateBusinessTag();
            }}
          />
        </Tooltip>
        <DropDownMenu items={menuItems} />
      </TableCell>
      {deleteModal && (
        <ModalConfirmation
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          title={'Are you sure you want to delete the tag?'}
          onBtnClick={() => deleteTag()}
          colorBtn={'secondary'}
          btnText={'Delete'}
          onCancelBtnClick={() => setDeleteModal(false)}
        />
      )}
    </TableRow>
  );
};

export { TagsTableRow, };
