import React from 'react';
import {
  Typography,
  Toolbar,
  Tooltip,
  Grid,
} from '@mui/material';
import { StyledTextField, } from '../../Reusable';

interface EnhancedTableToolbarProps {
  // eslint-disable-next-line no-unused-vars
  handleSearch: (event: any) => void;
}

const EnhancedTableToolbar = ({ handleSearch, }: EnhancedTableToolbarProps)  => {
  return (
    <div>
      <Toolbar>
        <Grid container>
          <Grid item xs={2}>
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Schedules
            </Typography>
          </Grid>
        </Grid>
        <Tooltip title="Search by name">
          <StyledTextField
            className="align-items-end"
            size={'small'}
            name="search"
            label="Search"
            color="primary"
            onChange={(event) => {
              handleSearch(event);
            }}
          />
        </Tooltip>
      </Toolbar>
    </div>
  );
};

export { EnhancedTableToolbar, };
