import {
  createStyles,
  makeStyles,
} from '@mui/styles';
const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      marginBottom: 10,
      marginTop: 10,
    },
    headerName: {
      marginTop: 4,
    },
    formControl: {
      width: 350,
    },
  })
);

export { useStyles, };
