import React, { useState, } from 'react';
import {
  CircularProgress,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, } from '@mui/icons-material';
import { Interfaces, } from '../../../config';
import { useSnackbar, } from 'notistack';
import { scheduleServices, } from '../../../services';
import { helpers, } from '../../../utils';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation';
import {
  DropDownMenu,
  MenuItemType,
} from '../../Reusable/DropDownMenu';
import { formatDate, } from '../../../config/helpers';

interface TableRowScheduleProps {
  schedule: Interfaces.CatalogScheduleJob;
  index: number;
  writeAccess: boolean;
  adminAccess: boolean;
  fetchSchedules: () => void;
}


const TableRowSchedule = ({ schedule,
  index,
  writeAccess,
  adminAccess,
  fetchSchedules, } : TableRowScheduleProps) => {
  const { enqueueSnackbar, } = useSnackbar();
  const [loadingOneSchedule, setLoadingOneSchedule,] = useState<boolean>(false);
  const [selectedSchedule, setSelectedSchedule,] = useState<Interfaces.CatalogScheduleJob | null>(null);

  const deleteSchedule = (catalogId: string) => {
    setLoadingOneSchedule(true);
    scheduleServices.deleteSchedule(catalogId)
      .then(() => {
        enqueueSnackbar('Schedule deleted successfully', { variant: 'success', });
        fetchSchedules();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingOneSchedule(false);
      });
  };

  const deactivateSchedule = (catalogId: string) => {
    setLoadingOneSchedule(true);
    scheduleServices.deactivateSchedule(catalogId)
      .then(() => {
        enqueueSnackbar('Schedule deactivated successfully', { variant: 'success', });
        fetchSchedules();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingOneSchedule(false);
      });
  };

  const activateSchedule = (catalogId: string) => {
    setLoadingOneSchedule(true);
    scheduleServices.activateSchedule(catalogId)
      .then(() => {
        enqueueSnackbar('Schedule activated successfully', { variant: 'success', });
        fetchSchedules();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingOneSchedule(false);
      });
  };

  const menuItems: MenuItemType[] = React.useMemo(() => {
    const items: MenuItemType[] = [];

    if (!loadingOneSchedule) {
      items.push({
        text: 'Delete',
        icon: loadingOneSchedule ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <Delete />
        ),
        onClick: () => {
          setSelectedSchedule(schedule);
        },
      });
    }

    return items;
  }, [loadingOneSchedule, schedule, setSelectedSchedule,]);

  const deleteAction = () => {
    if (loadingOneSchedule) { return; }
    selectedSchedule && deleteSchedule(selectedSchedule.id);
    setSelectedSchedule(null);
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={schedule.id}
      >
        <TableCell padding="checkbox">
          <Typography variant="subtitle2" align="center">
            {index + 1}
          </Typography>
        </TableCell>
        <TableCell align="left">
          {schedule.inputCatalogMetadata?.name}
        </TableCell>
        <TableCell align="left">
          {schedule.cronExpression}
        </TableCell>
        <TableCell align="left">{formatDate(schedule.inputCatalogMetadata?.lastRun)}</TableCell>
        <TableCell align="left">
          {formatDate(schedule.nextRun)}
        </TableCell>
        <TableCell>
          <div>
            {writeAccess && (
              <Tooltip title={schedule.active ? 'Deactivate' : 'Activate'}>
                <Switch
                  color='primary'
                  checked={schedule.active}
                  onClick={() => {
                    if (schedule.active) {
                      deactivateSchedule(schedule.id);
                    } else {
                      activateSchedule(schedule.id);
                    }
                  }}
                />
              </Tooltip>
            )}
            {adminAccess && (
              <DropDownMenu items={menuItems} />
            )}
          </div>
        </TableCell>
      </TableRow>
      <ModalConfirmation
        open={!!selectedSchedule}
        onClose={() => setSelectedSchedule(null)}
        title={'Are you sure you want to delete the Schedule?'}
        onBtnClick={() => deleteAction()}
        colorBtn={'secondary'}
        btnText={'Delete'}
        onCancelBtnClick={() => setSelectedSchedule(null)} />
    </React.Fragment>
  );
};

export default TableRowSchedule;
