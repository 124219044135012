import React, {
  useEffect,
  useState,
} from 'react';
import { Grid, } from '@mui/material';
import { CatalogForm, } from './CatalogForm';
import LoadingComponent from '../../Loading';
import { useParams, } from 'react-router-dom';
import {
  Constants,
  Interfaces,
} from '../../../config';
import {
  catalogsServices,
  datasourceServices,
} from '../../../services';
import { AxiosResponse, } from 'axios';
import { helpers, } from './helpers';
import { useSnackbar, } from 'notistack';
import { HeaderTabs, } from './MainHeader';
import { RunHistoryTable, } from './RunHistory';
import { ScheduleForm, } from './ScheduleForm';
import { DBDiagram, } from './ERDiagram';

type Params = {
  catalogId?: string;
  tab?: string;
}

type DataSourceType =
  Interfaces.BaseDataSource |
  Interfaces.JDBCDataSource |
  Interfaces.GoogleBucketDataSource |
  Interfaces.S3DataSource |
  Interfaces.AzureDataSource

const AddCatalog = () => {
  const { catalogId, tab, } = useParams<Params>();
  const { enqueueSnackbar, } = useSnackbar();

  const [activeStep, setActiveStep,] = useState(1);

  const [dataSources, setDataSources,] = useState<Array<DataSourceType>>([]);
  const [selectedDataSource, setSelectedDataSource,] = useState<DataSourceType | null>(null);
  const [schemaError, setSchemaError,] = useState(false);

  const [catalog, setCatalog,] = useState<Interfaces.InputCatalogMetadata>({
    includeAllSchemas: true,
    autoSelectAllFields: true,
  } as Interfaces.InputCatalogMetadata);
  const [isLoading, setIsLoading,] = useState(false);
  const [loadingSchema, setLoadingSchema,] = useState(false);

  const [totalRecords, setTotalRecords,] = useState<any>(null);
  const [loadingTotalRecords, setLoadingTotalRecords,] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    datasourceServices.fetchDataSources()
      .then((response: AxiosResponse<Array<DataSourceType>>) => {
        setDataSources(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  }, []);

  useEffect(() => {
    if (catalogId) {
      catalogsServices.fetchCatalogById(catalogId)
        .then((response: AxiosResponse<Interfaces.InputCatalogMetadata>) => {
          setCatalog(response.data);
          const dataSource = dataSources.find((_dataSource) => _dataSource.id === response.data.dataSourceId);
          if (dataSource) {
            setSelectedDataSource(dataSource);
          }
          if (tab) {
            setActiveStep(parseInt(tab));
          }
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

  }, [dataSources,]);

  useEffect(() => {
    const abortController = new AbortController();

    if (selectedDataSource) {
      setTotalRecords(null);
      setLoadingSchema(true);
      catalogsServices.fetchSchema(selectedDataSource.name, abortController.signal)
        .then((response: AxiosResponse<Interfaces.Schema>) => {
          const _schema = helpers.addMissingFieldsToSchema(
            response.data,
            '',
            (!(catalog && !catalog.includeAllSchemas))
          );
          if (catalog.schema) {
            helpers.mapNewSchemaToExistingSchema(_schema, catalog.schema);
          }
          let _schedule = catalog.schedule ? catalog.schedule : null;
          if (!_schedule && tab && parseInt(tab) === 2) {
            _schedule = Constants.states.initialSchedule;
          }
          setCatalog({
            ...catalog,
            schema: _schema,
            dataSourceId: selectedDataSource.id,
            dataSourceName: selectedDataSource.name,
            schedule: _schedule,
          });
          setSchemaError(false);
        })
        .catch((error) => {
          setSchemaError(true);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => {
          setIsLoading(false);
          setLoadingSchema(false);
        });
    }
    else {
      setIsLoading(false);
    }
    return () => {
      abortController.abort();
    };
  }, [selectedDataSource,]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Grid item xs={12}>
        <HeaderTabs
          catalog={catalog}
          activeStep={activeStep}
          setActiveStep={(step) => setActiveStep(step)}
          selectedDataSource={selectedDataSource}
          schemaError={schemaError}
          loadingSchema={loadingSchema}
          inEditMode={!!catalogId}
        />
      </Grid>
      {
        activeStep == 1 && (
          <CatalogForm
            catalog={catalog}
            setCatalog={(catalog) => setCatalog(catalog)}
            inEditMode={!!catalogId}
            loadingSchema={loadingSchema}
            schemaError={schemaError}
            dataSources={dataSources}
            selectedDataSource={selectedDataSource}
            totalRecords={totalRecords}
            loadingTotalRecords={loadingTotalRecords}
            fetchTotalRecords={(selectedNode?: Interfaces.Schema) => {
              setLoadingTotalRecords(true);
              let tmpSchema: Interfaces.Schema = JSON.parse(JSON.stringify(catalog.schema));
              if (selectedNode) {
                helpers.setNodeSelectionTree(tmpSchema, tmpSchema, false);
                helpers.setNodeSelectionTree(tmpSchema, selectedNode, true);
                helpers.setReverseSelection(tmpSchema, selectedNode);
                tmpSchema = helpers.extractSelectedOnlyFields(tmpSchema);
              } else {
                if (!catalog.includeAllSchemas) {
                  tmpSchema = helpers.extractSelectedOnlyFields(tmpSchema);
                }
              }
              const tmpCatalog = {
                ...catalog,
                schema: helpers.cleanseSchema(tmpSchema),
              };
              selectedDataSource && catalogsServices.fetchTotalRecords(tmpCatalog, selectedDataSource.name)
                .then((response: AxiosResponse<{ data: any }>) => {
                  const _totalRecords = response.data.data;
                  helpers.mapTotalRecordsToSchema(catalog.schema, _totalRecords);
                  setTotalRecords(Object.keys(_totalRecords).map((key) => ({
                    name: key,
                    totalRecords: _totalRecords[key].totalRecords,
                    sizeInBytes: _totalRecords[key].sizeInBytes,
                  })));
                })
                .catch((error) => enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', }))
                .finally(() => {
                  setLoadingTotalRecords(false);
                });
            }}
            setSelectedDataSource={(id) => {
              const dataSource = dataSources.find((_dataSource) => _dataSource.id === id);
              if (dataSource) {
                setSelectedDataSource(dataSource);
              }
            }}
            setFieldSelectionMode={(value) => {
              setCatalog({
                ...catalog,
                includeAllSchemas: value,
                autoSelectAllFields: true,
                schema: {
                  ...catalog.schema,
                  properties: {
                    ...catalog.schema.properties,
                    includeAllFields: value,
                  },
                },
              });
            }}
            handleSelectAllFields={() => {
              helpers.setNodeSelectionTree(catalog.schema, catalog.schema, !catalog.autoSelectAllFields);
              setCatalog({
                ...catalog,
                autoSelectAllFields: !catalog.autoSelectAllFields,
              });
            }}
            isBucket={false}
          />
        )
      }
      {
        activeStep == 2 && catalog.schema && (
          <DBDiagram catalog={catalog} />
        )
      }
      {activeStep == 3 && (
        <ScheduleForm
          catalog={catalog}
          setCatalog={setCatalog}
          inEditMode={!!catalogId}
          loadingSchema={loadingSchema}
          schemaError={schemaError}
        />
      )}
      {activeStep == 4 && catalogId && <RunHistoryTable catalogId={catalogId} />}
    </>
  );
};

export { AddCatalog, };
