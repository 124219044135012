import {
  AppBar,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  AccountCircle,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { NotificationsList, } from './Notifications';
import React, { useState, } from 'react';
import { useStyles, } from './styles';
import Rosetta from '../../assets/icons/RosettaDB.svg';
import {
  useAuth,
  useSidebar,
} from '../../context';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

const getPageTitle = (location: string) => {
  const routes: any = {
    '/': '',
    '/search': 'Metadata Search',
    '/catalogs': 'Catalogs',
    '/tags': 'Business Tags',
    '/schedules': 'Schedules',
    '/schema-evolution': 'Schema Evolution',
    '/data-sources': 'Data Sources',
    '/catalog/add': 'Select Connection',
    '/tag/add': 'Add Tag',
    '/data-source/jdbc': 'JDBC Connection',
    '/data-source/file': 'File Connection',
    '/data-source/azure': 'Azure Connection',
    '/data-source/ftp': 'FTP Connection',
    '/data-source/google-bucket': 'Google Bucket Connection',
    '/data-source/s3': 'S3 Connection',
    '/data-source/add': 'Select Data Source Type',
    '/login': 'Authentication',
    '/account': 'Account Details',
    '/about': 'About',
    '/data-transfer': 'Data Transfer',
    '/select-catalog': 'Select Catalog',
    '/admin': 'Admin Panel',
    '/git-config/add': 'Add Git Config',
    '/models': 'Models',
    '/models/add': 'Add Model',
    '/alerts': 'Alerts',
    '/alerts/add': 'Add Alert',
    '/alert/histories': 'Alert Histories',
  };

  switch (true) {
  case location.includes('/catalog/add/'): {
    return 'Add Catalog';
  }
  case location.includes('/search/details'): {
    return <Typography variant="h6" style={{ color: 'black',
      fontWeight: 'bold', }}>Metadata Search/{<Link style={{ cursor: 'pointer', }} variant='inherit' onClick={() => history.back()}> Results </Link>}/Details</Typography>;
  }
  case location.includes('/search/'): {
    return 'Metadata Search/Results';
  }
  case location.includes('/catalog/edit/'): {
    return 'Edit Catalog';
  }
  case location.includes('/schema-evolution/'): {
    return 'Schema Evolution';
  }
  case location.includes('/data-sources/sql/'): {
    return `Datasource: ${location.split('/').pop()}`;
  }
  case location.includes('/sql'): {
    return 'SQL Explorer';
  }
  case location.includes('/tag/edit/'): {
    return 'Edit Tag';
  }
  case location.includes('/data-source/edit/'): {
    return 'Edit Data Source';
  }
  case location.includes('/git-config/edit/'): {
    return 'Edit Git Config';
  }
  case location.includes('/alerts/add/'): {
    return 'Edit Alert';
  }
  case location.includes('/alerts/report/'): {
    return 'Alert Detail Report';
  }
  default: {
    return routes[location];
  }
  }
};

const NavBar = () => {
  const classes = useStyles();
  const { isOpen, setIsOpen, } = useSidebar();
  const [anchorEl, setAnchorEl,] = useState<null | HTMLElement>(null);
  const { isAuthorized, logout, health, deploymentType, } = useAuth();
  const [readAlerts,] = React.useState(isAuthorized('ADAPTIVE_ALERT_READ'));
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AppBar position="fixed" style={{ boxShadow: 'none', }}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          color="default"
          aria-label="open drawer"
          onClick={() => setIsOpen(!isOpen)}
          edge="start"
          style={{ marginLeft: -15, }}
        >
          <MenuIcon />
        </IconButton>
        <img
          src={Rosetta}
          alt={'Rosetta Logo'}
          onClick={() => {
            navigate('/');
          }}
          className={classes.rosettaLogo}
        />
        <Typography className={classes.location}  variant="h6" style={{ color: '#6b6b6b',
          marginLeft: '13px', }}>
          {getPageTitle(location.pathname)}
        </Typography>
        <Box sx={{ flexGrow: 1, }}/>
        <Box sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}>
          <div>
            {readAlerts && (
              <NotificationsList readAlerts={readAlerts} />
            )}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              color="primary"
            >
              <AccountCircle fontSize="large"/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorPosition={{
                top: 63,
                left: 2400,
              }}
              anchorReference="anchorPosition"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {
                deploymentType === 'local' && (
                  <MenuItem
                    onClick={() => {
                      navigate('/about');
                      setAnchorEl(null);
                    }}
                  >
                    About
                  </MenuItem>
                )
              }
              <MenuItem
                onClick={() => {
                  navigate('/admin');
                  setAnchorEl(null);
                }}
              >
                Admin Panel
              </MenuItem>
              {
                health.isAuthenticationEnabled && (
                  <MenuItem
                    onClick={() => {
                      logout();
                      navigate('/');
                    }}
                  >
                    Logout
                  </MenuItem>
                )
              }
            </Menu>
          </div>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export { NavBar, };
