import React, { useEffect, } from 'react';
import {
  Checkbox,
  Tooltip,
} from '@mui/material';
import {
  checkBox,
  checkBoxDisabled,
  useStyles,
} from './styles';
import {
  Label,
  LocalOffer,
  VpnKey,
} from '@mui/icons-material';
import { Interfaces, } from '../../config';
import { Icon, } from '../Icon';

interface Props {
  dataToRender: Interfaces.Schema;
  // eslint-disable-next-line no-unused-vars
  onTreeNodeClick?: (node: Interfaces.Schema) => void;
  showCheckBox?: boolean;
  checkBoxReadOnly?: boolean;
  checkBoxDefaultState?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelectChange?: (node: Interfaces.Schema, state: boolean) => void;
  minimal?: boolean;
  selectedTreeNode?: string;
  treeNode: Interfaces.Schema;
}

function DataRepresentation(props: Props) {
  const [availableWidth, setAvailableWidth,] = React.useState<number>(0);
  const refValue = React.useRef<HTMLDivElement>(null);
  const {
    treeNode,
    onTreeNodeClick,
    showCheckBox,
    checkBoxReadOnly,
    checkBoxDefaultState,
    onSelectChange,
    minimal,
    selectedTreeNode,
  } = props;

  useEffect(() => {
    if (refValue.current) {
      setAvailableWidth(refValue.current.clientWidth - 10);
    }
  }, [refValue,]);

  const classes = useStyles();
  const containsFields = Array.isArray(treeNode.fields);

  const _selected =
    checkBoxDefaultState !== undefined ?
      checkBoxDefaultState : treeNode._selected;

  const treeItemClassName =
    minimal
      ? classes.minimalTreeItemStyle
      : selectedTreeNode === treeNode._uid
        ? classes.selectedTreeItemStyle
        : classes.treeItemStyle;

  const treeTextClassName =
    treeNode.isNew
      ? classes.greenTreeItemStyle
      : treeNode.isRemoved
        ? classes.redTreeItemStyle
        : treeNode.isChanged
          ? classes.orangeTreeItemStyle
          : classes.itemTextInner;


  const hasTags = (treeNode: any, totalTags = 0) => {
    const hasFields = !!treeNode.fields;
    if (hasFields) {
      treeNode.fields.map((field: any) => totalTags += hasTags(field, totalTags));
    }
    return totalTags + treeNode?.tags?.length || 0;
  };

  const hasLabels = (treeNode: any, totalLabels = 0) => {
    const hasFields = !!treeNode.fields;
    if (hasFields) {
      treeNode.fields.map((field: any) => totalLabels += hasLabels(field, totalLabels));
    }
    return totalLabels + treeNode?.labels?.length || 0;
  };

  const calculateColor = (treeNode: any) => {
    let color = '';
    if (treeNode.sourceType === 'database') {
      color = '#1E5CAE';
    }
    if (treeNode.sourceType === 'schema') {
      color = '#29913A';
    }
    if (treeNode.sourceType === 'table') {
      color = '#D67E0A';
    }
    if (!treeNode.fields) {
      // color = '#29913A';
      color = '#1E5CAE';
    }
    return color;
  };

  return (
    <div
      onClick={() => onTreeNodeClick && onTreeNodeClick(treeNode)}
      className={treeItemClassName}
    >
      <div style={{ flex: 1, }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          {treeNode.isNew && <span className={treeTextClassName}>+</span>}
          {treeNode.isRemoved && <span className={treeTextClassName}>-</span>}
          {
            showCheckBox
            && <Checkbox
              style={
                checkBoxReadOnly
                  ? checkBoxDisabled
                  : checkBox
              }
              size={'small'}
              checked={_selected}
              disabled={checkBoxReadOnly}
              onChange={(e) => onSelectChange && onSelectChange(treeNode, e.target.checked)}
              onClick={(e) => e.stopPropagation()}
            />
          }
          <Icon
            type={treeNode.sourceType}
            style={{ color: calculateColor(treeNode), }}
            className={
              minimal
                ? classes.minimalSchemaTypeIcon
                : classes.schemaTypeIcon
            }
          />
          <div className={classes.itemText} title={treeNode.name} ref={refValue}>
            <div className={treeTextClassName}  style={{ width: `${availableWidth}px`, }}>
              {treeNode.name}
            </div>
          </div>
        </div>
      </div>
      {
        !minimal
        && <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
          {
            hasLabels(treeNode) > 0
            && <Label className={classes.tagAndLabelIcon} />
          }
          {
            hasTags(treeNode) > 0
            && (
              treeNode.properties?.metadata_check?.pii_type ?
                <Tooltip
                  title={'auto tag'}
                  placement={'top'}
                >
                  <LocalOffer className={classes.tagAndLabelIconAuto} />
                </Tooltip> :
                <LocalOffer className={classes.tagAndLabelIcon} />
            )
          }
          {
            containsFields && (
              <div className={classes.fieldsCount}>
                <p className={classes.fieldsCountText}>
                  {treeNode.fields.length}
                </p>
              </div>
            )
          }
          {
            treeNode.properties?.foreignKeys?.length > 0 && (
              <Tooltip
                placement={'right'}
                title={`${treeNode.properties.foreignKeys[0].primaryTableName}.${treeNode.properties.foreignKeys[0].primaryColumnName}`}
              >
                <VpnKey className={classes.foreignKeyIcon} />
              </Tooltip>
            )
          }
        </div>
      }
    </div>
  );
}

export { DataRepresentation, };
