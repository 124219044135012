import * as Interfaces from '../config/interfaces';
import { axiosClient, } from '../config/helpers';

export const authenticationServices = {
  authenticate,
  verifyEmail,
};

function authenticate(credentials: Interfaces.Credentials) {
  return axiosClient().post('auth', {}, {
    headers: {
      'Authorization': `Basic ${window.btoa(credentials.username + ':' + credentials.password)}`,
    },
  });
}

function verifyEmail(token: string) {
  return axiosClient().get(`auth/activate/${token}`);
}
