import React from 'react';
import { useStyles, } from './styles';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { AccountCircle, } from '@mui/icons-material';
import { useAuth, } from '../../context';
import { passwordServices, } from '../../services';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../utils';
import { CheckEmail, } from './CheckEmail';
import { ResetPassword, } from './ResetPassword';

const ForgottenPassword = ({ backToLogin, } : {backToLogin: () => void;}) => {
  const classes = useStyles();
  const { health, } = useAuth();
  const [email, setEmail,] = React.useState('');
  const [checkEmail, setCheckEmail,] = React.useState(false);
  const [loading, setLoading,] = React.useState(false);
  const { enqueueSnackbar, } = useSnackbar();

  const handleForgotPassword = async () => {
    setLoading(true);
    passwordServices.forgotPassword({
      email,
    })
      .then(() => {
        setLoading(false);
        setCheckEmail(true);
        enqueueSnackbar('Email has been sent', { variant: 'success', });
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  if (location.pathname.includes('/password-reset')) {
    return <ResetPassword/>;
  }

  if (checkEmail) {
    return (
      <CheckEmail title="Password reset request sent successfully" subtitle="Check your email for confirmation" />
    );
  }

  return (
    <Box
      alignItems="center"
      justifyContent="center"
    >
      <form onSubmit={(e) => {
        e.preventDefault();
        handleForgotPassword();
      }}>
        <TextField
          fullWidth
          placeholder="Email adrress"
          margin="normal"
          variant="outlined"
          size="medium"
          name="email"
          type="text"
          onChange={({ target, }) => setEmail(target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle/>
              </InputAdornment>
            ),
          }}
        />
        <Button
          fullWidth
          color="primary"
          type="submit"
          variant="contained"
          className={classes.button}
          disabled={loading}
        >
            Send Password Reset
        </Button>
      </form>
      <Typography
        style={{
          textAlignLast: 'center',
        }}
      >
          Remember your password? {' '}
        <p
          style={{
            color: 'blue',
            textDecoration: 'underline',
            display: 'inline-flex',
            cursor: 'pointer',

          }}
          onClick={() => backToLogin()}
        >
            Log in
        </p>
      </Typography>
      <div style={{ textAlignLast: 'center',
        marginTop: 10, }}>
        <span style={{ fontWeight: 'bold',
          color: 'gray',  }}>
          {'v'+ health.version || ''}
        </span>
      </div>
    </Box>
  );
};

export { ForgottenPassword, };
