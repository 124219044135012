import React from 'react';
import * as interfaces from '../../config/interfaces';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/ext-error_marker';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import styles from './Styles';
import {
  Delete,
  ExpandMore,
  InfoOutlined,
} from '@mui/icons-material';
import { StyledTextField, } from '../Reusable';

interface Props {
  staticRules: Array<interfaces.TagRule>;
  newBusinessTag: interfaces.NewBusinessTag;
  handleRuleSelect: any;
  newCustomRule: interfaces.TagRule;
  handleCustomRuleChange: any;
  addCustomRule: any;
}


function Rules(props: Props) {
  const classes = styles();
  const {
    newBusinessTag,
    handleRuleSelect,
    newCustomRule,
    handleCustomRuleChange,
    addCustomRule,
  } = props;

  const renderSelectedRules = () => (
    <div className={classes.selectedRules}>
      {
        newBusinessTag.rules?.map((rule) => (
          <Accordion key={rule.name}>
            <AccordionSummary
              expandIcon={<ExpandMore/>}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <Delete
                className={classes.rulesFormDeleteButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRuleSelect(rule);
                }}/>
              <Typography variant="subtitle2">{`Name: ${rule.name}`}</Typography>

            </AccordionSummary>
            {
              rule.code && (
                <AccordionDetails>
                  <AceEditor
                    mode="javascript"
                    theme="solarized_dark"
                    width='100%'
                    fontSize={14}
                    value={rule.code}
                    readOnly
                    height="150px"
                    setOptions={{
                      useWorker: false,
                    }}
                  />
                </AccordionDetails>
              )
            }
          </Accordion>
        ))
      }
    </div>
  );

  const renderCustomRuleForm = () => (
    <div>
      <Grid container direction='row' alignItems='center'>
        <Grid item xs={11}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            label="Rule name"
            variant="outlined"
            fullWidth
            name="name"
            value={newCustomRule.name}
            onChange={handleCustomRuleChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip style={{ marginLeft: 10, }} title="Custom rule that will apply to the tagged fields." placement="top">
            <InfoOutlined fontSize="small"/>
          </Tooltip>
        </Grid>
      </Grid>
      <AceEditor
        name="code"
        mode="javascript"
        onChange={(e) => handleCustomRuleChange(e, 'code')}
        width="inherit"
        height="250px"
        theme="solarized_dark"
        value={newCustomRule.code}
        setOptions={{
          fontSize: 14,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          showGutter: true,
          showPrintMargin: true,
          highlightActiveLine: true,
          showLineNumbers: true,
          tabSize: 2,
          newLineMode: true,
          useWorker: false,
        }}
      />
      <Grid container justifyContent="flex-end">
        <Button
          size="small"
          style={{ margin: '10px 0', }}
          variant="contained"
          color="primary"
          disabled={newCustomRule.name === '' || newCustomRule.code === ''}
          onClick={addCustomRule}
        >
          Add Rule
        </Button>
      </Grid>
    </div>
  );


  return (
    <div className={classes.rulesForm}>
      {renderCustomRuleForm()}
      <Typography>Rules:</Typography>
      {renderSelectedRules()}
      <br/>
      <FormControl size="small" fullWidth />
    </div>
  );
}

export { Rules, };
