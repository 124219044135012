import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

const EnhancedTableHead = (props: any) => {
  const headCells = [
    {
      id: 'catalogName',
      numeric: false,
      disablePadding: true,
      label: 'Catalog Name',
    },
    {
      id: 'schedule',
      numeric: true,
      disablePadding: false,
      label: 'Schedule',
    },
    {
      id: 'lastRun',
      numeric: false,
      disablePadding: false,
      label: 'Last Run',
    },
    { id: 'nextRun',
      numeric: false,
      disablePadding: false,
      label: 'Next Run', },
    { id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions', },
  ];

  const createSortHandler = (property: any) => (event: any) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Typography variant="subtitle2" align="center">#</Typography>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={
              props.orderBy === headCell.id ? props.order : false
            }
          >
            <TableSortLabel
              active={props.orderBy === headCell.id}
              direction={
                props.orderBy === headCell.id ? props.order : 'asc'
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {props.orderBy === headCell.id ? (
                <span className={props.classes.visuallyHidden}>
                  {props.order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { EnhancedTableHead, };
