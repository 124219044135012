import React from 'react';
import AceEditor from 'react-ace';
import {
  parse as yamlParser,
  stringify as yamlStringify,
} from 'yaml';
import { Interfaces, } from '../../../config';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import { FileCopy, } from '@mui/icons-material';
import { useSnackbar, } from 'notistack';
import classes from './YAMLEditor.module.css';

type Props = {
  yamlModel: string;
  // eslint-disable-next-line no-unused-vars
  setYamlModel: (value: string) => void;
  selectedTable?: Interfaces.ModelTable;
}

type ParsedYaml = {
  [key:string]: any;
};

const YAMLEditor: React.FC<Props> = ({
  yamlModel,
  setYamlModel,
  selectedTable,
}) => {
  const { enqueueSnackbar, } = useSnackbar();
  const _aceEditor = React.useRef(null);
  const [loading, setIsLoading,] = React.useState<boolean>();
  const [isHeaderExpanded, setIsHeaderExpanded,] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (_aceEditor?.current !== null && selectedTable) {
      const tmpData = yamlModel.split('\n');
      let counter = 0;
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData[i].split(':').map((el) => el.trim()).includes(selectedTable.name) && tmpData[i].includes('name')) {
          if (selectedTable.schema) {
            if (tmpData[i + 1].includes(selectedTable.schema)) {
              counter = i;
              break;
            }
          } else {
            if (!tmpData[i + 1].includes('schema')) {
              counter = i;
              break;
            }
          }
        }
      }

      (_aceEditor.current as any)?.editor?.gotoLine(counter + 1, 0, true);
    }
  }, [selectedTable,]);

  const onChange = async (value: string) => {
    setIsLoading(true);
    try {
      const parsedHeader: ParsedYaml = yamlParser(yamlHeader);
      const parsedBody: ParsedYaml = yamlParser(value);
      setYamlModel(yamlStringify({
        ...parsedHeader,
        database: {
          ...parsedHeader.database,
          tables: parsedBody.tables,
        },
      }));
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (_) {
      enqueueSnackbar('Make sure your YAML config is correct!', { variant: 'error', });
    } finally {
      setIsLoading(false);
    }
  };

  const yamlHeader = React.useMemo(() => {
    try {
      const parsed = yamlParser(yamlModel);
      let header: ParsedYaml = {};
      Object.keys(parsed).forEach((key) => {
        if (key !== 'database') {
          header[key] = parsed[key];
        }
      });
      header.database = {};
      Object.keys(parsed.database).forEach((key) => {
        if (key !== 'tables') {
          header.database[key] = parsed.database[key];
        }
      });
      return yamlStringify(header);
    } catch (_) {
      return '';
    }
  }, [yamlModel,]);

  const yamlBody = React.useMemo(() => {
    try {
      const parsed = yamlParser(yamlModel);
      return yamlStringify({ tables: parsed.database.tables, });
    } catch (e) {
      return yamlModel;
    }
  }, [yamlModel,]);

  return (
    <div style={{ position: 'relative', }}>
      <Accordion
        expanded={isHeaderExpanded}
        onChange={(_, expanded) => setIsHeaderExpanded(expanded)}
        style={{ marginBottom: 10, }}
      >
        <AccordionSummary>
          <div className={classes.accordionHeader}>
            <span className={classes.accordionHeaderText}>Header</span>
            <span className={classes.accordionHeaderTextMuted}>{!isHeaderExpanded && '(Click to expand)'}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ opacity: 0.8, }}>
            <AceEditor
              mode="yaml"
              width="auto"
              fontSize={16}
              height={'100px'}
              style={{
                overflowY: 'auto',
                marginBottom: 4,
              }}
              theme={'tomorrow'}
              value={yamlHeader}
              readOnly
              showGutter={false}
              setOptions={{
                tabSize: 2,
                autoScrollEditorIntoView: true,
                useWorker: false,
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <AceEditor
        ref={_aceEditor}
        mode="yaml"
        width="auto"
        fontSize={16}
        height={isHeaderExpanded ? 'calc(-580px + 100vh)' : 'calc(-440px + 100vh)'}
        theme={'tomorrow'}
        value={yamlBody}
        debounceChangePeriod={700}
        readOnly={loading}
        onChange={(e) => onChange(e)}
        setOptions={{
          tabSize: 2,
          autoScrollEditorIntoView: true,
          useWorker: false,
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: isHeaderExpanded ? 200 : 55,
          right: 25,
          display: 'flex',
        }}
      >
        <Tooltip title={'Copy only tables content'} placement={'top'}>
          <IconButton
            style={{
              marginTop: 6,
              fontWeight: 1000,
            }}
            onClick={() => {
              navigator.clipboard.writeText(yamlBody)
                .then(() => {
                  enqueueSnackbar('Copied to clipboard', { variant: 'info', });
                });
            }}
          >
            <FileCopy className={classes.editorIcon} color={'primary'} />
          </IconButton>
        </Tooltip>
      </div>
      {loading && <div className={classes.loader}><CircularProgress /></div>}
    </div>
  );
};

export { YAMLEditor, };
