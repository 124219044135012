import { axiosClient, } from '../config/helpers';
import { Constants, } from '../config';

export const jobsServices = {
  fetchJobs,
  runJob,
  getSparkCode,
  getScalaCode,
};

function fetchJobs() {
  return axiosClient().get(Constants.api.endpoints.jobs);
}

function runJob(toSend: any) {
  return axiosClient().post(Constants.api.endpoints.dataTransfer, toSend);
}

function getSparkCode( body: any) {
  return axiosClient().post(`${Constants.api.endpoints.dataTransfer}/spark`, body);
}

function getScalaCode( body: any) {
  return axiosClient().post(`${Constants.api.endpoints.dataTransfer}/scala`, body);
}
